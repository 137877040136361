import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private _httputilityService: HttputilityService) {} 
  
  SendMail(ID)
  {
    return this._httputilityService.Get("SendMail?ID=" + ID);
  }

  CreateRequest(requestData) {
    return this._httputilityService.Post('CreateRequest', requestData);
  }

  MyRequests(myRequestsData) {
    return this._httputilityService.Post('GetRequests', myRequestsData);
  }
  GetMyTasks(userID,Pending)
  {
    return this._httputilityService.Get("GetMyTasks?UserID=" + userID + "&Pending=" + Pending);
  }
  GetReleaseHistory(model) {
    return this._httputilityService.Post('GetReleaseHistory', model);
  }
  GetLogs(model) {
    return this._httputilityService.Post('GetLogs', model);
  }
  GetAllRequestsWithAssignee(userID)
  {
  return this._httputilityService.Get("GetAllRequestsWithAssignee?userID=" + userID);
  }

  AddNote(Udata) {
    return this._httputilityService.Post('AddNote', Udata);
  }

  UpdateRequest(Udata) {
    return this._httputilityService.Post('UpdateRequest', Udata);
  }
  GetConfirmation(userID,pending)
  {
  return this._httputilityService.Get("GetConfirmation?userID=" + userID+"&pending="+pending);
  }
  GetConfirmationSeek(userID)
  {
  return this._httputilityService.Get("GetConfirmationSeek?userID=" + userID);
  }
  updateConfirmation(model)
  {
  return this._httputilityService.Post("updateConfirmation",model);
  }
  AddQuestion(model)
  {
  return this._httputilityService.Post("AddQuestion",model);
  }
  addADocuments(model) {
    return this._httputilityService.FormDataPost("AddDocuments", model);
  }
  AddtestCase(model,userId) {
    return this._httputilityService.Post("AddTestCases?UserID=" + userId, model);
  }
  UpdateTestCases(model,userId) {
    return this._httputilityService.Post("UpdateTestCases?UserID=" + userId, model);
  }
  AddAPIList(model,userId) {
    return this._httputilityService.Post("AddAPIList?UserID=" + userId, model);
  }
  addInputNoFile(model) {
    return this._httputilityService.Post("AddInputNoFile", model);
  }
  addInput(model) {
    return this._httputilityService.FormDataPost("AddInput", model);
  }
  addDocuments(model) {
    return this._httputilityService.FormDataPost("AddRequestDocuments", model);
  }
  AddAnswer(model)
  {
    return this._httputilityService.Post("AddAnswer", model);
  }
  UpdateInput(model)
  {
    return this._httputilityService.Post("UpdateInput", model);
  }
  getRequestDetailsByID(requestid){
    return this._httputilityService.Get("GetRequestByID?requestID=" + requestid);
  }
  getRequestByCode(code){
    return this._httputilityService.Get("GetRequestByCode?code=" + code);
  }

  getUserDetailsByGUID(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid);
  }
  GetDates(phaseTasks, da) {
    return this._httputilityService.Post('GetDates?strDate='+ da,phaseTasks);
  }
  SendUAT(model)
  {
    return this._httputilityService.Post("UpdateUAT",model);
  }
  SendProduction(model)
  {
    return this._httputilityService.Post("UpdateProduction",model);
  }
  SendCancel(model)
  {
    return this._httputilityService.Post("UpdateCancel",model);
  }
  SendClose(model)
  {
    return this._httputilityService.Post("UpdateClosed",model);
  }
  CloseRequest(model)
  {
    return this._httputilityService.Post("CloseRequest",model);
  }
  UpdateRequestStatus(RequestID,UserID,status,isternal)
  {
    return this._httputilityService.Get("UpdateRequestStatus?RequestID=" + RequestID+"&UserID="+UserID+"&status="+status+"&isinternal="+isternal);
  }
  UpdateRequestStartDate(RequestID,Type,ChangeDate)
  {
    return this._httputilityService.Get("UpdateRequestStartDate?RequestID=" + RequestID+"&Type="+Type+"&ChangeDate="+ChangeDate);
  }
  UpdateTask(model,updatedby)
  {
    return this._httputilityService.Post("UpdateTask?updatedby="+updatedby,model);
  }
  UpdateRequestData(model)
  {
    return this._httputilityService.Post("UpdateRequestData",model);
  }
  GetToCloseRequest()
  {
    return this._httputilityService.Get("GetToCloseRequest");
  }
  SendLog()
  {
    return this._httputilityService.Get("SendWorkLogMail");
  }
  GetLogRequest(LogDate,userId)
  {
    return this._httputilityService.Get("GetLogRequest?LogDate="+LogDate +"&UserID=" + userId);
  }
  GetRequeforRelease(Date)
  {
    return this._httputilityService.Get("GetRequeforRelease?Date=" + Date);
  }
  GetAsigneeTasks(requestid,userId)
  {
    return this._httputilityService.Get("GetAsigneeTasks?requestID="+requestid+"&userID=" + userId);
  }
  AddLog(model)
  {
    return this._httputilityService.Post("AddLog",model);
  }
  AddRelease(model)
  {
    return this._httputilityService.Post("AddRelease",model);
  }

  getCancelTeamData() {
    return this._httputilityService.Get("Team/GetCancelTeam");
  }

  UpdateCancelTeam(model){
    return this._httputilityService.Post("Team/UpdateCancelTeam", model);
  }

  GetAssgineeTeam(RequestID) {
    return this._httputilityService.Get("Team/GetAssgineeTeam?RequestID="+RequestID);
  }

  UpdateAssigneeTeam(model,RequestID){
    return this._httputilityService.Post("Team/UpdateAssigneeTeam?RequestID="+RequestID, model);
  }
  GetOwnerRequest(userID) {
    return this._httputilityService.Get("GetOwnerRequest?userID="+userID);
  }
}
