import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { MyIssuesService } from "../services/myissues.service";
import { ReportService } from "../services/report.service";
@Injectable({
  providedIn: "root",
})
export class FacadeService {
 
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _myIssuesService: MyIssuesService,
    private _reportService: ReportService
  ) {}

  GetMenusByUser(userId,applicationid){
    return this._dashboardService.GetMenusByUser(userId,applicationid);
  }
  login(model) {
    return this._loginService.login(model);
  }
  
  getDashboardCounts(userId) {
    return this._dashboardService.getDashboardCounts(userId);
  }
  getSupportDashboardCounts(userId) {
    return this._dashboardService.getSupportDashboardCounts(userId);
  }

  CreateSecurityToken(){
    return this._dashboardService.CreateSecurityToken();
  }

  GetInternalChartData(userId) {
    return this._dashboardService.GetInternalChartData(userId);
  }

  getChartData(userId) {
    return this._dashboardService.getChartData(userId);
  }

  getTypeChartData(userId) {
    return this._dashboardService.getTypeChartData(userId);
  }

  getPriorityChartData(userId,Support) {
    return this._dashboardService.getPriorityChartData(userId,Support);
  }

  getDashboardTimeline(userId,type,clientID) {
    return this._dashboardService.getDashboardTimelineData(userId,type,clientID);
  }

  GetDashboardTableData(userId){
    return this._dashboardService.GetDashboardTableData(userId);
  }
  GetSupportDashboardTableData(userId){
    return this._dashboardService.GetSupportDashboardTableData(userId);
  }

  GetUserDetails(userGuid){
    return this._dashboardService.GetUserDetails(userGuid); 
  }

  CreateRequest(requestData) {
    return this._reportService.CreateRequest(requestData);
  }
  SendMail(ID)
  {
    return this._reportService.SendMail(ID);
  }
  MyRequests(myRequestsData) {
    return this._reportService.MyRequests(myRequestsData);
  }
  GetMyTasks(userID,Pending) {
    return this._reportService.GetMyTasks(userID,Pending);
  }
  
  GetLogs(model)
  {
    return this._reportService.GetLogs(model);
  }
  GetReleaseHistory(model)
  {
    return this._reportService.GetReleaseHistory(model);
  }

  GetAllRequestsWithAssignee(userId)
  {
    return this._reportService.GetAllRequestsWithAssignee(userId);
  }
  AddNote(Udata) {
    return this._reportService.AddNote(Udata);
  }

  updateConfirmation(model)
  {
    return this._reportService.updateConfirmation(model);
  }
  AddQuestion(model)
  {
    return this._reportService.AddQuestion(model);
  }

  UpdateRequest(Udata) {
    return this._reportService.UpdateRequest(Udata);
  }

  GetConfirmation(userId, Pending) {
    return this._reportService.GetConfirmation(userId, Pending);
  }
  GetConfirmationSeek(userId) {
    return this._reportService.GetConfirmationSeek(userId);
  }
  
  addADocuments(model) {
    return this._reportService.addADocuments(model);
  }
  addInputNoFile(model) {
    return this._reportService.addInputNoFile(model);
  }
  AddAPIList(model,UserID)
  {
    return this._reportService.AddAPIList(model,UserID);
  }
  AddtestCase(model, userid) {
    return this._reportService.AddtestCase(model, userid);
  }
  UpdateTestCases(model, userid) {
    return this._reportService.UpdateTestCases(model, userid);
  }
  addInput(model) {
    return this._reportService.addInput(model);
  }
  addDocuments(model) {
    return this._reportService.addDocuments(model);
  }
  AddAnswer(model)
  {
    return this._reportService.AddAnswer(model);
  }
  UpdateInput(model)
  {
    return this._reportService.UpdateInput(model);
  }
  getRequestDetailsByGUID(ID) {
    return this._reportService.getRequestDetailsByID(ID);
  }
  getRequestByCode(code) {
    return this._reportService.getRequestByCode(code);
  }

  SendUAT(model)
  {
    return this._reportService.SendUAT(model);
  }

  SendProduction(model)
  {
    return this._reportService.SendProduction(model);
  }
  SendCancel(model)
  {
    return this._reportService.SendCancel(model);
  }
  SendClose(model)
  {
    return this._reportService.SendClose(model);
  }
  CloseRequest(model)
  {
    return this._reportService.CloseRequest(model);
  }
  UpdateRequestStatus(ID,userId,status,isternal)
  {
    return this._reportService.UpdateRequestStatus(ID,userId,status,isternal);
  }
  UpdateRequestStartDate(RequestID,Type,ChangeDate)
  {
    return this._reportService.UpdateRequestStartDate(RequestID,Type,ChangeDate);
  }
  GetDates(phaseTasks, da)
  {
    return this._reportService.GetDates(phaseTasks, da);
  }

  UpdateTask(model,updatedby)
  {
    return this._reportService.UpdateTask(model,updatedby);
  }s
  UpdateRequestData(model)
  {
    return this._reportService.UpdateRequestData(model);
  }
  getUserDetailsByGUID(userGuid) {
    return this._reportService.getUserDetailsByGUID(userGuid);
  }
  GetRequeforRelease(Date)
  {return this._reportService.GetRequeforRelease(Date);

  }
  GetToCloseRequest()
  {
    return this._reportService.GetToCloseRequest();
  }
  SendLog()
  {
    return this._reportService.SendLog();
  }
  GetLogRequest(Date,userGuid) {
    return this._reportService.GetLogRequest(Date,userGuid);
  }
  GetAsigneeTasks(requestid,userGuid) {
    return this._reportService.GetAsigneeTasks(requestid,userGuid);
  }
  AddLog(model)
  {
    return this._reportService.AddLog(model);
  }
  AddRelease(model)
  {
    return this._reportService.AddRelease(model);
  }
  getCancelTeamData() {
    return this._reportService.getCancelTeamData();
  }
  UpdateCancelTeam(model) {
    return this._reportService.UpdateCancelTeam(model);
  }
  getAssigneeTeamData(requestID) {
    return this._reportService.GetAssgineeTeam(requestID);
  }
  UpdateAssigneeTeam(model,requestID) {
    return this._reportService.UpdateAssigneeTeam(model,requestID);
  }
  GetOwnerRequest(userID) {
    return this._reportService.GetOwnerRequest(userID);
  }

  getDepartments() {
    return this._myIssuesService.getDepartments();
  }
  getRolesByDepartmentGuid(departMentGuid) {
    return this._myIssuesService.getRolesByDepartmentGuid(departMentGuid);
  }
  GetUsersByRoleGuid(RoleGUID) {
    return this._myIssuesService.GetUsersByRoleGuid(RoleGUID);
  }
  getRolesData() {
    return this._myIssuesService.getRoles();
  }

  getApplications(){
    var app = this._myIssuesService.getApplications();
    app.subscribe(
      (data) => {
        if(data.returnObject != null && data.returnObject != undefined)
        {
          if(data.returnObject.length > 0)
          {
            var a = {
              "applicationName": "All",
              "applicationId": 0,
              "applicationGuid": "00000000-0000-0000-0000-000000000000",
              "active":"Y",
              "sequence": 0
              }
            data.returnObject.push(a);
          }
        }
      });
    return app;
  }

  getUsers(){
    return this._myIssuesService.getUsers();
  }
  getSupportUsers(){
    return this._myIssuesService.getSupportUsers();
  }
  getAllUsers(){
    return this._myIssuesService.getAllUsers();
  }
  UpdateSecurityToken(token){
    return this._dashboardService.UpdateSecurityToken(token);
  }
  downloadFile(filename,newname){
    return this._dashboardService.downloadFile(filename,newname);
  }
  AddUserTracking(model){
    return this._dashboardService.AddUserTracking(model);
  }
}
