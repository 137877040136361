import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightmenuComponent} from './app.rightmenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {BreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import {LoginComponent} from './components/login/login.component';
import {FacadeService} from './facade/facade.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminComponent } from './components/admin/admin.component';
import {EditorModule} from 'primeng/editor';
import { DatePipe } from '@angular/common';
import { EntrypointComponent } from './components/entrypoint/entrypoint.component';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { AddrequestsComponent } from './components/addrequests/addrequests.component';
import { ReportBytimeComponent } from './components/report-bytime/report-bytime.component';
import { ReportByHistoryComponent } from './components/report-by-history/report-by-history.component';
import { RequestDetailsComponent } from './components/request-details/request-details.component';
import { MyRequestsComponent } from './components/my-requests/my-requests.component';
import { RequestSummaryDetailsComponent } from './components/request-summary-details/request-summary-details.component';
import { SupportDashboardComponent } from './components/support-dashboard/support-dashboard.component';
import { PendingConfirmationRequestsComponent } from './components/pending-confirmation-requests/pending-confirmation-requests.component';
import { PendingConfirmationDetailsRequestComponent } from './components/pending-confirmation-details-request/pending-confirmation-details-request.component';
import { AssignedToMeComponent } from './components/assigned-to-me/assigned-to-me.component';
import { UnAssignedRequestsComponent } from './components/un-assigned-requests/un-assigned-requests.component';
import { UnAssignedDetailsRequestsComponent } from './components/un-assigned-details-requests/un-assigned-details-requests.component';
import { ClientRequestsComponent } from './components/client-requests/client-requests.component';
import { ClientRequestsDetailsComponent } from './components/client-requests-details/client-requests-details.component';
import { ClientRequestDetailsComponent } from './components/client-request-details/client-request-details.component';
import { AddRowDirective } from './components/admin/add-row.directive';
import { DailylogComponent } from './components/dailylog/dailylog.component';
import { DailylogreportComponent } from './components/dailylogreport/dailylogreport.component';
import { ManageassigneeComponent } from './components/manageassignee/manageassignee.component';
import { TokenInterceptor } from "./token.interceptor";
import { ReleasenotesComponent } from './components/releasenotes/releasenotes.component';
import { ReleasenoteshistoryComponent } from './components/releasenoteshistory/releasenoteshistory.component';
import { CloseComponent } from './components/close/close.component';
import { BnNgIdleService } from './bn-ng-idle.service';
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        EditorModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppRightmenuComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        LoginComponent,
        DashboardComponent,
        AdminComponent,
        EntrypointComponent,
        HelpguideComponent,
        AddrequestsComponent,
        ReportBytimeComponent,
        ReportByHistoryComponent,
        RequestDetailsComponent,
        MyRequestsComponent,
        RequestSummaryDetailsComponent,
        SupportDashboardComponent,
        PendingConfirmationRequestsComponent,
        PendingConfirmationDetailsRequestComponent,
        AssignedToMeComponent,
        UnAssignedRequestsComponent,
        UnAssignedDetailsRequestsComponent,
        ClientRequestsComponent,
        ClientRequestsDetailsComponent,
        ClientRequestDetailsComponent,
        AddRowDirective,
        DailylogComponent,
        DailylogreportComponent,
        ManageassigneeComponent,
        ReleasenotesComponent,
        ReleasenoteshistoryComponent,
        CloseComponent,
    ],
    providers: [
       
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        MenuService, BreadcrumbService,FacadeService,DatePipe,BnNgIdleService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
