import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';

@Component({
  selector: 'app-report-by-history',
  templateUrl: './report-by-history.component.html',
  styleUrls: ['./report-by-history.component.scss']
})
export class ReportByHistoryComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  selectedClosedFromDate: any = "";
  selectedClosedToDate : any = "";
  internal:boolean = false;
  internalstatus: any[];
  code:any;
  selectedInternalStatus: any;
  AUsers: any[];
  selectedAssignee: any;
  selectedOwners: any;
  type: SelectItem[];
  selectedtype: any;
  priority: SelectItem[];
  selectedPriority: any;
  status: SelectItem[];
  selectedStatus: any;
  Users: any[];
  selectedUsers: any;
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  Error: string = "";
  
  requestData = [];

  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService,private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;

    if(sessionStorage.getItem('RToDate') != null && sessionStorage.getItem('RToDate') != undefined)
    {
      if(sessionStorage.getItem('RToDate') != "")
        this.selectedToDate = new Date(sessionStorage.getItem('RToDate') );
    }
    if(sessionStorage.getItem('RFromDate') != null && sessionStorage.getItem('RFromDate') != undefined)
    {
      if(sessionStorage.getItem('RFromDate') != "")
        this.selectedFromDate = new Date(sessionStorage.getItem('RFromDate') );
    }
    if(sessionStorage.getItem('RClosedToDate') != null && sessionStorage.getItem('RClosedToDate') != undefined)
    {
      if(sessionStorage.getItem('RClosedToDate') != "")
        this.selectedClosedToDate = new Date(sessionStorage.getItem('RClosedToDate') );
    }
    if(sessionStorage.getItem('RClosedFromDate') != null && sessionStorage.getItem('RClosedFromDate') != undefined)
    {
      if(sessionStorage.getItem('RClosedFromDate') != "")
        this.selectedClosedFromDate = new Date(sessionStorage.getItem('RClosedFromDate') );
    }
    // this.selectedToDate = new Date();
    // let dte = new Date();
    // dte.setMonth(dte.getMonth() - 1);
    // this.selectedFromDate = new Date(dte.toString());

    this.internal = false;
    if(sessionStorage.getItem('RInternal') != null && sessionStorage.getItem('RInternal') != undefined)
    {
      if(sessionStorage.getItem('RInternal') != "")
        this.internal = sessionStorage.getItem('RInternal') == "true"?true:false;
    }
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value: 'Issue' },
      { label: 'Support', value: 'Support' }
    ];
    this.selectedtype = [];
    let ok = false;
    if(sessionStorage.getItem('RType') != null && sessionStorage.getItem('RType') != undefined && sessionStorage.getItem('RType') != "")
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('RType').split(',');
      projectstats.forEach((element) => {
        var a = this.type.find(x=>x.value == element);
        if(a != null)
          this.selectedtype.push(a);
      });
      ok = true;
    }
    if(!ok)
    {
      this.type.forEach(element => {
        this.selectedtype.push(element);
      });
      var a = "";
      this.selectedtype.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RType = a;
    }
    this.priority = [
      { label: 'Critical', value: 'Critical'},
      { label: 'High', value:'High' },
      { label: 'Medium', value: 'Medium'},
      { label: 'Low', value: 'Low' }
    ];
    this.selectedPriority = [];
    ok = false;
    if(sessionStorage.getItem('RPriority') != null && sessionStorage.getItem('RPriority') != undefined && sessionStorage.getItem('RPriority') != "")
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('RPriority').split(',');
      projectstats.forEach((element) => {
        var a = this.priority.find(x=>x.value == element);
        if(a != null)
          this.selectedPriority.push(a);
      });
      ok = true;
    }
    if(!ok)
    {
      this.priority.forEach(element => {
        this.selectedPriority.push(element);
      });
      var a = "";
      this.selectedPriority.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RPriority = a;
    }

    this.status = [
      { label: 'Saved', value: 'Saved' },
      { label: 'Pending Confirmation', value: 'Pending Confirmation' },
      { label: 'Seek Clarification', value: 'Seek Clarification' },
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Closed', value: 'Closed' },
      { label: 'Cancelled', value: 'Cancelled' },
    ];
    this.selectedStatus = [];
    let upcoming = false;
    if(sessionStorage.getItem('upcoming') != null && sessionStorage.getItem('upcoming') != undefined)
    {
      if(sessionStorage.getItem('upcoming') != 'null')
      {
        if(sessionStorage.getItem('upcoming') == 'U')
          upcoming = true;
      }
    }
    ok = false;
   
    if(upcoming)
    { 
      if(sessionStorage.getItem('dashboardstatus') != null && sessionStorage.getItem('dashboardstatus') != undefined)
      {
        if(sessionStorage.getItem('dashboardstatus') != 'null')
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('dashboardstatus').split(',');
          projectstats.forEach((element) => {
            var st = this.status.find(x=>x.value.toLowerCase() == element.toLowerCase());
            if(st != null)
              this.selectedStatus.push(st);
            if(element == "Pending Confirmation")
            {
              var st = this.status.find(x=>x.value.toLowerCase() == "seek clarification");
              if(st != null)
                this.selectedStatus.push(st);
            }
          });
          ok = true;
          sessionStorage.dashboardstatus = null;
          var a = "";
          this.selectedStatus.forEach(element => {
            a = a + element.value + ",";
          });
          sessionStorage.RStatus = a;
        }
      }
    }
    if(!ok)
    {
      if(sessionStorage.getItem('RStatus') != null && sessionStorage.getItem('RStatus') != undefined  && sessionStorage.getItem('RStatus') != "")
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('RStatus').split(',');
        projectstats.forEach((element) => {
          var a = this.status.find(x=>x.value == element);
          if(a != null)
            this.selectedStatus.push(a);
        });
        ok = true;
      }
    }
    if(!ok)
    {
      this.status.forEach(element => {
        if(element.value != "Closed" && element.value != "Cancelled")
          this.selectedStatus.push(element);
      });
      var a = "";
      this.selectedStatus.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RStatus = a;
    }

    this.internalstatus = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'In-Testing', value: 'In-Testing' },
      { label: 'UAT', value: 'UAT' },
      { label: 'UAT-Rework', value: 'UAT-Rework' },
      { label: 'UAT-Bug', value: 'UAT-Bug' },
      { label: 'Production', value: 'Production' },
    ];
    ok = false;
    this.selectedInternalStatus = [];
    
    if(!upcoming)
    {
      if(sessionStorage.getItem('dashboardstatus') != null && sessionStorage.getItem('dashboardstatus') != undefined)
      {
        if(sessionStorage.getItem('dashboardstatus') != 'null')
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('dashboardstatus').split(',');
          projectstats.forEach((element) => {
            var st = this.internalstatus.find(x=>x.value.toLowerCase() == element.toLowerCase());
            if(st != null)
              this.selectedInternalStatus.push(st);
          });
          ok = true;
          sessionStorage.dashboardstatus = null;
          var a = "";
          this.selectedInternalStatus.forEach(element => {
            a = a + element.value + ",";
          });
          sessionStorage.RInternalStatus = a;
        }
      }
    }
    if(!ok)
    {
      if(sessionStorage.getItem('RInternalStatus') != null && sessionStorage.getItem('RInternalStatus') != undefined && sessionStorage.getItem('RInternalStatus') != "")
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('RInternalStatus').split(',');
        projectstats.forEach((element) => {
          var a = this.internalstatus.find(x=>x.value == element);
          if(a != null)
            this.selectedInternalStatus.push(a);
        });
        ok = true;
      }
    }
    if(!ok)
    {
      this.internalstatus.forEach(e => {
        if(e.value == "New" || e.value == "In-Progress" || e.value == "In-Testing" || e.value == "UAT-Rework" || e.value == "UAT-Bug")
          this.selectedInternalStatus.push(e);
      });
      var a = "";
      this.selectedInternalStatus.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RInternalStatus = a;
    }

    this.getUsers();
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'ownerName', header: 'Request Owner', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'internalStatus', header: 'Internal Status', dynamicPlaceHolder: 'Search', width: '130px' },
    ];
    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.ownerName = "";
    sessionStorage.status = "";
    sessionStorage.internalStatus = "";
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "owner")
      sessionStorage.ownerName = val;
    else if (field == "internalStatus")
      sessionStorage.internalStatus = val;
    else if (field == "status")
      sessionStorage.status = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }
  
  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.requestedByName != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.type != "")
          temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
          temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
        if (sessionStorage.ownerName != "")
          temp = temp.filter(x => x.ownerName == null ? '' : x.ownerName.toLowerCase().includes(sessionStorage.ownerName.toLowerCase()));
        if (sessionStorage.internalStatus != "")
          temp = temp.filter(x => x.internalStatus == null ? '' : x.internalStatus.toLowerCase().includes(sessionStorage.internalStatus.toLowerCase()));
        if (sessionStorage.status != "")
          temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getUsers() {
    this.Users = [];
    this._facadeService.getUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.Users = data.returnObject;
        this.getSupportUsers();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getSupportUsers() {
    this.AUsers = [];
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.AUsers = data.returnObject;
        this.selectedOwners = [];  this.selectedAssignee = [];
        let ok = false;
        var a = this.AUsers.find(m => m.userId == parseInt(this.userId));
        if(sessionStorage.getItem('ROwners') != null && sessionStorage.getItem('ROwners') != undefined && sessionStorage.getItem('ROwners') != "" && sessionStorage.getItem('ROwners').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('ROwners').split(',');
          projectstats.forEach((element) => {
            this.AUsers.forEach((ln) => {
              if(ln.userId == element)
                this.selectedOwners.push(ln);
            });
          });
          ok = true;
        }
        if(!ok)
        { 
          if(a != null && a != undefined)
            this.selectedOwners.push(a);
          var lm=[];
          this.selectedOwners.forEach((element) => {
            lm.push(element.userId);
          });
          sessionStorage.ROwners = lm;
        }
        ok = false;
        if(sessionStorage.getItem('RAssignee') != null && sessionStorage.getItem('RAssignee') != undefined && sessionStorage.getItem('RAssignee') != "" && sessionStorage.getItem('RAssignee').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('RAssignee').split(',');
          projectstats.forEach((element) => {
            this.AUsers.forEach((ln) => {
              if(ln.userId == element)
                this.selectedAssignee.push(ln);
            });
          });
          ok = true;
        }
        if(!ok)
        { 
          if(a != null && a != undefined)
            this.selectedAssignee.push(a);
          var lm=[];
          this.selectedAssignee.forEach((element) => {
            lm.push(element.userId);
          });
          sessionStorage.RAssignee = lm;
        }
        this.AUsers.forEach(element => {
          this.Users.push(element);
        });
        this.selectedUsers = [];
        ok = false;
        if(sessionStorage.getItem('RRequestedBy') != null && sessionStorage.getItem('RRequestedBy') != undefined && sessionStorage.getItem('RRequestedBy') != "" && sessionStorage.getItem('RRequestedBy').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('RRequestedBy').split(',');
          projectstats.forEach((element) => {
            this.Users.forEach((ln) => {
              if(ln.userId == element)
                this.selectedUsers.push(ln);
            });
          });
          ok = true;
        }
        if(!ok)
        {
          this.Users.forEach((ln) => {
            this.selectedUsers.push(ln);
          });
          var lm=[];
          this.selectedUsers.forEach((element) => {
            lm.push(element.userId);
          });
          sessionStorage.RRequestedBy = lm;
        }
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  CheckDate(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }
  CheckDate1(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedClosedToDate = this.selectedClosedFromDate;
      }
    }
  }

  getRequestByCode()
  {
    if (this.code == null)
      this.Error = "Please enter Request Id";
    else {
      this.showLoading = true;
      sessionStorage.RequestCodeId = this.code;
      this._facadeService.getRequestByCode(this.code).subscribe(
        (data) => {
          console.log(data.returnObject);
          console.log(data.returnObject.id);
          sessionStorage.From = "SReport";
          sessionStorage.requestID =data.returnObject.id;
          this.router.navigate(['/clientRequestDetails']);
          // this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }  


  getMyRequests() {
    this.Error = "";
    if (this.selectedInternalStatus == null || this.selectedInternalStatus == undefined || this.selectedInternalStatus.length == 0)
      this.Error = "Please select atleast one internal status";
    else if (this.selectedUsers == null || this.selectedUsers == undefined || this.selectedUsers.length == 0)
      this.Error = "Please select atleast one requester";
    else if (this.selectedtype == null || this.selectedtype == undefined || this.selectedtype.length == 0)
      this.Error = "Please select atleast one type";
    else if (this.selectedPriority == null || this.selectedPriority == undefined || this.selectedPriority.length == 0)
      this.Error = "Please select atleast one priority";
    else if (this.selectedStatus == null || this.selectedStatus == undefined || this.selectedStatus.length == 0)
      this.Error = "Please select atleast one status";
      
    else if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "Requested To Date should be greater then from date";
      this.selectedToDate = this.selectedFromDate;
    }
    else if (this.selectedClosedFromDate != null && this.selectedClosedFromDate != undefined && this.selectedClosedFromDate != ""
      && this.selectedClosedToDate != null && this.selectedClosedToDate != undefined && this.selectedClosedToDate != "" && this.selectedClosedFromDate > this.selectedClosedToDate) {
      this.Error = "Closed To Date should be greater then from date";
      this.selectedClosedToDate = this.selectedClosedFromDate;
    }
    else {
      this.showLoading = true;
      sessionStorage.RToDate = this.selectedToDate;
      sessionStorage.RFromDate = this.selectedFromDate;
      sessionStorage.RClosedToDate = this.selectedClosedToDate;
      sessionStorage.RClosedFromDate = this.selectedClosedFromDate;
      sessionStorage.RInternal = this.internal;
      var types = []; var type = "";
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.value);
            type = type + element.value + ",";
          });
          if(this.type.length == this.selectedtype.length)
            types = [];
        }
      }
      sessionStorage.RType = type;
      var priority = []; var par = "";
      if (this.selectedPriority != null && this.selectedPriority != undefined) {
        if (this.selectedPriority.length > 0) {
          this.selectedPriority.forEach(element => {
            priority.push(element.value);
            par = par + element.value + ",";
          });
          if(this.priority.length == this.selectedPriority.length)
            priority = [];
        }
      }
      sessionStorage.RPriority = par;
      var status = []; var stat = "";
      if (this.selectedStatus != null && this.selectedStatus != undefined) {
        if (this.selectedStatus.length > 0) {
          this.selectedStatus.forEach(element => {
            status.push(element.value);
            stat = stat + element.value + ",";
          });
          if(this.status.length == this.selectedStatus.length)
            status = [];
        }
      }
      sessionStorage.RStatus = stat;
      var Requestedby = []; var req = "";
      if (this.selectedUsers != null && this.selectedUsers != undefined) {
        if (this.selectedUsers.length > 0) {
          this.selectedUsers.forEach(element => {
            Requestedby.push(element.userId);
            req = req + element.userId + ",";
          });
          if(this.Users.length == this.selectedUsers.length)
            Requestedby = [];
          else
          {
            if (this.AUsers != null && this.AUsers != undefined) {
              if (this.AUsers.length > 0) {
                this.AUsers.forEach(element => {
                  Requestedby.push(element.userId);
                });
              }
            }
          }
        }
      }
      sessionStorage.RRequestedBy = req;
      var Assignee = []; var ass;
      if (this.selectedAssignee != null && this.selectedAssignee != undefined) {
        if (this.selectedAssignee.length > 0) {
          this.selectedAssignee.forEach(element => {
            Assignee.push(element.userId);
            ass = ass + element.userId + ",";
          });
          if(this.AUsers.length == this.selectedAssignee.length)
            Assignee = [];
        }
      }
      sessionStorage.RAssignee = req;
      var InStatus = []; var instate = "";
      if (this.selectedInternalStatus != null && this.selectedInternalStatus != undefined) {
        if (this.selectedInternalStatus.length > 0) {
          this.selectedInternalStatus.forEach(element => {
            InStatus.push(element.value);
            instate = instate + element.value + ",";
          });
          if(this.internalstatus.length == this.selectedInternalStatus.length)
            InStatus = [];
        }
      }
      sessionStorage.RInternalStatus = instate;
      var Owners = []; var ow = "";
      if (this.selectedOwners != null && this.selectedOwners != undefined) {
        if (this.selectedOwners.length > 0) {
          this.selectedOwners.forEach(element => {
            Owners.push(element.userId);
            ow = ow + element.userId + ",";
          });
          if(this.AUsers.length == this.selectedOwners.length)
            Owners = [];
        }
      }
      sessionStorage.ROwners = ow;
      var myRequestsData =
      {
        "type": types,
        "status": status,
        "application": [],
        "priority": priority,
        "requestedBy": Requestedby,
        "assignTo": Assignee,
        "internalStatus": InStatus,
        "requestOwner": Owners,
        "userID": 0,
        "includeInternal" : this.internal
      }
      this._facadeService.MyRequests(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
          {
            this.requestData.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");      
            });
            if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) >= new Date(this.selectedFromDate));
            }
            if (this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) <= new Date(this.selectedToDate));
            }
            if (this.selectedClosedFromDate != null && this.selectedClosedFromDate != undefined && this.selectedClosedFromDate != "") {
              var nulldata = this.requestData.filter(x => x.closedDate == null || x.closedDate == "");
              this.requestData = this.requestData.filter(x => new Date(x.closedDate) >= new Date(this.selectedClosedFromDate));
              if(nulldata != null)
              {
                if(nulldata.length > 0)
                {
                  nulldata.forEach(element => {
                      this.requestData.push(element);
                  });
                }
              }
            }
            if (this.selectedClosedToDate != null && this.selectedClosedToDate != undefined && this.selectedClosedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.closedDate) <= new Date(this.selectedClosedToDate));
            }
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }

  ViewMyRequest(rowdata) {
    sessionStorage.From = "SReport";
    sessionStorage.requestID = rowdata.id;
    this.router.navigate(['/clientRequestsDetails']);
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Requests Summary','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 120 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 120 },{ wpx : 135 },{ wpx : 135 }];
    /* save to file */
    XLSX.writeFile(wb, "Requests Summary.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['J1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

}
