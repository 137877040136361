import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import {Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';

@Component({
  selector: 'app-un-assigned-requests',
  templateUrl: './un-assigned-requests.component.html',
  styleUrls: ['./un-assigned-requests.component.scss']
})
export class UnAssignedRequestsComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  Error: string = "";
  
  requestData = [];

  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '180px' },
    ];

    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.applicationName = "";
    this.getMyRequests();
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "applicationName")
      sessionStorage.applicationName = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.requestedByName != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.type != "")
          temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
          temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
        if (sessionStorage.applicationName != "")
          temp = temp.filter(x => x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getMyRequests() {
    this.Error = "";
      this.showLoading = true;
      var status = [];
      status.push("New");
      var status1 = [];
      status1.push("Unassigned");
      var myRequestsData =
      {
        "type": [],
        "status": status,
        "application": [],
        "priority": [],
        "requestedBy": [],
        "assignTo": [],
        "internalStatus": status1,
        "requestOwner": [],
        "userID": 0,
        "includeInternal" : true
      }
      this._facadeService.MyRequests(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
          {
            this.requestData.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");   
            });
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

  ViewMyRequest(rowdata) {
    this.router.navigate(['/unAssignedDetailsRequests',{requestID:rowdata.id}]);
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Unassigned Requests','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 120 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 200 }];
    /* save to file */
    XLSX.writeFile(wb, "Unassigned Requests.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

}
