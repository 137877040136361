import { Component, Inject, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FacadeService } from "../../facade/facade.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: [
    `
      .box {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1.25rem;
        font-size: 1.5rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
          0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }

      .box-stretched {
        height: 100%;
      }

      .vertical-container {
        margin: 0;
        height: 200px;
        background: var(--surface-d);
        border-radius: 4px;
      }

      .nested-grid .p-col-4 {
        padding-bottom: 1rem;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router,private route: ActivatedRoute, private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {}
  //baseUrl: any = "http://132.148.242.138/FPUMS/api/";
  loginModel: any;
  userName: any;
  password: any;
  loginMessage: any;
  loginFlag: boolean = false;
  showLoading:boolean;
  issueId:any;

  ngOnInit(): void {
    this.issueId = this.route.snapshot.queryParamMap.get('iid');
  }

  login() {
    this.showLoading = true;

    this.loginModel = {
      UserName: this.userName,
      Password: this.password,
    };

    let strongPassword: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    if (this.userName == undefined || this.userName == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter valid username.";
    } else if (this.password == undefined || this.password == "") {
      this.loginFlag = true;
      this.loginMessage = "Please enter  password.";
    } else if (strongPassword.test(this.password) == false) {
      this.loginFlag = true;

      this.loginMessage = "Please enter valid password.";
    }

    if (
      this.userName != undefined &&
      this.userName != "" &&
      this.password != undefined &&
      this.password != "" &&
      strongPassword.test(this.password) == true
    ) {
      this.loginModel = {
        email: this.userName,
        Password: this.password,
      };
      // this._facadeService.login(this.loginModel, this.baseUrl["API_ENDPOINTS"]["UMSAPI"]).subscribe(
      //   (data) => {
      //     if (data.returnCode == 0) {
      //       sessionStorage.userId = data.returnObject.userId;
      //       sessionStorage.userGuid = data.returnObject.userGuid;
      //       sessionStorage.firstName = data.returnObject.firstName;
      //       //sessionStorage.applicationID = 5;
      //       console.log(data.returnMessage);
      //       if(this.issueId == null || this.issueId ==undefined){
      //         this.router.navigate(["/dashboard"]);
      //       }
      //       else{
      //         let f = this.baseUrl["API_ENDPOINTS"];
      //         location.href=this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'editissue?issueId='+this.issueId;  
      //       }
      //       console.log(data);
      //     } else {
      //       this.loginFlag = true;
      //       this.loginMessage = data.returnMessage;

      //       console.log(data.returnMessage);
      //       this.showLoading = false;
      //     }
      //   },

      //   (error) => {
      //     console.log(error);
      //   }
      // );
    }
  }
}
