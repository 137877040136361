<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12"> <h5>Request Details</h5></div>
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button pButton type="button" (click)="SendClose()" *ngIf="(status1 == 'Production' || status1 == 'Ready to Close') && canClose"
          label="Close Request" iconPos="left"  class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2"
          icon="pi pi-times" style="width: 160px; height:35px;"></button> 
        </div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" >
          <a routerLink="/reportByStatus"> <button pButton type="button" label="Back" iconPos="left" class="p-button-raised p-button-primary"
            icon="pi pi-angle-left" style="margin-left:5px ; height:35px;"></button> </a>
        </div>
      </div>
      <p-confirmDialog key="confirm-close" header="Close Request" acceptLabel="Yes" rejectLabel="No"
      [(visible)]="confirmClose"></p-confirmDialog>
      <hr style="margin-top: 0px;" />    
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-2">
          <label>Request ID</label>
          <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested By</label>
          <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Initiated By</label>
          <input type="text" pInputText [(ngModel)]="createdByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Application</label>
          <p-dropdown [options]="applications" placeholder="Select a Application" [(ngModel)]="selectedapplication" optionLabel="applicationName" [disabled]="ViewOnly"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Type</label>
          <p-dropdown [options]="type" placeholder="Select a Request Type" [(ngModel)]="selectedtype"  optionLabel="label" [disabled]="ViewOnly"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 != 'Saved' && status1 != 'Pending Confirmation' && status1 != 'Seek Clarification'">
          <label>Confirmed By</label>
          <input type="text" pInputText [(ngModel)]="confirmedbyName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 != 'Saved' && status1 != 'Pending Confirmation' && status1 != 'Seek Clarification'">
          <label>Confirmed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="confirmedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status1 != 'Saved' && status1 != 'Pending Confirmation' && status1 != 'Seek Clarification'">
          <label>Confirmed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="confirmedremarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 == 'Cancelled'">
          <label>Cancelled By</label>
          <input type="text" pInputText [(ngModel)]="cancelledByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 == 'Cancelled'">
          <label>Cancelled Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="cancelledDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status1 == 'Cancelled'">
          <label>Cancelled Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="cancelledRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 == 'Closed'">
          <label>Closed By</label>
          <input type="text" pInputText [(ngModel)]="closedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status1 == 'Closed'">
          <label>Closed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="closedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status1 == 'Closed'">
          <label>Closed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="closedRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
          <label>UAT Approved By</label>
          <input type="text" pInputText [(ngModel)]="uATAcknowledgeName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
          <label>UAT Approved Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="uATAcknowledgeDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="isUAT">
          <label>UAT Approved Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="uATAcknowledgeRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
          <label>Production Approved By</label>
          <input type="text" pInputText [(ngModel)]="productionAcknowledgeName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
          <label>Production Approved Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="productionAcknowledgeDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="isProduction">
          <label>Production Approved Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="productionAcknowledgeRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label>Short Description</label>
          <small class="p-invalid" style="float: right;">{{100 - title.length}} characters are remaining</small>
          <input type="text" pInputText placeholder="Short Description" required="true" requiredMessage="Short Description must not be empty"
            [(ngModel)]="title" maxlength=100 [disabled]="ViewOnly"/>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Planned Start Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedStartDate" [minDate]="selectedreqDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(plannedStartDate, plannedEndDate,'P')" (onClickOutside)="CheckDate(plannedStartDate, plannedEndDate,'P')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Planned End Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedEndDate" [minDate]="plannedStartDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(plannedStartDate, plannedEndDate,'P')" (onClickOutside)="CheckDate(plannedStartDate, plannedEndDate,'P')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Current Start Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [minDate]="plannedStartDate" [(ngModel)]="currentStartDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(currentStartDate, currentEndDate,'C')" (onClickOutside)="CheckDate(currentStartDate, currentEndDate,'C')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Current End Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="currentEndDate" [minDate]="currentStartDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(currentStartDate, currentEndDate,'C')" (onClickOutside)="CheckDate(currentStartDate, currentEndDate,'C')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Actual Start Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [minDate]="currentStartDate" [(ngModel)]="actualStartDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(actualStartDate, actualEndDate,'A')" (onClickOutside)="CheckDate(actualStartDate, actualEndDate,'A')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Actual End Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="actualEndDate" [minDate]="currentStartDate" [disabled]="ViewOnly"
          (onSelect)="CheckDate(actualStartDate, actualEndDate,'A')" (onClickOutside)="CheckDate(actualStartDate, actualEndDate,'A')"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Owner</label>
          <p-dropdown [options]="users" placeholder="Select a Request Owner" [(ngModel)]="owner" optionLabel="fullName" [disabled]="ViewOnly"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Estimated Effort (Hours)</label>
          <input type="text" pInputText [(ngModel)]="estimatedHours"  maxlength="10" type="number" [disabled]="ViewOnly" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Actual Effort (Hours)</label>
          <input type="text" pInputText [(ngModel)]="actualHours"  maxlength="10" type="number" [disabled]="ViewOnly" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Progress Completed (%)</label>
          <p-inputNumber mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" maxlength="3"  [min] = "0" [max]="100" [disabled]="ViewOnly"
          [(ngModel)]="progress" placeholder="Progress Completed"></p-inputNumber>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Priority</label>
          <p-dropdown [options]="priority" placeholder="Select a Priority" [(ngModel)]="selectedPriority" optionLabel="label" [disabled]="ViewOnly"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Status</label>
          <p-dropdown [options]="status" placeholder="Select a Status" [(ngModel)]="selectedStatus" optionLabel="label" [disabled]="ViewOnly"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Internal Status</label>
          <p-dropdown [options]="internalstatus" placeholder="Select a status" [(ngModel)]="selectedinternalStatus" [disabled]="ViewOnly" optionLabel="label"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="GoogleAvaialable" style="margin-top: 10px;">
          <br>
          <a href="{{GoogleLink}}" target="_blank">Google Drive</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button pButton pRipple type="button" icon="pi pi-pencil"  iconPos="left" [disabled]="ViewOnly" (click)="GoogleAvaialable = false" style="height:25px; width:25px;"></button>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="!GoogleAvaialable">
          <label>Google Drive</label>
          <input type="text" pInputText [(ngModel)]="GoogleLink" maxlength="1000" [disabled]="ViewOnly" />
        </div>
        <div class="p-field p-col-12 p-md-2" style="margin-top: 25px;">
          <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update" [disabled]="ViewOnly"
          (click)="Update()" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width:auto; float:right;"></button>              
        </div>
        <div class="p-field p-col-12 p-md-8" style="float: right; margin-top: 30px;"><small class="p-invalid">{{Error}}</small></div>
      </div> 
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="" [headerStyle]="{'width': '0px'}"><br></p-tabPanel>
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'350px'}" [readonly]="ViewOnly" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip" *ngIf="ViewAddedDocuments.length > 0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                      {{rowData.fileName}} </a></span></td>
                <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Clarifications" leftIcon="pi pi-question" *ngIf="Clarification1.length > 0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content1" style="overflow-x: auto;">
              <p-table #dtClarification1 [value]="Clarification1" [columns]="Clarification1Cols" sortMode="multiple" [loading]="loading"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Support Clarifications" leftIcon="pi pi-question">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddClare()" [disabled]="ViewOnly"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2" style="width:auto;float: right;"></button>
          </span>
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content2" style="overflow-x: auto;">
              <p-table #dtSClarification [value]="SClarification" [columns]="Clarification1Cols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="SselectedClarification"> 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Final Requirements" leftIcon="pi pi-table">
          <div class="p-col-12 p-md-12 p-xl-12">
            <label>Requirements <span style="color: red">*</span> </label>
            <p-editor [(ngModel)]="requirement" [style]="{'height':'350px'}" [readonly]="ViewOnly" placeholder="Enter Requirements"></p-editor>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="requiremntsdocuments.length > 0">
          <label>Attachements</label>
          <ul *ngFor="let temp of requiremntsdocuments" class="tempul">
              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
          </ul>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <form>
            <p-fileUpload #fileInputreq name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
              accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                [showUploadButton]="false" [showCancelButton]="true" fileLimit="25" auto="true">
              <ng-template pTemplate="content">
                <ul *ngIf="fileInputreq.length">
                  <li *ngFor="let file of fileInputreq">{{file.name}} - {{file.size}} bytes</li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </form>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <small class="p-invalid">{{ ReqInputError }}</small>
            <span style="float: right;">
                <button pButton type="button" (click)="AddReqInput()" label="Save" icon="pi pi-plus" pTooltip="Save" tooltipPosition="right"
                  class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                  style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
            </span>
        </div>
        </p-tabPanel>
        <p-tabPanel header="UI Design" leftIcon="pi pi-palette">
          <div class="p-col-12 p-md-12 p-xl-12">
            <label>Notes</label>
            <p-editor [(ngModel)]="designreq" [style]="{'height':'350px'}" [readonly]="ViewOnly" placeholder="Enter Notes"></p-editor>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="desgindocuments?.length > 0">
          <label>Attachements</label>
          <ul *ngFor="let temp of desgindocuments" class="tempul">
              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
          </ul>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <form>
            <p-fileUpload #fileInputdesign name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
              accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                [showUploadButton]="false" [showCancelButton]="true" fileLimit="25" auto="true">
              <ng-template pTemplate="content">
                <ul *ngIf="fileInputdesign.length">
                  <li *ngFor="let file of fileInputdesign">{{file.name}} - {{file.size}} bytes</li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </form>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <small class="p-invalid">{{ DesignInputError }}</small>
            <span style="float: right;">
                <button pButton type="button" (click)="AddDesignInput()" label="Save" icon="pi pi-plus" pTooltip="Save" tooltipPosition="right"
                  class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                  style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
            </span>
        </div>
        </p-tabPanel>
        <p-tabPanel header="Project Plan" leftIcon="pi pi-compass">
          <div class="p-col-12">
            <p-table #dt [value]="docTypes" id="excel-table" styleClass="p-datatable-striped" sortMode="multiple" class="table-align" editMode="row" 
            [filterDelay]="0" selectionMode="multiple" dataKey="taskName">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th style="width: 30px;">Seq</th>
                    <th>Task</th>
                    <th>Scope</th>
                    <th style="width:80px;">Target Start Date</th>
                    <th style="width:80px;">Target End Date</th>
                    <th style="width:80px;">Current Start Date</th>
                    <th style="width:80px;">Current End Date</th>
                    <th style="width:150px;">Actual Start Date</th>
                    <th style="width:150px;">Actual End Date</th>
                    <th style="width:70px;">Estimated Hours</th>
                    <th style="width:50px;">Actual Hours</th>
                    <th style="width:60px;">Progress Completed (%)</th>
                    <th style="width:80px;">Status</th>
                    <th>Predecessor</th>
                    <th>Assigned To</th>
                    <th style="width:80px;">Affect Allocation</th>
                    <th style="text-align: center; width:80px;">Actions</th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
                <tr [pEditableRow]="rowData">
                    <td style="text-align: center;width: 30px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.taskSeq}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.taskSeq}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: left;padding-left: 15px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.taskName}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.taskName}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: left;padding-left: 15px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.scope}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.scope}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> 
                          <span *ngIf="rowData.plannedStartDate != '--' && rowData.plannedStartDate != null">{{rowData.plannedStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.plannedStartDate == '--' || rowData.plannedStartDate == null">--</span>
                        </ng-template>
                        <ng-template pTemplate="output"> 
                          <span *ngIf="rowData.plannedStartDate != '--' && rowData.plannedStartDate != null">{{rowData.plannedStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.plannedStartDate == '--' || rowData.plannedStartDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> 
                          <span *ngIf="rowData.plannedEndDate != '--' && rowData.plannedEndDate != null">{{rowData.plannedEndDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.plannedEndDate == '--' || rowData.plannedEndDate == null">--</span>
                        </ng-template>
                        <ng-template pTemplate="output"> 
                          <span *ngIf="rowData.plannedEndDate != '--' && rowData.plannedEndDate != null">{{rowData.plannedEndDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.plannedEndDate == '--' || rowData.plannedEndDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> 
                          <span *ngIf="rowData.currentStartDate != '--' && rowData.currentStartDate != null">{{rowData.currentStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.currentStartDate == '--' || rowData.currentStartDate == null">--</span>
                        </ng-template>
                        <ng-template pTemplate="output"> 
                          <span *ngIf="rowData.currentStartDate != '--' && rowData.currentStartDate != null">{{rowData.currentStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.currentStartDate == '--' || rowData.currentStartDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> 
                          <span *ngIf="rowData.currentEndDate != '--' && rowData.currentEndDate != null">{{rowData.currentEndDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.currentEndDate == '--' || rowData.currentEndDate == null">--</span>
                        </ng-template>
                        <ng-template pTemplate="output"> 
                          <span *ngIf="rowData.currentEndDate != '--' && rowData.currentEndDate != null">{{rowData.currentEndDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.currentEndDate == '--' || rowData.currentEndDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:150px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> 
                          <!-- [minDate]="rowData.currentStartDate" -->
                          <!-- [minDate]="actualmin" -->
                          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="tactualStartDate" 
                          [disabledDays]="[0,6]" [style]="{'width':'100%'}" [inputStyle]="{'width':'200%'}"
                          (onSelect)="CheckDate2(tactualStartDate, tactualEndDate,rowData)" (onClickOutside)="CheckDate2(tactualStartDate, tactualEndDate,rowData)"></p-calendar>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span *ngIf="rowData.actualStartDate != '--' && rowData.actualStartDate != null">{{rowData.actualStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.actualStartDate == '--' || rowData.actualStartDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:150px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <!-- [minDate]="rowData.currentStartDate || rowData.actualStartDate" -->
                          <!-- [minDate]="actualendmin" -->
                          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" 
                          [(ngModel)]="tactualEndDate" [disabledDays]="[0,6]" [style]="{'width':'100%'}" [inputStyle]="{'width':'200%'}"
                          (onSelect)="CheckDate2(tactualStartDate, tactualEndDate,rowData)" (onClickOutside)="CheckDate2(tactualStartDate, tactualEndDate,rowData)"></p-calendar>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span *ngIf="rowData.actualEndDate != '--' && rowData.actualEndDate != null">{{rowData.actualEndDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.actualEndDate == '--' || rowData.actualEndDate == null">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:70px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3" [disabled]="!EnableCurrent"
                          [(ngModel)]="testimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output"> {{rowData.estimatedHours}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:50px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.actualHours}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.actualHours}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:60px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.progressCompleted}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.progressCompleted}}</ng-template>
                        <!-- <ng-template pTemplate="input">
                          <p-inputNumber mode="decimal" [minFractionDigits]="0" [min] = "1" [max]="100"   maxlength="3" 
                          [(ngModel)]="tprogressCompleted" placeholder="Progress Completed"></p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output"> {{rowData.progressCompleted}}</ng-template> -->
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.status}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.status}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.predecessor}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.predecessor}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"> {{rowData.assignee}}</ng-template>
                        <ng-template pTemplate="output"> {{rowData.assignee}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:80px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input"><span *ngIf="rowData.affectAllocation">Yes</span><span *ngIf="!rowData.affectAllocation">No</span></ng-template>
                        <ng-template pTemplate="output"><span *ngIf="rowData.affectAllocation">Yes</span><span *ngIf="!rowData.affectAllocation">No</span></ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align:center;width:80px;">
                      <button *ngIf="!editing && rowData.canEdit" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" [disabled]="ViewOnly"
                      (click)="onRowEditInit1(rowData)" style="height:25px; width:25px;"></button>
                      <button *ngIf="editing" pButton type="button" icon="pi pi-check" pSaveEditableRow class="ui-button-info" [disabled]="ViewOnly"
                      (click)="updateTask()" style="height:25px; width:25px;"></button>&nbsp;
                      <button *ngIf="editing" pButton type="button" pCancelEditableRow [disabled]="ViewOnly" icon="pi pi-times-circle" class="ui-button-danger" 
                      (click)="onRowEditCancel(rowData)" style="height:25px; width:25px;"></button>
                    </td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="17" style="text-align: left;">No records found...</td>
                  </tr>
              </ng-template>
          </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="API List" leftIcon="pi pi-list">
          <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-10"> <small class="p-invalid" style="float: right;padding-top: 10px;">{{ APIError }}</small></div>
            <div class="p-col-12 p-md-2">
                <span class="p-input-icon-left">
                    <button [disabled]="Aclicked" (click)="Aclicked = true;" pButton type="button" icon="pi pi-plus" class="ui-button-info" 
                    style="float: right; width:120px;" [label]="'Add New'" pAddRow [table]="dtA" [newRow]="newRow()" ></button>
                </span>
            </div>
            <div style="margin-left: 15px;border: 2px solid #e9ecef;">
                <p-table #dtA [value]="APIData" styleClass="p-datatable-striped" class="table-align" editMode="row" selectionMode="multiple" dataKey="apiName">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr style="margin-left: 15px;"> 
                        <th>API Name</th>
                        <th>Input Params</th>
                        <th>Output Params</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-team  let-editing="editing">
                    <tr [pEditableRow]="team" >
                       <td style="text-align:left; padding-left:15px;">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                  <input type="text" pInputText placeholder="API Name" [(ngModel)]="apiName" maxlength=250/>
                                  <small class="p-invalid">{{ apiNameError }}</small>
                                </ng-template>
                                <ng-template pTemplate="output">{{team.apiName}}</ng-template>
                            </p-cellEditor>
                        </td>
                        <td style="text-align:left; padding-left:15px;">
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input type="text" pInputText placeholder="Input Params" [(ngModel)]="inputParams" maxlength=250/>
                              </ng-template>
                              <ng-template pTemplate="output">{{team.inputParams}}</ng-template>
                            </p-cellEditor>
                        </td>
                        <td style="text-align:left; padding-left:15px;">
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input type="text" pInputText placeholder="Output Params" [(ngModel)]="outputParams" maxlength=250/>
                              </ng-template>
                              <ng-template pTemplate="output">{{team.outputParams}}</ng-template>
                            </p-cellEditor>
                        </td>
                        <td style="text-align:center">
                            <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" pTooltip="Edit" tooltipPosition="right"
                            (click)="onRowEdit(team)" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                            <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" class="ui-button-info" (click)="Delete(team)" pTooltip="Delete" tooltipPosition="right"
                              style="height:25px; width:25px;"></button>
                            <button *ngIf="editing" pButton type="button" icon="pi pi-check" class="ui-button-info"pTooltip="OK" tooltipPosition="right"
                                (click)="AddAPI()" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                            <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times-circle" class="ui-button-danger"pTooltip="Cancel" tooltipPosition="right"
                                (click)="onRowEditCancelAPI()" style="height:25px; width:25px;"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage"><tr><td colspan="4" style="text-align: left;">No records found...</td></tr> </ng-template>
            </p-table>
            </div>
            <div class="p-col-12 p-md-10">&nbsp;</div>
            <div class="p-col-12 p-md-2" style="float: right;">
              <button pButton pRipple [disabled]="ViewOnly" type="button" icon="pi pi-check" iconPos="left" label="Save"
              class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" (click)="saveapidata()"></button>
            </div>
        </div>
        </p-tabPanel>
        <p-tabPanel header="Test Cases" leftIcon="pi pi-key">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddTestCases()" [disabled]="ViewOnly"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2" style="width:auto;float: right;"></button>
          </span>
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtTC [value]="TestCases" [columns]="TestCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 60px;">Update</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 100px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: left;padding-left: 15px; width: 30%;">{{ rowData[col.field]}}</td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left: 15px; width: 30%;">{{ rowData[col.field]}}</td>
                        <td *ngIf="i == 3" style="text-align: center;width: 80px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4" style="text-align: left;padding-left: 15px; width: 30%;">
                          <p-editor [(ngModel)]="rowData[col.field]" [readonly]="true"><p-header hidden></p-header></p-editor>
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;width: 110px;">
                          <span *ngIf=rowData.qaConfirm>Yes</span><span *ngIf=!rowData.qaConfirm>No</span></td>
                        <td *ngIf="i == 6" style="text-align: center;width: 120px;">
                          <span *ngIf=rowData.developerConfirm>Yes</span><span *ngIf=!rowData.developerConfirm>No</span></td>
                      </ng-container>
                        <td style="text-align: center;width:60px">
                          <button pButton type="button" icon="pi pi-pencil" class="ui-button-info" [disabled]="ViewOnly"
                           (click)="UpdateTestCase(rowData)" style="height:25px; width:25px;"></button>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="8" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="QA Inputs" leftIcon="pi pi-file-excel">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddInputs('Defect')" [disabled]="ViewOnly"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2" style="width:auto;float: right;"></button>
          </span>
          <div class="p-col-12" *ngIf="Inputs1.length>0">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtInputs1 [value]="Inputs1" [columns]="InputsCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 150px;">Attachments</th>
                          <th style="width: 60px;">Update</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;width: 110Ppx;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:50%">
                          <p-editor [(ngModel)]="rowData[col.field]" [readonly]="true"><p-header hidden></p-header></p-editor></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:100px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:25%">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align:left; padding-left:15px;width: 150px;">
                          <ul *ngFor="let temp of rowData.documents" class="tempul">
                              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
                          </ul>
                        </td>
                        <td style="text-align: center;width:60px">
                          <button pButton type="button" icon="pi pi-pencil" class="ui-button-info" [disabled]="ViewOnly"
                          *ngIf="rowData.noteStatus == 'Open'"  (click)="UpdateInput(rowData.inputID,rowData.note,rowData.noteStatus,'D')" style="height:25px; width:25px;"></button>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="8" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
          <div class="card" *ngIf="Addupdateinput1">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5 style="text-align:left;">{{note1}}</h5>
                    <p-dropdown [options]="inputstatus" placeholder="Select a status" [(ngModel)]="popupstatus1" optionLabel="label"></p-dropdown>
                    <br>
                    <textarea rows="6" pInputTextarea id="popupcomment1" [(ngModel)]="popupcomment1"
                        placeholder="Enter comments" maxlength="1000" ng-trim="false" style="width: 100%;"></textarea>
                    <small class="p-invalid">{{1000 - popupcomment1.length}} characters are remaining</small>
                    <br>
                    <small class="p-invalid">{{ InputUpdateError1 }}</small>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-9"> </div>
                <div class="p-col-12 p-md-3">
                    <button pButton type="button" (click)="updateInput1()" label="Continue"
                      pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus"
                      style="width:100px;height:25px;" class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                    <button pButton type="button" (click)="InputClear1()" label="Reset"
                      pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh"
                      style="width:100px;height:25px;" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                </div>
            </div>
          </div>
          <br><br>
        </p-tabPanel>
        <p-tabPanel header="Inputs" leftIcon="pi pi-bars">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddInputs('UAT')" [disabled]="ViewOnly"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2" style="width:auto;float: right;"></button>
          </span>
          <div class="p-col-12" *ngIf="Inputs.length>0">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtInputs [value]="Inputs" [columns]="InputsCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 150px;">Attachments</th>
                          <th style="width: 60px;">Update</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;width: 110Ppx;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 130px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:50%">
                          <p-editor [(ngModel)]="rowData[col.field]" [readonly]="true"><p-header hidden></p-header></p-editor></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:100px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:25%">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 6" style="text-align: center;width:80px">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align:left; padding-left:15px;width: 150px;">
                          <ul *ngFor="let temp of rowData.documents" class="tempul">
                              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
                          </ul>
                        </td>
                        <td style="text-align: center;width:60px;">
                          <button pButton type="button" icon="pi pi-pencil" class="ui-button-info" [disabled]="ViewOnly"
                          *ngIf="rowData.noteStatus == 'Open' && (status1 == 'UAT' || status1 == 'Production')"
                          (click)="UpdateInput(rowData.inputID,rowData.note,rowData.noteStatus,'U')" style="height:25px; width:25px;"></button>
                        </td>                    
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="9" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
          <div class="card" *ngIf="Addupdateinput">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5 style="text-align:left;">{{note}}</h5>
                    <p-dropdown [options]="inputstatus" placeholder="Select a status" [(ngModel)]="popupstatus" optionLabel="label"></p-dropdown>
                    <!-- <input type="text" pInputText placeholder="Status" id="popupstatus" [(ngModel)]="popupstatus" maxlength=25/>
                    <small class="p-invalid" style="float: right;">{{25 - popupstatus.length}} characters are remaining</small> -->
                    <br>
                    <textarea rows="6" pInputTextarea id="popupcomment" [(ngModel)]="popupcomment"
                        placeholder="Enter comments" maxlength="1000" ng-trim="false" style="width: 100%;"></textarea>
                    <small class="p-invalid">{{1000 - popupcomment.length}} characters are remaining</small>
                    <br>
                    <small class="p-invalid">{{ InputUpdateError }}</small>
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-9"> </div>
                <div class="p-col-12 p-md-3">
                    <button pButton type="button" (click)="updateInput()" label="Continue"
                      pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus"
                      style="width:100px;height:25px;" class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                    <button pButton type="button" (click)="InputClear()" label="Reset"
                      pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh"
                      style="width:100px;height:25px;" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                </div>
            </div>
          </div>
          <br><br>
        </p-tabPanel>
        <p-tabPanel header="Work Logs" leftIcon="pi pi-align-right" *ngIf="Logs.length > 0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content10" style="overflow-x: auto;">
              <p-table #dtlog [value]="Logs" id="excel-table10" [columns]="Logcols" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                        dataKey="taskID">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test($event.target.value,col.field,dtlog)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                     <td *ngIf="i == 0" style="text-align: center;width:90px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width:130px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width:25%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: center;width:80px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;width:90px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: center;width:70px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;width:130px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: left;padding-left:15px;width: 65%;">
                                        {{rowData[col.field]}}
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Manage Tasks" leftIcon="pi pi-compass">
          <div class="p-field p-col-12 p-md-12" style="margin-left:auto;margin-right:auto;">
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">
                <button [disabled]="clicked" (click)="clicked = true;" pButton type="button" icon="pi pi-plus"
                class="ui-button-info" style="float: right; width: 160px; margin-bottom:10px;" [label]="'Add New Task'" pAddRow [table]="dt1" [newRow]="newRow1()" 
                ></button>
            </span>
          </div>
          <div class="p-col-12">
            <p-table #dt1 [value]="docTypes1" id="excel-table" styleClass="p-datatable-striped" sortMode="multiple" class="table-align" editMode="row" 
              [filterDelay]="0" selectionMode="multiple" dataKey="taskName">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th style="width: 60px;"></th>
                      <th style="width: 40px;">Seq</th>
                      <th>Task</th>
                      <th>Scope</th>
                      <th style="width:120px;">Estimated Hours</th>
                      <th style="width:120px;">Actual Hours</th>
                      <th style="width:120px;">Progress</th>
                      <th style="width:120px;">Status</th>
                      <!-- <th style="width:120px;">Target Start Date</th>
                      <th style="width:110px;">Target End Date</th> -->
                      <th style="width:150px;">Predecessor</th>
                      <th style="width:150px;">Assigned To</th>
                      <th style="width:110px;">Affect Allocation</th>
                      <th style="text-align: center; width:80px;">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
                  <tr [pEditableRow]="rowData" [style.background]="rowData.isEdit == 'Y' ? 'aquamarine' : rowData.isEdit == 'E' ? 'tan' : ''"
                  [pReorderableRow]="index">
                      <td style="text-align: center;">
                          <i class="pi pi-bars" pReorderableRowHandle  (handler)="updateSeq($event)" ></i>
                            <!-- {{index}} - {{rowData.taskSeq}} -->
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.taskSeq}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.taskSeq}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                              <input type="text" pInputText placeholder="Task Name" [(ngModel)]="taskName" maxlength=50/>
                              <small class="p-invalid">{{ taskNameError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">{{rowData.taskName}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                              <input type="text" pInputText placeholder="Scope" [(ngModel)]="scope" maxlength=500/>
                              <small class="p-invalid">{{ scopeError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">{{rowData.scope}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:120px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3" [(ngModel)]="taskestimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                              <small class="p-invalid">{{ hoursError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3"  (onInput)="updatedoctype()"
                            [(ngModel)]="rowData.estimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.actualHours}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.actualHours}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.progressCompleted}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.progressCompleted}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.status}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.status}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <!-- <td style="text-align: center;width:120px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">--</ng-template>
                          <ng-template pTemplate="output">
                            <span *ngIf="rowData.startDate != '--' && rowData.startDate != null && rowData.cycleTime != 0">{{rowData.startDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="rowData.startDate == '--' || rowData.startDate == null || rowData.cycleTime == 0">--</span>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:110px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">--</ng-template>
                          <ng-template pTemplate="output">
                            <span *ngIf="rowData.dueDate != '--' && rowData.dueDate != null && rowData.cycleTime != 0">{{rowData.dueDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="rowData.dueDate == '--' || rowData.dueDate == null || rowData.cycleTime == 0">--</span>
                          </ng-template>
                        </p-cellEditor>
                      </td> -->
                      <td style="padding-left: 15px;width:150px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-multiSelect [options]="docTypes" [(ngModel)]="selectedPredecessor" placeholder="Select Predecessor" 
                            optionLabel="taskName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                            <small class="p-invalid">{{ PredecessorError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-multiSelect [options]="rowData.pData" [(ngModel)]="rowData.taskPredecessor" placeholder="Select Predecessor" 
                            optionLabel="predecessorName" class="text" (onChange)="updatedoctype()" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;width:150px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-multiSelect [options]="users" [(ngModel)]="selectedAssignee" placeholder="Select Assignee" (onChange)="onRowEditSave(rowData)"
                            optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                            <small class="p-invalid">{{ AssigneeError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-multiSelect [options]="users" [(ngModel)]="rowData.taskAssignee" placeholder="Select Assignee" 
                            optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:110px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-checkbox binary="affectAllocation" [(ngModel)]="affectAllocation" [value]="affectAllocation"></p-checkbox>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-checkbox binary="rowData.affectAllocation" [(ngModel)]="rowData.affectAllocation" [value]="rowData.affectAllocation"></p-checkbox>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                        <td style="text-align:center;width:80px;">
                          <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" 
                          [disabled]="rowData.actualHours > 0"
                          (click)="onRowEditInit2(rowData)" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                          <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" [disabled]="rowData.actualHours > 0"
                              class="ui-button-info" (click)="onRowEditInit(rowData)" style="height:25px; width:25px;"></button>
                          <!-- <button *ngIf="!editing && rowData.isEdit == 'D'" pButton type="button" icon="pi pi-undo"
                              class="ui-button-info" (click)="UndoDelete(rowData)" style="height:25px; width:25px;"></button> -->
                          <button *ngIf="editing" pButton type="button" pCancelEditableRow
                              icon="pi pi-times-circle" class="ui-button-danger" [disabled]="rowData.actualHours > 0"
                              (click)="onRowEditCancel1(rowData)" style="height:25px; width:25px;"></button>
                        </td>
                    </tr>
                </ng-template>
        <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="11" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            
            </p-table>
            <br>
            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
            (click)="Update1()" class="p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width:auto; float:right;"></button>
              <small class="p-invalid"  style="float: right;margin-right: 30px; margin-top: 10px;">{{UpdateError}}</small>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="card">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5> 
      </div>
      <div class="p-field p-col-12" style="padding: 0px;">
        <div class="card" style="border:1px solid #eee;">
          <div class="widget-chat">
            <ul *ngFor="let note of notesList">
              <li class="message-from">
                <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.notesAddedByName }}</span>
                  <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                  </span>
                  <span style="font-size: 14px;padding-right: 10px;" *ngIf="note.dmsID > 0">{{ note.note }}</span>
                  <p-editor [(ngModel)]="note.note" [readonly]="true" *ngIf="note.dmsID == 0"><p-header hidden></p-header></p-editor>
                </div>
                <div *ngIf="note.dmsID > 0" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="DMSToken(note.downloadFileName,note.userFileName)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Download File">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                 border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-download"></i></span></a></div>
                <!-- <div *ngIf="note.dmsID == 0 && !note.sentMail && note.ok" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="SendMail(note.id)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Send Mail">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                  border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-envelope"></i></span></a></div> -->
              </li>
            </ul>
            <div class="message-input p-field p-col-12 " *ngIf="status1 != 'Closed' && status1 != 'Cancelled'">
              <!-- <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
              style="background-color: #eee;border:1px solid #bbb;" /> -->
              <p-editor [(ngModel)]="issueNote" [style]="{'height':'200px'}" placeholder="Write a note here" style="background-color: #eee;"></p-editor>
              <!-- <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
                style="background-color: #eee;border:1px solid #bbb;" /> -->
              <div class="card-header">
                <div class="p-col-2">
                <span class="p-input-icon-left">
                <p-fileUpload #fubauto mode="basic" customUpload="true"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,false)" auto="true" chooseLabel="Attach File"
                  pTooltip="Attach file"></p-fileUpload>
                   </span>
                   </div>
                   <div class="p-col-4">
                  <span class="p-input-icon-left" style="left: -23%;">
                <p-fileUpload #fubautoD mode="basic" customUpload="true" [disabled]="internal"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,true)" auto="true" chooseLabel="Attach File & Display to Customer"
                  pTooltip="Attach file"></p-fileUpload>
                  </span>
                  </div>
                  <div class="p-col-2"></div>
                  <div class="p-col-4" style="float:right;text-align: right;">
                  <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px;">
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                    class="p-button-raised p-button-info p-mr-2 p-mb-2 width80" (click)="AddNote(false)"></button>
                      <button pButton pRipple type="button" [disabled]="internal" icon="pi pi-plus" iconPos="left" label="Add & Display to Customer"
                        class="p-button-raised p-button-info p-mr-2 p-mb-2 width230" (click)="AddNote(true)"></button>
                  </span>
                  </div>
              </div>
            </div>
            <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>
<p-dialog header="Reason" [(visible)]="reasondisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <h5 style="text-align:center;">{{code}} : {{title}}</h5>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
          <div class="p-col-12 p-md-12 p-xl-12">
              <label>Close Comments<span style="color: red">*</span> </label>
              <textarea rows="5" pInputTextarea id="popupreason" [(ngModel)]="popupreason" placeholder="Enter Comments"
                  required="true" requiredMessage="please enter reason." maxlength="1000" ng-trim="false"
                  style="width: 100%;"></textarea>
              <small class="p-invalid">{{1000 - popupreason.length}} characters are remaining</small>
          </div>
          <div class="p-col-12 p-md-12 p-xl-12">
              <span style="float: right;">
                  <button pButton type="button" (click)="ReasonClear()" label="Clear" icon="pi pi-refresh"
                      pTooltip="Clear" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;"></button>
                  <button pButton type="button" (click)="AddReason()" label="Confirm" icon="pi pi-plus"
                      pTooltip="Confirm" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
              </span>
          </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
          <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ ReasonError
                  }}</small></span>
      </div>
  </div>
</p-dialog>


<p-dialog header="Add Defects" [(visible)]="addInputs" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr /><br>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
        <h5 style="text-align:center;">{{code}} : {{title}}</h5>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
        <div class="p-col-12 p-md-12 p-xl-12">
            <label>Description <span style="color: red">*</span> </label>
            <p-editor [(ngModel)]="popupdescription" [style]="{'height':'350px'}" placeholder="Enter Description"></p-editor>
            <!-- <textarea rows="3" pInputTextarea id="popupdescription" [(ngModel)]="popupdescription" placeholder="Enter Reason"
                required="true" requiredMessage="please enter description." maxlength="1000" ng-trim="false"
                style="width: 100%;"></textarea>
            <small class="p-invalid">{{1000 - popupdescription.length}} characters are remaining</small> -->
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <form>
            <p-fileUpload #fileInput name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
              accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                [showUploadButton]="false" [showCancelButton]="true" fileLimit="25" auto="true">
              <ng-template pTemplate="content">
                <ul *ngIf="fileInput.length">
                  <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </form>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
            <span style="float: right;">
                <button pButton type="button" (click)="AddInput()" label="Add" icon="pi pi-plus" pTooltip="Add" tooltipPosition="right"
                  class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                  style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
            </span>
        </div>
        <div class="p-col-12">
          <div class="invoice invoice-header" id="invoice-content5" style="overflow-x: auto;width: 100%;">
            <p-table [value]="TempInputs" sortMode="multiple"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" > 
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th style="width: 60px;">S.No</th>
                      <th>Description</th>
                      <th style="width: 250px;">Attachments</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                      <td style="text-align: center;width: 60px;">{{ rowData.seq }}</td>
                      <td style="text-align: left; padding-left:15px;">
                        <p-editor [(ngModel)]="rowData.note" [readonly]="true"><p-header hidden></p-header></p-editor></td>
                      <td style="text-align:left; padding-left:15px;width: 250px;">
                        <ul *ngFor="let temp of rowData.documents1" class="tempul">
                            <li class="templi">{{temp}}</li>
                        </ul>
                      </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            </p-table>
          </div>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <span style="float: right;">
              <button pButton type="button" (click)="MainInput()" label="Submit" icon="pi pi-plus" pTooltip="Submit" tooltipPosition="right"
                class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
          </span>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
        <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ InputError }}</small></span>
    </div>
</div>
</p-dialog>

<p-dialog header="Add Clarification" [(visible)]="addclare" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr /><br>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
        <h5 style="text-align:center;">{{code}} : {{title}}</h5>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
      <label> Question  <span style="color: red">*</span> </label> 
      <small class="p-invalid" style="float: right;">{{1000 - popupremark.length}} characters are remaining</small>
      <textarea rows="5" pInputTextarea id="popupremark" [(ngModel)]="popupremark" placeholder="Confirmed"
          maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
      <br>
      <small class="p-invalid"><b>{{ SError }}</b></small>
      <span style="float: right;">
        <button pButton type="button" (click)="Confirm()" label="Seek Clarification" pTooltip="Seek Clarification" tooltipPosition="right" icon="pi pi-question" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width:220px; height:25px;" ></button>
      </span>
    </div>
</div>
</p-dialog>

<p-dialog header="{{TestType}} Test Case" [(visible)]="addTestCase" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr /><br>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
        <h5 style="text-align:center;">{{code}} : {{title}}</h5>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -1rem;">
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-3">
          <label>Test Case ID&nbsp;&nbsp;</label>
          <input type="text" pInputText placeholder="Test Case ID " [(ngModel)]="testcaseID" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-3">
          <p-checkbox label="QA Confirm" binary="true" inputId="binary" [disabled]="!fromEdit" [(ngModel)]="qaconfirm" ></p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <p-checkbox label="Developer Confirm" binary="true" inputId="binary" [disabled]="!fromEdit" [(ngModel)]="devconfirm" ></p-checkbox>
      </div>
      <div class="p-field p-col-12 p-md-3">
        <label>Status&nbsp;&nbsp;</label>
        <p-dropdown [options]="inputstatus" placeholder="Select a status" [(ngModel)]="testcasestatus" optionLabel="label" [disabled]="!fromEdit"></p-dropdown>
      </div>
      </div>
        <div class="p-col-12 p-md-12 p-xl-12">
            <label>Test Steps <span style="color: red">*</span> </label> <small class="p-invalid" style="float: right;">{{1000 - TestSteps.length}} characters are remaining</small>
            <textarea rows="3" pInputTextarea id="TestSteps" [(ngModel)]="TestSteps" placeholder="Enter Test Step"
                required="true" requiredMessage="please enter test step." maxlength="1000" ng-trim="false"
                style="width: 100%;"></textarea>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <label>Actual Result</label> <small class="p-invalid" style="float: right;">{{1000 - actualresult.length}} characters are remaining</small>
          <textarea rows="3" pInputTextarea id="actualresult" [(ngModel)]="actualresult" placeholder="Enter actual result"
              required="true" requiredMessage="please enter actual result." maxlength="1000" ng-trim="false"
              style="width: 100%;"></textarea>
        </div>
        
        <div class="p-col-12 p-md-12 p-xl-12">
          <label>Comments</label>
          <p-editor [(ngModel)]="comments" [style]="{'height':'150px'}" placeholder="Enter Comments"></p-editor>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <span style="float: right;">
              <button pButton type="button" (click)="SaveTestCase()" label="Submit" icon="pi pi-plus" pTooltip="Submit" tooltipPosition="right"
                class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
          </span>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
        <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ TestCaseError }}</small></span>
    </div>
</div>
</p-dialog>