<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
        <p-tabView [activeIndex]="index">
            <p-tabPanel leftIcon="pi pi-sign-in">
                <ng-template pTemplate="header">Requests ({{ DocumentsCount }})</ng-template>
                <p-accordion [activeIndex]="0">
                    <p-accordionTab header="Search Criteria">
                    <div class="p-grid p-fluid p-formgrid" style="margin-left: 3px;">
                        <div class="p-grid p-col-12 p-formgrid">
                            <div class="p-field p-col-12 p-md-2">
                                <label>From:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedFromDate" ></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>To:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedToDate" ></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Application:</label>
                                <p-multiSelect [options]="apps" defaultLabel="Select an Application" [(ngModel)]="selectedApplications" optionLabel="applicationName" [virtualScroll]="true" 
                                selectedItemsLabel="{0} applications selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                        
                            <div class="p-field p-col-12 p-md-2">
                                <label>Request Type</label>
                                <p-multiSelect defaultLabel="Select a Type" [options]="type" [(ngModel)]="selectedtype" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} types selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Priority</label>
                                <p-multiSelect  defaultLabel="Select a Priority" [options]="priority" [(ngModel)]="selectedPriority" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} Priorities selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Status:</label>
                                <p-multiSelect defaultLabel="Select a Status" [options]="status" [(ngModel)]="selectedStatus" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} status selected"[filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div> 
                            <div class="p-field p-col-12 p-md-2">
                                <label>Internal Status:</label>
                                <p-multiSelect defaultLabel="Select a Status" [options]="internalstatus" [(ngModel)]="selectedinternalStatus" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} status selected"[filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div> 
                            <div class="p-field p-col-12 p-md-1" style="padding-right: 10px;">
                                <a>
                                    <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" pTooltip="Search" label="Search"
                                    (click)="getMyRequests()" class="p-button-raised p-button-primary" style="width:100%; margin-top:25px;"></button>
                                </a>
                            </div>
                            <small class="p-invalid" style="float: right;">{{Error}}</small>
                        </div>
                    </div>
                    </p-accordionTab>
                </p-accordion>
                <div class="card-header"
                    style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">My Requests / Requests Assigned to Me</h5>
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                        <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content','My Requests / Requests Assigned to Me','trSearch')" pTooltip="Print"
                            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                            style="float:right;height:25px; width:25px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel('excel-table','My Requests / Requests Assigned to Me')"
                            pTooltip="Download" tooltipPosition="top"
                            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                            style="float:right; height:25px; width:25px;"></button>
                    </span>
                </div>
                <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                    <p-table #dt [value]="requestData" id="excel-table" [columns]="cols" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                        dataKey="iD">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th style="width:80px;">Actions</th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: center;width: 110px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: center;width: 140px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 150px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 40%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 180px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: center;width: 100px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;width: 150px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: left;padding-left:15px;width: 120px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 8" style="text-align: left;padding-left:15px;width: 130px;">
                                    {{rowData[col.field]}}
                                </td>
                                </ng-container>
                                <td style="text-align: center;">
                                    <a (click)="ViewMyRequest(rowData)"> <button pButton type="button" icon="pi pi-eye"
                                            pTooltip="view" tooltipPosition="left"
                                            class="p-button-raised p-button-primary"
                                            style="width:25px; height:25px;text-align:center;"></button>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="10" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel leftIcon="pi pi-sign-in">
                <ng-template pTemplate="header">Tasks - To Complete ({{ PendingCount }})</ng-template>
                <div class="card-header"
                    style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Tasks - To Complete</h5>
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                        <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content1','Tasks - To Complete','trSearch1')" pTooltip="Print"
                            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                            style="float:right;height:25px; width:25px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel('excel-table1','Tasks - To Complete')"
                            pTooltip="Download" tooltipPosition="top"
                            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                            style="float:right; height:25px; width:25px;"></button>
                    </span>
                </div>
                <div class="invoice invoice-header" id="invoice-content1" style="overflow-x: auto;">
                    <p-table #dtPending [value]="TasksPending" id="excel-table1" [columns]="cols1" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"  dataKey="taskID">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                                    {{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th style="width:80px;">Actions</th>
                            </tr>
                            <tr id="trSearch1">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test1($event.target.value,col.field,dtPending)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: center;width: 110px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: center;width: 140px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 150px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 40%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;width: 90px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 40%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;width: 140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: center;width: 130px;">
                                    {{rowData[col.field]}}
                                </td>
                                </ng-container>
                                <td style="text-align: center;">
                                    <a (click)="ViewMyRequest(rowData)"> <button pButton type="button" icon="pi pi-eye"  pTooltip="view" 
                                        tooltipPosition="left" class="p-button-raised p-button-primary" style="width:25px; height:25px;text-align:center;">
                                    </button></a>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="9" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel leftIcon="pi pi-sign-in">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab header="Search Criteria">
                    <div class="p-grid p-fluid p-formgrid" style="margin-left: 3px;">
                        <div class="p-grid p-col-12 p-formgrid">
                            <div class="p-field p-col-12 p-md-2">
                                <label>Completed From:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedCFromDate" ></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Completed To:</label>
                                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedCToDate" ></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Application:</label>
                                <p-multiSelect [options]="apps" defaultLabel="Select an Application" [(ngModel)]="selectedCApplications" optionLabel="applicationName" [virtualScroll]="true" 
                                selectedItemsLabel="{0} applications selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Request Type</label>
                                <p-multiSelect defaultLabel="Select a Type" [options]="type" [(ngModel)]="selectedCtype" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} types selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Priority</label>
                                <p-multiSelect  defaultLabel="Select a Priority" [options]="priority" [(ngModel)]="selectedCPriority" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} Priorities selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label>Status:</label>
                                <p-multiSelect defaultLabel="Select a Status" [options]="status1" [(ngModel)]="selectedCStatus" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} status selected"[filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div> 
                            <div class="p-field p-col-12 p-md-2">
                                <label>Internal Status:</label>
                                <p-multiSelect defaultLabel="Select a Status" [options]="internalstatus" [(ngModel)]="selectedCinternalStatus" optionLabel="label" [virtualScroll]="true"
                                selectedItemsLabel="{0} status selected"[filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                            </div> 
                            <div class="p-field p-col-12 p-md-1" style="padding-right: 10px;">
                                <a>
                                    <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" pTooltip="Search" label="Search"
                                    (click)="getMyRequests()" class="p-button-raised p-button-primary" style="width:100%; margin-top:25px;"></button>
                                </a>
                            </div>
                            <small class="p-invalid" style="float: right;">{{Error}}</small>
                        </div>
                    </div>
                    </p-accordionTab>
                </p-accordion>
                <ng-template pTemplate="header">Tasks - Completed ({{ CompletedCount }})</ng-template>
                <div class="card-header"
                    style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Tasks - Completed</h5>
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                        <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content2','Tasks - Complete','trSearch2')" pTooltip="Print"
                            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                            style="float:right;height:25px; width:25px;"></button>
                        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel('excel-table2','Tasks - Complete')"
                            pTooltip="Download" tooltipPosition="top"
                            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                            style="float:right; height:25px; width:25px;"></button>
                    </span>
                </div>
                <div class="invoice invoice-header" id="invoice-content2" style="overflow-x: auto;">
                    <p-table #dtCompleted [value]="TasksCompleted" id="excel-table2" [columns]="cols2" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"  dataKey="taskID">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                                    {{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th style="width:80px;">Actions</th>
                            </tr>
                            <tr id="trSearch2">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test2($event.target.value,col.field,dtCompleted)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: center;width: 110px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: center;width: 140px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 150px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 40%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;width: 90px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 40%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;width: 140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: center;width: 130px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 8" style="text-align: center;width: 100px;">
                                    {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                </td>
                                <td *ngIf="i == 9" style="text-align: center;width: 100px;">
                                    {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                </td>
                                </ng-container>
                                <td style="text-align: center;">
                                    <a (click)="ViewMyRequest(rowData)"> <button pButton type="button" icon="pi pi-eye"  pTooltip="view" 
                                        tooltipPosition="left" class="p-button-raised p-button-primary" style="width:25px; height:25px;text-align:center;">
                                    </button></a>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="11" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>