import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import {Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';

@Component({
  selector: 'app-support-dashboard',
  templateUrl: './support-dashboard.component.html',
  styleUrls: ['./support-dashboard.component.scss']
})
export class SupportDashboardComponent implements OnInit {

  showLoading:boolean;
  userId = sessionStorage.getItem('userId');
  // clientID= sessionStorage.getItem('clientId');
  Counts: any;
  isChartDataAvailable:boolean;
  statusChart:any;
  options: any;
  labels: number[] = [];
  priorityChartlabels: number[] = [];
  isPriorityChartDataAvailable:boolean;
  priorityChart:any;

  TimelineOptions: any;
  selectedTimelineOption: any;
  isTimelineDataAvailable:boolean;
  Timeline: any = [];
  requestData:any;
  DocumentsCount:any;
  data:any[]=[];
  priorityChartdata:any[]=[];

  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    sessionStorage.dashboardstatus = null;
    this.TimelineOptions = [
      { name: 'Week',value: 7 },
      { name: '2 Weeks',value: 14 },
      { name: '3 Weeks',value: 21 },
      { name: 'Month',value: 30 },
    ];
    
      this.getCounts();
      this.getChartData();
      this.getPriorityChartData();
      this.cols = [
        { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
        { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
        { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
        { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
        { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
        { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
        { field: 'ownerName', header: 'Request Owner', dynamicPlaceHolder: 'Search', width: '150px' },
        { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '120px' },
        { field: 'internalStatus', header: 'Internal Status', dynamicPlaceHolder: 'Search', width: '130px' },
      ];
      sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.ownerName = "";
    sessionStorage.status = "";
    sessionStorage.internalStatus = "";
      this.getDashboardTableData();
    this.getDashboardTimeline(7);
}

getCounts() {
  this.showLoading = true;
  this._facadeService.getSupportDashboardCounts(this.userId).subscribe(
    (data) => {
      this.Counts = data.returnObject;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}

getChartData() {
  this.showLoading = true;
  this._facadeService.GetInternalChartData(this.userId).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.labels.push(res.returnObject[i].status);
      }
      this.data = [];
      for (let i = 0; i < res.returnObject.length; i++) {
        this.data.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.statusChart = {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              '#FBC02D',
              '#2196F3',
              '#689F38',
              '#FF0000',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

getPriorityChartData() {
  this.showLoading = true;
  this._facadeService.getPriorityChartData(this.userId,true).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartlabels.push(res.returnObject[i].status);
      }
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartdata.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isPriorityChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.priorityChart = {
        labels: this.priorityChartlabels,
        datasets: [
          {
            data: this.priorityChartdata,
            backgroundColor: [
              '#990000',
              '#FF0000',
              '#ff9933',
              '#66cc33',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

getDashboardTimeline(type) {
  this.showLoading = true;
  // this._facadeService.getDashboardTimeline(this.userId, type,parseInt(this.clientID)).subscribe(
    this._facadeService.getDashboardTimeline(this.userId, type,2).subscribe(
    (data) => {
      this.showLoading = false;
      this.Timeline = data.returnObject;  
      if( this.Timeline.length>0) {  
        this.isTimelineDataAvailable = true;   
      var dict = []; // create an empty dictionary  
      for (let i = 0; i < this.Timeline.length; i++) { 
        let convertactiondate = new Date(this.Timeline[i].actionDate+'Z');  
        dict.push({
          requestID: this.Timeline[i].requestID,
          title: this.Timeline[i].title,
          icon: this.Timeline[i].icon,
          subTitleMod: this.sanitizer.bypassSecurityTrustHtml("The Request " + '<a style="cursor:pointer;">' + this.Timeline[i].requestname+ '</a>' +" "+this.Timeline[i].subTitle+ " by "+ this.Timeline[i].actionByUserName + " on " +convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString())  
      });       
      }        
      //console.log("Time Line subtitle modified data Data :: " + JSON.stringify(dict));
      this.Timeline = dict;  
    } 
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'Active Requests','trSearch',true);
}
exportExcel() {
  /* table id is passed over here */
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 120 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 120 },{ wpx : 135 },{ wpx : 135 }];
  /* save to file */
  XLSX.writeFile(wb, "Active Requests.xlsx");
}
delete_row(ws, row_index) {
  let range = XLSX.utils.decode_range(ws["!ref"])
  for (var R = row_index; R < range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
      if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
        ws[this.ec(R + 1, C)].v = "";
    }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({ r: r, c: c })
}
getDashboardTableData() {
  this.requestData=[];
  this.showLoading = true;
  this._facadeService.GetSupportDashboardTableData(this.userId).subscribe(
    (data) => {
      this.requestData = data.returnObject;
      if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
      {
        this.requestData.forEach((value, key) => {              
          if(value.requestDate != '' && value.requestDate != null)
            value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");        
        });
      }
      this.CountData();
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
test(val, field, dt) {
  if (field == "code")
    sessionStorage.code = val;
  else if (field == "requestDate")
    sessionStorage.requestDate = val;
  else if (field == "requestedByName")
    sessionStorage.requestedByName = val;
  else if (field == "title")
    sessionStorage.title = val;
  else if (field == "type")
    sessionStorage.type = val;
  else if (field == "priority")
    sessionStorage.priority = val;
  else if (field == "ownerName")
    sessionStorage.ownerName = val;
  else if (field == "internalStatus")
    sessionStorage.internalStatus = val;
  else if (field == "status")
    sessionStorage.status = val;
  dt = dt.filter(val, field, 'contains');
  this.CountData();
}

CountData() {
  if (this.requestData != undefined && this.requestData != null) {
    var temp = this.requestData;
    if (this.requestData.length > 0) {
      if (sessionStorage.code != "")
        temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.requestDate != "")
        temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
      if (sessionStorage.requestedByName != "")
        temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.type != "")
        temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
      if (sessionStorage.priority != "")
        temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
      if (sessionStorage.ownerName != "")
        temp = temp.filter(x => x.ownerName == null ? '' : x.ownerName.toLowerCase().includes(sessionStorage.ownerName.toLowerCase()));
      if (sessionStorage.internalStatus != "")
        temp = temp.filter(x => x.internalStatus == null ? '' : x.internalStatus.toLowerCase().includes(sessionStorage.internalStatus.toLowerCase()));
      if (sessionStorage.status != "")
        temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    }
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
ViewMyRequest(rowdata) {
  sessionStorage.From = "SDashboard";
  sessionStorage.requestID = rowdata;
  this.router.navigate(['/clientRequestsDetails']);
}

Tile(val){
  if(val == "new" || val == "inprocess")
  {
    if(val == "new")
      sessionStorage.dashboardstatus = "New"
    else 
      sessionStorage.dashboardstatus = "In-Progress,In-Testing,UAT-Rework,UAT-Bug";
    this.router.navigate(['/assignedToMe',{ index: 0 }]);
  }
  else if(val == "tocomplete")
    this.router.navigate(['/assignedToMe',{ index: 1 }]);
  else if(val == "completed")
    this.router.navigate(['/assignedToMe',{ index: 2 }]);
  else if(val == "unassigned")
    this.router.navigate(['/unAssignedRequests']);
  else
  {
    if(val == 'upcoming')
    {
      sessionStorage.upcoming = "U";
      sessionStorage.dashboardstatus = "Saved,Pending Confirmation,Seek Clarification";
    }
    else
    {
      if(val == "allnew")
        sessionStorage.dashboardstatus = "New"
      else 
        sessionStorage.dashboardstatus = "In-Progress,In-Testing,UAT-Rework,UAT-Bug";
    }
    this.router.navigate(['/reportByStatus']); 
  }  
}

}

