<div class="p-grid  p-fluid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-grid p-fluid p-formgrid">
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" >
            <button pButton type="button" label="Back" iconPos="left" class="p-button-raised p-button-primary"  (click)="Back()"
              icon="pi pi-angle-left" style="margin-left:5px; width: 90px; height:35px;"></button>
          </div>
          <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12" style="text-align: center;"> <h5>Request Details</h5></div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float: right;" >
            <button pButton type="button" (click)="SendClose()" *ngIf="status == 'Production' && canClose"
            label="Close Request" iconPos="left"  class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2"
            icon="pi pi-times" style="width: 160px; height:35px;"></button> 
          </div>
        </div>
        <p-confirmDialog key="confirm-close" header="Close Request" acceptLabel="Yes" rejectLabel="No"
        [(visible)]="confirmClose"></p-confirmDialog>
        <hr style="margin-top: 0px;" />    
        <div class="p-col-12" style="float: right;"><small class="p-invalid">{{Error}}</small></div>   
        <div class="p-grid p-formgrid">
          <div class="p-field p-col-12 p-md-2">
            <label>Request ID</label>
            <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Requested Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date"
              [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Requested By</label>
            <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Initiated By</label>
            <input type="text" pInputText [(ngModel)]="createdByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Application</label>
            <input type="text" pInputText [(ngModel)]="applicationName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Request Type</label>
            <input type="text" pInputText [(ngModel)]="type" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
            <label>Confirmed By</label>
            <input type="text" pInputText [(ngModel)]="confirmedbyName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
            <label>Confirmed Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="confirmedDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-8" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
            <label>Confirmed Remarks</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="confirmedremarks" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
            <label>Cancelled By</label>
            <input type="text" pInputText [(ngModel)]="cancelledByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
            <label>Cancelled Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="cancelledDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Cancelled'">
            <label>Cancelled Remarks</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="cancelledRemarks" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
            <label>Closed By</label>
            <input type="text" pInputText [(ngModel)]="closedByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
            <label>Closed Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="closedDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Closed'">
            <label>Closed Remarks</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="closedRemarks" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
            <label>UAT Approved By</label>
            <input type="text" pInputText [(ngModel)]="uATAcknowledgeName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
            <label>UAT Approved Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="uATAcknowledgeDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-8" *ngIf="isUAT">
            <label>UAT Approved Remarks</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="uATAcknowledgeRemarks" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
            <label>Production Approved By</label>
            <input type="text" pInputText [(ngModel)]="productionAcknowledgeName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
            <label>Production Approved Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="productionAcknowledgeDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-8" *ngIf="isProduction">
            <label>Production Approved Remarks</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="productionAcknowledgeRemarks" />
          </div>
          <div class="p-field p-col-12 p-md-10">
            <label>Short Description</label>
            <input type="text" pInputText [disabled]="true" [(ngModel)]="title"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Priority</label>
            <input type="text" pInputText [(ngModel)]="priority" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Status</label>
            <input type="text" pInputText [(ngModel)]="status" [disabled]="true" />
          </div>

          <div class="p-field p-col-12 p-md-2">
            <label>Internal Status</label>
            <input type="text" pInputText [(ngModel)]="internalstatus" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Request Owner</label>
            <input type="text" pInputText [(ngModel)]="ownerName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Estimated Effort (Hours)</label>
            <input type="text" pInputText [(ngModel)]="estimatedHours" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual Effort (Hours)</label>
            <input type="text" pInputText [(ngModel)]="actualHours"  maxlength="10" type="number" [disabled]="true"  />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Progress Completed (%)</label>
            <input type="text" pInputText [(ngModel)]="progress" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Planned Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedStartDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Planned End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Current Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="currentStartDate" [disabled] = "true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Current End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="currentEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="actualStartDate" [disabled] = "true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="actualEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="GoogleAvaialable" style="margin-top: 10px;">
            <br>
            <a href="{{GoogleLink}}" target="_blank">Google Drive</a>         
          </div>
          <div class="p-field p-col-12 p-md-12"></div>
          <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:99%;">
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
              <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Tasks</h5>
             </div>
            <div class="invoice invoice-header" id="invoice-content">
              <p-table #dt [value]="docTypes" id="excel-table" styleClass="p-datatable-striped" sortMode="multiple" class="table-align" editMode="row" 
              [filterDelay]="0" selectionMode="multiple" dataKey="taskName">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th style="width: 30px;">Seq</th>
                      <th>Task</th>
                      <th>Scope</th>
                      <th style="width:80px;">Target Start Date</th>
                      <th style="width:80px;">Target End Date</th>
                      <th style="width:80px;">Current Start Date</th>
                      <th style="width:80px;">Current End Date</th>
                      <th style="width:80px;">Actual Start Date</th>
                      <th style="width:80px;">Actual End Date</th>
                      <th style="width:70px;">Estimated Hours</th>
                      <th style="width:50px;">Actual Hours</th>
                      <th style="width:60px;">Progress Completed</th>
                      <th style="width:80px;">Status</th>
                      <th style="width:120px;">Predecessor</th>
                      <th style="width:120px;">Assigned To</th>
                      <th style="width:80px;">Affect Allocation</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
                  <tr [pEditableRow]="rowData">
                      <td style="text-align: center;width: 30px;">{{rowData.taskSeq}}</td>
                      <td style="text-align: left;padding-left: 15px;">{{rowData.taskName}}</td>
                      <td style="text-align: left;padding-left: 15px;">{{rowData.scope}}</td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.plannedStartDate != '--' && rowData.plannedStartDate != null">{{rowData.plannedStartDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="rowData.plannedStartDate == '--' || rowData.plannedStartDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.plannedEndDate != '--' && rowData.plannedEndDate != null">{{rowData.plannedEndDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="rowData.plannedEndDate == '--' || rowData.plannedEndDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.currentStartDate != '--' && rowData.currentStartDate != null">{{rowData.currentStartDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="rowData.currentStartDate == '--' || rowData.currentStartDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.currentEndDate != '--' && rowData.currentEndDate != null">{{rowData.currentEndDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="rowData.currentEndDate == '--' || rowData.currentEndDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.actualStartDate != '--' && rowData.actualStartDate != null">{{rowData.actualStartDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.actualStartDate == '--' || rowData.actualStartDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:80px;">
                        <span *ngIf="rowData.actualEndDate != '--' && rowData.actualEndDate != null">{{rowData.actualEndDate | date:'MM/dd/yyyy'}}</span>
                        <span *ngIf="rowData.actualEndDate == '--' || rowData.actualEndDate == null">--</span>
                      </td>
                      <td style="text-align: center;width:70px;">{{rowData.estimatedHours}}</td>
                      <td style="text-align: center;width:50px;">{{rowData.actualHours}}</td>
                      <td style="text-align: center;width:60px;">{{rowData.progressCompleted}} </td>
                      <td style="text-align: center;width:80px;"> {{rowData.status}}</td>
                      <td style="text-align: left;padding-left: 15px;width:120px;">{{rowData.predecessor}}</td>
                      <td style="text-align: left; padding-left: 15px;width:120px;">{{rowData.assignee}}</td>
                      <td style="text-align: center;width:80px;"><span *ngIf="rowData.affectAllocation">Yes</span><span *ngIf="!rowData.affectAllocation">No</span> </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="17" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            
            </p-table>
            </div>
        </div>
      </div>
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="" [headerStyle]="{'width': '0px'}"><br></p-tabPanel>
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'160px'}" [readonly]="true" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip" *ngIf="ViewAddedDocuments.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                      {{rowData.fileName}} </a></span></td>
                <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Clarifications" leftIcon="pi pi-question" *ngIf="Clarification1.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtClarification1 [value]="Clarification1" [columns]="Clarification1Cols" sortMode="multiple" [loading]="loading"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Support Clarifications" leftIcon="pi pi-question" *ngIf="SClarification.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content2" style="overflow-x: auto;">
              <p-table #dtSClarification [value]="SClarification" [columns]="Clarification1Cols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="SselectedClarification"> 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Inputs" leftIcon="pi pi-bars" *ngIf="Inputs.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtInputs [value]="Inputs" [columns]="InputsCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 150px;">Attachments</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;width: 110Ppx;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 130px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:50%">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:100px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:25%">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align:left; padding-left:15px;width: 150px;">
                          <ul *ngFor="let temp of rowData.documents" class="tempul">
                              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
                          </ul>
                        </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Defects" leftIcon="pi pi-file-excel" *ngIf="Inputs1.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtInputs1 [value]="Inputs1" [columns]="InputsCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 150px;">Attachments</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;width: 110Ppx;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:50%">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:100px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:25%">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align:left; padding-left:15px;width: 150px;">
                          <ul *ngFor="let temp of rowData.documents" class="tempul">
                              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
                          </ul>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Work Logs" leftIcon="pi pi-align-right" *ngIf="Logs.length > 0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content10" style="overflow-x: auto;">
              <p-table #dtlog [value]="Logs" id="excel-table10" [columns]="Logcols" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                        dataKey="taskID">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test($event.target.value,col.field,dtlog)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                     <td *ngIf="i == 0" style="text-align: center;width:90px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width:130px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width:25%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: center;width:80px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: center;width:90px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: center;width:70px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;width:130px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: left;padding-left:15px;width: 65%;">
                                        {{rowData[col.field]}}
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="card">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5> 
      </div>
      <div class="p-field p-col-12" style="padding: 0px;">
        <div class="card" style="border:1px solid #eee;">
          <div class="widget-chat">
            <ul *ngFor="let note of notesList">
              <li class="message-from">
                <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.notesAddedByName }}</span>
                  <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                  </span>
                  <span style="font-size: 14px;padding-right: 10px;">{{ note.note }}</span>
                </div>
                <div *ngIf="note.dmsID > 0" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="DMSToken(note.downloadFileName,note.userFileName)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Download File">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                 border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-download"></i></span></a></div>
                <!-- <div *ngIf="note.dmsID == 0 && !note.sentMail && note.ok" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="SendMail(note.id)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Send Mail">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                  border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-envelope"></i></span></a></div> -->
              </li>
            </ul>
            <div class="message-input p-field p-col-12 " *ngIf="status != 'Closed' && status != 'Cancelled'">
              <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
                style="background-color: #eee;border:1px solid #bbb;" />
              <div class="card-header">
                <div class="p-col-2">
                <span class="p-input-icon-left">
                <p-fileUpload #fubauto mode="basic" customUpload="true"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,false)" auto="true" chooseLabel="Attach File"
                  pTooltip="Attach file"></p-fileUpload>
                   </span>
                   </div>
                   <div class="p-col-4">
                  <span class="p-input-icon-left" style="left: -23%;">
                <p-fileUpload #fubautoD mode="basic" customUpload="true" [disabled]="internal"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,true)" auto="true" chooseLabel="Attach File & Display to Customer"
                  pTooltip="Attach file"></p-fileUpload>
                  </span>
                  </div>
                  <div class="p-col-2"></div>
                  <div class="p-col-4" style="float:right;text-align: right;">
                  <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px;">
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                    class="p-button-raised p-button-info p-mr-2 p-mb-2 width80" (click)="AddNote(false)"></button>
                      <button pButton pRipple type="button" [disabled]="internal" icon="pi pi-plus" iconPos="left" label="Add & Display to Customer"
                        class="p-button-raised p-button-info p-mr-2 p-mb-2 width230" (click)="AddNote(true)"></button>
                  </span>
                  </div>
              </div>
            </div>
            <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>
<p-dialog header="Reason" [(visible)]="reasondisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <h5 style="text-align:center;">{{code}} : {{title}}</h5>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
          <div class="p-col-12 p-md-12 p-xl-12">
              <label>Cancel Reason <span style="color: red">*</span> </label>
              <textarea rows="5" pInputTextarea id="popupreason" [(ngModel)]="popupreason" placeholder="Enter Reason"
                  required="true" requiredMessage="please enter reason." maxlength="1000" ng-trim="false"
                  style="width: 100%;"></textarea>
              <small class="p-invalid">{{1000 - popupreason.length}} characters are remaining</small>
          </div>
          <div class="p-col-12 p-md-12 p-xl-12">
              <span style="float: right;">
                  <button pButton type="button" (click)="ReasonClear()" label="Clear" icon="pi pi-refresh"
                      pTooltip="Clear" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;"></button>
                  <button pButton type="button" (click)="AddReason()" label="Confirm" icon="pi pi-plus"
                      pTooltip="Confirm" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
              </span>
          </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
          <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ ReasonError
                  }}</small></span>
      </div>
  </div>
</p-dialog>