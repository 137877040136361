
<div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-3">
        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:160px;">
          <span class="overview-icon">
            <i class="pi pi-user-edit"></i>
          </span>
          <span class="overview-title">My Requests</span>
          <div class="p-grid overview-detail">
            <a class="p-col-6" style="cursor: pointer;border-right: 1px solid #dee2e6;" (click)="Tile('new')">
              <div class="overview-number">{{ Counts?.mynew }}</div>
              <div class="overview-subtext">New</div>
            </a>
            <a class="p-col-6" style="cursor: pointer" (click)="Tile('inprocess')">
              <div class="overview-number">{{ Counts?.myinprocess }}</div>
              <div class="overview-subtext">In-Progress</div>
            </a>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-3">
        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:160px;">
          <span class="overview-icon">
            <i class="pi pi-user-edit"></i>
          </span>
          <span class="overview-title">My Tasks</span>
          <div class="p-grid overview-detail">
            <a class="p-col-6" style="cursor: pointer;border-right: 1px solid #dee2e6;" (click)="Tile('tocomplete')">
              <div class="overview-number">{{ Counts?.mytaskspending }}</div>
              <div class="overview-subtext">To Completed</div>
            </a>
            <a class="p-col-6" style="cursor: pointer" (click)="Tile('completed')">
              <div class="overview-number">{{ Counts?.mytaskscompleted }}</div>
              <div class="overview-subtext">Completed</div>
            </a>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-3">
        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:160px;">
          <span class="overview-icon">
            <i class="pi pi-user-edit"></i>
          </span>
          <span class="overview-title">Active Requests</span>
          <div class="p-grid overview-detail">
            <a class="p-col-6" style="cursor: pointer;border-right: 1px solid #dee2e6;" (click)="Tile('allnew')">
                <div class="overview-number">{{ Counts?.allnew }}</div>
                <div class="overview-subtext">New</div>
              </a>
              <a class="p-col-6" style="cursor: pointer" (click)="Tile('allinprocess')">
                <div class="overview-number">{{ Counts?.allinprogress }}</div>
                <div class="overview-subtext">In-Progress</div>
              </a>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-3">
        <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:160px;">
          <span class="overview-icon">
            <i class="pi pi-user-edit"></i>
          </span>
          <span class="overview-title">Upcoming Requests</span>
          <div class="p-grid overview-detail">
            <!-- <a class="p-col-6" style="cursor: pointer;border-right: 1px solid #dee2e6;" (click)="Tile('unassigned')">
              <div class="overview-number">{{ Counts?.unassigned }}</div>
              <div class="overview-subtext">Unassigned</div>
            </a> -->
            <a class="p-col-12" style="cursor: pointer;" (click)="Tile('upcoming')">
              <div class="overview-number">{{ Counts?.upcoming }}</div>
              <div class="overview-subtext">Saved / Pending Confirmation</div>
            </a> 
          </div>
        </div>
      </div>
        <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
        <div class="card" style="height:400px;overflow-x: auto;">
          <div class="card-header" style="padding-bottom:0px;">
            <h4>Active Requests By Status</h4>
          </div>
          <hr>
          <div class="p-grid p-formgrid">
            <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container"
            style="width: 400px;">
              <p-chart type="pie" id="revenue-chart" [data]="statusChart" [options]="options"></p-chart>
            </div>
            <ng-template #Chartemptymessage>
              <div style="text-align: center;margin-left: 15px;">No records found...</div>
            </ng-template>
          </div>
        </div>
      </div>
  
      <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
        <div class="card" style="height:400px;overflow-x: auto;">
          <div class="card-header" style="padding-bottom:0px;">
            <h4>Active Requests By Priority</h4>
          </div>
          <hr>
          <div class="p-grid p-formgrid">
            <div *ngIf="isPriorityChartDataAvailable;else PriorityChartemptymessage;" class="revenue-chart-container"
            style="width: 400px;">
              <p-chart type="pie" id="revenue-chart" [data]="priorityChart" [options]="options"></p-chart>
            </div>
            <ng-template #PriorityChartemptymessage>
              <div style="text-align: center;margin-left: 15px;">No records found...</div>
            </ng-template>
          </div>
        </div>
      </div>
  
      <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
        <div class="card" style="height:400px; overflow-y: scroll;">
          <div class="card-header" style="padding-bottom:0px;">
            <h4>Timeline</h4>
            <p-dropdown [options]="TimelineOptions" (onChange)="getDashboardTimeline($event.value.value)"
              [(ngModel)]="selectedTimelineOption" optionLabel="name" styleClass="dashbard-demo-dropdown"
              [ngStyle]="{'min-width':'8rem'}"></p-dropdown>
          </div>
          <hr>
          <div class="widget-timeline" *ngIf='isTimelineDataAvailable;else TimeLineemptymessage'>
            <div *ngFor="let item of Timeline;">
              <div class="timeline-event">
                <span class="timeline-event-icon" style="background-color: #64B5F6;">
                  <i class="pi {{item.icon}}"></i>
                </span>
                <div class="timeline-event-title">{{item.title}}</div>
                <!-- <div class="timeline-event-detail" [innerHTML]="item.subTitleMod"></div> -->
                <div class="timeline-event-detail" (click)="ViewMyRequest(item.requestID)" [innerHTML]="item.subTitleMod"></div>
  
              </div>
            </div>
          </div>
          <ng-template #TimeLineemptymessage>
            <div style="text-align: center;">No records found...</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
  
        <h5 style="color:#495057; padding:15px 0 0 10px;width: 60%;">Active Requests - {{DocumentsCount}} </h5> <!-- T0010003 -->
        <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
  
  
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
  
        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
        <p-table #dt [value]="requestData" id="excel-table" [columns]="cols" sortMode="multiple"
            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
            dataKey="iD">
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                        [ngStyle]="{'width': col.width}">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                    <th style="width:80px;">Actions</th>
                </tr>
                <tr id="trSearch">
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                        (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 110px;">
                            {{ rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 1" style="text-align: center;width: 140px;">
                            {{rowData[col.field] | date:'MM/dd/yyyy'}}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 150px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 40%;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 180px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;width: 100px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 6" style="text-align: left;padding-left:15px;width: 150px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 7" style="text-align: left;padding-left:15px;width: 120px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 8" style="text-align: left;padding-left:15px;width: 130px;">
                          {{rowData[col.field]}}
                       </td>
                    </ng-container>
                    <td style="text-align: center;">
                        <a (click)="ViewMyRequest(rowData.id)"> <button pButton type="button" icon="pi pi-eye"
                                pTooltip="view" tooltipPosition="left"
                                class="p-button-raised p-button-primary"
                                style="width:25px; height:25px;text-align:center;"></button>
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="10" style="text-align: left;">No records found...</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
  
    </div>
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>