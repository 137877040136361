import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit {
  constructor(private route: Router, private router: ActivatedRoute, private _facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  showLoading: boolean;
  ngOnInit(): void {
    var guid = this.router.snapshot.queryParamMap.get('userguid');
    var userId = this.router.snapshot.queryParamMap.get('userId');
    var IsDCCQuery = this.router.snapshot.queryParamMap.get('IsDCC');
    var destination = this.router.snapshot.queryParamMap.get('destination');
    var token = this.router.snapshot.queryParamMap.get('access_token');
    var requestID = this.router.snapshot.queryParamMap.get('requestID');
    var releasedate = this.router.snapshot.queryParamMap.get('releasedate');
    var userIdint = parseInt(userId);
    //var issueId = this.router.snapshot.queryParamMap.get('requestGuid');
    sessionStorage.frommailreleasedate = releasedate;
    sessionStorage.userId = userIdint;
    sessionStorage.userGuid = guid;
    sessionStorage.IsDCC = IsDCCQuery;
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    var clientId = this.router.snapshot.queryParamMap.get('clientId');
    sessionStorage.appliId = appliId;
    sessionStorage.clientId = clientId;
    sessionStorage.setItem("access_token", token);
    this.showLoading = true;
    this._facadeService.UpdateSecurityToken(token).subscribe(
      (data) => {
        if (data.returnObject) {
          this.showLoading = false;
          if (destination == "pendingConfirmation")
            this.route.navigate(["/pendingConfirmationRequest"]);
          else if (destination == "myRequests") {
            sessionStorage.From = "MyRequest";
            sessionStorage.requestID = requestID;
            this.route.navigate(['/requestDetails']);
            // this.route.navigate(["/myRequests"]);
          }
          else if (destination == "clientreport") {
            sessionStorage.From = "Report";
            sessionStorage.requestID = requestID;
            this.route.navigate(['/requestDetails']);
            // this.route.navigate(["/reportByTime"]);
          }
          else if (destination == "unassign")
            this.route.navigate(["/unAssignedRequests"]);
          else if (destination == "supportreport") {
            sessionStorage.From = "SReport";
            sessionStorage.requestID = requestID;
            this.route.navigate(['/clientRequestsDetails']);
            // this.route.navigate(["/reportByStatus"]);
          }
          else if (destination == "releasenotesreport")
            this.route.navigate(["/releasenotesreport"]);
          else {
            if (clientId != "2")
              this.route.navigate(["/dashboard", { IsDCC: IsDCCQuery }]);
            else
              this.route.navigate(["/supportDashboard", { IsDCC: IsDCCQuery }]);
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );

  }
}
