import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FacadeService } from "../../facade/facade.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BASE_API_URL_TOKEN } from "src/app/injectors";
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { FileUpload } from "primeng/fileupload";
import { Table } from "primeng/table";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: 'app-request-summary-details',
  templateUrl: './request-summary-details.component.html',
  styleUrls: ['./request-summary-details.component.scss'],
  providers: [ConfirmationService],
})
export class RequestSummaryDetailsComponent implements OnInit {
  isUAT:boolean = false;
  isProduction:boolean = false;
  GoogleAvaialable:boolean = false;
  GoogleLink:string="#";

  Inputs: any[] = [];
  @ViewChild('dtInputs') tableInputs: Table;
  Inputs1: any[] = [];
  @ViewChild('dtInputs1') tableInputs1: Table;
  InputsCols: any[];

  internal :boolean = false;
  reasondisplay = false;
  popupreason: string = "";
  ReasonError: string;
  confirmClose = false;

  index:number=0;
  Error:string = "";
  canClose:boolean = false
  showLoading:boolean;
  requestID = parseInt(sessionStorage.getItem("requestID"));
  code:string="";
  requestedByName:string="";
  selectedreqDate: any;
  applicationName:any;
  type: any;
  priority: any;
  status:string;
  createdByName:string;
  selecteddueDate:any;
  estimatedHours:string;
  actualHours:string;
  description: string = ""; 
  title:any;
  owner:any;
  ownerName:string;
  confirmedbyName:string;
  confirmedDate:any;
  confirmedremarks: string = "";
  cancelledByName:string;
  cancelledDate:any;
  cancelledRemarks: string = "";
  closedByName:string;
  closedDate:any;
  closedRemarks: string = "";

  uATAcknowledgeName:string;
  uATAcknowledgeDate:any;
  uATAcknowledgeRemarks: string = "";

  productionAcknowledgeName:string;
  productionAcknowledgeDate:any;
  productionAcknowledgeRemarks: string = "";
  startDate:any;

  internalstatus: any;
  plannedStartDate:any;
  plannedEndDate:any;
  currentStartDate:any;
  currentEndDate:any;
  actualStartDate:any;
  actualEndDate:any;

  SClarification: any[] = [];
  SselectedClarification: any[];
  @ViewChild('dtSClarification') tableSClarification: Table;

  userId = sessionStorage.getItem('userId');
  cols: any[];  
  ViewAddedDocuments: any[] = [];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;
  selectedUser:any;
  users:any;
  // Notes
  notesList: any[] = [];
  issueNote:any;
  DocsError:string="";

  docTypes: any[];
  @ViewChild('dt') table: Table;
  ShowTasks:boolean = false;

  progress:any;
  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  apiResponse: any;
  @ViewChild("fileInput") fileInput: FileUpload;
  @ViewChild("fubauto") fubauto: FileUpload;
  @ViewChild("fubautoD") fubautoD: FileUpload;

  Clarification1: any[] = [];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  Clarification1Cols: any[];

  requestDetails: any;
  Logcols:any[];
  Logs:any[] = [];
  constructor( private _facadeService: FacadeService,
    private http: HttpClient,private confirmation: ConfirmationService,
    private route: ActivatedRoute, private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }
    Back() {
      var from = sessionStorage.getItem("From");
      if(from != null && from != undefined)
      {
        if(from != "")
        {
          if(from == "SDashboard")
            this.router.navigate(['/supportDashboard']);
          if(from == "SReport")
            this.router.navigate(['/reportByStatus']);
          if(from == "ReleaseReport")
            this.router.navigate(['/releasenotesreport']);
          if(from == "LogReport")
            this.router.navigate(['/dailylogreport']);
        }
        else
          this.router.navigate(['/reportByStatus']);
      }
      else
          this.router.navigate(['/reportByStatus']);
    }
  ngOnInit(): void {   
    // this.requestID = parseInt(this.route.snapshot.paramMap.get('requestID'));
    this.Logcols = [
      { field: 'logDate', header: 'Log Date', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'logByname', header: 'Log By', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '25%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '80px' },
      { field: 'totalWorkHours', header: 'Total spent till Now', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'workHours', header: 'Work Hours', dynamicPlaceHolder: 'Search', width: '70px' },
      { field: 'progressCompleted', header: 'Progress Completed (%)', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '65%' }
    ];
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.Clarification1Cols = [
      { field: 'askedDate', header: 'Sought Date' },
      { field: 'question', header: 'Clarification' },
      { field: 'askedByName', header: 'Sought By' },
      { field: 'answerDate', header: 'Response Date' },
      { field: 'answer', header: 'Response' },
      { field: 'answerByName', header: 'Response By' }
    ];
    this.InputsCols = [
      { field: 'seq', header: 'S.No', width: '60px' },
      { field: 'addedDate', header: 'Added Date', width: '110px' },
      { field: 'addedByName', header: 'Added By', width: '130px' },
      { field: 'note', header: 'Description', width: '50%' },
      { field: 'noteStatus', header: 'Status' , width: '100px'},
      { field: 'noteComment', header: 'Comment' , width: '25%'},
    ];
    this.getSupportUsers();
  }
  getSupportUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.users = data.returnObject;
        this.selectedUser = [];
        var a = this.users.find(m => m.userId == parseInt(this.userId));
        if(a != null && a != undefined)
          this.selectedUser.push(a);
        this.getRequestDetailsByID();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRequestDetailsByID(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestID).subscribe(
      (data) => {
        this.showLoading = false;
        this.requestDetails = data.returnObject;                
        if(this.requestDetails!=null){  
          this.isUAT = this.requestDetails.isUAT;
          this.isProduction = this.requestDetails.isProduction;
          if(this.requestDetails.googleDrive != null && this.requestDetails.googleDrive != undefined && this.requestDetails.googleDrive != "")
          {
            this.GoogleAvaialable = true; this.GoogleLink = this.requestDetails.googleDrive;
          }
          this.internal = this.requestDetails.isInternal;
          this.code = this.requestDetails.code;
          this.requestedByName = this.requestDetails.requestedByName;
          this.selectedreqDate = new Date(this.requestDetails.requestDate+'Z');
          this.applicationName = this.requestDetails.applicationName;
          this.type = this.requestDetails.type;
          this.priority = this.requestDetails.priority;
          this.progress = this.requestDetails.progressCompleted;
          this.title = this.requestDetails.title;
          this.description = this.requestDetails.description;
          this.status = this.requestDetails.status; 
          this.internalstatus = this.requestDetails.internalStatus; 
          this.createdByName = this.requestDetails.createdByName; 
          this.selecteddueDate = this.requestDetails.dueDate != null && this.requestDetails.dueDate != undefined ? new Date(this.requestDetails.dueDate+'Z') : null; 
          this.estimatedHours = this.requestDetails.estimatedHours; 
          this.actualHours = this.requestDetails.actualHours; 
          this.owner = this.requestDetails.owner;
          this.ownerName = this.requestDetails.ownerName;
          this.confirmedbyName = this.requestDetails.confirmedbyName;
          this.confirmedremarks = this.requestDetails.confirmedRemarks;
          this.confirmedDate =this.requestDetails.confirmedDate != null && this.requestDetails.confirmedDate != undefined ? new Date(this.requestDetails.confirmedDate+'Z') : null; 

          this.cancelledByName = this.requestDetails.cancelledByName;
          this.cancelledRemarks = this.requestDetails.cancelledRemarks;
          this.cancelledDate =this.requestDetails.cancelledDate != null && this.requestDetails.cancelledDate != undefined ? new Date(this.requestDetails.cancelledDate+'Z') : null; 

          this.closedByName = this.requestDetails.closedByName;
          this.closedRemarks = this.requestDetails.closedRemarks;
          this.closedDate = this.requestDetails.closedDate != null && this.requestDetails.closedDate != undefined ? new Date(this.requestDetails.closedDate+'Z') : null; 

          this.uATAcknowledgeName = this.requestDetails.uatAcknowledgeName;
          this.uATAcknowledgeRemarks = this.requestDetails.uatAcknowledgeRemarks;
          this.uATAcknowledgeDate = this.requestDetails.uatAcknowledgeDate != null && this.requestDetails.uatAcknowledgeDate != undefined ? new Date(this.requestDetails.uatAcknowledgeDate+'Z') : null; 
          
          this.productionAcknowledgeName = this.requestDetails.productionAcknowledgeName;
          this.productionAcknowledgeRemarks = this.requestDetails.productionAcknowledgeRemarks;
          this.productionAcknowledgeDate = this.requestDetails.productionAcknowledgeDate != null && this.requestDetails.productionAcknowledgeDate != undefined ? new Date(this.requestDetails.productionAcknowledgeDate+'Z') : null; 

          this.startDate = this.requestDetails.currentEndDate != null && this.requestDetails.currentEndDate != undefined ? new Date(this.requestDetails.currentEndDate+'Z') : null;  
          this.startDate = this.requestDetails.startDate != null && this.requestDetails.startDate != undefined ? new Date(this.requestDetails.startDate+'Z') : null; 
          this.docTypes = this.requestDetails.tasks;
          this.ShowTasks = true;
          if(this.internalstatus == "Unassigned" || this.internalstatus == null || this.internalstatus == undefined)
            this.ShowTasks = false;
         
          this.Logs = this.requestDetails.logs;
          if(this.Logs!=undefined && this.Logs != null)
          {
            if(this.Logs.length > 0)
            {
              this.Logs.forEach((value, key) => {   
                if(value.logDate != '' && value.logDate != null)
                  value.logDate = new Date(new Date(value.logDate).toDateString() + " " + new Date(value.logDate).toTimeString() + " UTC");
              });
            }
          }
          else
            this.Logs = [];
          this.notesList = this.requestDetails.notes;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList.forEach((value, key) => {  
             value.ok = false;   
              if(value.createdBy == parseInt(this.userId))
                value.ok = true;         
              if(value.createdDate.toString().indexOf('Z') > 0)   
                value.createdDate = new Date(value.createdDate);   
              else
                value.createdDate = new Date(value.createdDate+'Z');              
            });
          }
          this.ViewAddedDocuments = this.requestDetails.documents;
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null && this.ViewAddedDocuments.length > 0)
          {
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
          else
            this.ViewAddedDocuments = [];
          this.plannedStartDate = this.requestDetails.plannedStartDate != null && this.requestDetails.plannedStartDate != undefined ? new Date(this.requestDetails.plannedStartDate+'Z') : null; 
          this.plannedEndDate = this.requestDetails.plannedEndDate != null && this.requestDetails.plannedEndDate != undefined ? new Date(this.requestDetails.plannedEndDate+'Z') : null; 
          this.currentStartDate = this.requestDetails.currentStartDate != null && this.requestDetails.currentStartDate != undefined ? new Date(this.requestDetails.currentStartDate+'Z') : null; 
          this.currentEndDate = this.requestDetails.currentEndDate != null && this.requestDetails.currentEndDate != undefined ? new Date(this.requestDetails.currentEndDate+'Z') : null; 
          this.actualStartDate = this.requestDetails.actualStartDate != null && this.requestDetails.actualStartDate != undefined ? new Date(this.requestDetails.actualStartDate+'Z') : null; 
          this.actualEndDate = this.requestDetails.actualEndDate != null && this.requestDetails.actualEndDate != undefined ? new Date(this.requestDetails.actualEndDate+'Z') : null; 
          this.selectedUser = [];
          if(this.requestDetails.assinees != undefined && this.requestDetails.assinees != null)
          {
            this.requestDetails.assinees.forEach(element => {
              var a = this.users.find(x=>x.userId == element.userID);
              if(a != undefined && a != null)
                this.selectedUser.push(a);
            });
          }

          this.canClose = false;
          if(this.requestDetails.requestedBy == parseInt(this.userId) || this.requestDetails.createdBy == parseInt(this.userId) || this.requestDetails.owner == parseInt(this.userId))
            this.canClose = true;

          this.Clarification1 = []; this.SClarification = [];
          var mainclare = this.requestDetails.clarifications;
          if(mainclare!=undefined && mainclare != null)
          {
            if(mainclare.length > 0)
            {
              mainclare.forEach((value, key) => {
                if ( value.askedDate !== null &&  value.askedDate !== undefined )
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
                if ( value.answerDate !== null &&  value.answerDate !== undefined )
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
              });
              this.Clarification1 = mainclare.filter(x=>x.type != "Support");
              this.SClarification = mainclare.filter(x=>x.type == "Support");
            } 
          }
          this.Inputs = []; this.Inputs1 = [];
          var MInputs = this.requestDetails.inputs;
          if(MInputs !=undefined && MInputs != null && MInputs.length > 0)
          {
            MInputs.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
            this.Inputs = MInputs.filter(x=> x.type == "UAT");
            this.Inputs1 = MInputs.filter(x=> x.type != "UAT");
          }
          this.index = 1;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  ReasonClear() {
    this.ReasonError = "";
    this.popupreason = "";
  }
  SendClose() {
    this.confirmation.confirm({
      key: "confirm-close",
      message: "Are you sure you want to close this request?",
      accept: () => {
        this.confirmClose = false;
        this.reasondisplay = true;
        this.popupreason = "";
        this.ReasonError = "";
      },
    });
    this.confirmClose = false;
  }
  AddReason() {
    this.showLoading = true;
    this.ReasonError = "";
    if (this.popupreason == undefined || this.popupreason == "") {
      this.ReasonError = "Please enter Reason.";
      this.showLoading = false;
    }
    if (
      this.popupreason != undefined &&
      this.popupreason != "" &&
      this.ReasonError == ""
    ) {
      var Model = {
        requestID: this.requestID,
        userID: parseInt(this.userId),
        remarks: this.popupreason,
      };
      this._facadeService.SendClose(Model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            this.reasondisplay = false;
            this.popupreason = "";
            this.ReasonError = "";
            // this.getRequestDetailsByID();
            this.Back();
          } else {
            this.showLoading = false;
            this.ReasonError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
  }


AddNote(display){
  this.DocsError = "";
  if(this.issueNote!=undefined && this.issueNote!= '')
  {
    this.showLoading = true;
    var model = {
      "requestID":this.requestID,
      "note":this.issueNote,
      "displayToCustomer" : display,
      "addedBy":parseInt(this.userId)
    };
    this._facadeService.AddNote(model).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.showLoading = false;
          if (this.fubauto != undefined) {
            if (this.fubauto.files.length > 0) {
              this.fubauto.clear();
            }
          }
          if (this.fubautoD != undefined) {
            if (this.fubautoD.files.length > 0) {
              this.fubautoD.clear();
            }
          }
          this.notesList =  this.apiResponse.returnObject;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList.forEach((value, key) => {  
              value.ok = false;   
              if(value.createdBy == parseInt(this.userId))
                value.ok = true;         
              if(value.createdDate.toString().indexOf('Z') > 0)   
                value.createdDate = new Date(value.createdDate);   
              else
                value.createdDate = new Date(value.createdDate+'Z');              
            });
          }
          this.issueNote = '';
          this.showLoading = false;
        } else {
          this.showLoading = false;
          this.DocsError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  else
  {
    this.DocsError = "Please enter note";
  }
}
SendMail(ID)
{
  this.showLoading = true;
  this._facadeService.SendMail(ID).subscribe(
    (data) => {
      if (data.returnCode == 0) {
        this.showLoading = false;
        this.getRequestDetailsByID();
      } else {
        this.showLoading = false;
        this.Error = data.returnMessage;
      }
    },
    (error) => {
      console.log(error);
      this.showLoading = false;
    }
  );
}
onBasicUploadAuto(event,displaytocustomer){
  this.showLoading = true;
  this.DocsError = "";
  let fileSize = event.files[0].size;
  if (fileSize <= 10)
  {
    this.DocsError = "File size should be greater than 1KB";
    this.showLoading = false;
  }
  else
  {
    const formData = new FormData();
    formData.append("requestID", this.requestID.toString());
    formData.append("applicationID", this.applicationID);
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("displayToCustomer",displaytocustomer);
    formData.append("addedBy", this.userId);
    if (event != undefined) {
      if (event.files.length > 0) {
        event.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    console.log(formData);
    this._facadeService.addADocuments(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            if (this.fubautoD != undefined) {
              if (this.fubautoD.files.length > 0) {
                this.fubautoD.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach((value, key) => {  
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;         
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
  }

  }

  