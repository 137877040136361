import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-releasenoteshistory',
  templateUrl: './releasenoteshistory.component.html',
  styleUrls: ['./releasenoteshistory.component.scss']
})
export class ReleasenoteshistoryComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  Users: any[];
  selectedUsers: any;
  type: SelectItem[];
  selectedtype: any;
  
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  Error: string = "";
  
  requestData = [];

  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService,private router: Router,  @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    var dateava = false;
    var d = sessionStorage.getItem('frommailreleasedate');
    if(d != null && d != undefined)
    {
      if(d != "" && d != "null")
      {
        let actualdate = new Date(d);
        let date = new Date();
        actualdate.setHours( date.getHours());
        actualdate.setMinutes( date.getMinutes());
        actualdate.setSeconds( date.getSeconds());
        actualdate.setMilliseconds( date.getMilliseconds());
        this.selectedToDate = new Date(actualdate);
        this.selectedFromDate = new Date(actualdate);
        dateava = true;
      }
    }
    if(!dateava)
    {
      this.selectedToDate = new Date();
      let dte = new Date();
      dte.setMonth(dte.getMonth() - 1);
      this.selectedFromDate = new Date(dte.toString());
    }
    this.type = [
      { label: 'UAT', value: 'UAT'},
      { label: 'Production', value: 'Production'}
    ];
    this.selectedtype = [];
    this.type.forEach(element => {
        this.selectedtype.push(element);
    });

    this.getUsers();
    this.cols = [
      { field: 'releaseDate', header: 'Release Date', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '30%' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'status', header: 'Release Type', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'comments', header: 'Note', dynamicPlaceHolder: 'Search', width: '30%' }
    ];

    sessionStorage.releaseDate = "";
    sessionStorage.code = "";
    sessionStorage.title = "";
    sessionStorage.requestDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.applicationName = "";
    sessionStorage.status = "";
    sessionStorage.comments = "";
  }

  ViewMyRequest(rowdata) {
    var clientID = parseInt(sessionStorage.getItem('clientId'));
    sessionStorage.From = "ReleaseReport";
    sessionStorage.requestID = rowdata.requestID;
    if(clientID != 2)
      this.router.navigate(['/requestDetails']);
    else
      this.router.navigate(['/clientRequestsDetails']);
  }

  test(val, field, dt) {
    if (field == "releaseDate")
      sessionStorage.releaseDate = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName = val;
    else if (field == "applicationName")
      sessionStorage.applicationName = val;
    else if (field == "status")
      sessionStorage.status = val;
    else if (field == "comments")
      sessionStorage.comments = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.releaseDate != "")
          temp = temp.filter(x => x.releaseDate.toLowerCase().includes(sessionStorage.releaseDate.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.requestedByName != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
        if (sessionStorage.applicationName != "")
          temp = temp.filter(x => x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
        if (sessionStorage.status != "")
          temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
        if (sessionStorage.comments != "")
          temp = temp.filter(x => x.comments.toLowerCase().includes(sessionStorage.comments.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getUsers() {
    this._facadeService.getUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.Users = data.returnObject;
        this.selectedUsers = [];
        this.Users.forEach(element => {
          this.selectedUsers.push(element);
        });
        this.getSupportUsers();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getSupportUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        var AUsers = data.returnObject;
        AUsers.forEach(element => {
          this.Users.push(element);
        });
        this.selectedUsers = [];
        this.Users.forEach(element => {
          this.selectedUsers.push(element);
        });
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  
  CheckDate(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }
  getMyRequests() {
    this.Error = "";
    if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "Release To Date should be greater then from date";
      this.selectedToDate = this.selectedFromDate;
    }
    else {
      this.showLoading = true;
      var types = [];
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.value);
          });
        }
      }
      var Requestedby = [];
      if (this.selectedUsers != null && this.selectedUsers != undefined) {
        if (this.selectedUsers.length > 0) {
          this.selectedUsers.forEach(element => {
            Requestedby.push(element.userId);
          });
        }
      }
      var myRequestsData =
      {
        "requests": [],
        "tasks":[],
        "status":types,
        "logBy": Requestedby,
        "fromDate":this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "" ? new Date(this.selectedFromDate) : null,
        "toDate":this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" ? new Date(this.selectedToDate) : null
      }
      this._facadeService.GetReleaseHistory(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null)
          {
            if(this.requestData.length > 0)
            {
              this.requestData.forEach((value, key) => {   
                if(value.releaseDate != '' && value.releaseDate != null)
                  value.releaseDate = new Date(new Date(value.releaseDate).toDateString() + " " + new Date(value.releaseDate).toTimeString() + " UTC");
                if(value.requestDate != '' && value.requestDate != null)
                  value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
              });
            }
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Release Summary','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 80 },{ wpx : 135},{ wpx : 135 },{ wpx : 100 },{ wpx : 400 }];
    /* save to file */
    XLSX.writeFile(wb, "Release Summary.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

}
