<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
     <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-lg-11 p-md-12 p-sm-12"> <h5>Request Details</h5></div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" >
          <a routerLink="/unAssignedRequests"> <button pButton type="button" label="Back" iconPos="left" class="p-button-raised p-button-primary"
            icon="pi pi-angle-left" style="margin-left:5px ; height:35px;"></button> </a>
        </div>
      </div>
      <hr style="margin-top: 0px;" />
      <div class="p-col-12" style="float: right;"><small class="p-invalid">{{Error}}</small></div>
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-2">
          <label>Request ID</label>
          <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date"
            [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested By</label>
          <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Initiated By</label>
          <input type="text" pInputText [(ngModel)]="createdByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Application</label>
          <input type="text" pInputText [(ngModel)]="applicationName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Type</label>
          <input type="text" pInputText [(ngModel)]="type" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed By</label>
          <input type="text" pInputText [(ngModel)]="confirmedbyName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="confirmedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="confirmedremarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
          <label>Cancelled By</label>
          <input type="text" pInputText [(ngModel)]="cancelledByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
          <label>Cancelled Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="cancelledDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Cancelled'">
          <label>Cancelled Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="cancelledRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
          <label>Closed By</label>
          <input type="text" pInputText [(ngModel)]="closedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
          <label>Closed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="closedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Closed'">
          <label>Closed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="closedRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label>Short Description</label>
          <input type="text" pInputText [disabled]="true" placeholder="Short Description 50 characters only" required="true" requiredMessage="Short Description must not be empty"
            [(ngModel)]="title" maxlength=50/>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Status</label>
          <input type="text" pInputText [(ngModel)]="status" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Priority</label>
          <p-dropdown [options]="priority" placeholder="Select a Priority" [(ngModel)]="selectedPriority" optionLabel="label"></p-dropdown>
          <!-- <input type="text" pInputText [(ngModel)]="priority" [disabled]="true" /> -->
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Owner</label>
          <p-dropdown [options]="users" placeholder="Select a Request owner" [(ngModel)]="owner" optionLabel="fullName"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Planned Start Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedStartDate" [disabled]="true" 
          (onSelect)="CheckDate(plannedStartDate, plannedEndDate)" (onClickOutside)="CheckDate(plannedStartDate, plannedEndDate)"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Planned End Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [disabled]="true" [minDate]="plannedStartDate" placeholder="Select Date" [(ngModel)]="plannedEndDate"
          (onSelect)="CheckDate(plannedStartDate, plannedEndDate)" (onClickOutside)="CheckDate(plannedStartDate, plannedEndDate)"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Estimated Effort (Hours)</label>
          <input type="text" pInputText [(ngModel)]="mainestimatedHours" [disabled]="true" maxlength="10" [disabled]="true" type="number" />
        </div>
        <div class="p-field p-col-12 p-md-12" style="margin-left:auto;margin-right:auto;">
            <span class="p-input-icon-left" style="float:right; margin-right:5px;">
                <button [disabled]="clicked" (click)="clicked = true;" pButton type="button" icon="pi pi-plus"
                class="ui-button-info" style="float: right; width: 160px; margin-bottom:10px;" [label]="'Add New Task'" pAddRow [table]="dt" [newRow]="newRow()" 
                ></button>
            </span>
      </div>
      <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:99%;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
          <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Tasks</h5>
         </div>
          <div class="invoice invoice-header" id="invoice-content">
            <p-table #dt [value]="docTypes" id="excel-table" styleClass="p-datatable-striped" sortMode="multiple" class="table-align" editMode="row" 
            [filterDelay]="0" selectionMode="multiple" dataKey="taskName">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th style="width: 60px;"></th>
                    <th style="width: 40px;">Seq</th>
                    <th>Task</th>
                    <th>Scope</th>
                    <th style="width:120px;">Estimated Hours</th>
                    <!-- <th style="width:120px;">Target Start Date</th>
                    <th style="width:110px;">Target End Date</th> -->
                    <th style="width:150px;">Predecessor</th>
                    <th style="width:150px;">Assigned To</th>
                    <th style="width:110px;">Affect Allocation</th>
                    <th style="text-align: center; width:80px;">Actions</th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
                <tr [pEditableRow]="rowData" [style.background]="rowData.isEdit == 'Y' ? 'aquamarine' : rowData.isEdit == 'E' ? 'tan' : ''"
                [pReorderableRow]="index">
                    <td style="text-align: center;">
                        <i class="pi pi-bars" pReorderableRowHandle  (handler)="updateSeq($event)" ></i>
                         <!-- {{index}} - {{rowData.taskSeq}} -->
                    </td>
                    <td style="text-align: center;width: 40px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">{{rowData.taskSeq}}</ng-template>
                        <ng-template pTemplate="output">{{rowData.taskSeq}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="padding-left: 15px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input type="text" pInputText placeholder="Task Name" [(ngModel)]="taskName" maxlength=50/>
                            <small class="p-invalid">{{ taskNameError }}</small>
                        </ng-template>
                        <ng-template pTemplate="output">{{rowData.taskName}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="padding-left: 15px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input type="text" pInputText placeholder="Scope" [(ngModel)]="scope" maxlength=500/>
                            <small class="p-invalid">{{ scopeError }}</small>
                        </ng-template>
                        <ng-template pTemplate="output">{{rowData.scope}}</ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:120px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3" [(ngModel)]="estimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                            <small class="p-invalid">{{ hoursError }}</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3"  (onInput)="Getdates()"
                            [(ngModel)]="rowData.estimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <!-- <td style="text-align: center;width:120px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">--</ng-template>
                        <ng-template pTemplate="output">
                          <span *ngIf="rowData.startDate != '--' && rowData.startDate != null && rowData.cycleTime != 0">{{rowData.startDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.startDate == '--' || rowData.startDate == null || rowData.cycleTime == 0">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:110px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">--</ng-template>
                        <ng-template pTemplate="output">
                          <span *ngIf="rowData.dueDate != '--' && rowData.dueDate != null && rowData.cycleTime != 0">{{rowData.dueDate | date:'MM/dd/yyyy'}}</span>
                          <span *ngIf="rowData.dueDate == '--' || rowData.dueDate == null || rowData.cycleTime == 0">--</span>
                        </ng-template>
                      </p-cellEditor>
                    </td> -->
                    <td style="padding-left: 15px;width:150px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-multiSelect [options]="docTypes" [(ngModel)]="selectedPredecessor" placeholder="Select Predecessor" 
                          optionLabel="taskName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                          <small class="p-invalid">{{ PredecessorError }}</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <p-multiSelect [options]="rowData.pData" [(ngModel)]="rowData.taskPredecessor" placeholder="Select Predecessor" 
                          optionLabel="predecessorName" class="text" (onChange)="Getdates()" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="padding-left: 15px;width:150px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-multiSelect [options]="users" [(ngModel)]="selectedAssignee" placeholder="Select Assignee" (onChange)="onRowEditSave(rowData)"
                          optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                          <small class="p-invalid">{{ AssigneeError }}</small>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <p-multiSelect [options]="users" [(ngModel)]="rowData.taskAssignee" placeholder="Select Assignee" 
                          optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="text-align: center;width:110px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-checkbox binary="affectAllocation" [(ngModel)]="affectAllocation" [value]="affectAllocation"></p-checkbox>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <p-checkbox binary="rowData.affectAllocation" [(ngModel)]="rowData.affectAllocation" [value]="rowData.affectAllocation"></p-checkbox>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                      <td style="text-align:center;width:80px;">
                        <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" 
                        (click)="onRowEditInit1(rowData)" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                        <button *ngIf="!editing" pButton type="button" icon="pi pi-minus"
                            class="ui-button-info" (click)="onRowEditInit(rowData)" style="height:25px; width:25px;"></button>
                        <!-- <button *ngIf="!editing && rowData.isEdit == 'D'" pButton type="button" icon="pi pi-undo"
                            class="ui-button-info" (click)="UndoDelete(rowData)" style="height:25px; width:25px;"></button> -->
                        <button *ngIf="editing" pButton type="button" pCancelEditableRow
                            icon="pi pi-times-circle" class="ui-button-danger"
                            (click)="onRowEditCancel(rowData)" style="height:25px; width:25px;"></button>
                      </td>
                  </tr>
              </ng-template>
      <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="11" style="text-align: left;">No records found...</td>
                  </tr>
              </ng-template>
          
          </p-table>
          </div>
      </div>

        <div class="p-col-12" style="float:right;margin-top: 20px; ">
          <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
          (click)="Update()" class="p-button-raised p-button-success p-mr-2 p-mb-2"
            style="width:auto; float:right;"></button>              
        </div>
        <div class="p-col-12" style="float: right;"><small class="p-invalid">{{UpdateError}}</small></div>
      </div>
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="" [headerStyle]="{'width': '0px'}"><br></p-tabPanel>
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'160px'}" [readonly]="true" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip"  *ngIf="ViewAddedDocuments.length >0">
          <div class="p-col-12">
            <div class="card-header">
              <h5 style="color:#fff;">Attachments</h5>
            </div>
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                      {{rowData.fileName}} </a></span></td>
                <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Clarifications" leftIcon="pi pi-question"  *ngIf="Clarification1.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtClarification1 [value]="Clarification1" [columns]="Clarification1Cols" sortMode="multiple" [loading]="loading"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="card">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5> 
      </div>
      <div class="p-field p-col-12" style="padding: 0px;">
        <div class="card" style="border:1px solid #eee;">
          <div class="widget-chat">
            <ul *ngFor="let note of notesList">
              <li class="message-from">
                <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.notesAddedByName }}</span>
                  <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                  </span>
                  <span style="font-size: 14px;padding-right: 10px;">{{ note.note }}</span>
                </div>
                <div *ngIf="note.dmsID > 0" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="DMSToken(note.downloadFileName,note.userFileName)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Download File">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                 border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-download"></i></span></a></div>
                <!-- <div *ngIf="note.dmsID == 0 && !note.sentMail && note.ok" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="SendMail(note.id)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Send Mail">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                  border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-envelope"></i></span></a></div> -->
              </li>
            </ul>
            <div class="message-input p-field p-col-12 " *ngIf="status != 'Closed' && status != 'Cancelled'">
              <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
                style="background-color: #eee;border:1px solid #bbb;" />
              <div class="card-header">
                <div class="p-col-2">
                <span class="p-input-icon-left">
                <p-fileUpload #fubauto mode="basic" customUpload="true"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,false)" auto="true" chooseLabel="Attach File"
                  pTooltip="Attach file"></p-fileUpload>
                   </span>
                   </div>
                   <div class="p-col-4">
                  <span class="p-input-icon-left" style="left: -23%;">
                <p-fileUpload #fubautoD mode="basic" customUpload="true" [disabled]="internal"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event,true)" auto="true" chooseLabel="Attach File & Display to Customer"
                  pTooltip="Attach file"></p-fileUpload>
                  </span>
                  </div>
                  <div class="p-col-2"></div>
                  <div class="p-col-4" style="float:right;text-align: right;">
                  <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px;">
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                    class="p-button-raised p-button-info p-mr-2 p-mb-2 width80" (click)="AddNote(false)"></button>
                      <button pButton pRipple type="button" [disabled]="internal" icon="pi pi-plus" iconPos="left" label="Add & Display to Customer"
                        class="p-button-raised p-button-info p-mr-2 p-mb-2 width230" (click)="AddNote(true)"></button>
                  </span>
                  </div>
              </div>
            </div>
            <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>