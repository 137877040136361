<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12" style="text-align: center">
          <h4 style="margin-top: 5px"> Close Request</h4>
        </div>
      </div>
      <hr />
      <div class="p-grid p-formgrid">
          <div class="p-field p-col-12 p-md-12">
              <div class="invoice invoice-header" id="invoice-content1" style="overflow-x: auto;">
                  <p-table #dt1 [value]="AddedRequests" id="excel-table1" [columns]="cols" sortMode="multiple" datakey="iD"
                  class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                      <ng-template pTemplate="header" let-columns class="invoice-items">
                          <tr>
                            <th style="text-align: center;width: 60px;">  
                              <p-tableHeaderCheckbox [(ngModel)]="headerCheckbox" binary="headerCheckbox" (click)="allChecked($event)"></p-tableHeaderCheckbox>                                     
                            </th>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                [ngStyle]="{'width': col.width}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns">
                          <tr >
                            <td style="text-align: center;width: 60px;">
                              <p-tableCheckbox (click)="selectedId(rowData)" [value]="rowData" binary="rowData.check"></p-tableCheckbox>
                            </td>
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center;width: 110px;"> {{ rowData[col.field]}}</td>
                                <td *ngIf="i == 1" style="text-align: left; padding-left: 15px; width: 30%;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 2" style="text-align: center; width: 150px;">{{rowData[col.field] | date:'MM/dd/yyyy'}}</td>
                                <td *ngIf="i == 3" style="text-align: left; padding-left: 15px; width: 150px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 4" style="text-align: left; padding-left: 15px; width: 180px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 5" style="text-align: left; padding-left: 15px; width: 100px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 6" style="text-align: left; padding-left: 15px; width: 150px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 7" style="text-align: center;width: 120px;">
                                    <a> 
                                        <button pButton type="button" icon="pi pi-plus-circle" pTooltip="Note"
                                        tooltipPosition="left" class="p-button-raised p-button-primary"
                                        (click)="Edit(rowData)"   [ngClass]="{'p-button-success': rowData.comments != ''}"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                    </a>
                                </td>
                            </ng-container>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr><td colspan="9" style="text-align: left;">No records found...</td></tr>
                      </ng-template>
                  </p-table>
              </div>
          </div>
          <div class="p-col-12" style="float:right;">
              <hr>
              <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Close"
              (click)="SaveLog()" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width:auto; float:right;"></button> 
          </div>
          <div class="p-field p-col-12 p-md-8"><small class="p-invalid">{{responseMessage}}</small></div>
      </div>
    </div>
    <p-dialog header="Comments" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }">
      <hr /><br>
      <div class="p-grid">
        <div class="p-field p-col-12 p-md-1"></div>
        <div class="p-field p-col-12 p-md-10"> 
            <label>Request: {{ Request}}</label> 
        </div>
        <div class="p-field p-col-12 p-md-1"></div>
        <div class="p-field p-col-12 p-md-1"></div>
        <div class="p-field p-col-12 p-md-10" style="margin-top: -20px;">
            <label>Comment:</label>
            <p-editor [(ngModel)]="comments" [style]="{'height':'160px'}" placeholder="Enter Note"></p-editor>
        </div>
        <div class="p-field p-col-12 p-md-1"></div>
        <div class="p-col-12" style="float: right;margin-top: -40px;">
          <button pButton type="button" label="Add" pTooltip="Add" (click)="AddLog()" tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
            class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 100px;height:25px; float: right;margin-left:120px;  margin-top: 20px;"></button>
        </div>
        <div class="p-col-12" style="float: right;margin-top: -20px;"><small class="p-invalid">{{AddLogError}}</small></div>
      </div>
    </p-dialog>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>