import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginComponent} from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminComponent } from './components/admin/admin.component';
import { EntrypointComponent } from "./components/entrypoint/entrypoint.component";
import {AuthGuard} from './guard/auth.guard';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { AddrequestsComponent } from './components/addrequests/addrequests.component';
import { ReportBytimeComponent } from './components/report-bytime/report-bytime.component';
import { ReportByHistoryComponent } from './components/report-by-history/report-by-history.component';
import { RequestDetailsComponent } from './components/request-details/request-details.component';
import { MyRequestsComponent } from './components/my-requests/my-requests.component';
import { RequestSummaryDetailsComponent } from './components/request-summary-details/request-summary-details.component';
import { SupportDashboardComponent } from './components/support-dashboard/support-dashboard.component';
import { PendingConfirmationRequestsComponent } from './components/pending-confirmation-requests/pending-confirmation-requests.component';
import { PendingConfirmationDetailsRequestComponent } from './components/pending-confirmation-details-request/pending-confirmation-details-request.component';
import { AssignedToMeComponent } from './components/assigned-to-me/assigned-to-me.component';
import { UnAssignedRequestsComponent } from './components/un-assigned-requests/un-assigned-requests.component';
import { UnAssignedDetailsRequestsComponent } from './components/un-assigned-details-requests/un-assigned-details-requests.component';
import { ClientRequestsComponent } from './components/client-requests/client-requests.component';
import { ClientRequestsDetailsComponent } from './components/client-requests-details/client-requests-details.component';
import { ClientRequestDetailsComponent } from './components/client-request-details/client-request-details.component';
import { DailylogComponent } from './components/dailylog/dailylog.component';
import { DailylogreportComponent } from './components/dailylogreport/dailylogreport.component';
import { ManageassigneeComponent } from './components/manageassignee/manageassignee.component';
import { ReleasenotesComponent } from './components/releasenotes/releasenotes.component';
import { ReleasenoteshistoryComponent } from './components/releasenoteshistory/releasenoteshistory.component';
import { CloseComponent } from './components/close/close.component';
@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: "", component:LoginComponent},
              { path: "", 
              component: AppMainComponent,
                
                children: [
                    { path: 'entrypoint', component:EntrypointComponent},
                    { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },
                    { path: "clientRequests", component: ClientRequestsComponent ,canActivate:[AuthGuard]},
                    { path: "newrequest", component: AddrequestsComponent ,canActivate:[AuthGuard]},
                    { path: "myRequests", component: MyRequestsComponent ,canActivate:[AuthGuard]},
                    { path: "requestDetails", component: RequestDetailsComponent ,canActivate:[AuthGuard]},
                    { path: "pendingConfirmationRequest", component: PendingConfirmationRequestsComponent ,canActivate:[AuthGuard]},
                    { path: "pendingConfirmationDetailsRequest", component: PendingConfirmationDetailsRequestComponent ,canActivate:[AuthGuard]},
                    { path: "admin", component: AdminComponent,canActivate:[AuthGuard] },
                    { path: "reportByTime", component: ReportBytimeComponent ,canActivate:[AuthGuard]},

                    { path: "assignedToMe", component: AssignedToMeComponent ,canActivate:[AuthGuard]},
                    { path: "clientRequestDetails", component:  ClientRequestDetailsComponent,canActivate:[AuthGuard]},
                    { path: "clientRequestsDetails", component:  ClientRequestsDetailsComponent,canActivate:[AuthGuard]},
                    { path: "unAssignedRequests", component: UnAssignedRequestsComponent ,canActivate:[AuthGuard]},
                    { path: "unAssignedDetailsRequests", component: UnAssignedDetailsRequestsComponent ,canActivate:[AuthGuard]},
                    { path: "supportDashboard", component: SupportDashboardComponent ,canActivate:[AuthGuard]},
                    { path: "reportByStatus", component: ReportByHistoryComponent ,canActivate:[AuthGuard]},
                    { path: "requestSummaryDetails", component: RequestSummaryDetailsComponent ,canActivate:[AuthGuard]},

                    { path: "dailylog", component: DailylogComponent ,canActivate:[AuthGuard]},
                    { path: "dailylogreport", component: DailylogreportComponent ,canActivate:[AuthGuard]},
                    { path: "releasenotes", component: ReleasenotesComponent ,canActivate:[AuthGuard]},
                    { path: "releasenotesreport", component: ReleasenoteshistoryComponent ,canActivate:[AuthGuard]},
                    { path: "close", component: CloseComponent ,canActivate:[AuthGuard]},
                    { path: "manageassignee", component: ManageassigneeComponent ,canActivate:[AuthGuard]},

                    { path: "searchhelp", component: HelpguideComponent ,canActivate:[AuthGuard]},
                ]
            },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
