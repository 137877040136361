<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" >
              <a> <button pButton type="button" (click)="back()"
                label="Back" iconPos="left" class="p-button-raised p-button-primary"
                icon="pi pi-angle-left" style="margin-left:5px ; height:35px;"></button> </a>
        </div>
        <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align: center">
          <h5 style="margin-top: 5px">Request Details</h5>
        </div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
      </div>
      <hr />
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-2">
          <label>Request ID</label>
          <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested By</label>
          <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Application</label>
          <p-dropdown [options]="applications" placeholder="Select a Application" [(ngModel)]="selectedapplication" optionLabel="applicationName"></p-dropdown>
          <!-- <input type="text" pInputText [(ngModel)]="applicationName" [disabled]="true" /> -->
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Type</label>
          <p-dropdown [options]="type" placeholder="Select a Request Type" [(ngModel)]="selectedtype"  optionLabel="label"></p-dropdown>
          <!-- <input type="text" pInputText [(ngModel)]="type" [disabled]="true" /> -->
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Priority</label>
          <p-dropdown [options]="priority" placeholder="Select a Priority" [(ngModel)]="selectedPriority" optionLabel="label"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label>Short Description</label>
           <small class="p-invalid" style="float: right;">{{100 - title.length}} characters are remaining</small>
          <input type="text" pInputText placeholder="Short Description" required="true" requiredMessage="Short Description must not be empty"
            [(ngModel)]="title" maxlength=100/>
        </div>
      </div>
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'300px'}" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddNewButton()"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
              style="width:auto;float: right;"></button>
          </span>
          <span class="p-input-icon-left" style="float: right">
            <p-dialog header="Add Documents" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade"
              [style]="{ width: '60%' }">
              <hr /><br>
              <div class="p-grid">
                <div class="p-col-12 p-md-12 p-xl-12">
                  <form>
                    <p-fileUpload #fileInput name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
                      accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                       [showUploadButton]="false" [showCancelButton]="true"
                      fileLimit="25" auto="true">
                      <ng-template pTemplate="content">
                        <ul *ngIf="fileInput.length">
                          <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                        </ul>
                      </ng-template>
                    </p-fileUpload>
                  </form>
                </div>
                <div class="p-col-12" style="float: right;">
                  <button pButton type="button" label="Add" pTooltip="Add Docs" (click)="AddDocs()"
                    tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
                    class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                    style="width: 100px;height:25px; float: right;margin-left:120px;  margin-top: 20px;"></button>
                </div>
                <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
              </div>
            </p-dialog>
          </span>
          <div class="p-col-12"  *ngIf="ViewAddedDocuments.length>0">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                      {{rowData.fileName}} </a></span></td>
                <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <br><br>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="card" style="display: none;">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5> 
      </div>
      <div class="p-field p-col-12" style="padding: 0px;">
        <div class="card" style="border:1px solid #eee;">
          <div class="widget-chat">
            <ul *ngFor="let note of notesList">
              <li class="message-from">
                <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.notesAddedByName }}</span>
                  <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                  </span>
                  <span style="font-size: 14px;padding-right: 10px;" *ngIf="note.dmsID > 0">{{ note.note }}</span>
                  <p-editor [(ngModel)]="note.note" [readonly]="true" *ngIf="note.dmsID == 0"><p-header hidden></p-header></p-editor>
                </div>
                <div *ngIf="note.dmsID > 0" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="DMSToken(note.downloadFileName,note.userFileName)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Download File">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                 border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-download"></i></span></a></div>
                <!-- <div *ngIf="note.dmsID == 0 && !note.sentMail && note.ok" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="SendMail(note.id)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Send Mail">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                  border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-envelope"></i></span></a></div> -->
              </li>
            </ul>
            <div class="message-input p-field p-col-12 " *ngIf="status != 'Closed' && status != 'Cancelled'">
              <p-editor [(ngModel)]="issueNote" [style]="{'height':'200px'}" placeholder="Write a note here" style="background-color: #eee;"></p-editor>
              <div class="card-header">
                <p-fileUpload #fubauto mode="basic" customUpload="true"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event)" auto="true" chooseLabel="Attach File"
                  pTooltip="Attach file"></p-fileUpload>
                <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px; width:120px;">
                  <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add Note"
                    class="p-button-raised p-button-info p-mr-2 p-mb-2" (click)="AddNote()">
                  </button>
                </span>
              </div>
            </div>
            <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError1}}</small></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="p-col-12 p-md-12 p-xl-12" *ngIf="Clarification1.length > 0" >
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
          <h5 style="color:#495057; padding:15px 0 0 15px;">Clarifications </h5> 
        </div>
        <p-table #dtClarification1 [value]="Clarification1" [columns]="Clarification1Cols" sortMode="multiple" [loading]="loading"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
        > 
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr><th style="text-align: center;width: 60px;">S.No</th>
                <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                    style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                <tr [pSelectableRow]="rowData" >
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                    <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                    <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                    <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                    <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" style="text-align: left;">No records found...</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" >
        <label> Remark / Question  <span style="color: red">*</span> </label> 
        <small class="p-invalid" style="float: right;">{{1000 - popupremark.length}} characters are remaining</small>
        <textarea rows="5" pInputTextarea id="popupremark" [(ngModel)]="popupremark" placeholder="Confirmed"
            maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
        <br>
        <small class="p-invalid"><b>{{ Error }}</b></small>
        <span style="float: right;">
          <button pButton type="button" (click)="Confirm('Approve')" label="Confirm Request" icon="pi pi-check" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 200px;height: 25px;"></button>
          <button pButton type="button" (click)="Confirm('Clare')" label="Seek Clarification" pTooltip="Seek Clarification" tooltipPosition="right" icon="pi pi-question" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width:220px; height:25px;" ></button>
          <button pButton type="button" (click)="SendCancel()" label="Cancel Request" pTooltip="Cancel" tooltipPosition="right" icon="pi pi-times" class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2" style="width:200px; height:25px;" ></button>
          <p-confirmDialog key="confirm-cancel" header="Cancel Request" acceptLabel="Yes" rejectLabel="No"
          [(visible)]="confirmCancel"></p-confirmDialog>
        </span>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>