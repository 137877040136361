import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-dailylogreport',
  templateUrl: './dailylogreport.component.html',
  styleUrls: ['./dailylogreport.component.scss']
})
export class DailylogreportComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  Users: any[];
  selectedUsers: any;

  type: any[];
  selectedtype: any;
  
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  Error: string = "";
  
  requestData = [];

  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService,
    private route: ActivatedRoute, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.selectedToDate = new Date();
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);
    this.selectedFromDate = new Date(dte.toString());

    this.getUsers();
    this.cols = [
      { field: 'logDate', header: 'Log Date', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'logByname', header: 'Log By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '20%' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '15%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'totalWorkHours', header: 'Total spent till Now', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'workHours', header: 'Work Hours', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'progressCompleted', header: 'Progress Completed (%)', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '25%' },
      { field: 'planforTomorrow', header: 'Plan for Tomorrow', dynamicPlaceHolder: 'Search', width: '25%' }
    ];

    sessionStorage.logDate = "";
    sessionStorage.logByname = "";
    sessionStorage.code = "";
    sessionStorage.title = "";
    sessionStorage.taskName = "";
    sessionStorage.estimatedHours = "";
    sessionStorage.workHours = "";
    sessionStorage.totalWorkHours = "";
    sessionStorage.progressCompleted = "";
    sessionStorage.comments = "";
    sessionStorage.planforTomorrow = "";
  }

  ViewMyRequest(rowdata) {
    sessionStorage.From = "LogReport";
    sessionStorage.requestID = rowdata.requestID;
    this.router.navigate(['/clientRequestsDetails']);
  }

  test(val, field, dt) {
    if (field == "logDate")
      sessionStorage.logDate = val;
    else if (field == "logByname")
      sessionStorage.logByname = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "taskName")
      sessionStorage.taskName = val;
    else if (field == "estimatedHours")
      sessionStorage.estimatedHours = val;
    else if (field == "workHours")
      sessionStorage.workHours = val;
    else if (field == "totalWorkHours")
      sessionStorage.totalWorkHours = val;
    else if (field == "progressCompleted")
      sessionStorage.progressCompleted = val;
    else if (field == "comments")
      sessionStorage.comments = val;
    else if (field == "planforTomorrow")
      sessionStorage.planforTomorrow = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.logDate != "")
          temp = temp.filter(x => x.logDate.toLowerCase().includes(sessionStorage.logDate.toLowerCase()));
        if (sessionStorage.logByname != "")
          temp = temp.filter(x => x.logByname.toLowerCase().includes(sessionStorage.logByname.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.taskName != "")
          temp = temp.filter(x => x.taskName.toLowerCase().includes(sessionStorage.taskName.toLowerCase()));
        if (sessionStorage.estimatedHours != "")
          temp = temp.filter(x => x.estimatedHours.toLowerCase().includes(sessionStorage.estimatedHours.toLowerCase()));
        if (sessionStorage.workHours != "")
          temp = temp.filter(x => x.workHours.toLowerCase().includes(sessionStorage.workHours.toLowerCase()));
        if (sessionStorage.progressCompleted != "")
          temp = temp.filter(x => x.progressCompleted.toLowerCase().includes(sessionStorage.progressCompleted.toLowerCase()));
        if (sessionStorage.totalWorkHours != "")
          temp = temp.filter(x => x.totalWorkHours.toLowerCase().includes(sessionStorage.totalWorkHours.toLowerCase()));
        if (sessionStorage.comments != "")
          temp = temp.filter(x => x.comments.toLowerCase().includes(sessionStorage.comments.toLowerCase()));
        if (sessionStorage.planforTomorrow != "")
          temp = temp.filter(x => x.planforTomorrow.toLowerCase().includes(sessionStorage.planforTomorrow.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.Users = data.returnObject;
        this.selectedUsers = [];
        var a = this.Users.find(m => m.userId == parseInt(this.userId));
        if(a!=null)
        {
          this.selectedUsers.push(a);
        }
        this.getRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRequests() {
    this._facadeService.GetAllRequestsWithAssignee(parseInt(this.userId)).subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.name = element.code + " : " + element.title;
        });
        this.type = data.returnObject;
        this.selectedtype = [];
        this.type.forEach(element => {
          // if(element.exists)
            this.selectedtype.push(element);
        });
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  CheckDate(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }
  getMyRequests() {
    this.Error = "";
    if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "Log To Date should be greater then from date";
      this.selectedToDate = this.selectedFromDate;
    }
    else {
      this.showLoading = true;
      var types = [];
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.id);
          });
        }
      }
      var Requestedby = [];
      if (this.selectedUsers != null && this.selectedUsers != undefined) {
        if (this.selectedUsers.length > 0) {
          this.selectedUsers.forEach(element => {
            Requestedby.push(element.userId);
          });
        }
      }
      var myRequestsData =
      {
        "requests": types,
        "tasks": [],
        "logBy": Requestedby,
        "fromDate":this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "" ? new Date(this.selectedFromDate) : null,
        "toDate":this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" ? new Date(this.selectedToDate) : null
      }
      this._facadeService.GetLogs(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null)
          {
            if(this.requestData.length > 0)
            {
              this.requestData.forEach((value, key) => {   
                if(value.logDate != '' && value.logDate != null)
                  value.logDate = new Date(new Date(value.logDate).toDateString() + " " + new Date(value.logDate).toTimeString() + " UTC");
              });
            }
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Log Summary','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 135 },{ wpx : 80 },{ wpx : 400 },{ wpx : 400 },{ wpx : 100},{ wpx : 100 },{ wpx : 100 },{ wpx : 100 },{ wpx : 400 },{ wpx : 400 }];
    /* save to file */
    XLSX.writeFile(wb, "Log Summary.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['L1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

}
