<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Search Criteria">
                <div class="p-grid p-formgrid">
                    <div class="p-field p-col-12 p-md-2">
                        <label>Requested From:</label>
                        <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedFromDate" 
                        (onSelect)="CheckDate(selectedFromDate, selectedToDate)" (onClickOutside)="CheckDate(selectedFromDate, selectedToDate)"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>To:</label>
                        <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedToDate"
                        (onSelect)="CheckDate(selectedFromDate, selectedToDate)" (onClickOutside)="CheckDate(selectedFromDate, selectedToDate)" ></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Closed From:</label>
                        <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedClosedFromDate" (onClickOutside)="CheckDate1(selectedClosedFromDate, selectedClosedToDate)"  ></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>To:</label>
                        <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedClosedToDate" (onClickOutside)="CheckDate1(selectedClosedFromDate, selectedClosedToDate)"  ></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Application:</label>
                        <p-multiSelect [options]="apps" defaultLabel="Select an Application" [(ngModel)]="selectedApplications" optionLabel="applicationName" [virtualScroll]="true" 
                        selectedItemsLabel="{0} applications selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Request Type</label>
                        <p-multiSelect defaultLabel="Select a Type" [options]="type" [(ngModel)]="selectedtype" optionLabel="label" [virtualScroll]="true"
                        selectedItemsLabel="{0} types selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Priority</label>
                        <p-multiSelect  defaultLabel="Select a Priority" [options]="priority" [(ngModel)]="selectedPriority" optionLabel="label" [virtualScroll]="true"
                        selectedItemsLabel="{0} Priorities selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Status:</label>
                        <p-multiSelect defaultLabel="Select a Status" [options]="status" [(ngModel)]="selectedStatus" optionLabel="label" [virtualScroll]="true"
                        selectedItemsLabel="{0} status selected"[filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Requested By:</label>
                        <p-multiSelect [options]="Users" defaultLabel="Select an Requested By" [(ngModel)]="selectedUsers" optionLabel="fullName" [virtualScroll]="true" 
                        selectedItemsLabel="{0} users selected" [filter]="true" [itemSize]="34" class="multiselect-custom customMulti"></p-multiSelect>
                    </div>                
                    <div class="p-field p-col-12 p-md-2">
                        <a>
                            <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" pTooltip="Search" label="Search"
                            (click)="getMyRequests()" class="p-button-raised p-button-primary" style="width:100%; margin-top:25px;"></button>
                        </a>
                    </div>
                    <small class="p-invalid" style="float: right;">{{Error}}</small>
                </div>
                </p-accordionTab>
            </p-accordion>
            <div class="card-header"
                style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Requests Summary - {{DocumentsCount}}</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                        tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                        pTooltip="Download" tooltipPosition="top"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;"></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
                <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="requestData" id="excel-table" [columns]="cols" [scrollable]="true" scrollHeight="520px" 
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="iD">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                            [ngClass]="{'code': i == 0,'requestdate':i==1,'shortdescription': i == 2,'requesttype': i == 3 || i == 7,'Priority': i == 4,
                            'requestedby': i==5 ,'app': i==6}">
                            <!-- 'duedate': i==5 , -->
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="width:80px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: center; width: 110px;">{{ rowData[col.field]}}</td>
                                <td *ngIf="i == 1" style="text-align: center;  width: 140px;">{{rowData[col.field]| date:'MM/dd/yyyy'}}</td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px; width: 40%;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px; width: 180px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 4" style="text-align: center; width: 100px;">{{rowData[col.field]}}</td>
                                <!-- <td *ngIf="i == 5" style="text-align: center; width: 120px;">{{rowData[col.field]| date:'MM/dd/yyyy'}}</td> -->
                                <td *ngIf="i == 5" style="text-align: left;padding-left:15px; width: 150px;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 6" style="text-align: left;padding-left:15px; width: 20%;">{{rowData[col.field]}}</td>
                                <td *ngIf="i == 7" style="text-align: left;padding-left:15px; width: 180px;">{{rowData[col.field]}}</td>
                            </ng-container>
                            <td style="text-align: center;width: 80px;">
                                <a (click)="ViewMyRequest(rowData)"> <button pButton type="button" icon="pi pi-eye"
                                        pTooltip="view" tooltipPosition="left"
                                        class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>