import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FileUpload } from "primeng/fileupload";
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TempInputs } from 'src/app/models/IssueDetails';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-client-request-details',
  templateUrl: './client-request-details.component.html',
  styleUrls: ['./client-request-details.component.scss'],
  providers:[ConfirmationService]
})
export class ClientRequestDetailsComponent implements OnInit {
  docTypes1: any[];
  @ViewChild('dt1') tableMain: Table;
  UpdateError:string ="";
  clicked: boolean;
  // clonedTeams: { [s: string]: iCancelTeam } = {};
  taskName:string = "";
  scope:string="";
  taskestimatedHours:number = 0;
  selectedPredecessor : any;
  selectedAssignee:any;
  affectAllocation:boolean = false;
  taskNameError = "";
  scopeError = "";
  hoursError = "";
  PredecessorError = "";
  AssigneeError = "";
  EditRow1: boolean = false;
  OldTaskSeq:number = 0;

  isUAT:boolean = false;
  isProduction:boolean = false;
  GoogleAvaialable:boolean = false;
  GoogleLink:string="";
  addInputs:boolean = false;
  InputError:string = "";
  popupdescription:string = "";

  Inputs: any[] = [];
  @ViewChild('dtInputs') tableInputs: Table;
  InputsCols: any[];
  Addupdateinput: boolean = false;
  note: string;
  popupcomment:string = "";
  inputstatus:any[]=[];
  popupstatus:any = [];
  InputUpdateError:string = "";
  InputID:number = 0;

   Addupdateinput1: boolean = false;
  note1: string;
  popupcomment1:string = "";
  popupstatus1:any = [];
  InputUpdateError1:string = "";
  InputID1:number = 0;
  Inputs1: any[] = [];
  @ViewChild('dtInputs1') tableInputs1: Table;
  TempInputs:TempInputs[]=[];

  ViewOnly: boolean = false;
  applications: any;
  selectedapplication: any;
  type: SelectItem[];
  selectedtype: any;
  priority: SelectItem[];
  selectedPriority: any;
  status1 :string = "";
  internal :boolean = false;
  reasondisplay = false;
  popupreason: string = "";
  ReasonError: string;
  confirmClose = false;

  // confirmActual = false;
  // confirmDeleteDocument = false;
  index:number=0;
  Error:string = "";
  canClose:boolean = false
  showLoading:boolean;
  requestID = parseInt(sessionStorage.getItem("requestID"));
  code:string="";
  requestedByName:string="";
  selectedreqDate: any;
  // applicationName:any;
  // type: any;
  // priority: any;
  // status:string;
  createdByName:string;
  estimatedHours:string;
  actualHours:string;
  description: string = ""; 
  title:any="";
  owner:any;
  ownerName:string;
  confirmedbyName:string;
  confirmedDate:any;
  confirmedremarks: string = "";
  cancelledByName:string;
  cancelledDate:any;
  cancelledRemarks: string = "";
  closedByName:string;
  closedDate:any;
  closedRemarks: string = "";

  uATAcknowledgeName:string;
  uATAcknowledgeDate:any;
  uATAcknowledgeRemarks: string = "";

  productionAcknowledgeName:string;
  productionAcknowledgeDate:any;
  productionAcknowledgeRemarks: string = "";
  startDate:any;
  progress:any;

  status: any[];
  selectedStatus: any;

  internalstatus: any[];
  selectedinternalStatus: any;
  plannedStartDate:any;
  plannedEndDate:any;
  currentStartDate:any;
  currentEndDate:any;
  actualStartDate:any;
  actualEndDate:any;
  EnableCurrent: boolean = false;
  EnableActual:boolean = false;
  actualmin:any;
  actualendmin:any;

  userId = sessionStorage.getItem('userId');
  cols: any[];  
  ViewAddedDocuments: any[] = [];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;
  selectedUser:any;
  users:any;
  // Notes
  notesList: any[] = [];
  issueNote:string = "";
  DocsError:string="";

  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  apiResponse: any;
  @ViewChild("fileInput") fileInput: FileUpload;
  @ViewChild("fubauto") fubauto: FileUpload;
  @ViewChild("fubautoD") fubautoD: FileUpload;
  @ViewChild("fileInputreq") fileInputreq: FileUpload;
  @ViewChild("fileInputdesign") fileInputdesign: FileUpload;

  Clarification1: any[] = [];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  Clarification1Cols: any[];
  SClarification: any[] = [];
  SselectedClarification: any[];
  @ViewChild('dtSClarification') tableSClarification: Table;
  popupremark:string='';
  SError:string = "";
  addclare:boolean = false;
  requestDetails: any;

  docTypes: any[];
  @ViewChild('dt') table: Table;
  testimatedHours:number = 0;
  tactualStartDate:any;
  tactualEndDate:any;
  EditRow: boolean = false;
  OldID:string = "";
  
  Logcols:any[];
  Logs:any[] = [];

  designreq:string = "";
  requirement:string = "";
  requiremntsdocuments:any[]=[];
  ReqInputError:string = "";
  desgindocuments:any[]=[];
  DesignInputError:string = "";
  Aclicked: boolean;
  @ViewChild("dtA") tableA: Table;
  APIData:any[]=[];
  apiName:string = "";
  apiNameError:string = "";
  inputParams:string = "";
  outputParams:string = "";
  EditARow: boolean = false;
  OapiName:string = "";
  OinputParams:string = "";
  OoutputParams:string = "";
  APIError:string = "";

  TestCases: any[] = [];
  @ViewChild('dtTC') tableTC: Table;
  addTestCase:boolean = false;
  TestSteps:string = "";
  testcaseID:string = "";
  actualresult:string = "";
  testcasestatus:any=[];
  fromEdit:boolean = false;
  qaconfirm:boolean = false;
  devconfirm:boolean = false;
  comments:string = "";
  TestCaseError:string = "";
  TCID :number = 0;
  TestType:string = "";
  TestCols:any[]=[];
  inputtype:string="";
  constructor( private _facadeService: FacadeService,private pipe:DatePipe,private route: ActivatedRoute,private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private confirmation: ConfirmationService,private sanitizer:DomSanitizer
    ) { }

  ngOnInit(): void {   
    // this.requestID = parseInt(this.route.snapshot.paramMap.get('requestID'));
    this.Logcols = [
      { field: 'logDate', header: 'Log Date', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'logByname', header: 'Log By', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '25%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '80px' },
      { field: 'totalWorkHours', header: 'Total spent till Now', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'workHours', header: 'Work Hours', dynamicPlaceHolder: 'Search', width: '70px' },
      { field: 'progressCompleted', header: 'Progress Completed (%)', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '65%' }
    ];
    this.inputstatus = [
      { label: 'Open', value: 'Open'},
      { label: 'Closed', value: 'Closed'}
    ];

    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value:'Issue'},
      { label: 'Support', value: 'Support' }
    ];
    this.priority = [
      { label: 'Critical', value: 'Critical' },
      { label: 'High', value: 'High' },
      { label: 'Medium', value: 'Medium' },
      { label: 'Low', value: 'Low' }
    ];
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.status = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
      { label: 'Hold', value: 'Hold' }
    ];

    this.internalstatus = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'In-Testing', value: 'In-Testing' },
      { label: 'UAT', value: 'UAT' },
      { label: 'UAT-Rework', value: 'UAT-Rework' },
      { label: 'UAT-Bug', value: 'UAT-Bug' },
      { label: 'Production', value: 'Production' },
    ];
    this.Clarification1Cols = [
      { field: 'askedDate', header: 'Sought Date' },
      { field: 'question', header: 'Clarification' },
      { field: 'askedByName', header: 'Sought By' },
      { field: 'answerDate', header: 'Response Date' },
      { field: 'answer', header: 'Response' },
      { field: 'answerByName', header: 'Response By' }
    ];
    this.InputsCols = [
      { field: 'seq', header: 'S.No', width: '60px' },
      { field: 'addedDate', header: 'Added Date', width: '110px' },
      { field: 'addedByName', header: 'Added By', width: '130px' },
      { field: 'note', header: 'Description', width: '50%' },
      { field: 'noteStatus', header: 'Status' , width: '100px'},
      { field: 'noteComment', header: 'Comment' , width: '25%'},
      { field: 'type', header: 'Type' , width: '80px'},
    ];

    this.TestCols = [
      { field: 'testCaseID', header: 'TestCase #', width: '100px' },
      { field: 'testSteps', header: 'Test Steps', width: '30%' },
      { field: 'actualResult', header: 'Actual Result', width: '30%' },
      { field: 'status', header: 'Status', width: '80px' },
      { field: 'comments', header: 'Comments' , width: '30%'},
      { field: 'qaConfirm', header: 'QA Confirm' , width: '110px'},
      { field: 'developerConfirm', header: 'Developer Confirm' , width: '120px'},
    ];
    this.getUsers();
  }
  newRow() {  
    this.APIError = "";
    if(this.EditARow)
    {
      if(this.tableA != null && this.tableA != undefined)
        this.tableA.value.forEach(x => { 
          if (this.OapiName == x.apiName && this.OinputParams == x.inputParams && this.OoutputParams == x.outputParams)
            this.tableA.cancelRowEdit(x);  
      });
    }
    this.EditARow = false;
    return { apiName: '', inputParams: '', outputParams: '' }; }
  onRowEdit(row) {
    this.showLoading = true;
    this.APIData = this.APIData.filter(i => i.apiName != "");
    if(this.EditARow)
    {
      if(this.tableA != null && this.tableA != undefined)
        this.tableA.value.forEach(x => { 
          if (this.OapiName == x.apiName && this.OinputParams == x.inputParams && this.OoutputParams == x.outputParams)
            this.tableA.cancelRowEdit(x);  
      });
    }
    this.APIError = "";
    this.EditARow = true;
    this.OapiName = row.apiName;
    this.OinputParams = row.inputParams;
    this.OoutputParams = row.userGUID;
    this.apiNameError = '';   
    this.Aclicked = true;
    this.apiName = row.apiName;
    this.inputParams = row.inputParams;
    this.outputParams = row.outputParams;
    this.showLoading = false;
  }
  Delete(row){
    this.showLoading = true;
    this.APIError = "";
    this.Aclicked = false;
    if(this.tableA.value != null && this.tableA.value != undefined)
      this.tableA.value.forEach(x => { this.tableA.cancelRowEdit(x); });
    this.APIData = this.APIData.filter(m=>m.apiName!=row.apiName);
    this.showLoading = false;
  }
  onRowEditCancelAPI() {
    this.showLoading = true;
    this.Aclicked = false;
    if(!this.EditARow)
      this.APIData = this.APIData.filter(i => i.apiName != "");
    this.apiNameError='';
    this.apiName='';
    this.inputParams='';
    this.outputParams = '';
    this.EditARow = false;
    this.OapiName = "";
    this.OinputParams = "";
    this.OoutputParams = ""; 
    this.APIError = "";
    this.showLoading = false;
  }
  AddAPI()
  {
    this.showLoading = true;
    this.apiNameError = '';

    if (this.apiName == undefined || this.apiName == '' || this.apiName == null) {
      this.apiNameError = 'Please enter API Name.';this.showLoading = false;
    }
    else {
      if (this.inputParams == undefined || this.inputParams == null) 
        this.inputParams = "";
      if (this.outputParams == undefined || this.outputParams == null) 
        this.outputParams = "";
      var ok = true;
      this.APIData.forEach(element => {
        if(this.EditARow)
        {
          if (this.apiName == element.apiName && ok && this.OapiName != element.apiName) {
            ok = false;
            this.apiNameError = 'Duplicate records found.';
          }
        }
        else if (this.apiName == element.apiName && ok) {
          ok = false;
          this.apiNameError = 'Duplicate records found.';
        }
      });
      if (ok) {
        var cancelteamModel = {
          apiName: this.apiName,
          inputParams: this.inputParams,
          outputParams: this.outputParams
        };
        this.Aclicked = false;
        this.APIData.push(cancelteamModel);
        this.APIData = this.APIData.filter(i => i.apiName != "");
        this.apiNameError='';
        this.apiName='';
        this.inputParams='';
        this.outputParams = '';
        this.EditARow = false;
        this.OapiName = "";
        this.OinputParams = "";
        this.OoutputParams = ""; 
        this.APIError = "";
      }
      this.showLoading = false;
    }
  }
  saveapidata()
  {
    this.showLoading = true;
    if( this.Aclicked)
      this.AddAPI();
    else
    {
      this.APIError = '';
      if(this.APIData == null || this.APIData == undefined || this.APIData.length == 0)
      { this.APIError = "Please add atleast one api";this.showLoading = false;}
      else
      { 
        this.APIData.forEach(element => {
          element.requestID =this.requestID;
        });
        this._facadeService.AddAPIList(this.APIData,this.userId).subscribe(
          (data) => {
            this.apiResponse = data;
              if(this.apiResponse.returnCode == 0){
                this.Aclicked = false;
                if(!this.EditARow)
                  this.APIData = this.APIData.filter(i => i.apiName != "");
                this.apiNameError='';
                this.apiName='';
                this.inputParams='';
                this.outputParams = '';
                this.EditARow = false;
                this.OapiName = "";
                this.OinputParams = "";
                this.OoutputParams = "";
                this.APIError = "Saved successfully.";
                this.showLoading=false;
              }
              else{
                this.APIError = this.apiResponse.returnMessage;
                this.showLoading=false;
              }
          },
          (error) => {
          console.log(error);
          this.showLoading = false;
          }
        );
      }
    }
  }

  getUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.users = data.returnObject;
        this.selectedUser = [];
        var a = this.users.find(m => m.userId == parseInt(this.userId));
        if(a != null && a != undefined)
          this.selectedUser.push(a);
        this.getApplications();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  getApplications() {
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.applications = data.returnObject;
        var all = this.applications.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            this.applications.push(a);
          this.applications.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
        }
        this.getRequestDetailsByGUID();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  SendMail(ID)
  {
    this.showLoading = true;
    this._facadeService.SendMail(ID).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.showLoading = false;
          this.getRequestDetailsByGUID();
        } else {
          this.showLoading = false;
          this.Error = data.returnMessage;
        }
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRequestDetailsByGUID(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestID).subscribe(
      (data) => {
        this.showLoading = false;
        this.requestDetails = data.returnObject;                
        if(this.requestDetails!=null){  
          this.isUAT = this.requestDetails.isUAT;
          this.isProduction = this.requestDetails.isProduction;
          if(this.requestDetails.googleDrive != null && this.requestDetails.googleDrive != undefined && this.requestDetails.googleDrive != "")
          {
            this.GoogleAvaialable = true; this.GoogleLink = this.requestDetails.googleDrive;
          }
          this.internal = this.requestDetails.isInternal;
          this.code = this.requestDetails.code;
          this.requestedByName = this.requestDetails.requestedByName;
          this.selectedreqDate = new Date(this.requestDetails.requestDate+'Z');
          this.createdByName = this.requestDetails.createdByName; 
          this.selectedapplication = this.applications.find(m => m.applicationId == this.requestDetails.applicationId);
          this.selectedtype = this.type.find(x=>x.value == this.requestDetails.type);
          this.selectedPriority = this.priority.find(x=>x.value == this.requestDetails.priority);
          // this.applicationName = this.requestDetails.applicationName;
          // this.type = this.requestDetails.type;
          // this.priority = this.requestDetails.priority;
          this.status1 = this.requestDetails.status;
          this.selectedStatus = [];
          if(this.requestDetails.status != undefined && this.requestDetails.status != null)
              this.selectedStatus = this.status.find(x=>x.label.toLowerCase() == this.requestDetails.status.toLowerCase());
            
          if(this.requestDetails.status == "Closed" || this.requestDetails.status == "Saved" || this.requestDetails.status == "Cancelled"
          || this.requestDetails.status == "Pending Confirmation" || this.requestDetails.status == "Seek Clarification" )
            this.ViewOnly = true;
          this.owner = this.users.find(x=>x.userId == this.requestDetails.owner);
          this.ownerName = this.requestDetails.ownerName;
          this.confirmedbyName = this.requestDetails.confirmedbyName;
          this.confirmedremarks = this.requestDetails.confirmedRemarks;
          this.confirmedDate =this.requestDetails.confirmedDate != null && this.requestDetails.confirmedDate != undefined ? new Date(this.requestDetails.confirmedDate+'Z') : null; 

          this.cancelledByName = this.requestDetails.cancelledByName;
          this.cancelledRemarks = this.requestDetails.cancelledRemarks;
          this.cancelledDate =this.requestDetails.cancelledDate != null && this.requestDetails.cancelledDate != undefined ? new Date(this.requestDetails.cancelledDate+'Z') : null; 

          this.closedByName = this.requestDetails.closedByName;
          this.closedRemarks = this.requestDetails.closedRemarks;
          this.closedDate = this.requestDetails.closedDate != null && this.requestDetails.closedDate != undefined ? new Date(this.requestDetails.closedDate+'Z') : null; 

          this.uATAcknowledgeName = this.requestDetails.uatAcknowledgeName;
          this.uATAcknowledgeRemarks = this.requestDetails.uatAcknowledgeRemarks;
          this.uATAcknowledgeDate = this.requestDetails.uatAcknowledgeDate != null && this.requestDetails.uatAcknowledgeDate != undefined ? new Date(this.requestDetails.uatAcknowledgeDate+'Z') : null; 
          
          this.productionAcknowledgeName = this.requestDetails.productionAcknowledgeName;
          this.productionAcknowledgeRemarks = this.requestDetails.productionAcknowledgeRemarks;
          this.productionAcknowledgeDate = this.requestDetails.productionAcknowledgeDate != null && this.requestDetails.productionAcknowledgeDate != undefined ? new Date(this.requestDetails.productionAcknowledgeDate+'Z') : null; 

          this.startDate = this.requestDetails.currentEndDate != null && this.requestDetails.currentEndDate != undefined ? new Date(this.requestDetails.currentEndDate+'Z') : null; 
          this.title = this.requestDetails.title;
          this.description = this.requestDetails.description;
          this.plannedStartDate = this.requestDetails.plannedStartDate != null && this.requestDetails.plannedStartDate != undefined ? new Date(this.requestDetails.plannedStartDate+'Z') : null; 
          this.plannedEndDate = this.requestDetails.plannedEndDate != null && this.requestDetails.plannedEndDate != undefined ? new Date(this.requestDetails.plannedEndDate+'Z') : null; 
          this.currentStartDate = this.requestDetails.currentStartDate != null && this.requestDetails.currentStartDate != undefined ? new Date(this.requestDetails.currentStartDate+'Z') : null; 
          this.currentEndDate = this.requestDetails.currentEndDate != null && this.requestDetails.currentEndDate != undefined ? new Date(this.requestDetails.currentEndDate+'Z') : null; 
          this.actualStartDate = this.requestDetails.actualStartDate != null && this.requestDetails.actualStartDate != undefined ? new Date(this.requestDetails.actualStartDate+'Z') : null; 
          this.actualEndDate = this.requestDetails.actualEndDate != null && this.requestDetails.actualEndDate != undefined ? new Date(this.requestDetails.actualEndDate+'Z') : null; 
          this.estimatedHours = this.requestDetails.estimatedHours; 
          this.actualHours = this.requestDetails.actualHours; 
          this.progress = this.requestDetails.progressCompleted;
         
          this.docTypes = this.requestDetails.tasks;
          if(this.docTypes != null && this.docTypes != undefined)
          {
            if(this.docTypes.length > 0)
            {
              this.docTypes.forEach(e => {  

                var assignee = false;
                if(e.taskAssignee != null && e.taskAssignee != undefined)
                {
                  if(e.taskAssignee.length > 0)
                  {
                    e.taskAssignee.forEach(b => {
                      if(b.userID == parseInt(this.userId) && !assignee)
                        assignee = true;
                    });
                  }
                }
                e.canEdit = this.requestDetails.status != "Closed" && this.requestDetails.status != "Saved" && this.requestDetails.status != "Cancelled"
                && this.requestDetails.status != "Pending Confirmation" && this.requestDetails.status != "Seek Clarification"
                && e.status != "NA" && e.start != "Completed" && (this.owner == parseInt(this.userId) || assignee);
              });
              if(this.table.value != null && this.table.value != undefined)
                this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
            }
          }
          this.docTypes1 = this.requestDetails.tasks;
          if(this.docTypes1 != null && this.docTypes1 != undefined)
          {
            this.ngAfterViewInit();
            this.updatedoctype();
          }

          if(this.owner == parseInt(this.userId))
            this.EnableCurrent= true;
          if(this.owner == parseInt(this.userId) && this.requestDetails.internalStatus == "New")
            this.EnableActual= true;
           this.startDate = this.requestDetails.startDate != null && this.requestDetails.startDate != undefined ? new Date(this.requestDetails.startDate+'Z') : null; 
           this.Logs = this.requestDetails.logs;
           if(this.Logs!=undefined && this.Logs != null)
           {
             if(this.Logs.length > 0)
             {
               this.Logs.forEach((value, key) => {   
                 if(value.logDate != '' && value.logDate != null)
                   value.logDate = new Date(new Date(value.logDate).toDateString() + " " + new Date(value.logDate).toTimeString() + " UTC");
               });
             }
           }
           else
              this.Logs = [];

           this.notesList = this.requestDetails.notes;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList.forEach((value, key) => {  
              value.ok = false;
              if(value.createdBy == parseInt(this.userId))
                value.ok = true;
              if(value.createdDate.toString().indexOf('Z') > 0)   
                value.createdDate = new Date(value.createdDate);   
              else
                value.createdDate = new Date(value.createdDate+'Z');              
            });
          }
          this.ViewAddedDocuments = this.requestDetails.documents;
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null && this.ViewAddedDocuments.length > 0)
          {
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
          else 
            this.ViewAddedDocuments = [];
          this.selectedUser = [];
          if(this.requestDetails.assinees != undefined && this.requestDetails.assinees != null)
          {
            this.requestDetails.assinees.forEach(element => {
              var a = this.users.find(x=>x.userId == element.UserID);
              if(a != undefined && a != null)
                this.selectedUser.push(a);
            });
          }
          this.selectedinternalStatus = [];
          if(this.requestDetails.internalStatus != undefined && this.requestDetails.internalStatus != null)
              this.selectedinternalStatus = this.internalstatus.find(x=>x.label.toLowerCase() == this.requestDetails.internalStatus.toLowerCase());

          this.canClose = false;
          if(this.requestDetails.requestedBy == parseInt(this.userId) || this.requestDetails.createdBy == parseInt(this.userId) || this.requestDetails.owner == parseInt(this.userId))
            this.canClose = true;

          this.Clarification1 = []; this.SClarification = [];
          var mainclare = this.requestDetails.clarifications;
          if(mainclare!=undefined && mainclare != null)
          {
            if(mainclare.length > 0)
            {
              mainclare.forEach((value, key) => {
                if ( value.askedDate !== null &&  value.askedDate !== undefined )
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
                if ( value.answerDate !== null &&  value.answerDate !== undefined )
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
              });
              this.Clarification1 = mainclare.filter(x=>x.type != "Support");
              this.SClarification = mainclare.filter(x=>x.type == "Support");
            } 
          }
          this.Inputs = []; this.Inputs1 = [];
          this.requirement = "";
          this.requiremntsdocuments = [];
          var MInputs = this.requestDetails.inputs;
          if(MInputs !=undefined && MInputs != null && MInputs.length > 0)
          {
            MInputs.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
            this.Inputs = MInputs.filter(x=> x.type == "UAT" || x.type == "Prodcution");
            this.Inputs1 = MInputs.filter(x=> x.type == "Defect");
            var req = MInputs.filter(x=> x.type == "Requirement");
            if(req != null)
            {
              if(req.length > 0)
              {
                this.requirement = req[0].note;
                this.requiremntsdocuments = req[0].documents;
              }
            }
            this.designreq = "";
            if(this.requiremntsdocuments == null ||  this.requiremntsdocuments == undefined)
              this.requiremntsdocuments = [];
            var des = MInputs.filter(x=> x.type == "Design");
            if(des != null)
            {
              if(des.length > 0)
              {
                this.designreq = des[0].note;
                this.desgindocuments = des[0].documents;
              }
            }
          }
          this.ReqInputError = "";
          if (this.fileInputreq != undefined) {
            if (this.fileInputreq.files.length > 0) {
              this.fileInputreq.clear();
            }
          }
          this.DesignInputError = "";
          if (this.fileInputdesign != undefined) {
            if (this.fileInputdesign.files.length > 0) {
              this.fileInputdesign.clear();
            }
          }
          this.APIData = this.requestDetails.apiList;
          if(this.APIData == null || this.APIData == undefined)
            this.APIData = [];

          this.TestCases = this.requestDetails.testCases;
            if(this.TestCases == null || this.TestCases == undefined)
              this.TestCases = [];
          this.index = 1;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  updatedoctype()
{
  var eh: number = 0;
  this.UpdateError = "";
  if(this.docTypes1 != null && this.docTypes1 != undefined)
  {
    if(this.docTypes1.length > 0)
    {
      this.docTypes1.forEach(e => {
        var duration: number =  0;
        var CT : number = parseInt(e.estimatedHours) / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        e.cycleTime = duration;
        
        eh = Number(eh) + Number(e.estimatedHours)
        e.pData = [];
        var aa = this.docTypes1.filter(x=>x.taskSeq < e.taskSeq);
        if(aa != undefined && aa != null)
        {
          aa.forEach(a => {
            var i = {
              "taskID" : e.taskID,
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            e.pData.push(i);
          });
        }
        var p = e.taskPredecessor;
        e.taskPredecessor = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              var a1 = e.pData.find(x=>x.predecessor == element.predecessor);
              if(a1 != undefined && a1 != null)
                  e.taskPredecessor.push(a1);
            });
          }
        }
        p = e.taskAssignee;
        e.taskAssignee = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              if(element.userID == null || element.userID == undefined)
                element.userID = element.userId;
              var a1 = this.users.find(x=>x.userId == element.userID);
              if(a1 != undefined && a1 != null)
                  e.taskAssignee.push(a1);
            });
          }
        }

      });
    }
  }
  this.estimatedHours = eh.toString();
}

ngAfterViewInit() {
  if(this.tableMain != undefined)
  {
    this.tableMain.onRowReorder.subscribe(data => {
      this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
      var dropIndex = data.dropIndex + 1;
      var dragIndex = data.dragIndex + 1;
      if(dragIndex > dropIndex)
      {
        this.docTypes1.forEach(e =>
        {
          if(e.taskSeq >= dropIndex && e.taskSeq <= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.taskSeq + 1;
          }
        });
      }
      if(dragIndex < dropIndex)
      {
        this.docTypes1.forEach(e =>
        {
          if(e.taskSeq <= dropIndex && e.taskSeq >= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.seq - 1;
          }
        });
      }
      if(this.docTypes1 != null && this.docTypes1 != undefined)
        this.updatedoctype();
    });
  }
}


onRowEditInit(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  let doc= [];
  this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
  this.docTypes1.forEach(i => {
    if(i.taskName == null)    i.taskName = "";
    if(teamModel.taskName == null)    teamModel.taskName = "";
    if(i.taskName.toUpperCase().trim() == teamModel.taskName.toUpperCase().trim())
    {
    }
    else
      doc.push(i);
  });
  this.docTypes1 = doc;
  this.docTypes1 = this.docTypes1.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  if(this.docTypes1 != null && this.docTypes1 != undefined)
  {
    if(this.docTypes1.length > 0)
    {
      this.docTypes1.forEach(e => {
        if(e.taskSeq > teamModel.taskSeq)
          e.taskSeq = e.taskSeq - 1;
      });
    }
  }
  this.updatedoctype();
  this.showLoading = false;
}

onRowEditInit2(row) {
  this.showLoading = true;
  this.tableMain.value.forEach(x => {
    if(x.taskID != row.taskID)
     this.tableMain.cancelRowEdit(x);
  });
  this.docTypes1 = this.docTypes1.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  this.EditRow1 = true;
  this.OldTaskSeq = row.taskSeq;
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.clicked = true;
  this.taskName = row.taskName;
  this.scope = row.scope;
  this.taskestimatedHours = row.estimatedHours;
  this.affectAllocation = row.affectAllocation;
  this.selectedAssignee = [];
  if(row.taskAssignee != null && row.taskAssignee != undefined)
  {
    if(row.taskAssignee.length > 0)
    {
      row.taskAssignee.forEach(element => {
        if(element.userID == null || element.userID == undefined)
        element.userID = element.userId;
        var a = this.users.find(x=>x.userId == element.userID);
        this.selectedAssignee.push(a);
      });
    }
  }
  this.selectedPredecessor = [];
  if(row.taskPredecessor != null && row.taskPredecessor != undefined)
  {
    if(row.taskPredecessor.length > 0)
    {
      row.taskPredecessor.forEach(element => {
        var a = this.docTypes1.find(x=>x.taskID == element.predecessor);
        this.selectedPredecessor.push(a);
      });
    }
  }
  this.showLoading = false;
}
onRowEditSave(docTypes: iCancelTeam) {
  this.showLoading = true;
  this.addCancelTeam();
}
onRowEditCancel1(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  if(!this.EditRow1)
    this.docTypes1 = this.docTypes1.filter(i => i.taskName != teamModel.taskName);
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.taskName='';
  this.affectAllocation=false;
  this.scope = "";
  this.taskestimatedHours = 0;
  this.selectedAssignee = [];
  this.selectedPredecessor = [];
  this.updatedoctype();
  
  this.EditRow1 = false;
  this.OldTaskSeq = 0;
  this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
  this.docTypes1 = this.docTypes1.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  this.showLoading = false;
}

  newRow1() {
    if(this.docTypes1 != null && this.docTypes1 != undefined)
      return {taskName: '', taskSeq: this.docTypes1.length + 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      actualHours:0,progressCompleted:0,status:'New', cycleTime: 0,taskAssignee :[],taskPredecessor: []};
    else
      return {taskName: '', taskSeq: 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      actualHours:0,progressCompleted:0,status:'New',cycleTime: 0,taskAssignee :[],taskPredecessor: []};
  }

  addCancelTeam() {
    this.showLoading = true;
    this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";

    if (this.taskName == undefined || this.taskName == '' || this.taskName == null) {
      this.taskNameError = 'Please enter task.';this.showLoading = false;
    }
    else if (this.scope == undefined || this.scope == '' || this.scope == null) {
      this.scopeError = 'Please enter scope.';this.showLoading = false;
    }
    else if (this.taskestimatedHours == undefined || this.taskestimatedHours == 0 || this.taskestimatedHours == null) {
      this.hoursError = 'Please enter Estimated Hours.';this.showLoading = false;
    }
    // else if (this.selectedPredecessor == undefined || this.selectedPredecessor.length == 0 || this.selectedPredecessor == null) {
    //   this.PredecessorError = 'Please select Predecessor.';this.showLoading = false;
    // }
    else if (this.selectedAssignee == undefined || this.selectedAssignee.length == 0 || this.selectedAssignee == null) {
      this.AssigneeError = 'Please select Assignee.';this.showLoading = false;
    }
    else 
    {
      var ok = true;
      this.docTypes1.forEach(element => {
        if (this.taskName == element.taskName && this.OldTaskSeq != element.taskSeq && ok) {
          ok = false;
          this.taskNameError = 'Duplicate records found.';
        }
      });
      if (ok) {
        var pp = [];
        if (this.selectedPredecessor != undefined &&this.selectedPredecessor != null) {
          this.selectedPredecessor.forEach(a => {
            var i = {
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            pp.push(i);
          });
        }
        var duration: number =  0;
        var CT : number = this.taskestimatedHours / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        if(this.EditRow1)
        {
          this.docTypes1.forEach(element => {
            if (this.OldTaskSeq == element.taskSeq) {
              pp.forEach(a => {
                a.taskID = element.taskID;
              });
              element.isEdit = "E";
              element.taskName =  this.taskName;
              element.affectAllocation = this.affectAllocation;
              element.scope = this.scope;
              element.estimatedHours = this.taskestimatedHours;
              element.taskAssignee = this.selectedAssignee;
              element.taskPredecessor = pp;
              element.cycleTime =  duration;
            }
          });
          this.EditRow1 = false;
          this.OldTaskSeq = 0;
          this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
        }
        else
        {
          var id = 0;
          pp.forEach(a => {
            a.taskID = id;
          });
          var cancelteamModel = {
            taskID: id,
            taskName: this.taskName,
            taskSeq: this.docTypes1.length,
            affectAllocation:this.affectAllocation,
            scope:this.scope,
            estimatedHours : this.taskestimatedHours,
            actualHours : 0,
            progressCompleted : 0,
            status : "New",
            isEdit:"Y",
            startDate:"--",
            dueDate:"--",
            plannedStartDate:"--",
            plannedEndDate:"--",
            currentStartDate:"--",
            currentEndDate:"--",
            actualStartDate:"--",
            actualEndDate:"--",
            cycleTime: duration,
            taskAssignee : this.selectedAssignee,
            taskPredecessor : pp
          };
          this.docTypes1.push(cancelteamModel);
        }
        this.clicked = false;
        this.docTypes1 = this.docTypes1.filter(i => i.taskName != "");
        this.taskName='';
        this.affectAllocation=false;
        this.scope = "";
        this.taskestimatedHours = 0;
        this.selectedAssignee = [];
        this.selectedPredecessor = [];
        this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
        this.updatedoctype();
      }
      this.showLoading = false;
    }
  }
  Update1()
  {
    this.UpdateError = "";
    if(this.docTypes1 == null || this.docTypes1 == undefined || this.docTypes1.length == 0) 
      this.UpdateError = "Please add alteast one task";
    else if(this.estimatedHours == null || this.estimatedHours == undefined || this.estimatedHours == "")
      this.UpdateError = "Please enter estimated hours";
    else if(this.owner == null || this.owner == undefined || this.owner == "" || this.owner.length == 0)
      this.UpdateError = "Please select request owner";
    else{
      var allok = false;
      this.docTypes1.forEach(element => {
        if((element.taskAssignee == null || element.taskAssignee == undefined || element.taskAssignee.length == 0) && !allok)
        {

        }
        else
          allok = true;
      });
      if(allok)
      {
        this.showLoading = true;
        var ass = [];
        this.docTypes1.forEach(element => {
          var assignee = [];
          if(element.taskAssignee != null && element.taskAssignee != undefined)
          {
            if(element.taskAssignee.length > 0)
            {
              element.taskAssignee.forEach(e => {
                if(e.userID == null || e.userID == undefined)
                  e.userID = e.userId;
                var as ={
                  "userID":e.userID,
                }
                assignee.push(as);
              });
            }
          }

          var predesss = [];
          if(element.taskPredecessor != null && element.taskPredecessor != undefined)
          {
            if(element.taskPredecessor.length > 0)
            {
              element.taskPredecessor.forEach(e => {
                var as ={
                  "predecessorName":e.predecessorName,
                }
                predesss.push(as);
              });
            }
          }
        
          var a ={
            "requestID":this.requestID,
            "taskID":element.taskID,
            "taskName":element.taskName,
            "taskSeq": element.taskSeq,
            "affectAllocation": element.affectAllocation,
            "plannedStartDate": element.plannedStartDate == "--" ? null : new Date(element.plannedStartDate+'Z'),
            "plannedEndDate": element.plannedEndDate == "--" ? null : new Date(element.plannedEndDate+'Z'),
            "currentStartDate": element.currentStartDate == "--" ? null : new Date(element.currentStartDate+'Z'),
            "currentEndDate": element.currentEndDate == "--" ? null : new Date(element.currentEndDate+'Z'),
            "actualStartDate": element.actualStartDate == "--" ? null : new Date(element.actualStartDate+'Z'),
            "actualEndDate": element.actualEndDate == "--" ? null : new Date(element.actualEndDate+'Z'),
            "startDate": element.startDate == "--" ? null : new Date(element.startDate+'Z'),
            "dueDate": element.dueDate == "--" ? null : new Date(element.dueDate+'Z'),
            "scope": element.scope,
            "estimatedHours" : element.estimatedHours == null || element.estimatedHours == undefined || element.estimatedHours == "" ? 0 : parseInt(element.estimatedHours),
            "actualHours":element.actualHours,
            "progressCompleted":element.progressCompleted,
            "status":element.status,
            "taskAssignee" : assignee,
            "taskPredecessor" : predesss
          }
          ass.push(a);
        });

        if(this.description == null || this.description == undefined)
          this.description = "";
        this.description = this.description.replace("<p>", ""); 
        this.description = this.description.replace("</p>", ""); 
      
      var model={
        "id":this.requestID,
        "plannedStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "plannedEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "currentStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "currentEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "actualStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "actualEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "internalStatus":this.selectedinternalStatus.value,
        "owner" :this.owner.userId,
        "estimatedHours" : this.estimatedHours == undefined || this.estimatedHours == null ? 0 : (this.estimatedHours == "" ? 0 : parseInt(this.estimatedHours)),
        "actualHours" : this.actualHours == undefined || this.actualHours == null ? 0 : (this.actualHours == "" ? 0 : parseInt(this.actualHours)),
        "progressCompleted":this.progress == undefined || this.progress == null ? 0 : (this.progress == "" ? 0 : parseInt(this.progress)),
        "status":this.selectedStatus.value,

        "priority":this.selectedPriority.value,
        "title":this.title,
        "applicationId":this.selectedapplication.applicationId,
        "type":this.selectedtype.value,
        "description":this.description,

        "createdBy":parseInt(this.userId),
        "tasks":ass,
        "exists" : true,
        "googleDrive": this.GoogleLink
        // "applicationId":this.applicationID,
          // "type":this.type,
        }
        this._facadeService.UpdateRequestData(model).subscribe(
          (data) => {
            var apiResponse = data;
            if (apiResponse.returnCode == 0) {
              this.showLoading = false;
              location.reload();
              // this.router.navigate(['/supportDashboard']);
            } else {
              this.showLoading = false;
              this.UpdateError = apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else 
        this.UpdateError = "Please select 1 assignee for the tasks";
    }
  }

  UpdateInput(ID, note,status,type) {
    if(type == "U")
    {
      this.Addupdateinput = true;
      this.note = note;
      this.InputID = ID;
      this.InputUpdateError = "";
      this.popupcomment = "";
      if(status != null && status != undefined && status != "")
        this.popupstatus = this.inputstatus.find(x=>x.value == status);
    }
    else
    {
      this.Addupdateinput1 = true;
      this.note1 = note;
      this.InputID1 = ID;
      this.InputUpdateError1 = "";
      this.popupcomment1 = "";
      if(status != null && status != undefined && status != "")
        this.popupstatus1 = this.inputstatus.find(x=>x.value == status);
    }
  }

  InputClear() {
    this.Addupdateinput = false;
    this.note = "";
    this.InputUpdateError = "";
    this.popupcomment = "";
    this.popupstatus = [];
    this.InputID = 0;
  }

  updateInput()
  {
    this.showLoading = true;
    this.InputUpdateError = "";
    if (this.popupstatus == undefined || this.popupstatus == null || this.popupstatus.length == 0) {
      this.InputUpdateError = "Please select status.";
      this.showLoading = false;
    }
    if (this.InputID == undefined || this.InputID == 0) {
      this.InputUpdateError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }

    if(this.popupstatus != undefined && this.popupstatus.length != 0 && this.InputID != undefined && this.InputID != 0 && this.InputUpdateError == "") 
    {
      if(this.popupcomment == null || this.popupcomment == undefined)
        this.popupcomment = "";
      var ClarifyModel = {
        InputID: this.InputID,
        NoteStatus: this.popupstatus.value,
        NoteComment: this.popupcomment,
        AddedBy : this.userId
      };
      this._facadeService.UpdateInput(ClarifyModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            location.reload();
          } else {
            this.showLoading = false;
            this.InputUpdateError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  InputClear1() {
    this.Addupdateinput1 = false;
    this.note1 = "";
    this.InputUpdateError1 = "";
    this.popupcomment1 = "";
    this.popupstatus1 = [];
    this.InputID1 = 0;
  }

  updateInput1()
  {
    this.showLoading = true;
    this.InputUpdateError1 = "";
    if (this.popupstatus1 == undefined || this.popupstatus1 == null || this.popupstatus1.length == 0) {
      this.InputUpdateError1 = "Please select status.";
      this.showLoading = false;
    }
    if (this.InputID1 == undefined || this.InputID1 == 0) {
      this.InputUpdateError1 = "Error occured, please referesh the page.";
      this.showLoading = false;
    }

    if(this.popupstatus1 != undefined && this.popupstatus1.length != 0 && this.InputID1 != undefined && this.InputID1 != 0 && this.InputUpdateError1 == "") 
    {
      if(this.popupcomment1 == null || this.popupcomment1 == undefined)
        this.popupcomment1 = "";
      var ClarifyModel = {
        InputID: this.InputID1,
        NoteStatus: this.popupstatus1.value,
        NoteComment: this.popupcomment1,
        AddedBy : this.userId
      };
      this._facadeService.UpdateInput(ClarifyModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            location.reload();
          } else {
            this.showLoading = false;
            this.InputUpdateError1 = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  AddClare()
  {
    this.SError = "";
    this.popupremark  = ""; 
    this.addclare = true;
  }

Confirm()
{
  this.SError = "";
  this.showLoading = true;
  if (this.popupremark == undefined || this.popupremark == '') 
  { 
    this.SError = 'Please enter question.';
    this.showLoading = false; 
  }
  else
  {
    var requestData = {
      requestid: this.requestID,
      userID: parseInt(this.userId),
      remark: this.popupremark,
    } 
    this._facadeService.AddQuestion(requestData).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnObject == "OK") {
          location.reload();
          this.showLoading = false;
        } else {
          this.showLoading = false;
          this.SError = this.apiResponse.returnObject;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
AddTestCases() {
  this.showLoading = true;
  this.addTestCase = true;
  this.fromEdit = false;
  this.testcaseID = "New";
  this.TestSteps = "";
  this.actualresult = "";
  this.testcasestatus = this.inputstatus.find(x=>x.value=="Open");
  this.qaconfirm = false;
  this.devconfirm = false;
  this.comments = "";
  this.TestCaseError = "";
  this.TCID = 0;
  this.TestType = "Add";
  this.showLoading = false;
}
UpdateTestCase(row)
{
  this.showLoading = true;
  this.addTestCase = true;
  this.fromEdit = true;
  this.testcaseID = row.testCaseID;
  this.TestSteps = row.testSteps;
  this.actualresult = row.actualResult;
  this.testcasestatus = this.inputstatus.find(x=>x.value==row.status);
  this.qaconfirm = row.qaConfirm;
  this.devconfirm = row.developerConfirm;
  this.comments = "";
  this.TestCaseError = "";
  this.TCID = row.id;
  this.TestType = "Edit";
  this.showLoading = false;
}
SaveTestCase()
{
  this.showLoading = true;
    this.TestCaseError = "";
    let ok = true;
    if (this.TestSteps == undefined || this.TestSteps == null || this.TestSteps == "") {
      ok = false;
      this.TestCaseError = "Please enter Test Step";
    }
    if(ok)
    {
      var model = {
        ID: this.TCID,
        RequestID : this.requestID,
        TestCaseID: this.testcaseID,
        TestSteps :this.TestSteps,
        ActualResult :this.actualresult,
        Status :this.testcasestatus.value,
        Comments : this.comments,
        QAConfirm :this.qaconfirm,
        DeveloperConfirm : this.devconfirm
      };
      if(this.TCID > 0)
      {
        this._facadeService.UpdateTestCases(model,this.userId)
        .subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) 
            { 
              location.reload();
              this.showLoading = false;
            }
            else
            {
              this.TestCaseError = this.apiResponse.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.AddtestCase(model,this.userId)
        .subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) 
            { 
              location.reload();
              this.showLoading = false;
            }
            else
            {
              this.TestCaseError = this.apiResponse.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
}

   AddInputs(type) {
    this.addInputs = true;
    this.inputtype = type;
    this.InputError = "";
    this.popupdescription = "";
    if (this.fileInput != undefined) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.clear();
      }
    }
  }
 
  AddInput()
  { 
    this.showLoading = true;
    this.InputError = "";
    let ok = true;
    if (this.popupdescription == undefined || this.popupdescription == null || this.popupdescription == "") {
      ok = false;
      this.InputError = "Please enter description";
    }
    if(ok)
    {
      if(this.fileInput != undefined && this.fileInput != null)
      {
        if(this.fileInput.files.length > 0)
        {
          this.fileInput.files.forEach((file) => {
            let fileSize = file.size
            if (fileSize <= 10 && ok) 
            {
              this.InputError = "File size should be greater than 1KB";
              ok = false;
            }
          });
        }
      }
    }
    if (ok) 
    {
      this.popupdescription = this.popupdescription.replace("<p>", ""); 
      this.popupdescription = this.popupdescription.replace("</p>", ""); 
      var data : TempInputs = {
        "seq" : this.Inputs1.length + this.TempInputs.length + 1,
        "note": this.popupdescription,
        "documents" : [],
        "documents1" : []
      }
      this.fileInput.files.forEach((file) => {
        data.documents.push(file);
        data.documents1.push(file.name);
      });
      this.TempInputs.push(data);
      this.addInputs = true;
      this.InputError = "";
      this.popupdescription = "";
      if (this.fileInput != undefined) {
        if (this.fileInput.files.length > 0) {
          this.fileInput.clear();
        }
      }
      this.showLoading = false;
    }
    else
      this.showLoading = false;
  }
  AddReqInput()
  {
    this.showLoading = true;
    this.ReqInputError = "";
    let ok = true;
    if (this.requirement == undefined || this.requirement == null || this.requirement == "") {
      ok = false;
      this.ReqInputError = "Please enter requirements";
    }
    if(ok)
    {
      if(this.fileInputreq != undefined && this.fileInputreq != null)
      {
        if(this.fileInputreq.files.length > 0)
        {
          this.fileInputreq.files.forEach((file) => {
            let fileSize = file.size
            if (fileSize <= 10 && ok) 
            {
              this.ReqInputError = "File size should be greater than 1KB";
              ok = false;
            }
          });
        }
      }
    }
    if(ok)
    {
      this.requirement = this.requirement.replace("<p>", ""); 
      this.requirement = this.requirement.replace("</p>", ""); 
      const formData = new FormData();
      formData.append("requestID", this.requestID.toString());
      formData.append("applicationID", this.applicationID);
      formData.append("departmentID", this.UserDepartmentID);
      formData.append("addedBy", this.userId);
      formData.append("type", "Requirement");
      formData.append("note", this.requirement);
      formData.append("seq", "1");
      this.fileInputreq.files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("last", "Y");
      this._facadeService.addInput(formData)
      .subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) 
          { 
            var req = this.apiResponse.returnObject.filter(x=> x.type == "Requirement");
            if(req != null)
            {
              if(req.length > 0)
              {
                this.requirement = req[0].note;
                this.requiremntsdocuments = req[0].documents;
              }
            }
            if(this.requiremntsdocuments == null ||  this.requiremntsdocuments == undefined)
              this.requiremntsdocuments = [];
            this.ReqInputError = "";
            if (this.fileInputreq != undefined) {
              if (this.fileInputreq.files.length > 0) {
                this.fileInputreq.clear();
              }
            }
            this.showLoading = false;
          }
          else
          {
            this.ReqInputError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.showLoading = false;
  }
  AddDesignInput()
  {
    this.showLoading = true;
    this.DesignInputError = "";
    let ok = true;
    if(ok)
    {
      if(this.fileInputdesign != undefined && this.fileInputdesign != null)
      {
        if(this.fileInputdesign.files.length > 0)
        {
          this.fileInputdesign.files.forEach((file) => {
            let fileSize = file.size
            if (fileSize <= 10 && ok) 
            {
              this.DesignInputError = "File size should be greater than 1KB";
              ok = false;
            }
          });
        }
      }
    }
    if(ok)
    {
      if(this.designreq == null || this.designreq == undefined)
        this.designreq = "";
      this.designreq = this.designreq.replace("<p>", ""); 
      this.designreq = this.designreq.replace("</p>", ""); 

      const formData = new FormData();
      formData.append("requestID", this.requestID.toString());
      formData.append("applicationID", this.applicationID);
      formData.append("departmentID", this.UserDepartmentID);
      formData.append("addedBy", this.userId);
      formData.append("type", "Design");
      formData.append("note", this.designreq);
      formData.append("seq", "1");
      this.fileInputdesign.files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("last", "Y");
      this._facadeService.addInput(formData)
      .subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) 
          { 
            var req = this.apiResponse.returnObject.filter(x=> x.type == "Design");
            if(req != null)
            {
              if(req.length > 0)
              {
                this.designreq = req[0].note;
                this.desgindocuments = req[0].documents;
              }
            }
            this.DesignInputError = "";
            if (this.fileInputdesign != undefined) {
              if (this.fileInputdesign.files.length > 0) {
                this.fileInputdesign.clear();
              }
            }
            this.showLoading = false;
          }
          else
          {
            this.DesignInputError = this.apiResponse.returnMessage;
            this.showLoading = false;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.showLoading = false;
  }
  MainInput()
  {
    this.showLoading = true;
    this.InputError = "";
    if(this.TempInputs != null && this.TempInputs != undefined && this.TempInputs.length > 0)
    {
      var nofile = this.TempInputs.filter(x=> x.documents == null || x.documents == undefined || x.documents.length == 0);
      var withfile = this.TempInputs.filter(x=> x.documents != null && x.documents != undefined && x.documents.length > 0);
      var onlynotes = (withfile != null && withfile != undefined && withfile.length > 0) ? false : true; 
      var a = false;
      if(nofile != null && nofile != undefined)
      {
        if(nofile.length > 0)
        {
          var NoFileInput = [];
          nofile.forEach(element => {
            var Model = {
              note:  element.note,
              seq : element.seq
            };
            NoFileInput.push(Model);
          });
          var FinalModel = {
            requestID:this.requestID,
            addedBy: parseInt(this.userId),
            type: this.inputtype,
            onlyNotes: onlynotes,
            notes: NoFileInput
          };
          this._facadeService.addInputNoFile(FinalModel)
          .subscribe(
            (data) => {
              this.apiResponse = data;
              if (this.apiResponse.returnCode == 0) 
              { 
                if(onlynotes)
                {
                  if(this.inputtype == "Defect")
                  {
                    this.Inputs1 = this.apiResponse.returnObject.filter(x=> x.type == "Defect");
                    if(this.Inputs1!=undefined && this.Inputs1 != null && this.Inputs1.length > 0)
                    {
                      this.Inputs1.forEach((value, key) => {              
                        value.addedDate = new Date(value.addedDate+'Z');              
                      });
                    }
                  }
                  else
                  {
                    this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT");
                    if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                    {
                      this.Inputs.forEach((value, key) => {              
                        value.addedDate = new Date(value.addedDate+'Z');              
                      });
                    }
                  }
                  this.addInputs = false;
                  this.TempInputs = [];
                  this.inputtype = "";
                  this.showLoading = false;
                }
                else
                {
                  if(withfile != null && withfile != undefined)
                  {
                  withfile.forEach((element,key) => {
                    const formData = new FormData();
                    formData.append("requestID", this.requestID.toString());
                    formData.append("applicationID", this.applicationID);
                    formData.append("departmentID", this.UserDepartmentID);
                    formData.append("addedBy", this.userId);
                    formData.append("type", this.inputtype);
                    formData.append("note", element.note);
                    formData.append("seq", element.seq.toString());
                      element.documents.forEach((file) => {
                        formData.append("files", file);
                      });
                    if(withfile.length == (key + 1))   formData.append("last", "Y");  else   formData.append("last", "N");
                    this._facadeService.addInput(formData)
                    .subscribe(
                      (data) => {
                        this.apiResponse = data;
                        if (this.apiResponse.returnCode == 0) 
                        { 
                          if(withfile.length == (key + 1))
                          {
                            if(this.inputtype == "Defect")
                            {
                              this.Inputs1 = this.apiResponse.returnObject.filter(x=> x.type == "Defect");
                              if(this.Inputs1!=undefined && this.Inputs1 != null && this.Inputs1.length > 0)
                              {
                                this.Inputs1.forEach((value, key) => {              
                                  value.addedDate = new Date(value.addedDate+'Z');              
                                });
                              }
                            }
                            else
                            {
                              this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT");
                              if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                              {
                                this.Inputs.forEach((value, key) => {              
                                  value.addedDate = new Date(value.addedDate+'Z');              
                                });
                              }
                            }
                            this.addInputs = false;
                            this.TempInputs = [];
                            this.inputtype = "";
                            this.showLoading = false;
                          }
                        }
                        else
                        {
                          this.InputError = this.apiResponse.returnMessage;
                          this.showLoading = false;
                        }
                      },
                      (error) => {
                        this.showLoading = false;
                        console.log(error);
                      }
                    );
                  });
                  }
                }
              }
              else
              {
                this.InputError = this.apiResponse.returnMessage;
                this.showLoading = false;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          );
        }
        else a = true;
      }
      else a = true;
      if(a)
      {
        if(withfile != null && withfile != undefined)
        {
        withfile.forEach((element,key) => {
          const formData = new FormData();
          formData.append("requestID", this.requestID.toString());
          formData.append("applicationID", this.applicationID);
          formData.append("departmentID", this.UserDepartmentID);
          formData.append("addedBy", this.userId);
          formData.append("type", this.inputtype);
          formData.append("note", element.note);
          formData.append("seq", element.seq.toString());
            element.documents.forEach((file) => {
              formData.append("files", file);
            });
          if(withfile.length == (key + 1))   formData.append("last", "Y");  else   formData.append("last", "N");
          this._facadeService.addInput(formData)
          .subscribe(
            (data) => {
              this.apiResponse = data;
              if (this.apiResponse.returnCode == 0) 
              { 
                if(withfile.length == (key + 1))
                {
                  if(this.inputtype == "Defect")
                  {
                    this.Inputs1 = this.apiResponse.returnObject.filter(x=> x.type == "Defect");
                    if(this.Inputs1!=undefined && this.Inputs1 != null && this.Inputs1.length > 0)
                    {
                      this.Inputs1.forEach((value, key) => {              
                        value.addedDate = new Date(value.addedDate+'Z');              
                      });
                    }
                  }
                  else
                  {
                    this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT");
                    if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                    {
                      this.Inputs.forEach((value, key) => {              
                        value.addedDate = new Date(value.addedDate+'Z');              
                      });
                    }
                  }
                  this.addInputs = false;
                  this.TempInputs = [];
                  this.inputtype = "";
                  this.showLoading = false;
                }
              }
              else
              {
                this.InputError = this.apiResponse.returnMessage;
                this.showLoading = false;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          );
        });
        }
      } 
    }
    else
    {
      this.InputError = "Please add atleast one input";
      this.showLoading = false;
    }
  }
  onFileUpload(event) {
    this.InputError = "";
    let fileSize = event.files[0].size;
    if (fileSize <= 10) {
     this.InputError = "File size should be greater than 1KB";
    }
  }

  updateTask() {
    this.showLoading = true;
    this.Error = "";

    if (this.testimatedHours == undefined || this.testimatedHours == 0 || this.testimatedHours == null) {
      this.Error = 'Please enter estimated hours.';this.showLoading = false;
    }
    else if ((this.tactualStartDate == undefined || this.tactualStartDate == '' || this.tactualStartDate == null)
    && this.tactualEndDate != undefined && this.tactualEndDate != '' && this.tactualEndDate != null) {
      this.Error = 'Please enter actual start date.';this.showLoading = false;
    }
    // else if ((this.tprogressCompleted == undefined || this.tprogressCompleted == 0 || this.tprogressCompleted == null)
    // && this.tactualEndDate != undefined && this.tactualEndDate != '' && this.tactualEndDate != null) {
    //   this.Error = 'Please enter progress completed.';this.showLoading = false;
    // }
    else if(this.EditRow)
    {
      var model = {
        "taskID":this.OldID,
        "actualStartDate":(this.tactualStartDate != undefined && this.tactualStartDate != "" && this.tactualStartDate != null) ? new Date(this.tactualStartDate+'Z'): null,
        "actualEndDate":(this.tactualEndDate != undefined && this.tactualEndDate != "" && this.tactualEndDate != null) ? new Date(this.tactualEndDate+'Z'): null,
        // "progressCompleted":(this.tprogressCompleted != undefined && this.tprogressCompleted != null) ? Number(this.tprogressCompleted) : 0,
        "estimatedHours":(this.testimatedHours != undefined && this.testimatedHours != null) ? Number(this.testimatedHours) : 0
      };
      this._facadeService.UpdateTask(model,this.userId).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            this.testimatedHours = 0;
            this.tactualStartDate = "";
            this.tactualEndDate = "";
            // this.tprogressCompleted = 0;
            this.OldID = "";
            this.EditRow = false;
            this.getRequestDetailsByGUID();
          } else {
            this.showLoading = false;
            this.Error = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }

  onRowEditInit1(row) {
    this.showLoading = true;
    this.table.value.forEach(x => { 
      if(row.taskID != x.taskID)
        this.table.cancelRowEdit(x); });

    this.EditRow = true;
    this.OldID = row.taskID;
    if(row.actualStartDate != null)
      this.tactualStartDate = new Date(row.actualStartDate);
    if(row.actualEndDate != null)
      this.tactualEndDate = new Date(row.actualEndDate);
    this.testimatedHours = row.estimatedHours;
    // this.tprogressCompleted = row.progressCompleted;
    this.actualmin = new Date(row.currentStartDate);
    var a =[];
    if(row.currentStartDate != null && row.currentStartDate != undefined)
      a.push(new Date(row.currentStartDate));
    if(row.actualStartDate != null && row.actualStartDate != undefined)
      a.push(new Date(row.actualStartDate));
    this.actualendmin = new Date(Math.max(...a.map(Number)));

    this.Error = "";
    this.showLoading = false;
  }

  onRowEditCancel(teamModel: any) {
    this.showLoading = true;
    if(!this.EditRow)
      this.docTypes = this.docTypes.filter(i => i.taskID != teamModel.taskID);
    this.testimatedHours = 0;
    this.tactualStartDate = '';
    this.tactualEndDate = '';
    this.Error = '';
    // this.tprogressCompleted = 0;
    this.OldID = "";
    this.EditRow = false;
    this.showLoading = false;
  }
  CheckDate2(startDate, endDate,row) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.tactualEndDate = this.tactualStartDate;
      }
      else
      {
        this.docTypes.forEach(x => { 
          if(x.taskSeq == row.taskSeq)
              x.status = "Completed";
        });
      }
    }
    if (startDate != null && startDate != undefined && startDate != "" && (endDate == null || endDate == undefined || endDate == "")) {
      this.docTypes.forEach(x => { 
        if(x.taskSeq == row.taskSeq)
            x.status = "InProgress";
      });
    }
  }

  CheckDate(startDate, endDate,type) {
    if(this.docTypes != null && this.docTypes != undefined)
    {
      if(this.docTypes.length > 0)
        this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
    }

    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate)
      {
        if(type == "C")
          this.currentEndDate = this.currentStartDate;
        else if(type == "A")
          this.actualEndDate = this.actualStartDate;
        else
          this.plannedEndDate = this.plannedStartDate;
      }
    }
  }

  // UpdateDate(type,changedate)
  // {
  //   if(this.docTypes != null && this.docTypes != undefined)
  //   {
  //     if(this.docTypes.length > 0)
  //       this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  //   }
  //   this.Error = "";
  //   this.showLoading = true;
  //   var da = this.pipe.transform(changedate,"MM/dd/yyyy")
  //   this._facadeService.UpdateRequestStartDate(this.requestID,type,da).subscribe(
  //     (data) => {
  //       this.apiResponse = data;
  //       if (this.apiResponse.returnCode == 0) {
  //         this.getRequestDetailsByGUID();
  //         this.showLoading = false;
  //       } else {
  //         this.showLoading = false;
  //         this.Error = this.apiResponse.returnMessage;
  //       }
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     }
  //   );
  // }
  // CheckDate1(startDate, endDate) {
  //   if(this.docTypes != null && this.docTypes != undefined)
  //   {
  //     if(this.docTypes.length > 0)
  //       this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  //   }
  //   if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
  //     if (startDate > endDate) {
  //       this.actualEndDate = this.actualStartDate;
  //     }
  //     this.confirmation.confirm({
  //       key: "confirm-document1",
  //       message: "Are you sure you want to change request actual date?",
  //       accept: () => {
  //         this.confirmActual = false;
  //         this.UpdateDate("Actual",this.actualStartDate);
  //       },
  //     });
  //   }
    
  // }

  ReasonClear() {
    this.ReasonError = "";
    this.popupreason = "";
  }
  SendClose() {
    this.confirmation.confirm({
      key: "confirm-close",
      message: "Are you sure you want to close this request?",
      accept: () => {
        this.confirmClose = false;
        this.reasondisplay = true;
        this.popupreason = "Close";
        this.ReasonError = "";
      },
    });
    this.confirmClose = false;
  }
  AddReason() {
    this.showLoading = true;
    this.ReasonError = "";
    if (this.popupreason == undefined || this.popupreason == "") {
      this.ReasonError = "Please enter Comments.";
      this.showLoading = false;
    }
    if (
      this.popupreason != undefined &&
      this.popupreason != "" &&
      this.ReasonError == ""
    ) {
      var Model = {
        requestID: this.requestID,
        userID: parseInt(this.userId),
        remarks: this.popupreason,
      };
      this._facadeService.SendClose(Model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            this.reasondisplay = false;
            this.popupreason = "";
            this.ReasonError = "";
            this.router.navigate(['/reportByStatus']);
            // this.getRequestDetailsByGUID();
          } else {
            this.showLoading = false;
            this.ReasonError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
  }
  
  Update()
  {
    if(this.docTypes != null && this.docTypes != undefined)
    {
      if(this.docTypes.length > 0)
        this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
    }
    this.Error = "";
    if (this.selectedapplication == "" || this.selectedapplication == null || this.selectedapplication == undefined )
      this.Error = "Please select application";
    else if (this.owner == "" || this.owner == null || this.owner == undefined )
      this.Error = "Please select owner";
    else if (this.selectedtype == "" || this.selectedtype == null || this.selectedtype == undefined )
      this.Error = "Please select request type";
    else if (this.selectedPriority == "" || this.selectedPriority == null || this.selectedPriority == undefined )
      this.Error = "Please select priority";
    else if (this.title == "" || this.title == null || this.title == undefined )
      this.Error = "Please enter short description";
    else
    {
      this.showLoading = true;
      var updatedstatus = this.selectedStatus.value;
      if(this.status1 == updatedstatus)
      {
        if(this.selectedinternalStatus.value == "In-Progress" || this.selectedinternalStatus.value == "In-Testing")
          updatedstatus = "In-Progress";
        if(this.selectedinternalStatus.value == "UAT")
          updatedstatus = "UAT";
        if(this.selectedinternalStatus.value == "UAT-Rework" || this.selectedinternalStatus.value == "UAT-Bug")
          updatedstatus = "In-Progress";
        if(this.selectedinternalStatus.value == "Production")
          updatedstatus = "Production";
      }

      if(this.description == null || this.description == undefined)
        this.description = "";
      this.description = this.description.replace("<p>", ""); 
      this.description = this.description.replace("</p>", ""); 
      
      var model={
        "id":this.requestID,
        "plannedStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "plannedEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "currentStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "currentEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "actualStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "actualEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "startDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
        "dueDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
        "internalStatus":this.selectedinternalStatus.value,
        "owner" :this.owner.userId,
        "estimatedHours" : this.estimatedHours == undefined || this.estimatedHours == null ? 0 : (this.estimatedHours == "" ? 0 : parseInt(this.estimatedHours)),
        "actualHours" : this.actualHours == undefined || this.actualHours == null ? 0 : (this.actualHours == "" ? 0 : parseInt(this.actualHours)),
        "progressCompleted":this.progress == undefined || this.progress == null ? 0 : (this.progress == "" ? 0 : parseInt(this.progress)),
        "status":updatedstatus,

        "priority":this.selectedPriority.value,
        "title":this.title,
        "applicationId":this.selectedapplication.applicationId,
        "type":this.selectedtype.value,
        "description":this.description,

        "createdBy":parseInt(this.userId),
        "tasks":null,
        "exists" : false,
        "googleDrive": this.GoogleLink
      }
      this._facadeService.UpdateRequestData(model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0)
            this.getRequestDetailsByGUID();
          else {
            this.showLoading = false;
            this.Error = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }


AddNote(display){
  if(this.docTypes != null && this.docTypes != undefined)
    {
      if(this.docTypes.length > 0)
        this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
    }
  this.DocsError = "";
  if(this.issueNote != null && this.issueNote != undefined) 
  {
    if(this.issueNote!= '')
    { 
      this.issueNote = this.issueNote.replace("<p>", ""); 
      this.issueNote = this.issueNote.replace("</p>", "");
      this.showLoading = true;
      var model = {
        "requestID":this.requestID,
        "note":this.issueNote,
        "displayToCustomer" : display,
        "addedBy":parseInt(this.userId)
      };
      this._facadeService.AddNote(model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            if (this.fubautoD != undefined) {
              if (this.fubautoD.files.length > 0) {
                this.fubautoD.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach((value, key) => {
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;        
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
            this.issueNote = '';
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.DocsError = "Please enter note";
  }
  else
    this.DocsError = "Please enter note"
}

onBasicUploadAuto(event,displaytocustomer){
  this.showLoading = true;
  this.DocsError = "";
  if(this.docTypes != null && this.docTypes != undefined)
  {
    if(this.docTypes.length > 0)
      this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  }

  let fileSize = event.files[0].size;
  if (fileSize <= 10)
  {
    this.DocsError = "File size should be greater than 1KB";
    this.showLoading = false;
  }
  else
  {
    const formData = new FormData();
    formData.append("requestID", this.requestID.toString());
    formData.append("applicationID", this.applicationID);
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("displayToCustomer", displaytocustomer);
    formData.append("addedBy", this.userId);
    if (event != undefined) {
      if (event.files.length > 0) {
        event.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    console.log(formData);
    this._facadeService.addADocuments(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            if (this.fubautoD != undefined) {
              if (this.fubautoD.files.length > 0) {
                this.fubautoD.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach((value, key) => {  
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;       
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
  }

  }

export interface iCancelTeam {
  taskID: string;
  taskName: string;
  taskSeq: number;
  affectAllocation:boolean;
  startDate:string;
  dueDate:string;
  isEdit : string;
  scope:string;
  estimatedHours:number;
  actualHours: number;
  progressCompleted: number;
  status:string;
  cycleTime:number;
  taskAssignee:any[];
  taskPredecessor:any[];
}


  