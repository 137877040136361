import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import * as _ from "lodash";
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pending-confirmation-requests',
  templateUrl: './pending-confirmation-requests.component.html',
  styleUrls: ['./pending-confirmation-requests.component.scss']
})
export class PendingConfirmationRequestsComponent implements OnInit {
  userId = sessionStorage.getItem('userId');
  loading: "loading";
  showLoading:boolean;
  cols: any[];
  activeproducts: any;
  @ViewChild('dtactive') tableactive: Table;
  cols1: any[];
  completedproducts: any;
  @ViewChild('dtcomplete') tablecomplete: Table;

  CountS: number = 0;
  seekproducts: any;
  @ViewChild('dtseek') tableseek: Table;

  index: number = 0;
  Count: number = 0;
  Count1: number = 0;
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintcommonService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
      this.showLoading = true;
      this.cols = [
        { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
        { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
        { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
        { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
        { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
        { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
        { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '20%' },
        { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '100px' }
      ];
      
      this.cols1 = [
        { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
        { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
        { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
        { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
        { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
        { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
        { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '20%' },
        { field: 'confirmedDate', header: 'Confirmed Date', dynamicPlaceHolder: 'Search', width: '140px' },
        { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '100px' }
      ];
      sessionStorage.code = "";
      sessionStorage.requestDate = "";
      sessionStorage.title = "";
      sessionStorage.type = "";
      sessionStorage.priority = "";
      sessionStorage.requestedByName = "";
      sessionStorage.applicationName = "";
      sessionStorage.status = "";

      sessionStorage.code2 = "";
      sessionStorage.requestDate2 = "";
      sessionStorage.title2 = "";
      sessionStorage.type2 = "";
      sessionStorage.priority2 = "";
      sessionStorage.requestedByName2 = "";
      sessionStorage.applicationName2 = "";
      sessionStorage.status2 = "";
      
      sessionStorage.code1 = "";
      sessionStorage.requestDate1 = "";
      sessionStorage.title1 = "";
      sessionStorage.type1 = "";
      sessionStorage.priority1 = "";
      sessionStorage.requestedByName1 = "";
      sessionStorage.applicationName1 = "";
      sessionStorage.confirmedDate = "";
      sessionStorage.status1 = "";
      this.GetConfirmation(true);
      this.index = 0;
      if(this.route.snapshot.paramMap.get('index')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('index');
        if(ii != '0' && ii != '')
          this.index = Number(ii);
      }
  }
  CountM()
  {
    if(this.activeproducts != undefined && this.activeproducts != null)
    {
      var temp = this.activeproducts;
      if(sessionStorage.code != "")
        temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if(sessionStorage.requestDate != "")
        temp = temp.filter(x=> x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
      if(sessionStorage.title != "")
        temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if(sessionStorage.type != "")
        temp = temp.filter(x=> x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
      if(sessionStorage.priority != "")
        temp = temp.filter(x=> x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
      if(sessionStorage.requestedByName != "")
        temp = temp.filter(x=> x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
      if(sessionStorage.applicationName != "")
        temp = temp.filter(x=> x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
      if(sessionStorage.status != "")
        temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  CountFilter(val,field,dt)
  {
    if(field == "code")
      sessionStorage.code = val;
    else if(field == "requestDate")
      sessionStorage.requestDate = val;
    else if(field == "title")
      sessionStorage.title = val;
    else if(field == "type")
      sessionStorage.type = val;
    else if(field == "priority")
      sessionStorage.priority = val;
    else if(field == "requestedByName")
      sessionStorage.requestedByName = val;
    else if(field == "applicationName")
      sessionStorage.applicationName = val;
    else if(field == "status")
      sessionStorage.status = val;
    dt = dt.filter(val,field, 'contains');
    this.CountM();
  }
  CountM1()
  {
    if(this.completedproducts != undefined && this.completedproducts != null)
    {
      var temp = this.completedproducts;
      if(sessionStorage.code1 != "")
        temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code1.toLowerCase()));
      if(sessionStorage.requestDate1 != "")
        temp = temp.filter(x=> x.requestDate.toLowerCase().includes(sessionStorage.requestDate1.toLowerCase()));
      if(sessionStorage.title1 != "")
        temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title1.toLowerCase()));
      if(sessionStorage.type1 != "")
        temp = temp.filter(x=> x.type.toLowerCase().includes(sessionStorage.type1.toLowerCase()));
      if(sessionStorage.priority1 != "")
        temp = temp.filter(x=> x.priority.toLowerCase().includes(sessionStorage.priority1.toLowerCase()));
      if(sessionStorage.requestedByName1 != "")
        temp = temp.filter(x=> x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName1.toLowerCase()));
      if(sessionStorage.applicationName1 != "")
        temp = temp.filter(x=> x.applicationName.toLowerCase().includes(sessionStorage.applicationName1.toLowerCase()));
      if(sessionStorage.status1 != "")
        temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status1.toLowerCase()));
      if(sessionStorage.confirmedDate != "")
        temp = temp.filter(x=> x.confirmedDate.toLowerCase().includes(sessionStorage.confirmedDate.toLowerCase()));
      this.Count1 = temp.length;
    }
    else
      this.Count1 = 0;
  }
  Count1Filter(val,field,dt)
  {
    if(field == "code")
      sessionStorage.code1 = val;
    else if(field == "requestDate")
      sessionStorage.requestDate1 = val;
    else if(field == "title")
      sessionStorage.title1 = val;
    else if(field == "type")
      sessionStorage.type1 = val;
    else if(field == "priority")
      sessionStorage.priority1 = val;
    else if(field == "requestedByName")
      sessionStorage.requestedByName1 = val;
    else if(field == "applicationName")
      sessionStorage.applicationName1 = val;
    else if(field == "status")
      sessionStorage.status1 = val;
    else if(field == "confirmedDate")
      sessionStorage.confirmedDate = val;
    dt = dt.filter(val,field, 'contains');
    this.CountM1();
  }
  CountGlobalFilter(val,dt)
  {
    sessionStorage.code = val;
    sessionStorage.requestDate = val;
    sessionStorage.title = val;
    sessionStorage.type = val;
    sessionStorage.priority = val;
    sessionStorage.requestedByName = val;
    sessionStorage.applicationName = val;
    sessionStorage.status = val;
    dt = dt.filterGlobal(val, 'contains');
    this.CountM();
  }
  Count1GlobalFilter(val,dt)
  {
    sessionStorage.code1 = val;
    sessionStorage.requestDate1 = val;
    sessionStorage.title1 = val;
    sessionStorage.type1 = val;
    sessionStorage.priority1 = val;
    sessionStorage.requestedByName1 = val;
    sessionStorage.applicationName1 = val;
    sessionStorage.confirmedDate = val;
    sessionStorage.status1 = val;
    dt = dt.filterGlobal(val, 'contains');
    this.CountM1();
  }

  CountMS()
  {
    if(this.seekproducts != undefined && this.seekproducts != null)
    {
      var temp = this.seekproducts;
      if(sessionStorage.code2 != "")
        temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code2.toLowerCase()));
      if(sessionStorage.requestDate2 != "")
        temp = temp.filter(x=> x.requestDate.toLowerCase().includes(sessionStorage.requestDate2.toLowerCase()));
      if(sessionStorage.title2 != "")
        temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title2.toLowerCase()));
      if(sessionStorage.type2 != "")
        temp = temp.filter(x=> x.type.toLowerCase().includes(sessionStorage.type2.toLowerCase()));
      if(sessionStorage.priority2 != "")
        temp = temp.filter(x=> x.priority.toLowerCase().includes(sessionStorage.priority2.toLowerCase()));
      if(sessionStorage.requestedByName2 != "")
        temp = temp.filter(x=> x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName2.toLowerCase()));
      if(sessionStorage.applicationName2 != "")
        temp = temp.filter(x=> x.applicationName.toLowerCase().includes(sessionStorage.applicationName2.toLowerCase()));
      if(sessionStorage.status2 != "")
        temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status2.toLowerCase()));
      this.CountS = temp.length;
    }
    else
      this.CountS = 0;
  }
  CountFilterS(val,field,dt)
  {
    if(field == "code")
      sessionStorage.code2 = val;
    else if(field == "requestDate")
      sessionStorage.requestDate2 = val;
    else if(field == "title")
      sessionStorage.title2 = val;
    else if(field == "type")
      sessionStorage.type2 = val;
    else if(field == "priority")
      sessionStorage.priority2 = val;
    else if(field == "requestedByName")
      sessionStorage.requestedByName2 = val;
    else if(field == "applicationName")
      sessionStorage.applicationName2 = val;
    else if(field == "status")
      sessionStorage.status2 = val;
    dt = dt.filter(val,field, 'contains');
    this.CountMS();
  }
  CountGlobalFilterS(val,dt)
  {
    sessionStorage.code2 = val;
    sessionStorage.requestDate2 = val;
    sessionStorage.title2 = val;
    sessionStorage.type2 = val;
    sessionStorage.priority2 = val;
    sessionStorage.requestedByName2 = val;
    sessionStorage.applicationName2 = val;
    sessionStorage.status2 = val;
    dt = dt.filterGlobal(val, 'contains');
    this.CountMS();
  }

  GetConfirmationSeek() {
    this._facadeService.GetConfirmationSeek(this.userId).subscribe(
      (data) => {
        this.seekproducts = data.returnObject;
        if(this.seekproducts!=undefined && this.seekproducts != null && this.seekproducts.length > 0)
        {
          this.seekproducts.forEach((value, key) => {              
            if(value.requestDate != '' && value.requestDate != null)
              value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");   
          });
        }
        this.CountMS();
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  GetConfirmation(Pending) {
    this._facadeService.GetConfirmation(this.userId, Pending).subscribe(
      (data) => {
        if (Pending) {
          this.activeproducts = data.returnObject;
          if(this.activeproducts!=undefined && this.activeproducts != null && this.activeproducts.length > 0)
          {
            this.activeproducts.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");   
            });
          }
          this.CountM();
          this.GetConfirmation(false);
        }
        else {
          this.completedproducts = data.returnObject;
          if(this.completedproducts!=undefined && this.completedproducts != null && this.completedproducts.length > 0)
          {
            this.completedproducts.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
              if(value.confirmedDate != '' && value.confirmedDate != null)
                value.confirmedDate = new Date(new Date(value.confirmedDate).toDateString() + " " + new Date(value.confirmedDate).toTimeString() + " UTC");     
            });
          }
          this.CountM1();
          this.GetConfirmationSeek();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  download(val) {
      /* table id is passed over here */   
      let element = document.getElementById(val); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      this.delete_row(wb.Sheets.Sheet1, 1,val);
      ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 135 },{ wpx : 90 },{ wpx : 120 },{ wpx : 200 },{ wpx : 100 },{ wpx : 100 }];
      /* save to file */
      if(val == "excel-table")
        XLSX.writeFile(wb, "Pending Confirmation.xlsx");
      if(val == "excel-table1")
        XLSX.writeFile(wb, "Confirmed / Cancelled by Me.xlsx");
      if(val == "excel-table2")
        XLSX.writeFile(wb, "For Clarifications.xlsx");
  }
  delete_row (ws, row_index,val){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    if(val == "excel-table1")
      delete (ws['J1'])
    else
      delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  print(MainID,SearchID) {
    var Content = document.getElementById(MainID).innerHTML;
    var Title = "";
    if(MainID == "invoice-content")
      Title =  "Confirmation Pending";
    if(MainID == "invoice-content1")
      Title = "Confirmed / Cancelled by Me";
    if(MainID == "invoice-content2")
      Title = "Seek Clarifications";
    this.printService.Print(Content,Title,SearchID,true);
}  

ViewMyRequest(rowdata) {
  sessionStorage.From="Pending";
  sessionStorage.requestID = rowdata.id;
this.router.navigate(['/requestDetails']);
}

ViewMyRequest1(rowdata,val) {
  sessionStorage.requestID = rowdata.id;
this.router.navigate(['/pendingConfirmationDetailsRequest',{index: val }]);
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'requestDate'||event.field === 'confirmedDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.requestDate = this.datePipe.transform(e.requestDate, 'MM/dd/yyyy');
    e.confirmedDate = this.datePipe.transform(e.confirmedDate, 'MM/dd/yyyy');
  });
}

}

