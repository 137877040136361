import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid);
  }
  
  GetUserDetails(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid );
  }

  CreateSecurityToken(){
    return this._httputilityService.PostU("user/CreateSecurityToken" ,null);
  }
  
  getDashboardCounts(userID) {
    return this._httputilityService.Get("DashboardCounts?userID=" + userID);
  }
  getSupportDashboardCounts(userID) {
    return this._httputilityService.Get("SupportDashboardCounts?userID=" + userID);
  }
  
  getChartData(userId) {
    return this._httputilityService.Get("GetChartData?userID=" + userId);
  }
  getTypeChartData(userId) {
    return this._httputilityService.Get("GetChartDataS?userID=" + userId);
  }
  getPriorityChartData(userId,Support) {
    return this._httputilityService.Get("GetChartDataP?userID=" + userId+"&Support="+ Support);
  }

  getDashboardTimelineData(userId,type,clientID) {
    return this._httputilityService.Get("UserTimelines?UGUID="+userId+"&days="+type+"&clientID="+clientID);
  }

  GetDashboardTableData(userId){
    return this._httputilityService.Get("GetDashboardTableData?userID=" + userId );
  }

  GetSupportDashboardTableData(userId){
    return this._httputilityService.Get("GetSupportDashboardTableData?userID=" + userId );
  }
  GetInternalChartData(userId) {
    return this._httputilityService.Get("GetInternalChartData?userID=" + userId);
  }
  
  UpdateSecurityToken(token){
    return this._httputilityService.PostU("user/UpdateSecurityToken?Token=" + token ,null);
  }

  downloadFile(filename,newname){
    return this._httputilityService.downloadFile('Download?filename='+filename+"&newname="+newname);
  }

  GetHelpByID(HelpID){
    return this._httputilityService.GetU("GetHelpByGuid?helpGuid=" + HelpID);
  }  
  GetQuestions()
  {
    return this._httputilityService.GetU("GetQuestions?active=true");
  }
  AddFeedback(model)
  {
    return this._httputilityService.PostU("AddFeedback",model);
  }
  SearchHelp(text,AppID){
    return this._httputilityService.GetU("SearchHelp?input=" + text+"&applicationguid=" + AppID);
  }  
  
  AddUserTracking(model){
    return this._httputilityService.PostU('user/AddUserTracking',model);
  }
}
