import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router } from '@angular/router';
import { SelectItem, SortEvent } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-bytime',
  templateUrl: './report-bytime.component.html',
  styleUrls: ['./report-bytime.component.scss']
})
export class ReportBytimeComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  selectedClosedFromDate: any = "";
  selectedClosedToDate : any = "";
  apps: any[];
  selectedApplications: any;
  type: SelectItem[];
  selectedtype: any;
  priority: SelectItem[];
  selectedPriority: any;
  status: SelectItem[];
  selectedStatus: any;
  Users: any[];
  selectedUsers: any;
  AUsers:any[];
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  requestData = [];
  Error:string = "";
  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    if(sessionStorage.getItem('RToDate') != null && sessionStorage.getItem('RToDate') != undefined)
    {
      if(sessionStorage.getItem('RToDate') != "")
        this.selectedToDate = new Date(sessionStorage.getItem('RToDate') );
    }
    if(sessionStorage.getItem('RFromDate') != null && sessionStorage.getItem('RFromDate') != undefined)
    {
      if(sessionStorage.getItem('RFromDate') != "")
        this.selectedFromDate = new Date(sessionStorage.getItem('RFromDate') );
    }
    if(sessionStorage.getItem('RClosedToDate') != null && sessionStorage.getItem('RClosedToDate') != undefined)
    {
      if(sessionStorage.getItem('RClosedToDate') != "")
        this.selectedClosedToDate = new Date(sessionStorage.getItem('RClosedToDate') );
    }
    if(sessionStorage.getItem('RClosedFromDate') != null && sessionStorage.getItem('RClosedFromDate') != undefined)
    {
      if(sessionStorage.getItem('RClosedFromDate') != "")
        this.selectedClosedFromDate = new Date(sessionStorage.getItem('RClosedFromDate') );
    }
    // this.selectedToDate = new Date();
    // let dte = new Date();
    // dte.setMonth(dte.getMonth() - 1);
    // this.selectedFromDate = new Date(dte.toString());
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value: 'Issue' },
      { label: 'Support', value: 'Support' }
    ];
    this.selectedtype = [];
    let ok = false;
    if(sessionStorage.getItem('RType') != null && sessionStorage.getItem('RType') != undefined && sessionStorage.getItem('RType') != "")
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('RType').split(',');
      projectstats.forEach((element) => {
        var a = this.type.find(x=>x.value == element);
        if(a != null)
          this.selectedtype.push(a);
      });
      ok = true;
    }
    if(!ok)
    {
      this.type.forEach(element => {
        this.selectedtype.push(element);
      });
      var a = "";
      this.selectedtype.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RType = a;
    }
    this.priority = [
      { label: 'Critical', value: 'Critical'},
      { label: 'High', value:'High' },
      { label: 'Medium', value: 'Medium'},
      { label: 'Low', value: 'Low' }
    ];
    this.selectedPriority = [];
    ok = false;
    if(sessionStorage.getItem('RPriority') != null && sessionStorage.getItem('RPriority') != undefined && sessionStorage.getItem('RPriority') != "")
    {
      var projectstats = [];
      projectstats = sessionStorage.getItem('RPriority').split(',');
      projectstats.forEach((element) => {
        var a = this.priority.find(x=>x.value == element);
        if(a != null)
          this.selectedPriority.push(a);
      });
      ok = true;
    }
    if(!ok)
    {
      this.priority.forEach(element => {
        this.selectedPriority.push(element);
      });
      var a = "";
      this.selectedPriority.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RPriority = a;
    }
    this.status = [
      { label: 'Saved', value: 'Saved' },
      { label: 'Pending Confirmation', value: 'Pending Confirmation' },
      { label: 'Seek Clarification', value: 'Seek Clarification' },
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Closed', value: 'Closed' },
       { label: 'Cancelled', value: 'Cancelled' },
    ];
    this.selectedStatus = [];
    ok = false;
   
    if(sessionStorage.getItem('dashboardstatus') != null && sessionStorage.getItem('dashboardstatus') != undefined && sessionStorage.getItem('dashboardstatus') != "")
    {
      if(sessionStorage.getItem('dashboardstatus') != 'null')
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('dashboardstatus').split(',');
        projectstats.forEach((element) => {
          var st = this.status.find(x=>x.value.toLowerCase() == element.toLowerCase());
          if(st != null)
            this.selectedStatus.push(st);
          if(element == "Pending Confirmation")
          {
            var st = this.status.find(x=>x.value.toLowerCase() == "seek clarification");
            if(st != null)
              this.selectedStatus.push(st);
          }
        });
        ok = true;
        var a = "";
        this.selectedStatus.forEach(element => {
          a = a + element.value + ",";
        });
        sessionStorage.RStatus = a;
        sessionStorage.dashboardstatus = null;
      }
    }
    if(!ok)
    {
      if(sessionStorage.getItem('RStatus') != null && sessionStorage.getItem('RStatus') != undefined && sessionStorage.getItem('RStatus') != "")
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('RStatus').split(',');
        projectstats.forEach((element) => {
          var a = this.status.find(x=>x.value == element);
          if(a != null)
            this.selectedStatus.push(a);
        });
        ok = true;
      }
    }
    if(!ok)
    {
      this.status.forEach(e => {
        if(e.value != "Saved" && e.value != "Closed" && e.value != "Cancelled")
          this.selectedStatus.push(e);
      });
      var a = "";
      this.selectedStatus.forEach(element => {
        a = a + element.value + ",";
      });
      sessionStorage.RStatus = a;
    }
    
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      // { field: 'dueDate', header: 'Target Date', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '20%' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '180px' },
    ];
    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.dueDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.applicationName = "";
    sessionStorage.status = "";
    this.getApplications();
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "dueDate")
      sessionStorage.dueDate = val;
    else if (field == "requestedByName")
        sessionStorage.requestedByName = val;
    else if (field == "applicationName")
      sessionStorage.applicationName = val;
    else if (field == "status")
      sessionStorage.status = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.type != "")
          temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
          temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
        if (sessionStorage.dueDate != "")
          temp = temp.filter(x => x.dueDate.toLowerCase().includes(sessionStorage.dueDate.toLowerCase()));
        if (sessionStorage.requestedByName != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
        if (sessionStorage.applicationName != "")
          temp = temp.filter(x => x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
        if (sessionStorage.status != "")
          temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getApplications() {
    this.showLoading = true;
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.selectedApplications = [];
        var allapps = data.returnObject;
        var all = allapps.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            allapps.push(a);
        }
        this.apps = [];
        var userapp = JSON.parse(sessionStorage.getItem('applications'))
        if(userapp != null && userapp != undefined)
        {
          userapp.forEach(element => {
            var a = allapps.find(x=>x.applicationGuid.toLowerCase() == element.id.toLowerCase());
            if(a != null)
              this.apps.push(a);
          });
        }
        let ok = false;
        if(sessionStorage.getItem('RApp') != null && sessionStorage.getItem('RApp') != undefined && sessionStorage.getItem('RApp') != "" && sessionStorage.getItem('RApp').indexOf("object") <= -1)
        {
          var projectstats = [];
          projectstats = sessionStorage.getItem('RApp').split(',');
          projectstats.forEach((element) => {
            if(element != "")
            {
              this.apps.forEach((ln) => {
                if(ln.applicationId == parseInt(element))
                  this.selectedApplications.push(ln);
              });
            }
          });
          ok = true;
        }
        if(!ok)
        { 
          if(this.apps != null && this.apps != undefined)
          {
            this.apps.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
            this.apps.forEach(element => {
              this.selectedApplications.push(element);
            });
          }
          var lm=[];
          this.selectedApplications.forEach((element) => {
            lm.push(element.applicationId);
          });
          sessionStorage.RApp = lm;
        }
        this.getUsers();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  getUsers() {
    this.Users = [];
    this._facadeService.getUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.Users = data.returnObject;
        this.selectedUsers = [];
        let ok = false;
        if(sessionStorage.getItem('FromDash') != null && sessionStorage.getItem('FromDash') != undefined  && sessionStorage.getItem('FromDash') != "")
        {
          if(sessionStorage.getItem('FromDash') != 'null')
          {
            if(sessionStorage.getItem('FromDash') == 'true')
            {
              ok = true;
              this.Users.forEach(a => {
                this.selectedUsers.push(a);
              });
              sessionStorage.FromDash = 'false';
              var lm=[];
              this.selectedUsers.forEach((element) => {
                lm.push(element.userId);
              });
              sessionStorage.RRequestedBy = lm;
            }
          }
        }
        if(!ok)
        {
          if(sessionStorage.getItem('RRequestedBy') != null && sessionStorage.getItem('RRequestedBy') != undefined && sessionStorage.getItem('RRequestedBy') != "" && sessionStorage.getItem('RRequestedBy').indexOf("object") <= -1)
          {
            var projectstats = [];
            projectstats = sessionStorage.getItem('RRequestedBy').split(',');
            projectstats.forEach((element) => {
              this.Users.forEach((ln) => {
                if(ln.userId == element)
                  this.selectedUsers.push(ln);
              });
            });
            ok = true;
          }
        }
        if(!ok)
        { 
          var a = this.Users.find(m => m.userId == parseInt(this.userId));
          if(a != null && a != undefined)
            this.selectedUsers.push(a);
          var lm=[];
          this.selectedUsers.forEach((element) => {
            lm.push(element.userId);
          });
          sessionStorage.RRequestedBy = lm;
        }
        this.getSupportUsers();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getSupportUsers() {
    this.AUsers = [];
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.AUsers = data.returnObject;
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  CheckDate(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }
  CheckDate1(startDate, endDate) {
    if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
      if (startDate > endDate) {
        this.selectedClosedToDate = this.selectedClosedFromDate;
      }
    }
  }
  getMyRequests() {
    this.showLoading = true;
    this.Error = "";
    if (this.selectedApplications == null || this.selectedApplications == undefined || this.selectedApplications.length == 0)
      {this.Error = "Please select atleast one application";this.showLoading = false;}
    // else if (this.selectedUsers == null || this.selectedUsers == undefined || this.selectedUsers.length == 0)
    //   this.Error = "Please select atleast one requester";
    else if (this.selectedtype == null || this.selectedtype == undefined || this.selectedtype.length == 0)
      {this.Error = "Please select atleast one type";this.showLoading = false;}
    else if (this.selectedPriority == null || this.selectedPriority == undefined || this.selectedPriority.length == 0)
      {this.Error = "Please select atleast one priority";this.showLoading = false;}
    else if (this.selectedStatus == null || this.selectedStatus == undefined || this.selectedStatus.length == 0)
     { this.Error = "Please select atleast one status";this.showLoading = false;}
      
    else if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "Requested To Date should be greater then from date";
      this.selectedToDate = this.selectedFromDate;this.showLoading = false;}
    else if (this.selectedClosedFromDate != null && this.selectedClosedFromDate != undefined && this.selectedClosedFromDate != ""
      && this.selectedClosedToDate != null && this.selectedClosedToDate != undefined && this.selectedClosedToDate != "" && this.selectedClosedFromDate > this.selectedClosedToDate) {
      this.Error = "Closed To Date should be greater then from date";
      this.selectedClosedToDate = this.selectedClosedFromDate;this.showLoading = false;}
    else {
      sessionStorage.RToDate = this.selectedToDate;
      sessionStorage.RFromDate = this.selectedFromDate;
      sessionStorage.RClosedToDate = this.selectedClosedToDate;
      sessionStorage.RClosedFromDate = this.selectedClosedFromDate;
      var types = []; var type = "";
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.value);
            type = type + element.value + ",";
          });
          if(this.type.length == this.selectedtype.length)
            types = [];
        }
      }
      sessionStorage.RType = type;
      var priority = []; var par = "";
      if (this.selectedPriority != null && this.selectedPriority != undefined) {
        if (this.selectedPriority.length > 0) {
          this.selectedPriority.forEach(element => {
            priority.push(element.value);
            par = par + element.value + ",";
          });
          if(this.priority.length == this.selectedPriority.length)
            priority = [];
        }
      }
      sessionStorage.RPriority = par;
      var status = []; var stat = "";
      if (this.selectedStatus != null && this.selectedStatus != undefined) {
        if (this.selectedStatus.length > 0) {
          this.selectedStatus.forEach(element => {
            status.push(element.value);
            stat = stat + element.value + ",";
          });
          if(this.status.length == this.selectedStatus.length)
            status = [];
        }
      }
      sessionStorage.RStatus = stat;
      var apps = []; var a = "";
      if (this.selectedApplications != null && this.selectedApplications != undefined) {
        if (this.selectedApplications.length > 0) {
          this.selectedApplications.forEach(element => {
            apps.push(element.applicationId);
            a = a + element.applicationId + ",";
          });
          if(this.apps.length == this.selectedApplications.length)
            apps = [];
        }
      }
      sessionStorage.RApp = a;
      var Requestedby = []; var req = "";
      if (this.selectedUsers != null && this.selectedUsers != undefined) {
        if (this.selectedUsers.length > 0) {
          this.selectedUsers.forEach(element => {
            Requestedby.push(element.userId);
            req = req + element.userId + ",";
          });
          if(this.Users.length == this.selectedUsers.length)
            Requestedby = [];
          else
          {
            if (this.AUsers != null && this.AUsers != undefined) {
              if (this.AUsers.length > 0) {
                this.AUsers.forEach(element => {
                  Requestedby.push(element.userId);
                });
              }
            }
          }
        }
      }
      sessionStorage.RRequestedBy = req;
      var myRequestsData =
      {
        "type": types,
        "status": status,
        "application": apps,
        "priority": priority,
        "requestedBy": Requestedby,
        "assignTo": [],
        "internalStatus": [],
        "requestOwner": [],
        "userID": 0,
        "includeInternal" : false
      }
      this._facadeService.MyRequests(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
          {
            this.requestData.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
              if(value.dueDate != '' && value.dueDate != null)
                value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");  
              if(value.closedDate != '' && value.closedDate != null)
                value.closedDate = new Date(new Date(value.closedDate).toDateString() + " " + new Date(value.closedDate).toTimeString() + " UTC"); 
            });
            if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) >= new Date(this.selectedFromDate));
            }
            if (this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) <= new Date(this.selectedToDate));
            }
            if (this.selectedClosedFromDate != null && this.selectedClosedFromDate != undefined && this.selectedClosedFromDate != "") {
              var nulldata = this.requestData.filter(x => x.closedDate == null || x.closedDate == "");
              this.requestData = this.requestData.filter(x => new Date(x.closedDate) >= new Date(this.selectedClosedFromDate));
              if(nulldata != null)
              {
                if(nulldata.length > 0)
                {
                  nulldata.forEach(element => {
                      this.requestData.push(element);
                  });
                }
              }
            }
            if (this.selectedClosedToDate != null && this.selectedClosedToDate != undefined && this.selectedClosedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.closedDate) <= new Date(this.selectedClosedToDate));
            }
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }

  ViewMyRequest(rowdata) {
  sessionStorage.From = "Report";
  sessionStorage.requestID = rowdata.id;
  this.router.navigate(['/requestDetails']);
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Requests Summary','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 120 },{ wpx : 200 },{ wpx : 135 }];
    // { wpx : 90 },
    /* save to file */
    XLSX.writeFile(wb, "Requests Summary.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'requestDate'||event.field === 'dueDate') {
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
        if (date1 === null)
          result = date2 === null ? 0 : -1;
        else if (date2 === null)
          result = 1;
        else
          result = date1.getTime() - date2.getTime();
      } 
      else if (typeof value1 === 'string' && typeof value2 === 'string') 
        result = value1.localeCompare(value2);
      else 
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      
      return (event.order * result);
    });
    
    event.data.forEach(e => {
      e.requestDate = this.datePipe.transform(e.requestDate, 'MM/dd/yyyy');
      e.dueDate = this.datePipe.transform(e.dueDate, 'MM/dd/yyyy');
    });
  }
}