import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from './app.main.component';
import { FacadeService } from './facade/facade.service';
import { BASE_API_URL_TOKEN } from './injectors';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  from="";
    model: any[];
    InfoDisplay:boolean;
    showLoading:boolean;
    constructor(public app: AppMainComponent,private route: Router, private router: ActivatedRoute, private _facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

    ngOnInit() { 
        var applicationId =0;var userId = 0;
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    if(appliId!=null&&appliId!=""&&appliId!=undefined){
      this.from="entrypoint";
      applicationId = parseInt(appliId); 
    }
else{
applicationId = parseInt(sessionStorage.getItem('appliId')); 
}

var userIDId = this.router.snapshot.queryParamMap.get('userId');
if(userIDId!=null&&userIDId!=""&&userIDId!=undefined)
    userId = parseInt(userIDId); 
else
    userId = parseInt(sessionStorage.getItem('userId')); 
      this.showLoading=true;
      console.log("application id: "+applicationId);
      console.log("userId: "+userId);
      this._facadeService.GetMenusByUser(userId,applicationId).subscribe(
        (data) => {
          this.showLoading=false;
          sessionStorage.menudata = JSON.stringify(data.returnObject);
          console.log(data.returnObject); 
          this.model =[];
          data.returnObject.forEach(element => {
            if(element.subMenus!=null&&element.subMenus.length>0){
              var subitems=[];
              element.subMenus.forEach(element1 => {
                var subsubitems=[];
                if(element1.subMenus!=null&&element1.subMenus.length>0){
                  element1.subMenus.forEach(element3 => {
                    subsubitems.push({label: element3.menuName, icon: element3.iconPath,command: () => this.ISAccessisable(element3.url,element3.roleMenuActive,element3)});
                  });
                }
                if(subsubitems.length>0){
                  subitems.push(
                    {label: element1.menuName, icon: element1.iconPath,command: () => this.ISAccessisable(element1.url,element1.roleMenuActive,element1),
                    items:subsubitems,
                    }
                    );
                }
                else{
                  subitems.push(
                    {label: element1.menuName, icon: element1.iconPath,command: () => this.ISAccessisable(element1.url,element1.roleMenuActive,element1)}
                    );
                }
               
              });
              this.model.push(
                {
                label: element.menuName, icon: element.iconPath, command: () => this.ISAccessisable(element.url,element.roleMenuActive,element),
                items:subitems,
              },
              { separator: true })
            }
            else{
              this.model.push(
                {
                label: element.menuName, icon: element.iconPath,command: () => this.ISAccessisable(element.url,element.roleMenuActive,element)
              },
              { separator: true })
            }
            
          });
          if(this.from=="entrypoint"){
            this.EntryPointAccess(data.returnObject);
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        } 
      );
    }
    EntryPointAccess(menumodal){
      var destination = this.router.snapshot.queryParamMap.get('destination');
      if(destination==""||destination==null||destination==undefined){
          menumodal.forEach(element => {
            if(element.url=="/dashboard"){
              return this.ISAccessisable(element.url,element.roleMenuActive,element)
            }
            else{
              element.subMenus.forEach(subelement => {
                if(subelement.url=="/dashboard"){
                 return this.ISAccessisable(subelement.url,subelement.roleMenuActive,subelement)
                }
              });
            }
          });
      }
      else{
        menumodal.forEach(element => {
          if(element.url=="/"+destination){
           return this.ISAccessisable(element.url,element.roleMenuActive,element)
          }
          else{
            element.subMenus.forEach(subelement => {
              if(subelement.url=="/"+destination){
                return this.ISAccessisable(subelement.url,subelement.roleMenuActive,subelement)
              }
              else{
                if(subelement.subMenus!=null&&subelement.subMenus.length>0){
                  subelement.subMenus.forEach(element2 => {
                    if(element2.url=="/"+destination){
                      return this.ISAccessisable(element2.url,element2.roleMenuActive,subelement)
                    }
                    else{
                      if(element2.subMenus!=null&&element2.subMenus.length>0){
                        element2.subMenus.forEach(element3 => {
                          if(element3.url=="/"+destination){
                            return this.ISAccessisable(element3.url,element3.roleMenuActive,subelement)
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    }
    ISAccessisable(navi,roleMenuActive,menudata){
        if(navi!=null){
          sessionStorage.requestID = 0;
          if(roleMenuActive=="Y"){
            this.showLoading = true;
            var userId = parseInt(sessionStorage.getItem('userId'));
            var modelData ={
              "userId": userId,
              "applicationId": menudata.applicationId,
              "menuId": menudata.menuId,
              "source":this.from = "entrypoint"?"moduleExchange":""
            };
            this._facadeService.AddUserTracking(modelData).subscribe(
              (data) => {
                this.showLoading = false;
                this.route.navigate([navi]);
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              }
            );
          }
          else
            this.InfoDisplay=true;
        }
      }
    onMenuClick(event) {
        this.app.onMenuClick(event);
    }
    LogoClick() {
        var userGuid = sessionStorage.getItem('userGuid');
        var userId = parseInt(sessionStorage.getItem('userId'));
        var application = sessionStorage.getItem("defaultIdentifierApp");
        var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
       
        var applications = JSON.parse(sessionStorage.getItem('applications'));
        var appli = applications.find(m=>m.projectIdentifier==application);
        this.showLoading = true;
        this._facadeService.CreateSecurityToken().subscribe(
            (data) => { 
            this.showLoading = false;
            var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
            if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                var accessToken = data.returnObject; 
                location.href=this.baseUrl["API_ENDPOINTS"][application]+'entrypoint?userguid='
                +userGuid+'&userId='+userId+'&access_token='+accessToken+'&IsDCC='+IsDCC+
                '&source=dashboard'+
                '&bt='+sessionStorage.getItem('bt')+"&appliId="+appli.id+"&clientId="+clientId;
                }
            else{
                location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            }
            },
            (error) => {
            this.showLoading = false;
            console.log(error);
            } 
        );
    }
}
