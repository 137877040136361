import { Component, Inject,  OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FileUpload } from "primeng/fileupload";
import { HttpClient } from "@angular/common/http";
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from "primeng/api";

@Component({
  selector: 'app-pending-confirmation-details-request',
  templateUrl: './pending-confirmation-details-request.component.html',
  styleUrls: ['./pending-confirmation-details-request.component.scss'],
  providers: [ConfirmationService],
})
export class PendingConfirmationDetailsRequestComponent implements OnInit {
  confirmCancel = false;
  indexback:number = 0;
  index:number=0;
  Error:string = "";
  showLoading:boolean;
  requestID = parseInt(sessionStorage.getItem("requestID"));
  code:string="";
  requestedByName:string="";
  selectedreqDate: any;
  // applicationName:any;
  applications: any;
  selectedapplication: any;
  type: SelectItem[];
  selectedtype: any;

  // type: any;
  priority: SelectItem[];
  selectedPriority: any;
  description: string = ""; 
  title:string="";
  status:string="";
  userId = sessionStorage.getItem('userId');
  cols: any[];  
  ViewAddedDocuments: any[] = [];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;
  popupremark:string='';
  Clarification1: any[] = [];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  Clarification1Cols: any[];

  // Notes
  notesList: any[] = [];
  issueNote:string = "";
  DocsError:string="";

  addDocsDisplay: boolean;
  @ViewChild("fileInput") fileInput: FileUpload;
  DocsError1:string="";
  fullName= sessionStorage.getItem("fullName");

  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  apiResponse: any;
  @ViewChild("fubauto") fubauto: FileUpload;

  requestDetails: any;
  
  constructor( private _facadeService: FacadeService,private route: ActivatedRoute,private router: Router, private confirmation: ConfirmationService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }

  ngOnInit(): void {   
    // this.requestID = parseInt(this.route.snapshot.paramMap.get('requestID'));
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value:'Issue'},
      { label: 'Support', value: 'Support' }
    ];
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.Clarification1Cols = [
      { field: 'askedDate', header: 'Sought Date' },
      { field: 'question', header: 'Clarification' },
      { field: 'askedByName', header: 'Sought By' },
      { field: 'answerDate', header: 'Response Date' },
      { field: 'answer', header: 'Response' },
      { field: 'answerByName', header: 'Response By' }
    ];
    this.priority = [
      { label: 'Critical', value: 'Critical' },
      { label: 'High', value: 'High' },
      { label: 'Medium', value: 'Medium' },
      { label: 'Low', value: 'Low' }
    ];
    this.indexback = 0;
    if(this.route.snapshot.paramMap.get('index')!=undefined)
    {
      var ii = this.route.snapshot.paramMap.get('index');
      if(ii != '0' && ii != '')
        this.indexback = Number(ii);
    }
    this.getApplications();
  }

  AddNewButton() {
    this.addDocsDisplay = true;
  }
  AddDocs(){
    this.showLoading = true;
    this.DocsError1 = "";
    let ok = true;
    if (this.fileInput == undefined || this.fileInput.files.length == 0 || this.fileInput == null) {
      ok = false;
      this.DocsError1 = "Please select atleast one file.";
    }
    if(ok)
    {
      this.fileInput.files.forEach((file) => {
        let fileSize = file.size
        if (fileSize <= 10 && ok) {
        this.DocsError1 = "File size should be greater than 1KB";
        ok = false;
        }
      });
    }
    if (ok) 
    {
      if(this.fileInput.files != null && this.fileInput.files != undefined)
      {
        if(this.fileInput.files.length > 0)
        {
          this.showLoading = true;
          const formData = new FormData();
          formData.append("requestID", this.requestID.toString());
          formData.append("applicationID", this.applicationID);
          formData.append("departmentID", this.UserDepartmentID);
          formData.append("addedBy", this.userId);
          this.fileInput.files.forEach((file) => {
            formData.append("files", file);
          });
          this._facadeService.addDocuments(formData)
          .subscribe(
            (data) => {
              console.log(data);
              this.apiResponse = data;
              if (this.apiResponse.returnCode == 0) {
                this.fileInput.files.forEach((file) => {
                  var data = {
                    "requestID" : this.requestID,
                    "addedDate": new Date(),
                    "addedby": parseInt(this.userId),
                    "addedbyName": this.fullName,
                    "fileName":file.name
                  }
                  this.ViewAddedDocuments.push(data);
                  if (this.fileInput != undefined) {
                    if (this.fileInput.files.length > 0) {
                      this.fileInput.clear();
                    }
                  }
                  this.addDocsDisplay = false;
                  this.showLoading = false;
                });

              } else {
                this.showLoading = false;
                this.DocsError1 = this.apiResponse.returnMessage;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          );
        }
      }
    }
    else
        this.showLoading = false;
  }

  getApplications() {
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.applications = data.returnObject;
        var all = this.applications.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            this.applications.push(a);
        }
        var userapp = JSON.parse(sessionStorage.getItem('applications'))
        if(userapp != null && userapp != undefined)
        {
          var allapps = this.applications;
          this.applications = [];
          userapp.forEach(element => {
            var a = allapps.find(x=>x.applicationGuid.toLowerCase() == element.id.toLowerCase());
            if(a != null)
              this.applications.push(a);
          });
          this.applications.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
        }
        this.getRequestDetailsByGUID();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  getRequestDetailsByGUID(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestID).subscribe(
      (data) => {
        this.requestDetails = data.returnObject;                
        if(this.requestDetails!=null){  
          this.code = this.requestDetails.code;
          this.requestedByName = this.requestDetails.requestedByName;
          this.selectedreqDate = new Date(this.requestDetails.requestDate+'Z');
          this.selectedapplication = this.applications.find(m => m.applicationId == this.requestDetails.applicationId);
          this.selectedtype = this.type.find(x=>x.value == this.requestDetails.type);

          // this.applicationName = this.requestDetails.applicationName;
          // this.type = this.requestDetails.type;
          this.status = this.requestDetails.status;
          this.selectedPriority = [];
          this.selectedPriority = this.priority.find(x=>x.value.toLowerCase() == this.requestDetails.priority.toLowerCase());
          this.title = this.requestDetails.title;
          this.description = this.requestDetails.description;
          this.notesList = this.requestDetails.notes;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach((value, key) => {  
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;         
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
          }
          this.ViewAddedDocuments = this.requestDetails.documents;
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null && this.ViewAddedDocuments.length > 0)
          {
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
          else
            this.ViewAddedDocuments = [];
          this.Clarification1 = [];
          this.Clarification1 = this.requestDetails.clarifications;
          if(this.Clarification1!=undefined && this.Clarification1 != null)
          {
            this.Clarification1 = this.Clarification1.filter(x=>x.type != "Support");
            if(this.Clarification1!=undefined && this.Clarification1 != null)
            {
              if(this.Clarification1.length > 0)
              {
                this.Clarification1.forEach((value, key) => {
                  if ( value.askedDate !== null &&  value.askedDate !== undefined )
                  value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
                  if ( value.answerDate !== null &&  value.answerDate !== undefined )
                  value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
                });
              }
            }
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  onFileUpload(event) {
    this.DocsError = "";
    let fileSize = event.files[0].size;
    if (fileSize <= 10) {
     this.DocsError = "File size should be greater than 1KB";
    }
}

  SendCancel() {
    this.confirmation.confirm({
      key: "confirm-cancel",
      message: "Are you sure you want to cancel this request?",
      accept: () => {
        this.confirmCancel = false;
        this.Confirm('Cancel');
      },
    });
    this.confirmCancel = false;
  }

Confirm(status)
{
  this.Error = "";
  this.showLoading = true;
  if(status == "Clare" || status == "Cancel")
  {
    if (this.popupremark == undefined || this.popupremark == '') { this.Error = 'Please enter remarks.';this.showLoading = false; }
  }
  else
  {
    if (this.popupremark == undefined || this.popupremark == '')  this.popupremark = "Confirmed";
  }
  if(this.Error == "")
  {
    var requestData = {
      requestID: this.requestID,
      userID: parseInt(this.userId),
      status: status,
      remark: this.popupremark,
      priority : this.selectedPriority.value,
      title: this.title,
      description: this.description,
      type: this.selectedtype.value,
      applicationId: this.selectedapplication.applicationId
    } 
    this._facadeService.updateConfirmation(requestData).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnObject == "OK") {
          this.showLoading = false;
          this.router.navigate(['/pendingConfirmationRequest',{ index: this.indexback }]);
        } else {
          this.showLoading = false;
          this.Error = this.apiResponse.returnObject;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
back()
{
  this.router.navigate(['/pendingConfirmationRequest',{ index: this.indexback }]);
}

SendMail(ID)
  {
    this.showLoading = true;
    this._facadeService.SendMail(ID).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.showLoading = false;
          this.getRequestDetailsByGUID();
        } else {
          this.showLoading = false;
          this.Error = data.returnMessage;
        }
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
AddNote(){
  this.DocsError = "";
  if(this.issueNote != null && this.issueNote != undefined) 
  {
    if(this.issueNote!= '')
    { 
      this.issueNote = this.issueNote.replace("<p>", ""); 
      this.issueNote = this.issueNote.replace("</p>", "");
      this.showLoading = true;
      var model = {
        "requestID":this.requestID,
        "note":this.issueNote,
        "displayToCustomer" : true,
        "addedBy":parseInt(this.userId)
      };
      this._facadeService.AddNote(model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
              if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
              {
                this.notesList.forEach((value, key) => {     
                  value.ok = false;   
                  if(value.createdBy == parseInt(this.userId))
                    value.ok = true;      
                  if(value.createdDate.toString().indexOf('Z') > 0)   
                    value.createdDate = new Date(value.createdDate);   
                  else
                    value.createdDate = new Date(value.createdDate+'Z');              
                });
              }
            }
            this.issueNote = '';
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.DocsError = "Please enter note";
  }
  else
    this.DocsError = "Please enter note";
}

onBasicUploadAuto(event){
  this.showLoading = true;
  this.DocsError = "";
  let fileSize = event.files[0].size;
  if (fileSize <= 10)
  {
    this.DocsError = "File size should be greater than 1KB";
    this.showLoading = false;
  }
  else
  {
    const formData = new FormData();
    formData.append("requestID", this.requestID.toString());
    formData.append("note", this.issueNote);
    formData.append("displayToCustomer", "true");
    formData.append("applicationID", this.applicationID);
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("addedBy", this.userId);
    if (event != undefined) {
      if (event.files.length > 0) {
        event.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    console.log(formData);
    this._facadeService.addADocuments(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
              if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
              {
                this.notesList.forEach((value, key) => {  
                  value.ok = false;   
                  if(value.createdBy == parseInt(this.userId))
                    value.ok = true;         
                  if(value.createdDate.toString().indexOf('Z') > 0)   
                    value.createdDate = new Date(value.createdDate);   
                  else
                    value.createdDate = new Date(value.createdDate+'Z');              
                });
              }
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
  }

  }
