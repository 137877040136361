import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class MyIssuesService {
  constructor(private _httputilityService: HttputilityService) {}
  
  getApplications(){
    return this._httputilityService.GetU('user/GetApplications');
  }
  getAllUsers(){
    return this._httputilityService.GetU('user/getAllUsers');
  }
  getUsers(){
    return this._httputilityService.GetU('user/GetUsers');
  }
  getSupportUsers(){
    return this._httputilityService.GetU('user/GetSupportUsers');
  }

  getDepartments() {
    return this._httputilityService.GetU("user/GetDepartments","");
  }

  getRolesByDepartmentGuid(departmentGuid){
    return this._httputilityService.GetU("user/GetRolesByDepartment?departmentGuid="+departmentGuid,"");
  }  

  GetUsersByRoleGuid(RoleGUID){
    return this._httputilityService.GetU("user/GetUsersByRole?RoleGUID="+RoleGUID,"");
  }

  getRoles() {
    return this._httputilityService.GetU("user/GetRoles","");
  }
}
