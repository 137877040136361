import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent implements OnInit {
  showLoading: boolean;
  userId = sessionStorage.getItem('userId');
  AddedRequests:any[];
  cols:any[];
  headerCheckbox:boolean;
  archiveArray: any[] = [];

  addDocsDisplay: boolean;
  comments:any;
  AddLogError:string="";
  responseMessage:string="";
  RequestID :number = 0;
  Request:any="";

  @ViewChild("dt") table: Table;
  constructor(
    private _facadeService: FacadeService,private router: Router,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }
  ngOnInit(): void {
    this.showLoading = true;
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '30%' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '120px' }
    ];
    this.getRequests();
  }
  getRequests() {
    this._facadeService.GetToCloseRequest().subscribe(
      (data) => {
        this.AddedRequests = data.returnObject;
        this.archiveArray=[];
        if(this.AddedRequests!=undefined && this.AddedRequests != null)
        {
          if(this.AddedRequests.length > 0)
          {
            this.AddedRequests.forEach((value, key) => { 
              value.comments = "";
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
              value.selected = value.check;
              if(value.check)
                this.archiveArray.push(value.requestID); 
            });
          }
        }
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  Edit(row)
  {
    this.AddLogError = "";
    this.RequestID = row.id;
    this.Request = row.code + " : " + row.title;
    this.comments = row.comments;
    this.addDocsDisplay = true;
  }

  AddLog()
  {
    this.AddLogError = "";
    if (this.RequestID == null || this.RequestID == 0 || this.RequestID == undefined)
      this.AddLogError = "Please select Request";
    else if (this.comments == null || this.comments == "" || this.comments == undefined)
      this.AddLogError = "Please enter comments";
    else {
      this.comments = this.comments.replace("<p>", ""); 
      this.comments = this.comments.replace("</p>", ""); 

      this.showLoading = true;
      this.AddedRequests.forEach(x=>{
        if(x.id == this.RequestID)
          x.comments = this.comments;
      });
      this.addDocsDisplay = false;
      this.comments = "";
      this.RequestID = 0;
      this.Request = "";
      this.showLoading = false;
    }
  }
  allChecked(event){
    this.showLoading = true;
    const checked = event.target.ariaChecked;
    this.AddedRequests.forEach(item => item.selected = checked);
    this.archiveArray =[];
    if(checked==null) 
    {
      this.headerCheckbox = false;
      this.AddedRequests.forEach(item => item.check = false);
    }
    else if(checked == 'true')
    {
      this.headerCheckbox = true;
      this.AddedRequests.forEach(item => item.check = true);
      this.AddedRequests.forEach((value, key) => {
        this.archiveArray.push(value.id);
      });
    }
    this.showLoading = false;
  }
  selectedId(selectedRow) {
    this.showLoading = true;
    this.headerCheckbox = false;
    this.AddedRequests.forEach(item => item.check = false);

    if (this.archiveArray.some((x) => x == selectedRow.id)) {
      this.archiveArray.forEach((value, key) => {
        if(value==selectedRow.id)
          this.archiveArray.splice((key), 1);
      });
    } 
    else
      this.archiveArray.push(selectedRow.id);
    
    var count: number = 0;
    this.AddedRequests.forEach(item => {
      this.archiveArray.forEach(value => {
        if(value==item.id){
          item.check = true;
          count = count + 1;
        }
      });
    });
    if(this.AddedRequests.length == count)
      this.headerCheckbox = true;
    this.showLoading = false;
  }
  SaveLog()
  {
    this.responseMessage = "";
    if (this.AddedRequests == null || this.AddedRequests == undefined)
      this.responseMessage = "Please add atleast one request for closing";
    else if (this.AddedRequests.length <= 0)
      this.responseMessage = "Please add atleast one request for closing";
    else {
      this.showLoading = true;
      this.headerCheckbox = false;
      if(this.archiveArray!=null && this.archiveArray.length>0)
      {
        let logs = [];
        this.archiveArray.forEach(e => {
          this.AddedRequests.forEach(element => {
            if(e == element.id && element.comments != "")
            {
              var Model = {
                requestID: element.id,
                userID: parseInt(this.userId),
                remarks: element.comments
              };
              logs.push(Model);
            }
          });
        });
        this._facadeService.CloseRequest(logs).subscribe(
          (data) => {
            if (data.returnCode == 0)
              this.router.navigate(['/reportByStatus']); 
            else
            {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        this.responseMessage = "Please add atleast one request for closing";
        this.showLoading = false;
      }
    }
  }
}
