import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FileUpload } from "primeng/fileupload";
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-un-assigned-details-requests',
  templateUrl: './un-assigned-details-requests.component.html',
  styleUrls: ['./un-assigned-details-requests.component.scss']
})
export class UnAssignedDetailsRequestsComponent implements OnInit {
  internal :boolean = false;
  index:number=0;
  UpdateError:string="";
  Error:string = "";
  showLoading:boolean;
  requestID = parseInt(sessionStorage.getItem("requestID"));
  code:string="";
  requestedByName:string="";
  selectedreqDate: any;
  applicationName:any;
  type: any;
  priority: SelectItem[];
  selectedPriority: any;
  RequestapplicationId:number=0;
  status:string;
  createdByName:string;
  selecteddueDate:any;
  mainestimatedHours:string;
  description: string = ""; 
  title:any;
  owner:any = [];
  ownerName:string;
  confirmedbyName:string;
  confirmedDate:any;
  confirmedremarks: string = "";
  cancelledByName:string;
  cancelledDate:any;
  cancelledRemarks: string = "";
  closedByName:string;
  closedDate:any;
  closedRemarks: string = "";
  startDate:any;

  // selectedUser:any = [];
  users:any;

  plannedStartDate:any;
  plannedEndDate:any;

  userId = sessionStorage.getItem('userId');
  cols: any[];  
  ViewAddedDocuments: any[] = [];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;

  // Notes
  notesList: any[] = [];
  issueNote:any;
  DocsError:string="";

  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  apiResponse: any;
  @ViewChild("fileInput") fileInput: FileUpload;
  @ViewChild("fubauto") fubauto: FileUpload;
  @ViewChild("fubautoD") fubautoD: FileUpload;

  Clarification1: any[] = [];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  Clarification1Cols: any[];

  requestDetails: any;

  docTypes: any[];
  @ViewChild('dt') table: Table;
  clicked: boolean;
  clonedTeams: { [s: string]: iCancelTeam } = {};
  taskName:string = "";
  scope:string="";
  estimatedHours:number = 0;
  selectedPredecessor : any;
  selectedAssignee:any;
  affectAllocation:boolean = false;
  taskNameError = "";
  scopeError = "";
  hoursError = "";
  PredecessorError = "";
  AssigneeError = "";
  EditRow: boolean = false;
  OldTaskSeq:number = 0;

  constructor( private _facadeService: FacadeService,private router: Router,private route: ActivatedRoute, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ,private pipe:DatePipe ) { }

  ngOnInit(): void {  
    this.showLoading = true; 
    // this.requestID = parseInt(this.route.snapshot.paramMap.get('requestID'));
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.priority = [
      { label: 'Critical', value: 'Critical' },
      { label: 'High', value: 'High' },
      { label: 'Medium', value: 'Medium' },
      { label: 'Low', value: 'Low' }
    ];
    this.Clarification1Cols = [
      { field: 'askedDate', header: 'Sought Date' },
      { field: 'question', header: 'Clarification' },
      { field: 'askedByName', header: 'Sought By' },
      { field: 'answerDate', header: 'Response Date' },
      { field: 'answer', header: 'Response' },
      { field: 'answerByName', header: 'Response By' }
    ];
    this.getUsers();
    
  }
  getUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.users = data.returnObject;
        this.getRequestDetailsByID();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  SendMail(ID)
  {
    this.showLoading = true;
    this._facadeService.SendMail(ID).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.showLoading = false;
          this.getRequestDetailsByID();
        } else {
          this.showLoading = false;
          this.Error = data.returnMessage;
        }
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRequestDetailsByID(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestID).subscribe(
      (data) => {
        this.requestDetails = data.returnObject;                
        if(this.requestDetails!=null){  
          this.internal = this.requestDetails.isInternal;
          this.code = this.requestDetails.code;
          this.requestedByName = this.requestDetails.requestedByName;
          this.selectedreqDate = new Date(this.requestDetails.requestDate+'Z');
          this.applicationName = this.requestDetails.applicationName;
          this.RequestapplicationId = this.requestDetails.applicationId;
          this.type = this.requestDetails.type;
          this.selectedPriority = [];
          this.selectedPriority = this.priority.find(x=>x.value.toLowerCase() == this.requestDetails.priority.toLowerCase());
          this.title = this.requestDetails.title;
          this.description = this.requestDetails.description;
          this.status = this.requestDetails.status; 
          this.createdByName = this.requestDetails.createdByName; 
          this.selecteddueDate = this.requestDetails.dueDate != null && this.requestDetails.dueDate != undefined ? new Date(this.requestDetails.dueDate+'Z') : null; 
          this.mainestimatedHours = this.requestDetails.estimatedHours; 
          if(this.requestDetails.owner != null && this.requestDetails.owner != undefined && this.requestDetails.owner != 0)
            this.owner = this.users.find(x=>x.userId == this.requestDetails.owner);
          this.ownerName = this.requestDetails.ownerName;
          if(this.requestDetails.owner == null || this.requestDetails.owner == undefined || this.requestDetails.owner == 0)
            this.owner = this.users.find(x=>x.userId == parseInt(this.userId));
          this.confirmedbyName = this.requestDetails.confirmedbyName;
          this.confirmedremarks = this.requestDetails.confirmedRemarks;
          this.confirmedDate =this.requestDetails.confirmedDate != null && this.requestDetails.confirmedDate != undefined ? new Date(this.requestDetails.confirmedDate+'Z') : null; 

          this.cancelledByName = this.requestDetails.cancelledByName;
          this.cancelledRemarks = this.requestDetails.cancelledRemarks;
          this.cancelledDate =this.requestDetails.cancelledDate != null && this.requestDetails.cancelledDate != undefined ? new Date(this.requestDetails.cancelledDate+'Z') : null; 

          this.closedByName = this.requestDetails.closedByName;
          this.closedRemarks = this.requestDetails.closedRemarks;
          this.closedDate = this.requestDetails.closedDate != null && this.requestDetails.closedDate != undefined ? new Date(this.requestDetails.closedDate+'Z') : null; 

          this.startDate = this.requestDetails.startDate != null && this.requestDetails.startDate != undefined ? new Date(this.requestDetails.startDate+'Z') : null; 
          this.notesList = this.requestDetails.notes;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList.forEach((value, key) => {  
             value.ok = false;   
              if(value.createdBy == parseInt(this.userId))
                value.ok = true;         
              if(value.createdDate.toString().indexOf('Z') > 0)   
                value.createdDate = new Date(value.createdDate);   
              else
                value.createdDate = new Date(value.createdDate+'Z');              
            });
          }
          this.ViewAddedDocuments = this.requestDetails.documents;
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null && this.ViewAddedDocuments.length > 0)
          {
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
          else
            this.ViewAddedDocuments = [];
          this.plannedStartDate = this.requestDetails.plannedStartDate != null && this.requestDetails.plannedStartDate != undefined ? new Date(this.requestDetails.plannedStartDate+'Z') : null; 
          this.plannedEndDate = this.requestDetails.plannedEndDate != null && this.requestDetails.plannedEndDate != undefined ? new Date(this.requestDetails.plannedEndDate+'Z') : null; 
          this.docTypes = this.requestDetails.tasks;
          if(this.docTypes != null && this.docTypes != undefined)
            this.Getdates();
          if(this.requestDetails.owner == null || this.requestDetails.owner == undefined)
            this.requestDetails.owner = 0;
          this.Clarification1 = this.requestDetails.clarifications;
          if(this.Clarification1!=undefined && this.Clarification1 != null)
          {
            if(this.Clarification1.length > 0)
            {
              this.Clarification1.forEach((value, key) => {
                if ( value.askedDate !== null &&  value.askedDate !== undefined )
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
                if ( value.answerDate !== null &&  value.answerDate !== undefined )
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
              });
            }
            else
              this.Clarification1 = [];
          }
          else
            this.Clarification1 = [];
        }
        this.index = 1;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    this.table.onRowReorder.subscribe(data => {
      this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
      var dropIndex = data.dropIndex + 1;
      var dragIndex = data.dragIndex + 1;
      if(dragIndex > dropIndex)
      {
        this.docTypes.forEach(e =>
        {
          if(e.taskSeq >= dropIndex && e.taskSeq <= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.taskSeq + 1;
          }
        });
      }
      if(dragIndex < dropIndex)
      {
        this.docTypes.forEach(e =>
        {
          if(e.taskSeq <= dropIndex && e.taskSeq >= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.seq - 1;
          }
        });
      }
      if(this.docTypes != null && this.docTypes != undefined)
        this.Getdates();
  });
}

Getdates()
{
  this.showLoading = true;
  this.UpdateError = "";
  this.updatedoctype();
  // var startdate = new Date(this.pipe.transform(this.plannedStartDate,"MM/dd/yyyy"));
  // let da = this.pipe.transform(startdate,"MM/dd/yyyy");
  // if(da != "01/01/1970")
  // {
  //   let phaseTasks=[];
  //   if(this.docTypes!=null && this.docTypes.length>0)
  //   {
  //     this.docTypes.forEach(element => {
  //       var assignee = [];
  //       if(element.taskAssignee != null && element.taskAssignee != undefined)
  //       {
  //         if(element.taskAssignee.length > 0)
  //         {
  //           element.taskAssignee.forEach(e => {
  //             var as ={
  //               "taskID":e.taskID,
  //               "fullName":e.fullName,
  //               "userGuid":e.userGuid,
  //               "id":e.id
  //             }
  //             assignee.push(as);
  //           });
  //         }
  //       }
  //       var predesss = [];
  //       if(element.taskPredecessor != null && element.taskPredecessor != undefined)
  //       {
  //         if(element.taskPredecessor.length > 0)
  //         {
  //           element.taskPredecessor.forEach(e => {
  //             var as ={
  //               "taskID":e.taskID,
  //               "predecessor":e.predecessor,
  //               "id":e.id,
  //               "predecessorName":e.taskName,
  //             }
  //             predesss.push(as);
  //           });
  //         }
  //       }
  //       var a ={
  //         "requestID":this.requestID,
  //         "taskID":element.taskID,
  //         "taskName":element.taskName,
  //         "taskSeq": element.taskSeq,
  //         "affectAllocation": element.affectAllocation,
  //         "startDate":element.startDate!=null && element.startDate!="--"&&element.startDate!=undefined?this.pipe.transform(element.startDate,"MM/dd/yyyy hh:mm"):null,
  //         "dueDate":element.endDate!=null && element.endDate!="--"&&element.endDate!=undefined?this.pipe.transform(element.endDate,"MM/dd/yyyy hh:mm"):null,
  //         "scope": element.scope,
  //         "estimatedHours" : element.estimatedHours == null || element.estimatedHours == undefined || element.estimatedHours == "" ? 0 : parseInt(element.estimatedHours),
  //         "taskAssignee" : assignee,
  //         "taskPredecessor" : predesss,
  //         "cycleTime" : element.cycleTime == null || element.cycleTime == undefined || element.cycleTime == "" ? 0 : parseInt(element.cycleTime),
  //         "isEdit" : element.isEdit
  //       }
  //       phaseTasks.push(a);
  //     });
  //   }
  //   this._facadeService.GetDates(phaseTasks,da).subscribe(
  //     (data) => {
  //       if(data.returnCode==0){
  //         this.showLoading = false;
  //         this.docTypes = data.returnObject;
  //         if(this.docTypes != null && this.docTypes != undefined)
  //         {
  //           if(this.docTypes.length > 0)
  //           {
  //             this.updatedoctype();
  //             this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  //             var all = this.docTypes.filter(t=> t.dueDate != "--");
  //             var arr = all.map(t=> new Date(t.dueDate));
  //             if(arr.length > 0)
  //               this.plannedEndDate = new Date(Math.max(...arr.map(Number)));
  //             else
  //               this.plannedEndDate = "";
  //           }
  //         }
  //       }
  //       else{
  //         this.showLoading = false;
  //         this.UpdateError=data.returnMessage;
  //       }
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     } 
  //   );
  // }
  this.showLoading = false;
}
onRowEditInit(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  let doc= [];
  var seq = 0;
  this.docTypes.forEach(i => {
    if(i.taskName == null)    i.taskName = "";
    if(teamModel.taskName == null)    teamModel.taskName = "";
    if(i.taskName.toUpperCase().trim() == teamModel.taskName.toUpperCase().trim())
    {
      // i.isEdit = "D";
      // doc.push(i);
    }
    else
      doc.push(i);
  });
  this.docTypes = doc;
  if(this.docTypes != null && this.docTypes != undefined)
  {
    if(this.docTypes.length > 0)
    {
      this.docTypes.forEach(e => {
        if(e.taskSeq > teamModel.taskSeq)
          e.taskSeq = e.taskSeq - 1;
      });
    }
  }
  this.Getdates();
  this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  this.showLoading = false;
}

onRowEditInit1(row) {
  this.showLoading = true;
  this.table.value.forEach(x => {
    if(x.taskID != row.taskID)
     this.table.cancelRowEdit(x);
  });
  this.EditRow = true;
  this.OldTaskSeq = row.taskSeq;
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.clicked = true;
  this.taskName = row.taskName;
  this.scope = row.scope;
  this.estimatedHours = row.estimatedHours;
  this.affectAllocation = row.affectAllocation;
  this.selectedAssignee = [];
  if(row.taskAssignee != null && row.taskAssignee != undefined)
  {
    if(row.taskAssignee.length > 0)
    {
      row.taskAssignee.forEach(element => {
        var a = this.users.find(x=>x.userId == element.userId);
        this.selectedAssignee.push(a);
      });
    }
  }
  this.selectedPredecessor = [];
  if(row.taskPredecessor != null && row.taskPredecessor != undefined)
  {
    if(row.taskPredecessor.length > 0)
    {
      row.taskPredecessor.forEach(element => {
        var a = this.docTypes.find(x=>x.taskID == element.predecessor);
        this.selectedPredecessor.push(a);
      });
    }
  }
  this.showLoading = false;
}
onRowEditSave(docTypes: iCancelTeam) {
  this.showLoading = true;
  this.addCancelTeam();
}
updatedoctype()
{
  var eh: number = 0;
  if(this.docTypes != null && this.docTypes != undefined)
  {
    if(this.docTypes.length > 0)
    {
      this.docTypes.forEach(e => {
        var duration: number =  0;
        var CT : number = parseInt(e.estimatedHours) / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        e.cycleTime = duration;
        
        eh = Number(eh) + Number(e.estimatedHours)
        e.pData = [];
        var aa = this.docTypes.filter(x=>x.taskSeq < e.taskSeq);
        if(aa != undefined && aa != null)
        {
          aa.forEach(a => {
            var i = {
              "taskID" : e.taskID,
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            e.pData.push(i);
          });
        }
        var p = e.taskPredecessor;
        e.taskPredecessor = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              var a1 = e.pData.find(x=>x.predecessor == element.predecessor);
              if(a1 != undefined && a1 != null)
                  e.taskPredecessor.push(a1);
            });
          }
        }
        p = e.taskAssignee;
        e.taskAssignee = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              var a1;
              if(element.userID == null || element.userID == undefined)
                a1 = this.users.find(x=>x.userId == element.userId);
              else
                a1 = this.users.find(x=>x.userId == element.userID);
              if(a1 != undefined && a1 != null)
                  e.taskAssignee.push(a1);
            });
          }
        }

      });
    }
  }
  this.mainestimatedHours = eh.toString();
}
onRowEditCancel(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  if(!this.EditRow)
    this.docTypes = this.docTypes.filter(i => i.taskName != teamModel.taskName);
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.taskName='';
  this.affectAllocation=false;
  this.scope = "";
  this.estimatedHours = 0;
  this.selectedAssignee = [];
  this.selectedPredecessor = [];
  this.updatedoctype();
  
  this.EditRow = false;
  this.OldTaskSeq = 0;
  this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
  this.showLoading = false;
}

  newRow() {
    if(this.docTypes != null && this.docTypes != undefined)
      return {taskName: '', taskSeq: this.docTypes.length + 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      cycleTime: 0,taskAssignee :[],taskPredecessor: []};
    else
      return {taskName: '', taskSeq: 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      cycleTime: 0,taskAssignee :[],taskPredecessor: []};
  }

  addCancelTeam() {
    this.showLoading = true;
    this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";

    if (this.taskName == undefined || this.taskName == '' || this.taskName == null) {
      this.taskNameError = 'Please enter task.';this.showLoading = false;
    }
    else if (this.scope == undefined || this.scope == '' || this.scope == null) {
      this.scopeError = 'Please enter scope.';this.showLoading = false;
    }
    else if (this.estimatedHours == undefined || this.estimatedHours == 0 || this.estimatedHours == null) {
      this.hoursError = 'Please enter Estimated Hours.';this.showLoading = false;
    }
    // else if (this.selectedPredecessor == undefined || this.selectedPredecessor.length == 0 || this.selectedPredecessor == null) {
    //   this.PredecessorError = 'Please select Predecessor.';this.showLoading = false;
    // }
    else if (this.selectedAssignee == undefined || this.selectedAssignee.length == 0 || this.selectedAssignee == null) {
      this.AssigneeError = 'Please select Assignee.';this.showLoading = false;
    }
    else 
    {
      var ok = true;
      this.docTypes.forEach(element => {
        if (this.taskName == element.taskName && this.OldTaskSeq != element.taskSeq && ok) {
          ok = false;
          this.taskNameError = 'Duplicate records found.';
        }
      });
      if (ok) {
        var pp = [];
        if (this.selectedPredecessor != undefined &&this.selectedPredecessor != null) {
          this.selectedPredecessor.forEach(a => {
            var i = {
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            pp.push(i);
          });
        }
        var duration: number =  0;
        var CT : number = this.estimatedHours / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        if(this.EditRow)
        {
          this.docTypes.forEach(element => {
            if (this.OldTaskSeq == element.taskSeq) {
              pp.forEach(a => {
                a.taskID = element.taskID;
              });
                element.isEdit = "E";
                element.taskName =  this.taskName;
                element.affectAllocation = this.affectAllocation;
                element.scope = this.scope;
                element.estimatedHours = this.estimatedHours;
                element.taskAssignee = this.selectedAssignee;
                element.taskPredecessor = pp;
                element.cycleTime =  duration;
                element.status = "New";
            }
          });
          this.EditRow = false;
          this.OldTaskSeq = 0;
          this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
        }
        else
        {
          var id = 0;
          pp.forEach(a => {
            a.taskID = id;
          });
          var cancelteamModel = {
            taskID: id,
            taskName: this.taskName,
            taskSeq: this.docTypes.length,
            affectAllocation:this.affectAllocation,
            scope:this.scope,
            estimatedHours : this.estimatedHours,
            isEdit:"Y",
            startDate:"--",
            dueDate:"--",
            cycleTime: duration,
            taskAssignee : this.selectedAssignee,
            taskPredecessor : pp,
            status : "New"
          };
          this.docTypes.push(cancelteamModel);
        }
        this.clicked = false;
        this.docTypes = this.docTypes.filter(i => i.taskName != "");
        this.taskName='';
        this.affectAllocation=false;
        this.scope = "";
        this.estimatedHours = 0;
        this.selectedAssignee = [];
        this.selectedPredecessor = [];
        this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
        this.updatedoctype();
      }
      this.showLoading = false;
    }
  }


  Update()
  {
    this.UpdateError = "";
   

    if(this.docTypes == null || this.docTypes == undefined || this.docTypes.length == 0) 
      this.UpdateError = "Please add alteast one task";
    else if( this.owner == null ||  this.owner == undefined ||  this.owner.length == 0)
      this.UpdateError = "Please select reuest owner";
    else if(this.mainestimatedHours == null || this.mainestimatedHours == undefined || this.mainestimatedHours == "")
      this.UpdateError = "Please enter estimated hours";
    // else if(this.plannedStartDate == null || this.plannedStartDate == undefined || this.plannedStartDate == "")
    //   this.UpdateError = "Please enter planned start date";
    // else if(this.plannedEndDate == null || this.plannedEndDate == undefined || this.plannedEndDate == "")
    //   this.UpdateError = "Please enter planned end date";
    else if(this.owner == null || this.owner == undefined || this.owner == "" || this.owner.length == 0)
      this.UpdateError = "Please select request owner";
    else{
      // var allok = false;
      // this.docTypes.forEach(element => {
      //   if((element.taskAssignee == null || element.taskAssignee == undefined || element.taskAssignee.length == 0) && !allok)
      //   {

      //   }
      //   else
      //     allok = true;
      // });
      // if(allok)
      // {
        this.showLoading = true;
        var ass = [];
        this.docTypes.forEach(element => {
          var assignee = [];
          if(element.taskAssignee != null && element.taskAssignee != undefined)
          {
            if(element.taskAssignee.length > 0)
            {
              element.taskAssignee.forEach(e => {
                var as ={
                  "userID":e.userId,
                }
                assignee.push(as);
              });
            }
          }

          var predesss = [];
          if(element.taskPredecessor != null && element.taskPredecessor != undefined)
          {
            if(element.taskPredecessor.length > 0)
            {
              element.taskPredecessor.forEach(e => {
                var as ={
                  "predecessorName":e.predecessorName,
                }
                predesss.push(as);
              });
            }
          }
        
          var a ={
             "requestID":this.requestID,
            "taskID":element.taskID,
            "taskName":element.taskName,
            "taskSeq": element.taskSeq,
            "affectAllocation": element.affectAllocation,
            "plannedStartDate": null,
            "plannedEndDate": null,
            "currentStartDate": null,
            "currentEndDate": null,
            "actualStartDate": null,
            "actualEndDate": null,
            "startDate": element.startDate == "--" ? null : new Date(element.startDate+'Z'),
            "dueDate": element.dueDate == "--" ? null : new Date(element.dueDate+'Z'),
            "scope": element.scope,
            "estimatedHours" : element.estimatedHours == null || element.estimatedHours == undefined || element.estimatedHours == "" ? 0 : parseInt(element.estimatedHours),
            "actualHours":element.actualHours,
            "progressCompleted":element.progressCompleted,
            "status":element.status,
            "taskAssignee" : assignee,
            "taskPredecessor" : predesss
          }
          ass.push(a);
        });
        var model={
          "id":this.requestID,
          "status":this.status,
          "priority":this.selectedPriority.value,
          "internalStatus":"New",
          "estimatedHours" : parseInt(this.mainestimatedHours),
          "progressCompleted":0,
          "owner" :this.owner.userId,
          "plannedStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
          "plannedEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
          "currentStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
          "currentEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
          "actualStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
          "actualEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
          "startDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
          "dueDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
          "createdBy":parseInt(this.userId),
          "tasks":ass,
          "exists" : false,
          "title":this.title,
          "applicationId":this.RequestapplicationId,
          "type":this.type,
          "description":this.description,
          "googleDrive": "",
        }
        this._facadeService.UpdateRequestData(model).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(['/unAssignedRequests']);
            } else {
              this.showLoading = false;
              this.UpdateError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      // }
      // else 
      //   this.UpdateError = "Please select 1 assignee for the tasks";
    }
  }

AddNote(display){
  this.DocsError = "";
  if(this.issueNote!=undefined && this.issueNote!= '')
  {
    this.showLoading = true;
    var model = {
      "requestID":this.requestID,
      "note":this.issueNote,
      "displayToCustomer" : display,
      "addedBy":parseInt(this.userId)
    };
    this._facadeService.AddNote(model).subscribe(
      (data) => {
        this.apiResponse = data;
        if (this.apiResponse.returnCode == 0) {
          this.showLoading = false;
          if (this.fubauto != undefined) {
            if (this.fubauto.files.length > 0) {
              this.fubauto.clear();
            }
          }
          if (this.fubautoD != undefined) {
            if (this.fubautoD.files.length > 0) {
              this.fubautoD.clear();
            }
          }
          this.notesList =  this.apiResponse.returnObject;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList.forEach((value, key) => {       
             value.ok = false;   
              if(value.createdBy == parseInt(this.userId))
                value.ok = true;    
              if(value.createdDate.toString().indexOf('Z') > 0)   
                value.createdDate = new Date(value.createdDate);   
              else
                value.createdDate = new Date(value.createdDate+'Z');              
            });
          }
          this.issueNote = '';
          this.showLoading = false;
        } else {
          this.showLoading = false;
          this.DocsError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  else
  {
    this.DocsError = "Please enter note";
  }
}
CheckDate(startDate, endDate) {
  if (startDate != null && startDate != undefined && startDate != "" && endDate != null && endDate != undefined && endDate != "") {
    if (startDate > endDate) {
      this.plannedEndDate = this.plannedStartDate;
    }
  }
  if(this.plannedStartDate != null && this.plannedStartDate != undefined && this.plannedStartDate != "")
  {
    //this.docTypes = this.requestDetails.tasks;
    if(this.docTypes != null && this.docTypes != undefined)
    {
      if(this.docTypes.length > 0)
        this.table.value.forEach(x => { this.table.cancelRowEdit(x); });
    }
    this.Getdates();
  }
}
onBasicUploadAuto(event,displaytocustomer){
  this.showLoading = true;
  this.DocsError = "";
  let fileSize = event.files[0].size;
  if (fileSize <= 10)
  {
    this.DocsError = "File size should be greater than 1KB";
    this.showLoading = false;
  }
  else
  {
    const formData = new FormData();
    formData.append("requestID", this.requestID.toString());
    formData.append("applicationID", this.applicationID);
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("displayToCustomer", displaytocustomer);
    formData.append("addedBy", this.userId);
    if (event != undefined) {
      if (event.files.length > 0) {
        event.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    console.log(formData);
    this._facadeService.addADocuments(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            if (this.fubautoD != undefined) {
              if (this.fubautoD.files.length > 0) {
                this.fubautoD.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach((value, key) => {   
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;        
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
    // this._facadeService.CreateSecurityToken().subscribe(
    //   (data) => {
    //     this.showLoading = false;
    //     if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
    //       var access_token = data.returnObject;
    //       window.open(this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
    //       "entrypoint?userguid=" +
    //       this.userintId +
    //       "&userId=" +
    //       this.userId +
    //       '&destination=docdetails'+'&access_token='+access_token+"&docId="+docId);
    //     }
    //     else{
    //       location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
    //     }
    //   },
    //   (error) => {
    //     this.showLoading = false;
    //     console.log(error);
    //   } 
    // );
  }

  }

  

export interface iCancelTeam {
  taskID: string;
  taskName: string;
  taskSeq: number;
  affectAllocation:boolean;
  startDate:string;
  dueDate:string;
  isEdit : string;
  scope:string;
  estimatedHours:number;
  cycleTime:number;
  taskAssignee:any[];
  taskPredecessor:any[];
}