import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manageassignee',
  templateUrl: './manageassignee.component.html',
  styleUrls: ['./manageassignee.component.scss']
})
export class ManageassigneeComponent implements OnInit {
  docTypes: any[];
  @ViewChild('dt') tableMain: Table;
  filteredProdType: any[];
  description: string = ""; 
  showLoading:boolean;
  userId = sessionStorage.getItem("userId");
  Requests: any[];
  selectedRequest: any;
  ShowDiv:boolean=false;
  code:string="";
  selectedreqDate: any;
  requestedByName:string="";
  createdByName:string="";
  applicationName:string = "";
  applicationID:number = 0;
  type: any;
  title:string = "";
  priority: any;
  GoogleDrive:string = "";
  status:string="";
  internalStatus:string = "";
  estimatedHours:string;
  actualHours:string;
  progress:any;
  StartDate:any;
  EndDate:any;
  plannedStartDate:any;
  plannedEndDate:any;
  currentStartDate:any;
  currentEndDate:any;
  actualStartDate:any;
  actualEndDate:any;
  owner:any;
  users:any;
  selectedUser:any;
  UpdateError:string ="";

  clicked: boolean;
  clonedTeams: { [s: string]: iCancelTeam } = {};
  taskName:string = "";
  scope:string="";
  taskestimatedHours:number = 0;
  selectedPredecessor : any;
  selectedAssignee:any;
  affectAllocation:boolean = false;
  taskNameError = "";
  scopeError = "";
  hoursError = "";
  PredecessorError = "";
  AssigneeError = "";
  EditRow: boolean = false;
  OldTaskSeq:number = 0;
  constructor(private _facadeService: FacadeService,private pipe:DatePipe ,private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {
  }
  ngOnInit(): void {
      this.showLoading = true;
      this.ShowDiv = false;
      this.getUsers();
  }
  getUsers() {
    this._facadeService.getSupportUsers().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
        this.users = data.returnObject;
        this.selectedUser = this.users.find(m => m.userId == parseInt(this.userId));
        this.GetRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  GetRequests() {
    this.showLoading = true;
    this.ShowDiv = false;
    this._facadeService
    .GetOwnerRequest(this.selectedUser.userId)
    .subscribe((data) => {
      this.Requests = data.returnObject;
      if(this.Requests != null && this.Requests != undefined)
      {
        if(this.Requests.length > 0)
        {
          this.Requests.forEach(element => {
            element.name = element.code + " : " + element.title;
          });
          // this.selectedRequest = this.Requests[0];
          // this.requestChange(this.selectedRequest);
        }
      }
      this.showLoading = false;
    });
  }
  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.Requests.length; i++) {
        let product= this.Requests[i];
        if (product.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
}
  requestChange() {
    if(this.selectedRequest != null && this.selectedRequest != undefined)
    {
      this.showLoading=true;
      this.clicked = false;
      this.ShowDiv = true;
      this.gettasks();
    }
  } 

gettasks()
{
    this._facadeService.getRequestDetailsByGUID(this.selectedRequest.id).subscribe(
      (data) => {
        var requestDetails = data.returnObject;  
        if(requestDetails!=null){  
          this.code = requestDetails.code;
          this.selectedreqDate = new Date(requestDetails.requestDate+'Z');
          this.applicationName = requestDetails.applicationName;
          this.applicationID = requestDetails.applicationId;
          this.requestedByName = requestDetails.requestedByName;
          this.createdByName = requestDetails.createdByName; 
          this.description = requestDetails.description;
          this.type = requestDetails.type;
          this.title = requestDetails.title;
          this.priority = requestDetails.priority;
          this.status = requestDetails.status; 
          this.internalStatus = requestDetails.internalStatus; 
          this.progress = requestDetails.progressCompleted;
          this.estimatedHours = requestDetails.estimatedHours; 
          this.actualHours = requestDetails.actualHours;
          this.plannedStartDate = requestDetails.plannedStartDate != null && requestDetails.plannedStartDate != undefined ? new Date(requestDetails.plannedStartDate+'Z') : null; 
          this.plannedEndDate = requestDetails.plannedEndDate != null && requestDetails.plannedEndDate != undefined ? new Date(requestDetails.plannedEndDate+'Z') : null; 
          this.currentStartDate = requestDetails.currentStartDate != null && requestDetails.currentStartDate != undefined ? new Date(requestDetails.currentStartDate+'Z') : null; 
          this.currentEndDate = requestDetails.currentEndDate != null && requestDetails.currentEndDate != undefined ? new Date(requestDetails.currentEndDate+'Z') : null; 
          this.actualStartDate = requestDetails.actualStartDate != null && requestDetails.actualStartDate != undefined ? new Date(requestDetails.actualStartDate+'Z') : null; 
          this.actualEndDate = requestDetails.actualEndDate != null && requestDetails.actualEndDate != undefined ? new Date(requestDetails.actualEndDate+'Z') : null; 
          this.StartDate = requestDetails.startDate != null && requestDetails.startDate != undefined ? new Date(requestDetails.startDate+'Z') : null; 
          this.EndDate = requestDetails.dueDate != null && requestDetails.dueDate != undefined ? new Date(requestDetails.dueDate+'Z') : null; 
          this.GoogleDrive = requestDetails.googleDrive;
          this.owner = this.users.find(x=>x.userId == requestDetails.owner);
          this.docTypes = requestDetails.tasks;
          if(this.docTypes != null && this.docTypes != undefined)
          {
            this.ngAfterViewInit();
            this.updatedoctype();
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
updatedoctype()
{
  var eh: number = 0;
  this.UpdateError = "";
  if(this.docTypes != null && this.docTypes != undefined)
  {
    if(this.docTypes.length > 0)
    {
      this.docTypes.forEach(e => {
        var duration: number =  0;
        var CT : number = parseInt(e.estimatedHours) / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        e.cycleTime = duration;
        
        eh = Number(eh) + Number(e.estimatedHours)
        e.pData = [];
        var aa = this.docTypes.filter(x=>x.taskSeq < e.taskSeq);
        if(aa != undefined && aa != null)
        {
          aa.forEach(a => {
            var i = {
              "taskID" : e.taskID,
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            e.pData.push(i);
          });
        }
        var p = e.taskPredecessor;
        e.taskPredecessor = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              var a1 = e.pData.find(x=>x.predecessor == element.predecessor);
              if(a1 != undefined && a1 != null)
                  e.taskPredecessor.push(a1);
            });
          }
        }
        p = e.taskAssignee;
        e.taskAssignee = [];
        if(p != null && p != undefined)
        {
          if(p.length > 0)
          {
            p.forEach(element => {
              if(element.userID == null || element.userID == undefined)
                element.userID = element.userId;
              var a1 = this.users.find(x=>x.userId == element.userID);
              if(a1 != undefined && a1 != null)
                  e.taskAssignee.push(a1);
            });
          }
        }

      });
    }
  }
  this.estimatedHours = eh.toString();
}

ngAfterViewInit() {
  if(this.tableMain != undefined)
  {
    this.tableMain.onRowReorder.subscribe(data => {
      this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
      var dropIndex = data.dropIndex + 1;
      var dragIndex = data.dragIndex + 1;
      if(dragIndex > dropIndex)
      {
        this.docTypes.forEach(e =>
        {
          if(e.taskSeq >= dropIndex && e.taskSeq <= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.taskSeq + 1;
          }
        });
      }
      if(dragIndex < dropIndex)
      {
        this.docTypes.forEach(e =>
        {
          if(e.taskSeq <= dropIndex && e.taskSeq >= dragIndex)
          {
            if(e.taskSeq == dragIndex)
              e.taskSeq = dropIndex;
            else
              e.taskSeq = e.seq - 1;
          }
        });
      }
      if(this.docTypes != null && this.docTypes != undefined)
        this.updatedoctype();
    });
  }
}

onRowEditInit(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  let doc= [];
  this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
  this.docTypes.forEach(i => {
    if(i.taskName == null)    i.taskName = "";
    if(teamModel.taskName == null)    teamModel.taskName = "";
    if(i.taskName.toUpperCase().trim() == teamModel.taskName.toUpperCase().trim())
    {
    }
    else
      doc.push(i);
  });
  this.docTypes = doc;
  this.docTypes = this.docTypes.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  if(this.docTypes != null && this.docTypes != undefined)
  {
    if(this.docTypes.length > 0)
    {
      this.docTypes.forEach(e => {
        if(e.taskSeq > teamModel.taskSeq)
          e.taskSeq = e.taskSeq - 1;
      });
    }
  }
  this.updatedoctype();
  this.showLoading = false;
}

onRowEditInit1(row) {
  this.showLoading = true;
  this.tableMain.value.forEach(x => {
    if(x.taskID != row.taskID)
     this.tableMain.cancelRowEdit(x);
  });
  this.docTypes = this.docTypes.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  this.EditRow = true;
  this.OldTaskSeq = row.taskSeq;
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.clicked = true;
  this.taskName = row.taskName;
  this.scope = row.scope;
  this.taskestimatedHours = row.estimatedHours;
  this.affectAllocation = row.affectAllocation;
  this.selectedAssignee = [];
  if(row.taskAssignee != null && row.taskAssignee != undefined)
  {
    if(row.taskAssignee.length > 0)
    {
      row.taskAssignee.forEach(element => {
        if(element.userID == null || element.userID == undefined)
        element.userID = element.userId;
        var a = this.users.find(x=>x.userId == element.userID);
        this.selectedAssignee.push(a);
      });
    }
  }
  this.selectedPredecessor = [];
  if(row.taskPredecessor != null && row.taskPredecessor != undefined)
  {
    if(row.taskPredecessor.length > 0)
    {
      row.taskPredecessor.forEach(element => {
        var a = this.docTypes.find(x=>x.taskID == element.predecessor);
        this.selectedPredecessor.push(a);
      });
    }
  }
  this.showLoading = false;
}
onRowEditSave(docTypes: iCancelTeam) {
  this.showLoading = true;
  this.addCancelTeam();
}
onRowEditCancel(teamModel: iCancelTeam) {
  this.showLoading = true;
  this.clicked = false;
  if(!this.EditRow)
    this.docTypes = this.docTypes.filter(i => i.taskName != teamModel.taskName);
  this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
  this.taskName='';
  this.affectAllocation=false;
  this.scope = "";
  this.taskestimatedHours = 0;
  this.selectedAssignee = [];
  this.selectedPredecessor = [];
  this.updatedoctype();
  
  this.EditRow = false;
  this.OldTaskSeq = 0;
  this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
  this.docTypes = this.docTypes.filter(x=>x.taskName != null && x.taskName != undefined && x.taskName != "");
  this.showLoading = false;
}

  newRow() {
    if(this.docTypes != null && this.docTypes != undefined)
      return {taskName: '', taskSeq: this.docTypes.length + 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      actualHours:0,progressCompleted:0,status:'New', cycleTime: 0,taskAssignee :[],taskPredecessor: []};
    else
      return {taskName: '', taskSeq: 1 ,affectAllocation: false, startDate: '--',dueDate : '--',scope:'',estimatedHours: 0,
      actualHours:0,progressCompleted:0,status:'New',cycleTime: 0,taskAssignee :[],taskPredecessor: []};
  }

  addCancelTeam() {
    this.showLoading = true;
    this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";

    if (this.taskName == undefined || this.taskName == '' || this.taskName == null) {
      this.taskNameError = 'Please enter task.';this.showLoading = false;
    }
    else if (this.scope == undefined || this.scope == '' || this.scope == null) {
      this.scopeError = 'Please enter scope.';this.showLoading = false;
    }
    else if (this.taskestimatedHours == undefined || this.taskestimatedHours == 0 || this.taskestimatedHours == null) {
      this.hoursError = 'Please enter Estimated Hours.';this.showLoading = false;
    }
    // else if (this.selectedPredecessor == undefined || this.selectedPredecessor.length == 0 || this.selectedPredecessor == null) {
    //   this.PredecessorError = 'Please select Predecessor.';this.showLoading = false;
    // }
    else if (this.selectedAssignee == undefined || this.selectedAssignee.length == 0 || this.selectedAssignee == null) {
      this.AssigneeError = 'Please select Assignee.';this.showLoading = false;
    }
    else 
    {
      var ok = true;
      this.docTypes.forEach(element => {
        if (this.taskName == element.taskName && this.OldTaskSeq != element.taskSeq && ok) {
          ok = false;
          this.taskNameError = 'Duplicate records found.';
        }
      });
      if (ok) {
        var pp = [];
        if (this.selectedPredecessor != undefined &&this.selectedPredecessor != null) {
          this.selectedPredecessor.forEach(a => {
            var i = {
              "predecessor" : a.taskID,
              "predecessorName" : a.taskName,
            };
            pp.push(i);
          });
        }
        var duration: number =  0;
        var CT : number = this.taskestimatedHours / 8;
        var a : number = CT - Math.floor(CT);
        var fp = CT.toString().split(".");
        if (a > 0)
            duration = Number(fp[0]) + 1;
        else
            duration = Number(fp[0]);
        if(this.EditRow)
        {
          this.docTypes.forEach(element => {
            if (this.OldTaskSeq == element.taskSeq) {
              pp.forEach(a => {
                a.taskID = element.taskID;
              });
              element.isEdit = "E";
              element.taskName =  this.taskName;
              element.affectAllocation = this.affectAllocation;
              element.scope = this.scope;
              element.estimatedHours = this.taskestimatedHours;
              element.taskAssignee = this.selectedAssignee;
              element.taskPredecessor = pp;
              element.cycleTime =  duration;
            }
          });
          this.EditRow = false;
          this.OldTaskSeq = 0;
          this.tableMain.value.forEach(x => { this.tableMain.cancelRowEdit(x); });
        }
        else
        {
          var id = 0;
          pp.forEach(a => {
            a.taskID = id;
          });
          var cancelteamModel = {
            taskID: id,
            taskName: this.taskName,
            taskSeq: this.docTypes.length,
            affectAllocation:this.affectAllocation,
            scope:this.scope,
            estimatedHours : this.taskestimatedHours,
            actualHours : 0,
            progressCompleted : 0,
            status : "New",
            isEdit:"Y",
            startDate:"--",
            dueDate:"--",
            plannedStartDate:"--",
            plannedEndDate:"--",
            currentStartDate:"--",
            currentEndDate:"--",
            actualStartDate:"--",
            actualEndDate:"--",
            cycleTime: duration,
            taskAssignee : this.selectedAssignee,
            taskPredecessor : pp
          };
          this.docTypes.push(cancelteamModel);
        }
        this.clicked = false;
        this.docTypes = this.docTypes.filter(i => i.taskName != "");
        this.taskName='';
        this.affectAllocation=false;
        this.scope = "";
        this.taskestimatedHours = 0;
        this.selectedAssignee = [];
        this.selectedPredecessor = [];
        this.taskNameError = '';this.scopeError = ""; this.hoursError = ""; this.PredecessorError = ""; this.AssigneeError = "";
        this.updatedoctype();
      }
      this.showLoading = false;
    }
  }
  Update()
  {
    this.UpdateError = "";
    if(this.docTypes == null || this.docTypes == undefined || this.docTypes.length == 0) 
      this.UpdateError = "Please add alteast one task";
    else if(this.estimatedHours == null || this.estimatedHours == undefined || this.estimatedHours == "")
      this.UpdateError = "Please enter estimated hours";
    // else if(this.plannedStartDate == null || this.plannedStartDate == undefined || this.plannedStartDate == "")
    //   this.UpdateError = "Please enter planned start date";
    // else if(this.plannedEndDate == null || this.plannedEndDate == undefined || this.plannedEndDate == "")
    //   this.UpdateError = "Please enter planned end date";
    else if(this.owner == null || this.owner == undefined || this.owner == "" || this.owner.length == 0)
      this.UpdateError = "Please select request owner";
    else{
      var allok = false;
      this.docTypes.forEach(element => {
        if((element.taskAssignee == null || element.taskAssignee == undefined || element.taskAssignee.length == 0) && !allok)
        {

        }
        else
          allok = true;
      });
      if(allok)
      {
        this.showLoading = true;
        var ass = [];
        this.docTypes.forEach(element => {
          var assignee = [];
          if(element.taskAssignee != null && element.taskAssignee != undefined)
          {
            if(element.taskAssignee.length > 0)
            {
              element.taskAssignee.forEach(e => {
                if(e.userID == null || e.userID == undefined)
                  e.userID = e.userId;
                var as ={
                  "userID":e.userID,
                }
                assignee.push(as);
              });
            }
          }

          var predesss = [];
          if(element.taskPredecessor != null && element.taskPredecessor != undefined)
          {
            if(element.taskPredecessor.length > 0)
            {
              element.taskPredecessor.forEach(e => {
                var as ={
                  "predecessorName":e.predecessorName,
                }
                predesss.push(as);
              });
            }
          }
        
          var a ={
            "requestID":this.selectedRequest.id,
            "taskID":element.taskID,
            "taskName":element.taskName,
            "taskSeq": element.taskSeq,
            "affectAllocation": element.affectAllocation,
            "plannedStartDate": element.plannedStartDate == "--" ? null : new Date(element.plannedStartDate+'Z'),
            "plannedEndDate": element.plannedEndDate == "--" ? null : new Date(element.plannedEndDate+'Z'),
            "currentStartDate": element.currentStartDate == "--" ? null : new Date(element.currentStartDate+'Z'),
            "currentEndDate": element.currentEndDate == "--" ? null : new Date(element.currentEndDate+'Z'),
            "actualStartDate": element.actualStartDate == "--" ? null : new Date(element.actualStartDate+'Z'),
            "actualEndDate": element.actualEndDate == "--" ? null : new Date(element.actualEndDate+'Z'),
            "startDate": element.startDate == "--" ? null : new Date(element.startDate+'Z'),
            "dueDate": element.dueDate == "--" ? null : new Date(element.dueDate+'Z'),
            "scope": element.scope,
            "estimatedHours" : element.estimatedHours == null || element.estimatedHours == undefined || element.estimatedHours == "" ? 0 : parseInt(element.estimatedHours),
            "actualHours":element.actualHours,
            "progressCompleted":element.progressCompleted,
            "status":element.status,
            "taskAssignee" : assignee,
            "taskPredecessor" : predesss
          }
          ass.push(a);
        });
        if(this.description == null || this.description == undefined)
          this.description = "";
        this.description = this.description.replace("<p>", ""); 
        this.description = this.description.replace("</p>", ""); 
        
        var model={
          "id":this.selectedRequest.id,
          "status":this.status,
          "priority":this.priority,
          "internalStatus":this.internalStatus,
          "estimatedHours" : parseInt(this.estimatedHours),
          "progressCompleted":this.progress,
          "owner" :this.owner.userId,
          "plannedStartDate" :this.plannedStartDate != null ? new Date(this.plannedStartDate+'Z') : null,
          "plannedEndDate" :this.plannedEndDate != null ? new Date(this.plannedEndDate+'Z') : null,
          "currentStartDate" :this.currentStartDate != null ? new Date(this.currentStartDate+'Z') : null,
          "currentEndDate" :this.currentEndDate != null ? new Date(this.currentEndDate+'Z') : null,
          "actualStartDate" :this.actualStartDate != null ? new Date(this.actualStartDate+'Z') : null,
          "actualEndDate" :this.actualEndDate != null ? new Date(this.actualEndDate+'Z') : null,
          "startDate" :this.StartDate != null ? new Date(this.StartDate+'Z') : null,
          "dueDate" :this.EndDate != null ? new Date(this.EndDate+'Z') : null,
          "title":this.title,
          "applicationId":this.applicationID,
          "type":this.type,
          "description":this.description,
          "createdBy":parseInt(this.userId),
          "tasks":ass,
          "exists" : true,
          "googleDrive": this.GoogleDrive,
        }
        this._facadeService.UpdateRequestData(model).subscribe(
          (data) => {
            var apiResponse = data;
            if (apiResponse.returnCode == 0) {
              this.showLoading = false;
              this.router.navigate(['/supportDashboard']);
            } else {
              this.showLoading = false;
              this.UpdateError = apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else 
        this.UpdateError = "Please select 1 assignee for the tasks";
    }
  }
}

export interface iCancelTeam {
  taskID: string;
  taskName: string;
  taskSeq: number;
  affectAllocation:boolean;
  startDate:string;
  dueDate:string;
  isEdit : string;
  scope:string;
  estimatedHours:number;
  actualHours: number;
  progressCompleted: number;
  status:string;
  cycleTime:number;
  taskAssignee:any[];
  taskPredecessor:any[];
}
