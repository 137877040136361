import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-releasenotes',
  templateUrl: './releasenotes.component.html',
  styleUrls: ['./releasenotes.component.scss'],
  providers:[ConfirmationService]
})
export class ReleasenotesComponent implements OnInit {
  confirmCancel = false;
  showLoading: boolean;
  userId = sessionStorage.getItem('userId');
  selectedDate: any;
  AddedRequests:any[];
  cols:any[];
  selectedCars:any[];
  @ViewChild("dt1") table1: Table;
  headerCheckbox:boolean;
  archiveArray: any[] = [];

  cols1:any[];
  requestData = [];
  
  @ViewChild("dt") table: Table;
 
  Request:string="";
  comments:any;
  addDocsDisplay: boolean;
  RequestID:number=0;
  
  AddLogError:string="";
  responseMessage:string="";
  
  constructor(
    private _facadeService: FacadeService,private confirmationService:ConfirmationService,
    private router: Router,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private pipe:DatePipe 
  ) { }
  ngOnInit(): void {
    this.showLoading = true;
    this.selectedDate = new Date();
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '30%' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'status', header: 'Release Type', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'comments', header: 'Note', dynamicPlaceHolder: 'Search', width: '120px' }
    ];
    this.cols1 = [
      { field: 'releaseDate', header: 'Release Date', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '30%' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'requestedByName', header: 'Requested By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'status', header: 'Release Type', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'comments', header: 'Note', dynamicPlaceHolder: 'Search', width: '30%' }
    ];
    this.getRequests();
  }
  
  AllRequests() {
    this.showLoading = true;
    if(this.AddedRequests != null && this.AddedRequests != undefined)
    {
      if(this.AddedRequests.length > 0)
      {
        var Requests = [];
        this.AddedRequests.forEach(element => {
          Requests.push(element.requestID);
        });
        var myRequestsData =
        {
          "requests": Requests,
          "status":[],
          "tasks":[],
          "logBy": [],
          "fromDate":null,
          "toDate":null
        }
        this._facadeService.GetReleaseHistory(myRequestsData).subscribe(
          (data) => {
            this.requestData = data.returnObject;
            if(this.requestData!=undefined && this.requestData != null)
            {
              if(this.requestData.length > 0)
              {
                this.requestData.forEach((value, key) => {   
                  if(value.releaseDate != '' && value.releaseDate != null)
                    value.releaseDate = new Date(new Date(value.releaseDate).toDateString() + " " + new Date(value.releaseDate).toTimeString() + " UTC");
                  if(value.requestDate != '' && value.requestDate != null)
                    value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
                });
              }
            }
            else 
                this.requestData = [];
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        ); 
      }
      else
        this.showLoading = false;
    }
    else
      this.showLoading = false;
  }
  
  getRequests() {
    this._facadeService.GetRequeforRelease(this.pipe.transform(new Date(),"MM/dd/yyyy")).subscribe(
      (data) => {
        this.AddedRequests = data.returnObject;
        this.archiveArray=[];
        if(this.AddedRequests!=undefined && this.AddedRequests != null)
        {
          if(this.AddedRequests.length > 0)
          {
            this.AddedRequests.forEach((value, key) => { 
              value.selected = value.check;
              if(value.check)
                this.archiveArray.push(value.requestID);  
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
            });
          }
        }
        this.AllRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  allChecked(event){
    this.showLoading = true;
    const checked = event.target.ariaChecked;
    this.AddedRequests.forEach(item => item.selected = checked);
    this.archiveArray =[];
    if(checked==null) 
    {
      this.headerCheckbox = false;
      this.AddedRequests.forEach(item => item.check = false);
    }
    else if(checked == 'true')
    {
      this.headerCheckbox = true;
      this.AddedRequests.forEach(item => item.check = true);
      this.AddedRequests.forEach((value, key) => {
        this.archiveArray.push(value.requestID);
      });
    }
    this.showLoading = false;
  }
  selectedId(selectedRow) {
    this.showLoading = true;
    this.headerCheckbox = false;
    this.AddedRequests.forEach(item => item.check = false);

    if (this.archiveArray.some((x) => x == selectedRow.requestID)) {
      this.archiveArray.forEach((value, key) => {
        if(value==selectedRow.requestID)
          this.archiveArray.splice((key), 1);
      });
    } 
    else
      this.archiveArray.push(selectedRow.requestID);
    
    var count: number = 0;
    this.AddedRequests.forEach(item => {
      this.archiveArray.forEach(value => {
        if(value==item.requestID){
          item.check = true;
          count = count + 1;
        }
      });
    });
    if(this.AddedRequests.length == count)
      this.headerCheckbox = true;
    this.showLoading = false;
  }
  Edit(row)
  {
    this.AddLogError = "";
    this.RequestID = row.requestID;
    this.Request = row.code + " : " + row.title;
    this.comments = row.comments;
    this.addDocsDisplay = true;
  }
  AddLog()
  {
    this.AddLogError = "";

    if (this.RequestID == null || this.RequestID == 0 || this.RequestID == undefined)
      this.AddLogError = "Please select Request";
    else if (this.comments == null || this.comments == "" || this.comments == undefined)
      this.AddLogError = "Please enter comments";
    else {
      this.comments = this.comments.replace("<p>", ""); 
      this.comments = this.comments.replace("</p>", ""); 

      this.showLoading = true;
      this.AddedRequests.forEach(x=>{
        if(x.requestID == this.RequestID)
          x.comments = this.comments;
      });
      this.addDocsDisplay = false;
      this.comments = "";
      this.RequestID = 0;
      this.Request = "";
      this.showLoading = false;
    }
  }
  Release()
  {
    
    this.showLoading = true;
    let logs1 = [];
    this.archiveArray.forEach(e => {
      this.AddedRequests.forEach(element => {
        if(e == element.requestID)
        {
          var bpi = {
            "requestID": element.requestID,
            "status": element.status,
            "comments": element.comments
          }
          logs1.push(bpi);
        }
      });
    });

    var UModel = {
      releaseBy: parseInt(this.userId),
      releaseDate: new Date(this.selectedDate),
      requests: logs1
    };
    this._facadeService.AddRelease(UModel).subscribe(
      (data) => {
        if (data.returnCode == 0)
          this.router.navigate(['/releasenotesreport']);
        else
        {
          this.responseMessage = data.returnMessage;
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  SaveLog()
  {
    this.responseMessage = "";
    if (this.selectedDate == null || this.selectedDate == "" || this.selectedDate == undefined) {
      this.responseMessage = "Please select release date";
    }
    else if (this.AddedRequests == null || this.AddedRequests == undefined)
      this.responseMessage = "Please add atleast one request for release";
    else if (this.AddedRequests.length <= 0)
      this.responseMessage = "Please add atleast one request for release";
    else {
      this.headerCheckbox = false;
      
      if(this.archiveArray!=null && this.archiveArray.length>0)
      {
        this.confirmationService.confirm({
          key: "confirm-cancel",
          message: "Are you sure you want to send release notes?",
          accept: () => {
            this.confirmCancel = false;
            this.Release();
          },
        });
        this.confirmCancel = false;
      }
      else
        this.responseMessage = "Please add atleast one request for release";
    }
  }
}
