import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FacadeService } from './facade/facade.service';
import { CONSTANTS } from './app.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  showLoading:boolean;
  constructor(private _facadeService: FacadeService) { } 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authreq = request;
    authreq = this.AddTokenheader(request, sessionStorage.getItem('bt'));
    if(authreq!=undefined){
      console.log("authreq" +JSON.stringify(authreq));
      return next.handle(authreq).pipe(
        catchError(errordata => {
          console.log("authreq next " +JSON.stringify(errordata));
          if (errordata.status === 401) {
            // refresh token logic
            return this.handleRefrehToken(request, next);
          }
          //return throwError(errordata);
        })
      );
    }
  }

  handleRefrehToken(request: HttpRequest<any>, next: HttpHandler) {
    this.showLoading=true;
    let params = {
      Email: sessionStorage.getItem('un'),
      Password: sessionStorage.getItem('pwd')
    };
    console.log("Refresh token " +JSON.stringify(params));
    return this._facadeService.login(params).pipe(
      switchMap((data: any) => {
        this.showLoading=false;
        console.log("Refresh token res" +JSON.stringify(data));
        if (data.returnCode == 0) {
          sessionStorage.bt = data.returnObject.bearer_token;
          sessionStorage.un = data.returnObject.username;
          sessionStorage.pwd = data.returnObject.password;
          return next.handle(this.AddTokenheader(request,data.returnObject.bearer_token));
        }
      }),
      catchError(errodata=>{
        console.log("Refresh token error" +JSON.stringify(errodata));
        this.showLoading=false;
        return throwError(errodata)
      })
    );
  }

  AddTokenheader(request: HttpRequest<any>, token: any) {
    console.log("Request url " + request.url);
    if (request.url.includes("ums")) {
      return request.clone({
        headers: request.headers.set('Authorization', 'bearer ' + token)
          .set('Ocp-Apim-Subscription-Key', CONSTANTS.ums)
      });
    } 
    else if (request.url.includes("plm")) {
      return request.clone({
        headers: request.headers.set('Authorization', 'bearer ' + token)
          .set('Ocp-Apim-Subscription-Key', CONSTANTS.plm)
      });
    }
    else if (request.url.includes("prt")) {
      return request.clone({
        headers: request.headers.set('Authorization', 'bearer ' + token)
          .set('Ocp-Apim-Subscription-Key', CONSTANTS.prt)
      });
    }
    else if (request.url.includes("auth")) {
      return request.clone({ headers: request.headers.set('Ocp-Apim-Subscription-Key', CONSTANTS.authKey) });
    }
  }
}
