import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dailylog',
  templateUrl: './dailylog.component.html',
  styleUrls: ['./dailylog.component.scss'],
  providers:[ConfirmationService]
})
export class DailylogComponent implements OnInit {
  showLoading: boolean;
  userId = sessionStorage.getItem('userId');
  selectedDate: any;
  today :Date;
  AddedRequests:any[];
  cols:any[];
  @ViewChild("dt1") table1: Table;
  responseMessage:string="";
  minimumDate:Date;
  @ViewChild("dt") table: Table;
  cols1:any[];
  requestData = [];
  totalworkhours: any = "0";
  constructor(
    private _facadeService: FacadeService,private confirmationService:ConfirmationService,private pipe:DatePipe,
    private router: Router,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }
  ngOnInit(): void {
    this.showLoading = true;
    this.today = new Date();
    this.selectedDate = new Date();
    let dte = new Date();
    dte.setDate(dte.getDate()-7);
    this.minimumDate = new Date(dte.toString());
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '20%' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '15%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '80px' },
      { field: 'totalWorkHours', header: 'Total spent till Now', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'workHours', header: 'Work Hours', dynamicPlaceHolder: 'Search', width: '70px' },
      { field: 'progressCompleted', header: 'Progress Completed (%)', dynamicPlaceHolder: 'Search', width: '70px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '25%' },
      { field: 'planforTomorrow', header: 'Plan for Tomorrow', dynamicPlaceHolder: 'Search', width: '25%' }
      
    ];
    this.cols1 = [
      { field: 'logDate', header: 'Log Date', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'logByname', header: 'Log By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '20%' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '15%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'totalWorkHours', header: 'Total spent till Now', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'workHours', header: 'Work Hours', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'progressCompleted', header: 'Progress Completed (%)', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'comments', header: 'Comments', dynamicPlaceHolder: 'Search', width: '25%' },
      { field: 'planforTomorrow', header: 'Plan for Tomorrow', dynamicPlaceHolder: 'Search', width: '25%' }
    ];
    sessionStorage.code = "";
    sessionStorage.title = "";
    sessionStorage.taskName = "";
    sessionStorage.estimatedHours = "";
    sessionStorage.totalWorkHours = "";
    sessionStorage.workHours = "";
    sessionStorage.progressCompleted = "";
    sessionStorage.comments = "";
    sessionStorage.planforTomorrow = "";
    this.getRequests();
  }
  SendLog()
  {
    this.showLoading = true;
    this._facadeService.SendLog().subscribe(
      (data) => {
        alert("Send");
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getRequests() {
    var d = this.pipe.transform(new Date(this.selectedDate),"MM/dd/yyyy")
    this._facadeService.GetLogRequest(d,this.userId).subscribe(
      (data) => {
        this.AddedRequests  = data.returnObject;
        if(this.AddedRequests != null && this.AddedRequests != undefined)
        {        
          if(this.AddedRequests.length > 0)
          {
            var total = 0;
            this.AddedRequests.forEach(element => {
              element.name = element.code + " : " + element.title;
              if(Number(element.workHours) > 0)
               total = total + Number(element.workHours);
            });
            this.totalworkhours = Number(total).toFixed(1);
          }
        }
        this.AllRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  AllRequests() {
    this.showLoading = true;
    if(this.AddedRequests != null && this.AddedRequests != undefined)
    {
      if(this.AddedRequests.length > 0)
      {
        var Requests = [];
        var tasks = [];
        this.AddedRequests.forEach(element => {
          Requests.push(element.requestID);
          tasks.push(element.taskID);
        });
        var myRequestsData =
        {
          "requests": Requests,
          "tasks":tasks,
          "logBy": [],
          "fromDate":null,
          "toDate":null
        }
        this._facadeService.GetLogs(myRequestsData).subscribe(
          (data) => {
            this.requestData = data.returnObject;
            if(this.requestData!=undefined && this.requestData != null)
            {
              if(this.requestData.length > 0)
              {
                this.requestData.forEach((value, key) => {   
                  if(value.logDate != '' && value.logDate != null)
                    value.logDate = new Date(new Date(value.logDate).toDateString() + " " + new Date(value.logDate).toTimeString() + " UTC");
                });
              }
            }
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        ); 
      }
      else
        this.showLoading = false;
    }
    else
      this.showLoading = false;
  }
  changeWork(value,taskID)
  {
    this.showLoading = true;
    var total = 0;
    this.AddedRequests.forEach(element => {
      if(element.taskID == taskID)
      {
        if(Number(value) > 0)
          total = total + Number(value);
      }
      else if(Number(element.workHours) > 0)
        total = total + Number(element.workHours);
    });
    this.totalworkhours = Number(total).toFixed(1);
    this.showLoading = false;
  }
  SaveLog()
  {
    this.responseMessage = "";
    if (this.selectedDate == null || this.selectedDate == "" || this.selectedDate == undefined) {
      this.responseMessage = "Please select log date";
    }
    else {
      this.showLoading = true;
      var ok = false;
      let logs1 = [];
      this.AddedRequests.forEach(element => {
        if(Number(element.workHours) > 0)
        {
          var bpi = {
            "requestID": element.requestID,
            "taskID": element.taskID,
            "workHours": Number(element.workHours),
            "progressCompleted": Number(element.progressCompleted),
            "comments": element.comments,
            "planforTomorrow" : element.planforTomorrow
          }
          logs1.push(bpi);
          ok = true;
        }
      });
      if(ok)
      {
        var UModel = {
          logBy: parseInt(this.userId),
          logDate: new Date(this.selectedDate),
          requests: logs1
        };
        this._facadeService.AddLog(UModel).subscribe(
          (data) => {
            if (data.returnCode == 0)
              this.router.navigate(['/dailylogreport']);
            else
            {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        this.responseMessage = "Please add work hours for atleast one task";
        this.showLoading = false;
      }
    }
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "taskName")
      sessionStorage.taskName = val;
    else if (field == "estimatedHours")
      sessionStorage.estimatedHours = val;
    else if (field == "totalWorkHours")
      sessionStorage.totalWorkHours = val;
    else if (field == "workHours")
      sessionStorage.workHours = val;
    else if (field == "progressCompleted")
      sessionStorage.progressCompleted = val;
    else if (field == "comments")
      sessionStorage.comments = val;
    else if (field == "planforTomorrow")
      sessionStorage.planforTomorrow = val;
    dt = dt.filter(val, field, 'contains');
  }
}
