import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FileUpload } from "primeng/fileupload";
import { HttpClient } from "@angular/common/http";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: 'app-addrequests',
  templateUrl: './addrequests.component.html',
  styleUrls: ['./addrequests.component.scss'],
  providers: [ConfirmationService],
})
export class AddrequestsComponent implements OnInit {
  filteredProdType: any[];
  disableconfirm:boolean = false;
  reasondisplay = false;
  confirmCancel = false;
  popupreason: string = "";
  ReasonError: string;
  cancancel: boolean = false;
  today:any;
  lastmonth:any;
  showLoading: boolean;
  internal:boolean = false;
  index: number = 0;
  clientID = parseInt(sessionStorage.getItem('clientId'));
  isClient:boolean = true;
  userId = sessionStorage.getItem('userId');
  applicationID = sessionStorage.getItem("applicationID");
  UserDepartmentID = sessionStorage.getItem("userDepartmentID");
  fullName= sessionStorage.getItem("fullName");
  pageTitle: string = 'Create a Request';
  code:string="New";
  users: any;
  selectedUser: any;
  reqnameError:string="";
  selectedreqDate: any;
  applications: any;
  selectedapplication: any;
  applicationError: any;
  type: SelectItem[];
  selectedtype: any;
  requestTypeError: any;
  priority: SelectItem[];
  selectedPriority: any;
  priorityError: any;
  titleError: any;
  title: any = "";
  description: any;
  addDocsDisplay: boolean;
  ViewAddedDocuments: any[];
  cols: any[];
  responseMessage:string="";
  confirmation:boolean=true;

  // Documents
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;
  DocsError: string;
  apiResponse: any;
  @ViewChild("fileInput") fileInput: FileUpload;
  finalfileInput : File[] = [];
  // @ViewChild("finalfileInput") finalfileInput: FileUpload;
  
  @ViewChild("fubauto") fubauto: FileUpload;

  saveAndSendApprovalDisplay:boolean;
  AreYouSureText:string;
  UserName = sessionStorage.getItem("firstName");
  requestId:number=0;
  constructor(
    private _facadeService: FacadeService,private confirmationS: ConfirmationService,
    private http: HttpClient, private router: Router, private route:ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  filterproductListChange(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.users.length; i++) {
        let product= this.users[i];
        if (product.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredProdType = filtered;
}

  ReasonClear() {
    this.ReasonError = "";
    this.popupreason = "";
  }
  SendCancel() {
    this.confirmationS.confirm({
      key: "confirm-cancel",
      message: "Are you sure you want to cancel this request?",
      accept: () => {
        this.confirmCancel = false;
        this.reasondisplay = true;
        this.popupreason = "";
        this.ReasonError = "";
      },
    });
    this.confirmCancel = false;
  }
  AddReason() {
    this.showLoading = true;
    this.ReasonError = "";
    if (this.popupreason == undefined || this.popupreason == "") {
      this.ReasonError = "Please enter Reason.";
      this.showLoading = false;
    }
    if (
      this.popupreason != undefined &&
      this.popupreason != "" &&
      this.ReasonError == ""
    ) {

      var Model = {
        requestID: this.requestId,
        userID: parseInt(this.userId),
        remarks: this.popupreason,
      };
      this._facadeService.SendCancel(Model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if(this.isClient)
              this.router.navigate(['/myRequests']);
            else
              this.router.navigate(['/reportByStatus']);
          } else {
            this.showLoading = false;
            this.ReasonError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
    // var userguid = sessionStorage.getItem("userGuid");
    // this._facadeService.CreateSecurityToken().subscribe(
    //   (data) => {
    //     this.showLoading = false;
    //     if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
    //       var access_token = data.returnObject;
    //       window.open(this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
    //       "entrypoint?userguid=" +
    //       userguid +
    //       "&userId=" +
    //       this.userId +
    //       '&destination=docdetails'+'&access_token='+access_token+"&docId="+docId);
    //     }
    //     else{
    //       location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
    //     }
    //   },
    //   (error) => {
    //     this.showLoading = false;
    //     console.log(error);
    //   } 
    // );
  }
  ngOnInit(): void {
    this.showLoading = true;
    this.today = new Date();
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);
    this.lastmonth = new Date(dte.toString());

    if(this.clientID != 1)
    {
      this.isClient = false;
      this.confirmation = false;
      this.internal = true;
    }
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value:'Issue'},
      { label: 'Support', value: 'Support' }
    ];
    this.selectedtype = this.type.find(x=>x.value == "Enhancement / Change");
    this.priority = [
      { label: 'Critical', value: 'Critical' },
      { label: 'High', value: 'High' },
      { label: 'Medium', value: 'Medium' },
      { label: 'Low', value: 'Low' }
    ];
    this.selectedPriority = this.priority.find(x=>x.value == "Medium");

    this.selectedreqDate = new Date();
    // var a = this.route.snapshot.paramMap.get('requestID');
    this.requestId = sessionStorage.getItem("requestID") != null ? parseInt(sessionStorage.getItem("requestID")) : 0;
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.UpdateCon("",true);
    // this.getUsers();
  }
  UpdateCon(userid,BindApp)
  {
    if(this.internal)
    {
      this.confirmation = false;
      this.disableconfirm = true;
      this.getUsers(userid,true,BindApp);
    }
    else{
      this.confirmation = true;
      this.disableconfirm = false;
      this.getUsers(userid,false,BindApp);
    }
  }
  getIssueDetailsById(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestId).subscribe(
      (data) => {
        var issueDetails = data.returnObject;
                  
        if(issueDetails!=null){      
          this.code =  issueDetails.code;
          this.selectedreqDate = new Date(issueDetails.requestDate+'Z');
          this.internal = issueDetails.isInternal;
          this.UpdateCon(issueDetails.requestedBy,false);
          this.selectedapplication = this.applications.find(m => m.applicationId == issueDetails.applicationId);
          this.selectedtype = this.type.find(x=>x.value == issueDetails.type);
          this.selectedPriority = this.priority.find(x=>x.value == issueDetails.priority);
          this.description = issueDetails.description;
          this.title = issueDetails.title;
          this.ViewAddedDocuments = issueDetails.documents;
          
          if(issueDetails.status != 'Production' && issueDetails.status != 'Closed' && issueDetails.status != 'Cancelled')
          {
            if(issueDetails.requestedBy == parseInt(this.userId))
              this.cancancel = true;
          }
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null){
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
        }
        this.showLoading=false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getUsers(userid,support,BindAPP) {
    if(!support)
    {
      this._facadeService.getUsers().subscribe(
        (data) => {
          if(data.returnObject != undefined && data.returnObject != null)
          {
            data.returnObject.forEach(element => {
              element.fullName = element.firstName + " " + element.lastName;
            });
            this.users = data.returnObject;
            if(userid == "")
              this.selectedUser = this.users.find(m => m.userId == parseInt(this.userId));
            else 
              this.selectedUser = this.users.find(m => m.userId == userid);
            if(BindAPP)
              this.getApplications();
            else
              this.showLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.showLoading = false;
        }
      );
    }
    else
    {
      this._facadeService.getSupportUsers().subscribe(
        (data) => {
          if(data.returnObject != undefined && data.returnObject != null)
          {
            data.returnObject.forEach(element => {
              element.fullName = element.firstName + " " + element.lastName;
            });
            this.users = data.returnObject.filter(x=>x.active == "Y");
            if(userid == "")
              this.selectedUser = this.users.find(m => m.userId == parseInt(this.userId));
            else 
              this.selectedUser = this.users.find(m => m.userId == userid);
            if(BindAPP)
              this.getApplications();
            else
              this.showLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.showLoading = false;
        }
      );
    }
  }

  getApplications() {
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.applications = data.returnObject;
        var all = this.applications.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            this.applications.push(a);
        }
        if(this.userId!=null && this.userId!= undefined)
        {
          var userapp = JSON.parse(sessionStorage.getItem('applications'))
          if(userapp != null && userapp != undefined)
          {
            var allapps = this.applications;
            this.applications = [];
            userapp.forEach(element => {
              var a = allapps.find(x=>x.applicationGuid.toLowerCase() == element.id.toLowerCase());
              if(a != null)
                this.applications.push(a);
            });
            this.applications.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
            var defaultApplication = sessionStorage.getItem('defaultApplication');
            this.selectedapplication = this.applications.find(m => m.applicationName == defaultApplication);
          }
          if(this.requestId != null && this.requestId != undefined && this.requestId != 0)
            this.getIssueDetailsById();
          else
            this.showLoading = false;
        }
        else
        {
          if(this.requestId != null && this.requestId != undefined && this.requestId != 0)
            this.getIssueDetailsById();
          else
            this.showLoading = false;
        }
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  AddNewButton() {
    this.addDocsDisplay = true;
  }

  saveAndSendApprovalClick(){
    this.showLoading = false;
    this.saveAndSendApprovalDisplay=true;
    this.AreYouSureText="Do you want to send this request for confirmation?";
  }
  onFileUpload(event) {
    this.DocsError = "";
    let fileSize = event.files[0].size;
    if (fileSize <= 10) {
     this.DocsError = "File size should be greater than 1KB";
    }
}
  createDoc(action) {
    this.showLoading = true;
    this.reqnameError = "";
    this.applicationError = "";
    this.requestTypeError = "";
    this.priorityError = "";
    this.titleError = "";
    this.responseMessage = "";
    if (this.selectedUser == "" || this.selectedUser == null || this.selectedUser == undefined || this.selectedUser.length == 0) {
      this.showLoading = false;
      this.reqnameError = "Please select requested by";
    }
    else if (this.selectedapplication == "" || this.selectedapplication == null || this.selectedapplication == undefined ) {
      this.showLoading = false;
      this.applicationError = "Please select application";
    }   
    else if (this.selectedtype == "" || this.selectedtype == null || this.selectedtype == undefined ) {
      this.showLoading = false;
      this.requestTypeError = "Please select request type";
    }   
    else if (this.selectedPriority == "" || this.selectedPriority == null || this.selectedPriority == undefined ) {
      this.showLoading = false;
      this.priorityError = "Please select priority";
    }   
    else if (this.title == "" || this.title == null || this.title == undefined ) {
      this.showLoading = false;
      this.titleError = "Please enter short description";
    }
    else if(!this.isClient && this.internal && this.confirmation)
    {
      this.showLoading = false;
      this.titleError = "If request is internal then Confirmation is not required";
    }
    else if(action === "saveAndSendApproval"){
      this.showLoading = true;
        this.saveAndSendApprovalClick();
    }
    else {
      var status = "";
      if(!this.isClient)
      {
        if(this.confirmation)
          status = "Pending Confirmation";
        else
          status = "New";
      }
      if(this.description == null || this.description == undefined)
        this.description = "";
      this.description = this.description.replace("<p>", ""); 
      this.description = this.description.replace("</p>", ""); 

      var requestData = {
        id: this.requestId,
        isInternal: this.isClient ? false : this.internal,
        code: this.code,
        title: this.title,
        description: this.description,
        type: this.selectedtype.value,
        requestDate: this.selectedreqDate,
        requestedBy: this.selectedUser.userId,
        applicationId: this.selectedapplication.applicationId,
        priority: this.selectedPriority.value,
        status: status != "" ? status : (action=="confirm"?"Pending Confirmation":"Saved"),
        createdBy: parseInt(this.userId),
        notes: [],
        documents: []
      }
      if(this.requestId != 0 && this.requestId != null && this.requestId != undefined)
      {
        this._facadeService.UpdateRequest(requestData).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              this.saveAndSendApprovalDisplay=false;
              this.AddDocs1();
            }
            else {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        this._facadeService.CreateRequest(requestData).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              this.saveAndSendApprovalDisplay=false;
              this.requestId = data.returnObject.id;
              this.AddDocs1();
            }
            else {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }

  AddDocs(){
    this.showLoading = true;
    this.DocsError = "";
    let ok = true;
    if (this.fileInput == undefined || this.fileInput.files.length == 0) {
      ok = false;
      this.DocsError = "Please select atleast one file.";
    }
    if(ok)
    {
      this.fileInput.files.forEach((file) => {
        let fileSize = file.size
        if (fileSize <= 10 && ok) {
        this.DocsError = "File size should be greater than 1KB";
        ok = false;
        }
      });
    }
    if (ok) 
    {
        if(this.ViewAddedDocuments==undefined || this.ViewAddedDocuments == null)
          this.ViewAddedDocuments = [];
        this.fileInput.files.forEach((file) => {
          this.finalfileInput.push(file);
          var data = {
            "requestID" : this.requestId,
            "addedDate": new Date(),
            "addedby": parseInt(this.userId),
            "addedbyName": this.fullName,
            "fileName":file.name
          }
          this.ViewAddedDocuments.push(data);
        });
        this.addDocsDisplay = false;
        if (this.fileInput != undefined) {
          if (this.fileInput.files.length > 0) {
            this.fileInput.clear();
          }
        }
        this.showLoading = false;
    }
    else
        this.showLoading = false;
  }

  AddDocs1(){
    if(this.finalfileInput != null && this.finalfileInput != undefined)
    {
      if(this.finalfileInput.length > 0)
      {
        this.showLoading = true;
        const formData = new FormData();
        formData.append("requestID", this.requestId.toString());
        formData.append("applicationID", this.applicationID);
        formData.append("departmentID", this.UserDepartmentID);
        formData.append("addedBy", this.userId);
        this.finalfileInput.forEach((file) => {
          formData.append("files", file);
        });
        this._facadeService.addDocuments(formData)
        .subscribe(
          (data) => {
            console.log(data);
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.showLoading = false;
              if(this.isClient)
                this.router.navigate(['/myRequests']);
              else
                this.router.navigate(['/reportByStatus']);
            } else {
              this.showLoading = false;
              this.responseMessage = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        if(this.isClient)
          this.router.navigate(['/myRequests']);
        else
          this.router.navigate(['/reportByStatus']);
      }
    }
    else
    {
      if(this.isClient)
        this.router.navigate(['/myRequests']);
      else
        this.router.navigate(['/reportByStatus']);
    }
  }


}
