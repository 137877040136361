import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';

@Component({
  selector: 'app-assigned-to-me',
  templateUrl: './assigned-to-me.component.html',
  styleUrls: ['./assigned-to-me.component.scss']
})
export class AssignedToMeComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  selectedCFromDate: any = "";
  selectedCToDate: any = "";

  apps: any[];
  selectedApplications: any;
  selectedCApplications: any;
  type: SelectItem[];
  selectedtype: any;
  selectedCtype: any;
  priority: SelectItem[];
  selectedPriority: any;
  selectedCPriority: any;
  status: SelectItem[];
  status1: SelectItem[];
  selectedStatus: any;
  selectedCStatus: any;
  internalstatus: SelectItem[];
  selectedinternalStatus: any;
  selectedCinternalStatus: any;
  cols: any[];
  cols1: any[];
  cols2: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  requestData = [];
  DocumentsCount: number = 0;

  @ViewChild("dtPending") tablePending: Table;
  TasksPending = [];
  PendingCount: number = 0;
  @ViewChild("dtCompleted") tableCompleted: Table;
  TasksCompleted = [];
  CompletedCount: number = 0;

  dynamicPlaceHolder: string;
  Error: string = "";
  index: number = 0;
  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.selectedCToDate = new Date();
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);
    this.selectedCFromDate = new Date(dte.toString());
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value: 'Issue' },
      { label: 'Support', value: 'Support' }
    ];
    this.selectedtype = [];this.selectedCtype = [];
    this.type.forEach(element => {
      this.selectedtype.push(element);
      this.selectedCtype.push(element);
    });
    this.priority = [
      { label: 'Critical', value: 'Critical'},
      { label: 'High', value:'High' },
      { label: 'Medium', value: 'Medium'},
      { label: 'Low', value: 'Low' }
    ];
    this.selectedPriority = [];  this.selectedCPriority = [];
    this.priority.forEach(element => {
      this.selectedPriority.push(element);
      this.selectedCPriority.push(element);
    });

    this.status = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'Hold', value: 'Hold' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
    ];
    this.status1 = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Closed', value: 'Closed' },
      { label: 'Cancelled', value: 'Cancelled' },
    ];

    this.selectedStatus = [];  this.selectedCStatus = [];
    this.status.forEach(element => {
      this.selectedStatus.push(element);
      this.selectedCStatus.push(element);
    });

    this.internalstatus = [
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'In-Testing', value: 'In-Testing' },
      { label: 'UAT', value: 'UAT' },
      { label: 'UAT-Rework', value: 'UAT-Rework' },
      { label: 'UAT-Bug', value: 'UAT-Bug' },
      { label: 'Production', value: 'Production' },
    ];
    let ok = false;
    if(sessionStorage.getItem('dashboardstatus') != null && sessionStorage.getItem('dashboardstatus') != undefined)
    {
      if(sessionStorage.getItem('dashboardstatus') != 'null')
      {
        this.selectedinternalStatus = [];
        var projectstats = [];
        projectstats = sessionStorage.getItem('dashboardstatus').split(',');
        projectstats.forEach((element) => {
          var st = this.internalstatus.find(x=>x.value.toLowerCase() == element.toLowerCase());
          if(st != null)
            this.selectedinternalStatus.push(st);
        });
        ok = true;
        sessionStorage.dashboardstatus = null;
      }
    }
    if(!ok)
    {
      this.selectedinternalStatus = [];
      this.internalstatus.forEach(e => {
        this.selectedinternalStatus.push(e);
      });
    }
    this.selectedCinternalStatus = [];
    this.internalstatus.forEach(e => {
      this.selectedCinternalStatus.push(e);
    });
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'ownerName', header: 'Request Owner', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'internalStatus', header: 'Internal Status', dynamicPlaceHolder: 'Search', width: '130px' },
    ];
    this.cols1 = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'taskSeq', header: 'Task Seq', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'totalWorkHours', header: 'Worked Hours', dynamicPlaceHolder: 'Search', width: '130px' }
    ];

    this.cols2 = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'taskSeq', header: 'Task Seq', dynamicPlaceHolder: 'Search', width: '90px' },
      { field: 'taskName', header: 'Task', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'estimatedHours', header: 'Estimated Hours', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'totalWorkHours', header: 'Worked Hours', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'startDate', header: 'Start Date', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'endDate', header: 'End Date', dynamicPlaceHolder: 'Search', width: '130px' }
    ];

    
    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.requestedByName = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.ownerName = "";
    sessionStorage.status = "";
    sessionStorage.internalStatus = "";

    sessionStorage.code1 = "";
    sessionStorage.requestDate1 = "";
    sessionStorage.requestedByName1 = "";
    sessionStorage.title1 = "";
    sessionStorage.taskSeq1 = "";
    sessionStorage.taskName1 = "";
    sessionStorage.estimatedHours1 = "";
    sessionStorage.totalWorkHours1 = "";

    sessionStorage.code2 = "";
    sessionStorage.requestDate2 = "";
    sessionStorage.requestedByName2 = "";
    sessionStorage.title2 = "";
    sessionStorage.taskSeq2 = "";
    sessionStorage.taskName2 = "";
    sessionStorage.estimatedHours2 = "";
    sessionStorage.totalWorkHours2 = "";

    this.index = 0;
    if(this.route.snapshot.paramMap.get('index')!=undefined)
    {
      var ii = this.route.snapshot.paramMap.get('index');
      if(ii != '0' && ii != '')
        this.index = Number(ii);
    }

    this.getApplications();
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "owner")
      sessionStorage.ownerName = val;
    else if (field == "internalStatus")
      sessionStorage.internalStatus = val;
    else if (field == "status")
      sessionStorage.status = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }
  
  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.requestedByName != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.type != "")
          temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
          temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
        if (sessionStorage.ownerName != "")
          temp = temp.filter(x => x.ownerName == null ? '' : x.ownerName.toLowerCase().includes(sessionStorage.ownerName.toLowerCase()));
        if (sessionStorage.internalStatus != "")
          temp = temp.filter(x => x.internalStatus == null ? '' : x.internalStatus.toLowerCase().includes(sessionStorage.internalStatus.toLowerCase()));
        if (sessionStorage.status != "")
          temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  test1(val, field, dt) {
    if (field == "code")
      sessionStorage.code1 = val;
    else if (field == "requestDate")
      sessionStorage.requestDate1 = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName1 = val;
    else if (field == "title")
      sessionStorage.title1 = val;
    else if (field == "taskSeq")
      sessionStorage.taskSeq1 = val;
    else if (field == "taskName")
      sessionStorage.taskName1 = val;
    else if (field == "estimatedHours")
      sessionStorage.estimatedHours1 = val;
    else if (field == "totalWorkHours")
      sessionStorage.totalWorkHours1 = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData1();
  }
  
  CountData1() {
    if (this.TasksPending != undefined && this.TasksPending != null) {
      var temp = this.TasksPending;
      if (this.TasksPending.length > 0) {
        if (sessionStorage.code1 != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code1.toLowerCase()));
        if (sessionStorage.requestDate1 != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate1.toLowerCase()));
        if (sessionStorage.requestedByName1 != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName1.toLowerCase()));
        if (sessionStorage.title1 != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title1.toLowerCase()));
        if (sessionStorage.taskSeq1 != "")
          temp = temp.filter(x => x.taskSeq.includes(sessionStorage.taskSeq1));
        if (sessionStorage.taskName1 != "")
          temp = temp.filter(x => x.taskName.toLowerCase().includes(sessionStorage.taskName1.toLowerCase()));
        if (sessionStorage.estimatedHours1 != "")
          temp = temp.filter(x => x.estimatedHours.includes(sessionStorage.estimatedHours1));
        if (sessionStorage.totalWorkHours1 != "")
          temp = temp.filter(x => x.totalWorkHours.includes(sessionStorage.totalWorkHours1));
      }
      this.PendingCount = temp.length;
    }
    else
      this.PendingCount = 0;
  }
  test2(val, field, dt) {
    if (field == "code")
      sessionStorage.code2 = val;
    else if (field == "requestDate")
      sessionStorage.requestDate2 = val;
    else if (field == "requestedByName")
      sessionStorage.requestedByName2 = val;
    else if (field == "title")
      sessionStorage.title2 = val;
    else if (field == "taskSeq")
      sessionStorage.taskSeq2 = val;
    else if (field == "taskName")
      sessionStorage.taskName2 = val;
    else if (field == "estimatedHours")
      sessionStorage.estimatedHours2 = val;
    else if (field == "totalWorkHours")
      sessionStorage.totalWorkHours2 = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData2();
  }
  
  CountData2() {
    if (this.TasksCompleted != undefined && this.TasksCompleted != null) {
      var temp = this.TasksCompleted;
      if (this.TasksCompleted.length > 0) {
        if (sessionStorage.code2 != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code2.toLowerCase()));
        if (sessionStorage.requestDate2 != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate2.toLowerCase()));
        if (sessionStorage.requestedByName2 != "")
          temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName2.toLowerCase()));
        if (sessionStorage.title2 != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title2.toLowerCase()));
        if (sessionStorage.taskSeq2 != "")
          temp = temp.filter(x => x.taskSeq.includes(sessionStorage.taskSeq2));
        if (sessionStorage.taskName2 != "")
          temp = temp.filter(x => x.taskName.toLowerCase().includes(sessionStorage.taskName2.toLowerCase()));
        if (sessionStorage.estimatedHours2 != "")
          temp = temp.filter(x => x.estimatedHours.includes(sessionStorage.estimatedHours2));
        if (sessionStorage.totalWorkHours2 != "")
          temp = temp.filter(x => x.totalWorkHours.includes(sessionStorage.totalWorkHours2));
      }
      this.CompletedCount = temp.length;
    }
    else
      this.CompletedCount = 0;
  }
  getApplications() {
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.selectedApplications = [];
        this.apps = data.returnObject;
        var all = this.apps.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            this.apps.push(a);
          this.apps.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
        }

        this.apps.forEach(element => {
          this.selectedApplications.push(element);
        });
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getMyRequests() {
    this.showLoading = true;
    this.Error = "";
    if (this.selectedApplications == null || this.selectedApplications == undefined || this.selectedApplications.length == 0)
      {this.Error = "Please select atleast one application";this.showLoading = false;}
    else if (this.selectedtype == null || this.selectedtype == undefined || this.selectedtype.length == 0)
     { this.Error = "Please select atleast one type";this.showLoading = false;}
    else if (this.selectedPriority == null || this.selectedPriority == undefined || this.selectedPriority.length == 0)
      {this.Error = "Please select atleast one priority";this.showLoading = false;}
    else if (this.selectedStatus == null || this.selectedStatus == undefined || this.selectedStatus.length == 0)
      {this.Error = "Please select atleast one status";this.showLoading = false;}
    else if (this.selectedinternalStatus == null || this.selectedinternalStatus == undefined || this.selectedinternalStatus.length == 0)
      {this.Error = "Please select atleast one internal status";this.showLoading = false;}
    else if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "End Date should be greater then start date";
      this.selectedToDate = this.selectedFromDate;
      this.showLoading = false;
    }
    else {
      var apps = [];
      if (this.selectedApplications != null && this.selectedApplications != undefined) {
        if (this.selectedApplications.length > 0) {
          this.selectedApplications.forEach(element => {
            apps.push(element.applicationId);
          });
        }
      }
      var types = [];
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.value);
          });
        }
      }
      var priority = [];
      if (this.selectedPriority != null && this.selectedPriority != undefined) {
        if (this.selectedPriority.length > 0) {
          this.selectedPriority.forEach(element => {
            priority.push(element.value);
          });
        }
      }
      var status = [];
      if (this.selectedStatus != null && this.selectedStatus != undefined) {
        if (this.selectedStatus.length > 0) {
          this.selectedStatus.forEach(element => {
            status.push(element.value);
          });
        }
      }
      var Instatus = [];
      if (this.selectedinternalStatus != null && this.selectedinternalStatus != undefined) {
        if (this.selectedinternalStatus.length > 0) {
          this.selectedinternalStatus.forEach(element => {
            Instatus.push(element.value);
          });
        }
      }
      var Assignee = [];
      Assignee.push(parseInt(this.userId));
      var myRequestsData =
      {
        "type": types,
        "status": status,
        "application": apps,
        "priority": priority,
        "requestedBy": [],
        "assignTo": Assignee,
        "internalStatus": Instatus,
        "requestOwner": [],
        "userID": 0,
        "includeInternal" : true
      }
      this._facadeService.MyRequests(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
          {
            this.requestData.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");             
            });

            if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) >= new Date(this.selectedFromDate));
            }
            if (this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) <= new Date(this.selectedToDate));
            }
          }
          this.CountData();
          this.GetTasks(true);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  GetTasks(pending) {
    this.showLoading = true;
    this._facadeService.GetMyTasks(this.userId,pending).subscribe(
      (data) => {
        if(pending)
        {
          this.TasksPending = data.returnObject;
          if(this.TasksPending!=undefined && this.TasksPending != null && this.TasksPending.length > 0)
          {
            this.TasksPending.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");             
            });
          }
          this.CountData1();
          this.GetTasks(false);
        }
        else
        {
          this.TasksCompleted = data.returnObject;
          if(this.TasksCompleted!=undefined && this.TasksCompleted != null && this.TasksCompleted.length > 0)
          {
            this.TasksCompleted.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");             
            });
          }
          this.CountData2();
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  ViewMyRequest(rowdata) {
    if(rowdata.id == null || rowdata.id == undefined)
      sessionStorage.requestID = rowdata.requestID;
    else
      sessionStorage.requestID = rowdata.id;
    this.router.navigate(['/clientRequestsDetails']);
  }

  print(ID,Title,Search) {
    var Content = document.getElementById(ID).innerHTML;
    this.printService.Print(Content,Title,Search,true);
  }
  exportExcel(ID,Title) {
    /* table id is passed over here */
    let element = document.getElementById(ID);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1,ID);
    if(ID == 'excel-table')
      ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 120 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 120 },{ wpx : 135 },{ wpx : 135 }];
    else
      ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 120 },{ wpx : 400 },{ wpx : 60},{ wpx : 400 },{ wpx : 100 },{ wpx : 100 }];
    /* save to file */
    XLSX.writeFile(wb, Title + ".xlsx");
  }
  delete_row(ws, row_index,ID) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    if(ID == 'excel-table')
      delete (ws['J1'])
    else
      delete (ws['I1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

}
