<div class="p-grid table-demo">
    <div class="p-col-12">
     <div class="card">
         <p-tabView [activeIndex]="index">
   <p-tabPanel leftIcon="pi pi-sign-in">
    <ng-template pTemplate="header">
      To Confirm ({{ Count }})
   </ng-template>
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px ;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">To Confirm</h5> 
        <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
          <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
          <input pInputText type="text" (input)="CountGlobalFilter($event.target.value,dtactive)"
          placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content','trSearch')" ></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download('excel-table')"></button>
        </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">
    <p-table  (sortFunction)="customSort($event)" [customSort]="true" #dtactive [value]="activeproducts" id="excel-table" [columns]="cols"  [loading]="loading" [autoLayout]="true"
    styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [scrollable]="true" scrollHeight="550px"
    [globalFilterFields]="['code','requestDate','title','type','priority','requestedByName','applicationName']"> 
       <ng-template pTemplate="header" let-columns class="invoice-items">
        <tr>              
          <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
          [ngClass]="{'width110': i==0,'width140':i==1, 'width40':i==2,'width180': i == 3,'width100':i==4 || i ==7,'width150':i==5,'width20':i==6}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
          <th style="text-align: center;width: 80px;">Actions</th>
        </tr>
        <tr id="trSearch">
          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
            <input pInputText type="text" (input)="CountFilter($event.target.value,col.field,dtactive)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px ;"/>
        </th>
          <th style="text-align: center"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
        <tr [pSelectableRow]="rowData">
          <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
            <td *ngIf="i == 0" style="text-align: center;width: 110px">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 1" style="text-align: center;width: 140px;">{{ rowData[col.field]| date:'MM/dd/yyyy'}}</td>
            <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 40%;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 180px;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 4" style="text-align: center; width: 100px;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 5" style="text-align:left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 6" style="text-align:left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
            <td *ngIf="i == 7" style="text-align:left; padding-left:15px;width: 100px">{{ rowData[col.field] }}</td>
          </ng-container>
          <td style="text-align: center;width: 80px;">
            <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="ViewMyRequest1(rowData,0)"
              icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="9" style="text-align: left;">No records found...</td>
        </tr>
    </ng-template>

         </p-table>
    </div>
     </p-tabPanel>
     <p-tabPanel leftIcon="pi pi-sign-in">
      <ng-template pTemplate="header">
        For Clarification ({{ CountS }})
     </ng-template>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px ;">
          <h5 style="color:#495057; padding:15px 0 0 15px;">For Clarification</h5> 
          <span style="float: right;">
            <table><tr><td style="background-color: lightcoral;width: 25px;"></td><td>Pending Response</td>
              <td style="background-color: lightgreen; width: 25px;"></td><td>Responded &nbsp;&nbsp;</td>
              </tr></table>
          </span>
          <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
            <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
            <input pInputText type="text" (input)="CountGlobalFilterS($event.target.value,dtseek)"
            placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content2','trSearch2')" ></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download('excel-table2')"></button>
          </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content2">
      <p-table #dtseek (sortFunction)="customSort($event)" [customSort]="true" [value]="seekproducts" id="excel-table2" [columns]="cols"  [loading]="loading" [autoLayout]="true"
      styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [scrollable]="true" scrollHeight="550px"
      [globalFilterFields]="['code','requestDate','title','type','priority','requestedByName','applicationName']"> 
         <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>              
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
            [ngClass]="{'width110': i==0,'width140':i==1, 'width40':i==2,'width180': i == 3,'width100':i==4 || i== 7,'width150':i==5,'width20':i==6}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
            <th style="text-align: center;width: 80px;">Actions</th>
          </tr>
          <tr id="trSearch2">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
              <input pInputText type="text" (input)="CountFilterS($event.target.value,col.field,dtseek)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px ;"/>
          </th>
            <th style="text-align: center"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
          <tr [pSelectableRow]="rowData" [style.background]="rowData.confirmedbyName == 'N' ? 'lightcoral' : 'lightgreen' ">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
              <td *ngIf="i == 0" style="text-align: center;width: 110px">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 1" style="text-align: center;width: 140px;">{{ rowData[col.field]| date:'MM/dd/yyyy'}}</td>
              <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 40%;">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 180px;">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 4" style="text-align: center; width: 100px;">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 5" style="text-align:left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 6" style="text-align:left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
              <td *ngIf="i == 7" style="text-align:left; padding-left:15px;width: 100px">{{ rowData[col.field] }}</td>
            </ng-container>
            <td style="text-align: center;width: 80px;">
              <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="ViewMyRequest1(rowData,1)"
                icon="pi pi-eye" class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="9" style="text-align: left;">No records found...</td>
          </tr>
      </ng-template>
  
           </p-table>
      </div>
       </p-tabPanel>
     <p-tabPanel leftIcon="pi pi-upload">
      <ng-template pTemplate="header">
        Confirmed / Cancelled by Me ({{ Count1 }})
     </ng-template>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Confirmed / Cancelled by Me </h5> 
            <span class="p-input-icon-left" style="float:right; margin-top: 7px;">
              <i class="pi pi-search" style="margin-top:-0.7rem;"></i>
              <input pInputText type="text" (input)="Count1GlobalFilter($event.target.value,dtcomplete)"
              placeholder="Search..." style="margin-top: 2px;margin-bottom:10px;" />
                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;width:25px; height:25px;margin-top: 7px;" (click)="print('invoice-content1','trSearch1')" ></button>
                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:15px; width:25px; height:25px;margin-top: 7px;" (click)="download('excel-table1')"></button>
            </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content1">
        <p-table  (sortFunction)="customSort($event)" [customSort]="true" #dtcomplete [value]="completedproducts" id="excel-table1"  [columns]="cols1" [loading]="loading" [autoLayout]="true"
        styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple" [scrollable]="true" scrollHeight="550px"
        [globalFilterFields]="['code','requestDate','title','type','priority','requestedByName','applicationName','confirmedDate']">
           <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>              
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
              [ngClass]="{'width110': i==0,'width140':i==1 || i == 7, 'width40':i==2,'width180': i == 3,'width100':i==4 || i ==8,'width150':i==5,'width20':i==6}" >{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
              <th style="text-align: center;width: 100px;">Actions</th>
            </tr>
            <tr id="trSearch1">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                <input pInputText type="text" (input)="Count1Filter($event.target.value,col.field,dtcomplete)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:25px;"/>
            </th>
              <th style="text-align: center"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: center;width: 110px">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 1" style="text-align: center;width: 140px;">{{ rowData[col.field]| date:'MM/dd/yyyy'}}</td>
                <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 40%;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 180px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 4" style="text-align: center; width: 100px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 5" style="text-align:left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 6" style="text-align:left; padding-left:15px;width: 20%;">{{ rowData[col.field] }}</td>
                <td *ngIf="i == 7" style="text-align: center;width: 140px;">{{ rowData[col.field]| date:'MM/dd/yyyy'}}</td>
                <td *ngIf="i == 8" style="text-align:left; padding-left:15px;width: 100px">{{ rowData[col.field] }}</td>
              </ng-container>
              <td style="text-align: center;width: 100px;">
                <button pButton type="button" pTooltip="View" tooltipPosition="right" (click)="ViewMyRequest(rowData)"
                icon="pi pi-eye"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10" style="text-align: left;">No records found...</td>
            </tr>
        </ng-template>    
             </p-table>
        </div>
         </p-tabPanel>
         </p-tabView>
     </div>
 </div>
 </div>

 <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>