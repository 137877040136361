<div class="p-grid  p-fluid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12" style="text-align: center">
            <h4 style="margin-top: 5px"> Log Work</h4>
          </div>
        </div>
        <hr  style="padding-top: 0px; margin-top: 0px;"/>
        <div class="p-grid p-formgrid">
            <div class="p-field p-col-12 p-md-4"></div>
            <div class="p-field p-col-12 p-md-1" style="float: right; padding-right:5px; padding-top: 7px;"> <label>Log Date<span style="color: red">*</span></label></div>
            <div class="p-field p-col-12 p-md-3">
                <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="selectedDate" [maxDate]="today"[minDate]="minimumDate"
                (onSelect)="getRequests()" (onClickOutside)="getRequests()" ></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2"></div>
            <div class="p-field p-col-12 p-md-2">
                <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Send Daily Log"
                (click)="SendLog()" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width:auto; float:right;"></button>   
            </div>
            <div class="p-field p-col-12 p-md-12">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                    <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Work Request Logs</h5>
                </div>
                <div class="invoice invoice-header" id="invoice-content1" style="overflow-x: auto;">
                    <p-table #dt1 [value]="AddedRequests" id="excel-table1" [columns]="cols" sortMode="multiple" datakey="taskID"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [scrollable]="true" scrollHeight="400px">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test($event.target.value,col.field,dt1)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr >
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: center;width: 110px;"> {{ rowData[col.field]}}</td>
                                    <td *ngIf="i == 1" style="text-align: left; padding-left: 15px; width: 20%;">{{rowData[col.field]}}</td>
                                    <td *ngIf="i == 2" style="text-align: left; padding-left: 15px; width: 15%;">{{rowData[col.field]}}</td>
                                    <td *ngIf="i == 3" style="text-align: center; width: 80px;">{{rowData[col.field]}}</td>
                                    <td *ngIf="i == 4" style="text-align: center; width: 90px;">{{rowData[col.field]}}</td>
                                    <td *ngIf="i == 5" style="text-align: center; width: 70px;">
                                        <p-inputNumber mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="1" [min] = "0" [max]="24"   maxlength="4"
                                        (onInput)="changeWork($event.value,rowData.taskID)"
                                        [(ngModel)]="rowData.workHours" placeholder="Enter Work Hours"></p-inputNumber>
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center; width: 70px;">
                                        <p-inputNumber mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" maxlength="3"  [min] = "0" [max]="100"
                                        [(ngModel)]="rowData.progressCompleted" placeholder="Progress Completed"></p-inputNumber>
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: center;width: 25%;">
                                        <input type="text" pInputText placeholder="Comments" [(ngModel)]="rowData.comments" maxlength=500 />
                                    </td>
                                    <td *ngIf="i == 8" style="text-align: center;width: 25%;">
                                        <input type="text" pInputText placeholder="Plan for Tomorrow" [(ngModel)]="rowData.planforTomorrow" maxlength=500 />
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="5"></td>
                                <td><b>Total:{{totalworkhours }} </b></td>
                                <td colspan="3"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr><td colspan="8" style="text-align: left;">No records found...</td></tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="p-col-12" style="float:right;">
                <hr>
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                (click)="SaveLog()" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width:auto; float:right;"></button>        
            </div>
            <div class="p-field p-col-12 p-md-8"><small class="p-invalid">{{responseMessage}}</small></div>
        </div>
          <div class="p-grid p-formgrid">
              <div class="p-field p-col-12 p-md-12">
                  <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                      <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Task Work Log History</h5>
                  </div>
                  <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                    <p-table #dt [value]="requestData" id="excel-table" [columns]="cols1" sortMode="multiple"
                        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                        dataKey="taskID">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                        (input)="dt.filter($event.target.value, col.field, 'contains')"
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                     <td *ngIf="i == 0" style="text-align: center;width:100px;">
                                        {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width:150px;">
                                        {{ rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: center;width:110px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width:20%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width:15%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 5" style="text-align: center;width:140px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 6" style="text-align: center;width:120px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 7" style="text-align: center;width:120px;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 8" style="text-align: left;padding-left:15px;width: 25%;">
                                        {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 9" style="text-align: left;padding-left:15px;width: 25%;">
                                        {{rowData[col.field]}}
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="11" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    </p-table>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  <div
    style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
  </div>