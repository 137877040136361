<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
        <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align: center">
          <h4 style="margin-top: 5px">Manage Tasks</h4>
        </div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
      </div>
      <hr />
      <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;">
        <div class="p-col-12 p-md-6">
          <label>Owner<span style="color: red">*</span></label>
          <p-dropdown [options]="users" [(ngModel)]="selectedUser" required="true" placeholder="Select Owner" optionLabel="fullName"
            requiredMessage="The input must not be empty" (onChange)="GetRequests()"
          ></p-dropdown>
      </div>

        <div class="p-col-12 p-md-6">
            <label>Request<span style="color: red">*</span></label>
            <!-- <p-dropdown [options]="Requests" [(ngModel)]="selectedRequest" required="true" placeholder="Select Request" optionLabel="name"
              requiredMessage="The input must not be empty" (onChange)="requestChange($event.value)"
            ></p-dropdown> -->
            <p-autoComplete [(ngModel)]="selectedRequest" [suggestions]="filteredProdType" placeholder="Select Request" 
            (completeMethod)="filterproductListChange($event)" field="name" [dropdown]="true"  (onSelect)="requestChange()">
            <ng-template>
              <div>
                <div>{{ filteredProdType.name }}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div *ngIf="ShowDiv">
        <div class="p-grid p-formgrid">
          <div class="p-field p-col-12 p-md-2">
            <label>Request ID</label>
            <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Requested Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date"
              [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Requested By</label>
            <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Initiated By</label>
            <input type="text" pInputText [(ngModel)]="createdByName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Application</label>
            <input type="text" pInputText [(ngModel)]="applicationName" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Request Type</label>
            <input type="text" pInputText [(ngModel)]="type" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label>Short Description</label>
            <input type="text" pInputText [disabled]="true" placeholder="Short Description 50 characters only" required="true" requiredMessage="Short Description must not be empty"
              [(ngModel)]="title" maxlength=50/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Priority</label>
            <input type="text" pInputText [(ngModel)]="priority" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Status</label>
            <input type="text" pInputText [(ngModel)]="status" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Internal Status</label>
            <input type="text" pInputText [(ngModel)]="internalStatus" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Estimated Effort (Hours)</label>
            <input type="text" pInputText [(ngModel)]="estimatedHours" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual Effort (Hours)</label>
            <input type="text" pInputText [(ngModel)]="actualHours" type="number" [disabled]="true"  />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Progress Completed (%)</label>
            <input type="text" pInputText [(ngModel)]="progress" [disabled]="true" />
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Planned Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedStartDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Planned End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="plannedEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Current Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="currentStartDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Current End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="currentEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual Start Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="actualStartDate" [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Actual End Date</label>
            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="actualEndDate" [disabled]=true></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Request Owner</label>
            <p-dropdown [options]="users" placeholder="Select a Request owner" [(ngModel)]="owner" optionLabel="fullName"></p-dropdown>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-12" style="margin-left:auto;margin-right:auto;">
              <span class="p-input-icon-left" style="float:right; margin-right:5px;">
                  <button [disabled]="clicked" (click)="clicked = true;" pButton type="button" icon="pi pi-plus"
                  class="ui-button-info" style="float: right; width: 160px; margin-bottom:10px;" [label]="'Add New Task'" pAddRow [table]="dt" [newRow]="newRow()" 
                  ></button>
              </span>
        </div>
        <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;width:99%;">
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">Tasks</h5>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
              <p-table #dt [value]="docTypes" id="excel-table" styleClass="p-datatable-striped" sortMode="multiple" class="table-align" editMode="row" 
              [filterDelay]="0" selectionMode="multiple" dataKey="taskName">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th style="width: 60px;"></th>
                      <th style="width: 40px;">Seq</th>
                      <th>Task</th>
                      <th>Scope</th>
                      <th style="width:120px;">Estimated Hours</th>
                      <th style="width:120px;">Actual Hours</th>
                      <th style="width:120px;">Progress</th>
                      <th style="width:120px;">Status</th>
                      <!-- <th style="width:120px;">Target Start Date</th>
                      <th style="width:110px;">Target End Date</th> -->
                      <th style="width:150px;">Predecessor</th>
                      <th style="width:150px;">Assigned To</th>
                      <th style="width:110px;">Affect Allocation</th>
                      <th style="text-align: center; width:80px;">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
                  <tr [pEditableRow]="rowData" [style.background]="rowData.isEdit == 'Y' ? 'aquamarine' : rowData.isEdit == 'E' ? 'tan' : ''"
                  [pReorderableRow]="index">
                      <td style="text-align: center;">
                          <i class="pi pi-bars" pReorderableRowHandle  (handler)="updateSeq($event)" ></i>
                            <!-- {{index}} - {{rowData.taskSeq}} -->
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.taskSeq}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.taskSeq}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                              <input type="text" pInputText placeholder="Task Name" [(ngModel)]="taskName" maxlength=50/>
                              <small class="p-invalid">{{ taskNameError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">{{rowData.taskName}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                              <input type="text" pInputText placeholder="Scope" [(ngModel)]="scope" maxlength=500/>
                              <small class="p-invalid">{{ scopeError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">{{rowData.scope}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:120px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3" [(ngModel)]="taskestimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                              <small class="p-invalid">{{ hoursError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-inputNumber mode="decimal" [minFractionDigits]="0" maxlength="3"  (onInput)="updatedoctype()"
                            [(ngModel)]="rowData.estimatedHours" placeholder="Estimated Hours"></p-inputNumber>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.actualHours}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.actualHours}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.progressCompleted}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.progressCompleted}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width: 40px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">{{rowData.status}}</ng-template>
                          <ng-template pTemplate="output">{{rowData.status}}</ng-template>
                        </p-cellEditor>
                      </td>
                      <!-- <td style="text-align: center;width:120px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">--</ng-template>
                          <ng-template pTemplate="output">
                            <span *ngIf="rowData.startDate != '--' && rowData.startDate != null && rowData.cycleTime != 0">{{rowData.startDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="rowData.startDate == '--' || rowData.startDate == null || rowData.cycleTime == 0">--</span>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:110px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">--</ng-template>
                          <ng-template pTemplate="output">
                            <span *ngIf="rowData.dueDate != '--' && rowData.dueDate != null && rowData.cycleTime != 0">{{rowData.dueDate | date:'MM/dd/yyyy'}}</span>
                            <span *ngIf="rowData.dueDate == '--' || rowData.dueDate == null || rowData.cycleTime == 0">--</span>
                          </ng-template>
                        </p-cellEditor>
                      </td> -->
                      <td style="padding-left: 15px;width:150px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-multiSelect [options]="docTypes" [(ngModel)]="selectedPredecessor" placeholder="Select Predecessor" 
                            optionLabel="taskName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                            <small class="p-invalid">{{ PredecessorError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-multiSelect [options]="rowData.pData" [(ngModel)]="rowData.taskPredecessor" placeholder="Select Predecessor" 
                            optionLabel="predecessorName" class="text" (onChange)="updatedoctype()" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="padding-left: 15px;width:150px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-multiSelect [options]="users" [(ngModel)]="selectedAssignee" placeholder="Select Assignee" (onChange)="onRowEditSave(rowData)"
                            optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>  
                            <small class="p-invalid">{{ AssigneeError }}</small>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-multiSelect [options]="users" [(ngModel)]="rowData.taskAssignee" placeholder="Select Assignee" 
                            optionLabel="fullName" class="text" autoWidth="false" [style]="{'width':'95%'}"></p-multiSelect>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td style="text-align: center;width:110px;">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-checkbox binary="affectAllocation" [(ngModel)]="affectAllocation" [value]="affectAllocation"></p-checkbox>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <p-checkbox binary="rowData.affectAllocation" [(ngModel)]="rowData.affectAllocation" [value]="rowData.affectAllocation"></p-checkbox>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                        <td style="text-align:center;width:80px;">
                          <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" 
                          [disabled]="rowData.actualHours > 0"
                          (click)="onRowEditInit1(rowData)" style="height:25px; width:25px;"></button>&nbsp;&nbsp;&nbsp;
                          <button *ngIf="!editing" pButton type="button" icon="pi pi-minus" [disabled]="rowData.actualHours > 0"
                              class="ui-button-info" (click)="onRowEditInit(rowData)" style="height:25px; width:25px;"></button>
                          <!-- <button *ngIf="!editing && rowData.isEdit == 'D'" pButton type="button" icon="pi pi-undo"
                              class="ui-button-info" (click)="UndoDelete(rowData)" style="height:25px; width:25px;"></button> -->
                          <button *ngIf="editing" pButton type="button" pCancelEditableRow
                              icon="pi pi-times-circle" class="ui-button-danger" [disabled]="rowData.actualHours > 0"
                              (click)="onRowEditCancel(rowData)" style="height:25px; width:25px;"></button>
                        </td>
                    </tr>
                </ng-template>
        <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="11" style="text-align: left;">No records found...</td>
                    </tr>
                </ng-template>
            
            </p-table>
            </div>
        </div>
        <div class="p-col-12" style="float:right;margin-top: 20px; ">
          <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
          (click)="Update()" class="p-button-raised p-button-success p-mr-2 p-mb-2"
            style="width:auto; float:right;"></button>              
        </div>
        <div class="p-col-12" style="float: right;"><small class="p-invalid">{{UpdateError}}</small></div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>