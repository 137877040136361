<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" >
          <button pButton type="button" label="Back" iconPos="left" class="p-button-raised p-button-primary"  (click)="Back()"
            icon="pi pi-angle-left" style="margin-left:5px; width: 90px; height:35px;"></button>
        </div>
        <div class="p-field p-col-12 p-lg-6 p-md-12 p-sm-12" style="text-align: center;"> <h5>Request Details</h5></div>
        <div class="p-field p-col-12 p-lg-4 p-md-12 p-sm-12" style="float: right;" >
          <button pButton type="button" *ngIf="status == 'UAT' && canClose && !isUAT" (click)="UATOK()" label="Move to Production" pTooltip="Move to Production " tooltipPosition="right" icon="pi pi-check" 
          class="p-button-primary p-button-raised p-mr-2 p-mb-2" style="width:180px; height:25px;float: right; " ></button>
          <button pButton type="button" *ngIf="status == 'Production' && canClose && !isProduction" (click)="PRODUCTIONOK()" label="Ready to Close Request" pTooltip="OK" tooltipPosition="right" icon="pi pi-check" 
          class="p-button-primary p-button-raised p-mr-2 p-mb-2" style="width:280px; height:25px;float: right; " ></button>
          <!-- <button pButton type="button" (click)="SendClose()" *ngIf="(status == 'Production' || status == 'Ready to Close') && canClose" label="Close Request" iconPos="left" 
          class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2" icon="pi pi-times" style="width: 160px; height:35px;"></button>  -->
          <button pButton type="button" *ngIf="cancancel" (click)="SendCancel()" label="Cancel Request" pTooltip="Cancel" tooltipPosition="right" icon="pi pi-times" 
          class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2" style="width:180px; height:25px;float: right; " ></button>
        </div>
      </div>
      <p-confirmDialog key="confirm-cancel" header="Cancel Request" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmCancel"></p-confirmDialog>
      <p-confirmDialog key="confirm-close" header="Close Request" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmClose"></p-confirmDialog>
      <p-confirmDialog key="confirm-uat" header="Confirm UAT" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmUAT"></p-confirmDialog>
      <p-confirmDialog key="confirm-production" header="Confirm Production" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmProduction"></p-confirmDialog>
      <hr style="margin-top: 0px;" />    
      <div class="p-col-12" style="float: right;"><small class="p-invalid">{{Error}}</small></div>
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-2">
          <label>Request ID</label>
          <input type="text" pInputText [(ngModel)]="code" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedreqDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested By</label>
          <input type="text" pInputText [(ngModel)]="requestedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Application</label>
          <input type="text" pInputText [(ngModel)]="applicationName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Type</label>
          <input type="text" pInputText [(ngModel)]="type" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Priority</label>
          <input type="text" pInputText [(ngModel)]="priority" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed By</label>
          <input type="text" pInputText [(ngModel)]="confirmedbyName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="confirmedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
          <label>Confirmed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="confirmedremarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
          <label>Cancelled By</label>
          <input type="text" pInputText [(ngModel)]="cancelledByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Cancelled'">
          <label>Cancelled Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="cancelledDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Cancelled'">
          <label>Cancelled Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="cancelledRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
          <label>Closed By</label>
          <input type="text" pInputText [(ngModel)]="closedByName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed'">
          <label>Closed Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="closedDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="status == 'Closed'">
          <label>Closed Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="closedRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
          <label>UAT Approved By</label>
          <input type="text" pInputText [(ngModel)]="uATAcknowledgeName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isUAT">
          <label>UAT Approved Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="uATAcknowledgeDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="isUAT">
          <label>UAT Approved Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="uATAcknowledgeRemarks" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
          <label>Production Approved By</label>
          <input type="text" pInputText [(ngModel)]="productionAcknowledgeName" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="isProduction">
          <label>Production Approved Date</label>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="productionAcknowledgeDate" [disabled]="true"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-8" *ngIf="isProduction">
          <label>Production Approved Remarks</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="productionAcknowledgeRemarks" />
        </div>
        
        <div class="p-field p-col-12 p-md-2">
          <label>Status</label>
          <input type="text" pInputText [(ngModel)]="status" [disabled]="true" />
        </div>
        <!-- <div class="p-field p-col-12 p-md-2">
          <label>Target Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="--" [(ngModel)]="startDate" [disabled]="true"></p-calendar>
        </div> -->
        <!-- <div class="p-field p-col-12 p-md-2">
          <label>Actual Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="--" [(ngModel)]="selecteddueDate" [disabled]="true"></p-calendar>
        </div> -->
        <!-- <div class="p-field p-col-12 p-md-2">
          <label>Estimated Effort (Hours)</label>
          <input type="text" pInputText [(ngModel)]="estimatedHours" [disabled]="true" />
        </div> -->
        <!-- <div class="p-field p-col-12 p-md-2" *ngIf="status == 'Closed' || status == 'Production'">
          <label>Actual Effort (Hours)</label>
          <input type="text" pInputText [(ngModel)]="actualHours" [disabled]="true" />
        </div> -->
        <div class="p-field p-col-12 p-md-2">
          <label>Progress Completed (%)</label>
          <input type="text" pInputText [(ngModel)]="progress" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-8">
          <label>Short Description</label>
          <input type="text" pInputText [disabled]="true" [(ngModel)]="title"/>
          <br>
        </div>
      </div>
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="" [headerStyle]="{'width': '0px'}"><br></p-tabPanel>
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'350px'}" [readonly]="true" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip" *ngIf="ViewAddedDocuments.length > 0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                      {{rowData.fileName}} </a></span></td>
                <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Clarifications" leftIcon="pi pi-question" *ngIf="Clarification1.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtClarification1 [value]="Clarification1" [columns]="Clarification1Cols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedClarification1"
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th>Action</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align: center">
                            <button pButton type="button" *ngIf="!rowData.isAnswered" label="Reply"
                                (click)="ResponseCon(rowData.clarificationID,rowData.question,rowData.askedByName,'N')"
                                icon="pi pi-answer" iconPos="right"
                                class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
            <div class="card" *ngIf="addclar1">
              <div class="p-grid p-fluid">
                  <div class="p-col-12">
                      <h5 style="text-align:left;">{{askedByName}}: {{Question}}</h5>
                      <textarea rows="6" pInputTextarea id="popupquestion" [(ngModel)]="popupquestion"
                          placeholder="Enter response" required="true"
                          requiredMessage="please enter response." maxlength="1000" ng-trim="false"
                          style="width: 100%;"></textarea>
                      <small class="p-invalid">{{1000 - popupquestion.length}} characters are
                          remaining</small>
                      <br>
                      <small class="p-invalid">{{ QuestionError }}</small>
                  </div>
              </div>
              <div class="p-grid p-fluid" *ngIf="QButton">
                  <div class="p-col-12 p-md-9"> </div>
                  <div class="p-col-12 p-md-3">
                      <button pButton type="button" (click)="AddAnswer('N')" label="Continue"
                          pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus"
                          style="width:100px;height:25px;"
                          class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                      <button pButton type="button" (click)="QuestionClear('N')" label="Reset"
                          pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh"
                          style="width:100px;height:25px;"
                          class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                  </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Support Clarifications" leftIcon="pi pi-question" *ngIf="SClarification.length>0">
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content2" style="overflow-x: auto;">
              <p-table #dtSClarification [value]="SClarification" [columns]="Clarification1Cols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="SselectedClarification"> 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr><th style="text-align: center;width: 60px;">S.No</th>
                      <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th>Action</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ j + 1 }}</td>
                        <td *ngIf="i == 0" style="text-align: center">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</td>
                        <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 2" style="text-align: center">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: center"><span *ngIf="rowData.answer">{{ rowData[col.field] | date:'MM/dd/yyyy HH:mm' }}</span></td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: center">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align: center">
                            <button pButton type="button" *ngIf="!rowData.isAnswered" label="Reply"
                                (click)="ResponseCon(rowData.clarificationID,rowData.question,rowData.askedByName,'S')"
                                icon="pi pi-answer" iconPos="right"
                                class="p-button-outlined p-button-seconday p-mr-2 p-mb-2"></button>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
            <div class="card" *ngIf="addclarS">
              <div class="p-grid p-fluid">
                  <div class="p-col-12">
                      <h5 style="text-align:left;">{{askedByNameS}}: {{QuestionS}}</h5>
                      <textarea rows="6" pInputTextarea id="popupquestionS" [(ngModel)]="popupquestionS"
                          placeholder="Enter response" required="true"
                          requiredMessage="please enter response." maxlength="1000" ng-trim="false"
                          style="width: 100%;"></textarea>
                      <small class="p-invalid">{{1000 - popupquestionS.length}} characters are remaining</small>
                      <br>
                      <small class="p-invalid">{{ QuestionErrorS }}</small>
                  </div>
              </div>
              <div class="p-grid p-fluid" *ngIf="QButtonS">
                  <div class="p-col-12 p-md-9"> </div>
                  <div class="p-col-12 p-md-3">
                      <button pButton type="button" (click)="AddAnswer('S')" label="Continue"
                          pTooltip="Continue" tooltipPosition="right" icon="pi pi-plus"
                          style="width:100px;height:25px;"
                          class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                      <button pButton type="button" (click)="QuestionClear('S')" label="Reset"
                          pTooltip="Reset" tooltipPosition="right" icon="pi pi-refresh"
                          style="width:100px;height:25px;"
                          class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                  </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Inputs" leftIcon="pi pi-bars" *ngIf="Inputs.length>0 || canAddInputs">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;" *ngIf="canAddInputs">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddInputs()"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
              style="width:auto;float: right;"></button>
          </span>
          <div class="p-col-12">
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtInputs [value]="Inputs" [columns]="InputsCols" sortMode="multiple"
              styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" 
              > 
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                      <tr>
                          <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"   [ngStyle]="{'width': col.width}">{{ col.header }}<p-sortIcon [field]="col.field"
                          style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                          <th style="width: 150px;">Attachments</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center;width: 60px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 1" style="text-align: center;width: 110px;">{{ rowData[col.field] | date:'MM/dd/yyyy' }}</td>
                        <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 150px;">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:50%">
                          <p-editor [(ngModel)]="rowData[col.field]" [readonly]="true"><p-header hidden></p-header></p-editor>
                        </td>
                        <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:100px">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:25%">{{ rowData[col.field] }}</td>
                        <td *ngIf="i == 6" style="text-align: center;width:80px">{{ rowData[col.field] }}</td>
                      </ng-container>
                        <td style="text-align:left; padding-left:15px;width: 150px;">
                          <ul *ngFor="let temp of rowData.documents" class="tempul">
                              <li class="templi"><a (click)="DMSToken(temp.downloadFileName,temp.userFileName)" href="javascript:;"> {{temp.userFileName}} </a></li>
                          </ul>
                        </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                      <tr>
                          <td colspan="7" style="text-align: left;">No records found...</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
          <br><br>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="card" *ngIf="status != 'Saved' && status != 'Pending Confirmation' && status != 'Seek Clarification'">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5> 
      </div>
      <div class="p-field p-col-12" style="padding: 0px;">
        <div class="card" style="border:1px solid #eee;">
          <div class="widget-chat">
            <ul *ngFor="let note of notesList">
              <li class="message-from">
                <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.notesAddedByName }}</span>
                  <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                    note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                  </span>
                  <span style="font-size: 14px;padding-right: 10px;" *ngIf="note.dmsID > 0">{{ note.note }}</span>
                  <p-editor [(ngModel)]="note.note" [readonly]="true" *ngIf="note.dmsID == 0"><p-header hidden></p-header></p-editor>
                </div>
                <div *ngIf="note.dmsID > 0" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="DMSToken(note.downloadFileName,note.userFileName)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Download File">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                 border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-download"></i></span></a></div>
                <!-- <div *ngIf="note.dmsID == 0 && !note.sentMail && note.ok" style="background-color: #fff;border:none;cursor: pointer;"><a
                  (click)="SendMail(note.id)" class="p-button-raised p-button-info p-mr-2 p-mb-2" title="Send Mail">
                    <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                  border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                      <i class="pi pi-envelope"></i></span></a></div> -->
              </li>
            </ul>
            <div class="message-input p-field p-col-12 " *ngIf="status != 'Closed' && status != 'Cancelled' && NoteAccess">
              <!-- <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
              style="background-color: #eee;border:1px solid #bbb;" /> -->
              <p-editor [(ngModel)]="issueNote" [style]="{'height':'200px'}" placeholder="Write a note here" style="background-color: #eee;"></p-editor>
              <div class="card-header">
                <p-fileUpload #fubauto mode="basic" customUpload="true"
                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                  (uploadHandler)="onBasicUploadAuto($event)" auto="true" chooseLabel="Attach File"
                  pTooltip="Attach file"></p-fileUpload>
                <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px; width:120px;">
                  <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add Note"
                    class="p-button-raised p-button-info p-mr-2 p-mb-2" (click)="AddNote()">
                  </button>
                </span>
              </div>
            </div>
            <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>
<p-dialog header="Reason" [(visible)]="reasondisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <h5 style="text-align:center;">{{code}} : {{title}}</h5>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
          <div class="p-col-12 p-md-12 p-xl-12">
            <label>{{Action}} Comments <span style="color: red">*</span> </label>
              <textarea rows="5" pInputTextarea id="popupreason" [(ngModel)]="popupreason" placeholder="Enter Comments"
                  required="true" requiredMessage="please enter reason." maxlength="1000" ng-trim="false"
                  style="width: 100%;"></textarea>
              <small class="p-invalid">{{1000 - popupreason.length}} characters are remaining</small>
          </div>
          <div class="p-col-12 p-md-12 p-xl-12">
              <span style="float: right;">
                  <button pButton type="button" (click)="ReasonClear()" label="Clear" icon="pi pi-refresh"
                      pTooltip="Clear" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;"></button>
                  <button pButton type="button" (click)="AddReason()" label="Confirm" icon="pi pi-plus"
                      pTooltip="Confirm" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
              </span>
          </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
          <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ ReasonError }}</small></span>
      </div>
  </div>
</p-dialog>

<p-dialog header="Add Inputs" [(visible)]="addInputs" modal="modal" showEffect="fade" [style]="{ width: '80%' }">
  <hr /><br>
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
        <label>Description <span style="color: red">*</span> </label>
        <p-editor [(ngModel)]="popupdescription" [style]="{'height':'350px'}" placeholder="Enter Description"></p-editor>

        <!-- <textarea rows="3" pInputTextarea id="popupdescription" [(ngModel)]="popupdescription" placeholder="Enter Description"
            required="true" requiredMessage="please enter description." maxlength="1000" ng-trim="false"
            style="width: 100%;"></textarea> -->
        <!-- <small class="p-invalid">{{1000 - popupdescription.length}} characters are remaining</small> -->
    </div>
    <div class="p-col-12 p-md-12 p-xl-12">
      <form>
        <p-fileUpload #fileInput name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
          accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
            [showUploadButton]="false" [showCancelButton]="true" fileLimit="25" auto="true">
          <ng-template pTemplate="content">
            <ul *ngIf="fileInput.length">
              <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </form>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12">
        <span style="float: right;">
            <button pButton type="button" (click)="AddInput()" label="Add" icon="pi pi-plus" pTooltip="Add" tooltipPosition="right"
              class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
        </span>
    </div>
    <div class="p-col-12">
      <div class="invoice invoice-header" id="invoice-content5" style="overflow-x: auto;width: 100%;">
        <p-table [value]="TempInputs" sortMode="multiple"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" > 
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th style="width: 60px;">S.No</th>
                  <th>Description</th>
                  <th style="width: 250px;">Attachments</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                  <td style="text-align: center;width: 60px;">{{ rowData.seq }}</td>
                  <td style="text-align: left; padding-left:15px;">
                    <p-editor [(ngModel)]="rowData.note" [readonly]="true"><p-header hidden style="padding: 0px;"></p-header></p-editor></td>
                    <!-- {{ rowData.note }}</td> -->
                  <td style="text-align:left; padding-left:15px;width: 250px;">
                    <ul *ngFor="let temp of rowData.documents1" class="tempul">
                        <li class="templi">{{temp}}</li>
                    </ul>
                  </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12">
        <span style="float: right;">
            <button pButton type="button" (click)="MainInput()" label="Submit" icon="pi pi-plus" pTooltip="Submit" tooltipPosition="right"
              class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
        </span>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
        <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ InputError }}</small></span>
    </div>
  </div>
</p-dialog>