import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-client-requests',
  templateUrl: './client-requests.component.html',
  styleUrls: ['./client-requests.component.scss']
})
export class ClientRequestsComponent implements OnInit {

  userId = sessionStorage.getItem('userId');
  cols: any[];
  @ViewChild('dt') table: Table;
  showLoading: boolean;
  clientRequests: any;
  Count:any=0;
  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private datePipe: DatePipe
  ) { }


  ngOnInit(): void {
    this.showLoading = true;
    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.createdByName = "";
    sessionStorage.applicationName = "";

    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Request Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'createdByName', header: 'Initiated By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '20%' }
    ];
    this.getMyRequests();
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "createdByName")
      sessionStorage.createdByName = val;
    else if (field == "applicationName")
      sessionStorage.applicationName = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    this.Count = 0;
    if (this.clientRequests != undefined && this.clientRequests != null) {
      var temp = this.clientRequests;
      if (sessionStorage.code != "")
        temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.requestDate != "")
        temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toString().includes(sessionStorage.title.toString()));
      if (sessionStorage.type != "")
        temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
        temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
      if (sessionStorage.createdByName != "")
        temp = temp.filter(x => x.createdByName.toLowerCase().includes(sessionStorage.createdByName.toLowerCase()));
      if (sessionStorage.applicationName != "")
        temp = temp.filter(x => x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
      this.Count = temp.length;
    }
  }

  getMyRequests() {
    var status = [];
    status.push("Saved");
    var myRequestsData =
    {
      "type": [],
      "status": status,
      "application": [],
      "priority": [],
      "requestedBy": [],
      "assignTo": [],
      "internalStatus": [],
      "requestOwner": [],
      "userID": parseInt(this.userId),
      "includeInternal" : false
    }
    this._facadeService.MyRequests(myRequestsData).subscribe(
      (data) => {
        console.log(data);
        if (data.returnObject != null && data.returnObject.length > 0) {
          this.clientRequests = data.returnObject;
          if(this.clientRequests!=undefined && this.clientRequests != null && this.clientRequests.length > 0)
          {
            this.clientRequests.forEach((value, key) => {
              if(value.requestDate != '' && value.requestDate != null)
              value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
            });
          }

          this.CountData();
          this.showLoading = false;
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  ViewMyRequest(rowdata) {
    sessionStorage.requestID = rowdata.id;
    if(rowdata.createdBy == parseInt(this.userId))
      this.router.navigate(['/newrequest']);
    else
      this.router.navigate(['/requestDetails']);
  }

  exportExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 120 },{ wpx : 200 }];
    /* save to file */
    XLSX.writeFile(wb, "New Requests.xlsx");
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'New Requests','trSearch',true);
}

  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
      delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'requestDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  
    event.data.forEach(e => {
      e.requestDate = this.datePipe.transform(e.requestDate, 'MM/dd/yyyy');
    });
  }
}
