import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router } from '@angular/router';
import { SelectItem, SortEvent } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss']
})
export class MyRequestsComponent implements OnInit {
  userId = sessionStorage.getItem('userId');
  selectedFromDate: any = "";
  selectedToDate: any = "";
  apps: any[];
  selectedApplications: any;
  type: SelectItem[];
  selectedtype: any;
  priority: SelectItem[];
  selectedPriority: any;
  status: SelectItem[];
  selectedStatus: any;
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  DocumentsCount: number = 0;
  Error: string = "";
  requestData = [];

  constructor(
    private _facadeService: FacadeService, public printService: PrintcommonService, private router: Router, 
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    // this.selectedToDate = new Date();
    // let dte = new Date();
    // dte.setMonth(dte.getMonth() - 1);
    // this.selectedFromDate = new Date(dte.toString());
    this.type = [
      { label: 'Enhancement / Change', value: 'Enhancement / Change'},
      { label: 'Suggestion', value: 'Suggestion'},
      { label: 'Issue', value: 'Issue' },
      { label: 'Support', value: 'Support' }
    ];
    this.selectedtype = [];
    this.type.forEach(element => {
      this.selectedtype.push(element);
    });
    this.priority = [
      { label: 'Critical', value: 'Critical'},
      { label: 'High', value:'High' },
      { label: 'Medium', value: 'Medium'},
      { label: 'Low', value: 'Low' }
    ];
    this.selectedPriority = [];
    this.priority.forEach(element => {
      this.selectedPriority.push(element);
    });

    this.status = [
      { label: 'Saved', value: 'Saved' },
      { label: 'Pending Confirmation', value: 'Pending Confirmation' },
      { label: 'Seek Clarification', value: 'Seek Clarification' },
      { label: 'New', value: 'New' },
      { label: 'In-Progress', value: 'In-Progress' },
      { label: 'Support Seek Clarification', value: 'Support Seek Clarification' },
      { label: 'UAT', value: 'UAT' },
      { label: 'Ready for Production', value: 'Ready for Production' },
      { label: 'Production', value: 'Production' },
      { label: 'Ready to Close', value: 'Ready to Close' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Closed', value: 'Closed' },
      { label: 'Cancelled', value: 'Cancelled' },
    ];
    let ok = false;
    if(sessionStorage.getItem('dashboardstatus') != null && sessionStorage.getItem('dashboardstatus') != undefined)
    {
      if(sessionStorage.getItem('dashboardstatus') != 'null')
      {
        this.selectedStatus = [];
        var projectstats = [];
        projectstats = sessionStorage.getItem('dashboardstatus').split(',');
        projectstats.forEach((element) => {
          var st = this.status.find(x=>x.value.toLowerCase() == element.toLowerCase());
          if(st != null)
            this.selectedStatus.push(st);
          if(element == "Pending Confirmation")
          {
            var st = this.status.find(x=>x.value.toLowerCase() == "seek clarification");
            if(st != null)
              this.selectedStatus.push(st);
          }
        });
        ok = true;
        sessionStorage.dashboardstatus = null;
      }
    }
    if(!ok)
    {
      this.selectedStatus = [];
      this.status.forEach(e => {
        if(e.value != "Closed" && e.value != "Cancelled")
          this.selectedStatus.push(e);
      });
    }
    
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '180px' },
    ];

    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.priority = "";
    sessionStorage.applicationName = "";
    sessionStorage.status = "";
    this.getApplications();
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "requestDate")
      sessionStorage.requestDate = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "type")
      sessionStorage.type = val;
    else if (field == "priority")
      sessionStorage.priority = val;
    else if (field == "applicationName")
      sessionStorage.applicationName = val;
    else if (field == "status")
      sessionStorage.status = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.requestData != undefined && this.requestData != null) {
      var temp = this.requestData;
      if (this.requestData.length > 0) {
        if (sessionStorage.code != "")
          temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if (sessionStorage.requestDate != "")
          temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
        if (sessionStorage.title != "")
          temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
        if (sessionStorage.type != "")
          temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
        if (sessionStorage.priority != "")
          temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
        if (sessionStorage.applicationName != "")
          temp = temp.filter(x => x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
        if (sessionStorage.status != "")
          temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      }
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  getApplications() {
    this._facadeService.getApplications().subscribe(
      (data) => {
        this.selectedApplications = [];
        this.apps = data.returnObject;
        var all = this.apps.find(m => m.applicationName == "All");
        if(all == null || all == undefined)
        {
          var a = {
            "applicationName": "All",
            "applicationId": 0,
            "applicationGuid": "00000000-0000-0000-0000-000000000000",
            "active":"Y",
            "sequence": 0
            }
            this.apps.push(a);
        }
        var userapp = JSON.parse(sessionStorage.getItem('applications'))
        if(userapp != null && userapp != undefined)
        {
          var allapps = this.apps;
          this.apps = [];
          userapp.forEach(element => {
            var a = allapps.find(x=>x.applicationGuid.toLowerCase() == element.id.toLowerCase());
            if(a != null)
              this.apps.push(a);
          });
          this.apps.sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
          this.apps.forEach(element => {
            this.selectedApplications.push(element);
          });
        }
        this.getMyRequests();
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  getMyRequests() {
    this.Error = "";
    if (this.selectedApplications == null || this.selectedApplications == undefined || this.selectedApplications.length == 0)
      this.Error = "Please select atleast one application";
    else if (this.selectedtype == null || this.selectedtype == undefined || this.selectedtype.length == 0)
      this.Error = "Please select atleast one type";
    else if (this.selectedPriority == null || this.selectedPriority == undefined || this.selectedPriority.length == 0)
      this.Error = "Please select atleast one priority";
    else if (this.selectedStatus == null || this.selectedStatus == undefined || this.selectedStatus.length == 0)
      this.Error = "Please select atleast one status";
    else if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != ""
      && this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "" && this.selectedFromDate > this.selectedToDate) {
      this.Error = "End Date should be greater then start date";
      this.selectedToDate = this.selectedFromDate;
    }
    else {
      this.showLoading = true;
      var apps = [];
      if (this.selectedApplications != null && this.selectedApplications != undefined) {
        if (this.selectedApplications.length > 0) {
          this.selectedApplications.forEach(element => {
            apps.push(element.applicationId);
          });
        }
      }
      var types = [];
      if (this.selectedtype != null && this.selectedtype != undefined) {
        if (this.selectedtype.length > 0) {
          this.selectedtype.forEach(element => {
            types.push(element.value);
          });
        }
      }
      var priority = [];
      if (this.selectedPriority != null && this.selectedPriority != undefined) {
        if (this.selectedPriority.length > 0) {
          this.selectedPriority.forEach(element => {
            priority.push(element.value);
          });
        }
      }
      var status = [];
      if (this.selectedStatus != null && this.selectedStatus != undefined) {
        if (this.selectedStatus.length > 0) {
          this.selectedStatus.forEach(element => {
            status.push(element.value);
          });
        }
      }
      var myRequestsData =
      {
        "type": types,
        "status": status,
        "application": apps,
        "priority": priority,
        "requestedBy": [],
        "assignTo": [],
        "internalStatus": [],
        "requestOwner": [],
        "userID": parseInt(this.userId),
        "includeInternal" : false
      }
      this._facadeService.MyRequests(myRequestsData).subscribe(
        (data) => {
          this.requestData = data.returnObject;
          if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
          {
            this.requestData.forEach((value, key) => {              
              if(value.requestDate != '' && value.requestDate != null)
                value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
              if(value.dueDate != '' && value.dueDate != null)
                value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");   
            });

            if (this.selectedFromDate != null && this.selectedFromDate != undefined && this.selectedFromDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) >= new Date(this.selectedFromDate));
            }
            if (this.selectedToDate != null && this.selectedToDate != undefined && this.selectedToDate != "") {
              this.requestData = this.requestData.filter(x => new Date(x.requestDate) <= new Date(this.selectedToDate));
            }
          }
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
      
    }
  }

  ViewMyRequest(rowdata) {
    sessionStorage.From = "MyRequest";
    sessionStorage.requestID = rowdata.id;
    if(rowdata.status == "Saved" && rowdata.createdBy == parseInt(this.userId))
      this.router.navigate(['/newrequest']);
    else
      this.router.navigate(['/requestDetails']);
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'My Requests','trSearch',true);
  }
  exportExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 135},{ wpx : 60 },{ wpx : 135 }];
    // { wpx : 90 },
    /* save to file */
    XLSX.writeFile(wb, "My Requests.xlsx");
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'requestDate'||event.field === 'dueDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  
    event.data.forEach(e => {
      e.requestDate = this.datePipe.transform(e.requestDate, 'MM/dd/yyyy');
      e.dueDate = this.datePipe.transform(e.dueDate, 'MM/dd/yyyy');
    });
  }
}
