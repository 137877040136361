<div class="p-grid  p-fluid">
  <div class="p-col-12">
    <div class="card">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" >
              <a routerLink="/clientRequests"> <button pButton type="button" *ngIf="isClient"
                label="Back" iconPos="left" class="p-button-raised p-button-primary"
                icon="pi pi-angle-left" style="margin-left:5px ; height:35px;"></button> </a>
        </div>
        <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align: center">
          <h4 style="margin-top: 5px">{{ pageTitle }}</h4>
        </div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
      </div>
      <hr />
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-md-2">
          <label>Request ID</label>
          <input type="text" pInputText placeholder="Request ID" [(ngModel)]="code" [disabled]="true" />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested Date</label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="Select Date" [(ngModel)]="selectedreqDate" [maxDate]="today" [minDate]="lastmonth" ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="!isClient" style="margin-top: 34px;">
          <p-checkbox label="Is Internal" binary="true" inputId="binary" class="padding" (click)="UpdateCon('',false)"
          [(ngModel)]="internal" ></p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Requested By<span style="color: red">*</span></label>
          <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filteredProdType" placeholder="Select a Request User" 
          [disabled]="isClient" (completeMethod)="filterproductListChange($event)" field="fullName" [dropdown]="true">
          <ng-template>
            <div>
              <div>{{ filteredProdType.fullName }}</div>
            </div>
          </ng-template>
          </p-autoComplete>
          <small class="p-invalid">{{reqnameError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-2" *ngIf="!isClient" style="margin-top: 34px;">
          <p-checkbox label="Need Confirmation" binary="false" inputId="binary" class="padding" [disabled]="disableconfirm"
          [(ngModel)]="confirmation" ></p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Application<span style="color: red">*</span></label>
          <p-dropdown [options]="applications" placeholder="Select a Application" [(ngModel)]="selectedapplication" optionLabel="applicationName"></p-dropdown>
          <small class="p-invalid">{{applicationError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Request Type<span style="color: red">*</span></label>
          <p-dropdown [options]="type" placeholder="Select a Request Type" [(ngModel)]="selectedtype"  optionLabel="label"></p-dropdown>
          <small class="p-invalid">{{requestTypeError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label>Priority<span style="color: red">*</span></label>
          <p-dropdown [options]="priority" placeholder="Select a Priority" [(ngModel)]="selectedPriority" optionLabel="label"></p-dropdown>
          <small class="p-invalid">{{priorityError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-8">
          <label>Short Description<span style="color: red">*</span></label>
          <small class="p-invalid" style="float: right;">{{100 - title.length}} characters are remaining</small>
          <input type="text" pInputText placeholder="Short Description" id="title" required="true" requiredMessage="Short Description must not be empty"
            [(ngModel)]="title" maxlength=100/>
          <small class="p-invalid">{{titleError}}</small>
        </div>
        <div class="p-field p-col-12 p-md-12"><small class="p-invalid">{{responseMessage}}</small></div>
      </div>
    </div>
    <div class="card">
      <p-tabView [activeIndex]="index">
        <p-tabPanel header="Description" leftIcon="pi pi-align-left">
          <br>
          <p-editor [(ngModel)]="description" [style]="{'height':'350px'}" placeholder="Enter Description"></p-editor>
        </p-tabPanel>
        <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip">
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add" (click)="AddNewButton()"
              class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
              style="width:auto;float: right;"></button>
          </span>
          <span class="p-input-icon-left" style="float: right">
            <p-dialog header="Add Documents" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade"
              [style]="{ width: '60%' }">
              <hr /><br>
              <div class="p-grid">
                <div class="p-col-12 p-md-12 p-xl-12">
                  <form>
                    <p-fileUpload #fileInput name="file" multiple="multiple" customUpload="true" (uploadHandler)="onFileUpload($event)"
                      accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.zip,.rar,.eml" maxFileSize="20971520"
                       [showUploadButton]="false" [showCancelButton]="true"
                      fileLimit="25" auto="true">
                      <ng-template pTemplate="content">
                        <ul *ngIf="fileInput.length">
                          <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                        </ul>
                      </ng-template>
                    </p-fileUpload>
                  </form>
                </div>
                <div class="p-col-12" style="float: right;">
                  <button pButton type="button" label="Add" pTooltip="Add Docs" (click)="AddDocs()"
                    tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
                    class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                    style="width: 100px;height:25px; float: right;margin-left:120px;  margin-top: 20px;"></button>
                </div>
                <div class="p-col-12" style="float: right;"><small class="p-invalid">{{DocsError}}</small></div>
              </div>
            </p-dialog>
          </span>
          <div class="p-col-12">
            <div class="card-header">
              <h5 style="color:#fff;">Attachments</h5>
            </div>
            <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
              <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                      [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="p-selectable-row">
                    <td style="text-align: center"><span><a (click)="DMSToken(rowData.downloadFileName,rowData.userFileName)"  href="javascript:;">
                          {{rowData.fileName}} </a></span></td>
                    <td style="text-align: center"><span>{{rowData.addedbyName}}</span></td>
                    <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm'}}</span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" style="text-align: left;">No records found...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <br><br>
        </p-tabPanel>
      </p-tabView>
      <div class="p-field p-col-12">
        <div class="p-grid">
          <div class="p-col-12" style="float:right;">
            <hr>
            <span style="float:right;">
            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
            (click)="createDoc('')" class="p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width:auto;"></button>  
            <button *ngIf="isClient" pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save & Send" (click)="createDoc('saveAndSendApproval')"
              class="p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width:auto;"></button>
            <button pButton pRipple type="button" *ngIf="cancancel" (click)="SendCancel()" label="Cancel Request" pTooltip="Cancel" tooltipPosition="right" icon="pi pi-times" 
            class="p-button-primary p-button-raised p-button-danger p-mr-2 p-mb-2" style="width:auto;" ></button>
            <p-confirmDialog key="confirm-cancel" header="Cancel Request" acceptLabel="Yes" rejectLabel="No"
            [(visible)]="confirmCancel"></p-confirmDialog>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Save and Send" [(visible)]="saveAndSendApprovalDisplay" modal="modal"
  showEffect="fade" [style]="{ width: '60%' }">
  <hr />
  <div class="p-grid">
    <div class="p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
      <h5>{{ AreYouSureText }}</h5>
    </div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="Yes" icon="pi pi-check" iconPos="left"
          (click)="createDoc('confirm')" class="p-button-raised p-button-success"></button>
      </span>
    </div>
    <div class="p-col-12 p-md-2">
      <span class="p-input-icon-left" style="margin-right: 10px; margin-top: 8px">
        <button pButton type="button" label="No" icon="pi pi-times" iconPos="left"
          (click)="saveAndSendApprovalDisplay = false"
          class="p-button-raised p-button-secondary"></button>
      </span>
    </div>
  </div>
</p-dialog>
<div
  style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #000000; opacity: 0.5; z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>
<p-dialog header="Reason" [(visible)]="reasondisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <h5 style="text-align:center;">{{code}} : {{title}}</h5>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3.5rem;">
          <div class="p-col-12 p-md-12 p-xl-12">
              <label>Cancel Reason <span style="color: red">*</span> </label>
              <textarea rows="5" pInputTextarea id="popupreason" [(ngModel)]="popupreason" placeholder="Enter Reason"
                  required="true" requiredMessage="please enter reason." maxlength="1000" ng-trim="false"
                  style="width: 100%;"></textarea>
              <small class="p-invalid">{{1000 - popupreason.length}} characters are remaining</small>
          </div>
          <div class="p-col-12 p-md-12 p-xl-12">
              <span style="float: right;">
                  <button pButton type="button" (click)="ReasonClear()" label="Clear" icon="pi pi-refresh"
                      pTooltip="Clear" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-secondary p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;"></button>
                  <button pButton type="button" (click)="AddReason()" label="Confirm" icon="pi pi-plus"
                      pTooltip="Confirm" tooltipPosition="right"
                      class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                      style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
              </span>
          </div>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -3rem;text-align:center;">
          <span style="text-align: center;"> <small class="p-invalid" style="font-size:14px;">{{ ReasonError
                  }}</small></span>
      </div>
  </div>
</p-dialog>