import { Component, Inject,  OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import {ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FileUpload } from "primeng/fileupload";
import { HttpClient } from "@angular/common/http";
import { ConfirmationService } from "primeng/api";
import { TempInputs } from 'src/app/models/IssueDetails';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
})
export class RequestDetailsComponent implements OnInit {
  isUAT:boolean = false;
  isProduction:boolean = false;
  canAddInputs:boolean = false;
  @ViewChild("fileInput") fileInput: FileUpload;
  addInputs:boolean = false;
  InputError:string = "";
  popupdescription:string = "";
  NoteAccess:boolean = false;
  reasondisplay = false;
  cancancel: boolean = false;
  popupreason: string = "";
  ReasonError: string;
  confirmCancel = false;
  confirmClose = false;
  confirmUAT = false;
  confirmProduction = false;
  index:number=0;
  Error:string = "";
  canClose:boolean = false
  showLoading:boolean;
  requestID = parseInt(sessionStorage.getItem("requestID"));
  code:string="";
  requestedByName:string="";
  selectedreqDate: any;
  applicationName:any;
  type: any;
  priority: any;
  status:string;
  selecteddueDate:any;
  description: string = ""; 
  title:any;
  progress:any;
  confirmedbyName:string;
  confirmedDate:any;
  confirmedremarks: string = "";
  cancelledByName:string;
  cancelledDate:any;
  cancelledRemarks: string = "";
  closedByName:string;
  closedDate:any;
  closedRemarks: string = "";

  uATAcknowledgeName:string;
  uATAcknowledgeDate:any;
  uATAcknowledgeRemarks: string = "";

  productionAcknowledgeName:string;
  productionAcknowledgeDate:any;
  productionAcknowledgeRemarks: string = "";
  startDate:any;
  userId = sessionStorage.getItem('userId');
  cols: any[];  
  ViewAddedDocuments: any[] = [];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;

  Clarification1: any[] = [];
  selectedClarification1: any[];
  @ViewChild('dtClarification1') tableClarification1: Table;
  Clarification1Cols: any[];

  SClarification: any[] = [];
  SselectedClarification: any[];
  @ViewChild('dtSClarification') tableSClarification: Table;

  Inputs: any[] = [];
  @ViewChild('dtInputs') tableInputs: Table;
  InputsCols: any[];
  TempInputs:TempInputs[]=[];

  addclar1: boolean = false;
  Question: string;
  askedByName: string;
  QuestionError: string;
  popupquestion: string;
  CID: number;
  QButton: boolean = false;

  addclarS: boolean = false;
  QuestionS: string;
  askedByNameS: string;
  QuestionErrorS: string;
  popupquestionS: string;
  CIDS: number;
  QButtonS: boolean = false;

  Action : string = '';
  // Notes
  notesList: any[] = [];
  issueNote:string = "";
  DocsError:string="";

  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  apiResponse: any;
  @ViewChild("fubauto") fubauto: FileUpload;

  requestDetails: any;
  userFeature:boolean;
  
  constructor( private _facadeService: FacadeService,private router: Router,
    private http: HttpClient,private confirmation: ConfirmationService,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }
    Back() {
      var from = sessionStorage.getItem("From");
      if(from != null && from != undefined)
      {
        if(from != "")
        {
          if(from == "ClientDashboard")
            this.router.navigate(['/dashboard']);
          if(from == "MyRequest")
            this.router.navigate(['/myRequests']);
          if(from == "Pending")
            this.router.navigate(['/pendingConfirmationRequest',{ index: 2 }]);
          if(from == "Report")
            this.router.navigate(['/reportByTime']);
          if(from == "ReleaseReport")
            this.router.navigate(['/releasenotesreport']);
        }
        else
          this.router.navigate(['/myRequests']);
      }
      else
          this.router.navigate(['/myRequests']);
    }
  ngOnInit(): void {   
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PRT_NOACCESS");
        if(featureExist!=null)
          this.userFeature = true;
      }
    }
    // this.requestID = parseInt(this.route.snapshot.paramMap.get('requestID'));
    this.getRequestDetailsByID();
    this.cols = [
      { field: 'fileName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
      { field: 'addedbyName', header: 'Uploaded By', dynamicPlaceHolder: 'Search' },
      { field: 'addedDate', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }
    ];
    this.Clarification1Cols = [
      { field: 'askedDate', header: 'Sought Date' },
      { field: 'question', header: 'Clarification' },
      { field: 'askedByName', header: 'Sought By' },
      { field: 'answerDate', header: 'Response Date' },
      { field: 'answer', header: 'Response' },
      { field: 'answerByName', header: 'Response By' }
    ];
    this.InputsCols = [
      { field: 'seq', header: 'S.No', width: '60px' },
      { field: 'addedDate', header: 'Added Date', width: '110px' },
      { field: 'addedByName', header: 'Added By', width: '150px' },
      { field: 'note', header: 'Description', width: '50%' },
      { field: 'noteStatus', header: 'Status' , width: '100px'},
      { field: 'noteComment', header: 'Comment' , width: '25%'},
      { field: 'type', header: 'Type' , width: '80px'},
    ];
  }
  UATOK()
  {
    this.confirmation.confirm({
      key: "confirm-uat",
      message: "Are you sure this request is ready for Production?",
      accept: () => {
        this.confirmUAT = false;
        this.confirmClose = false;
        this.Action = "Move to Production";
        this.reasondisplay = true;
        this.popupreason = "Ready for Production";
        this.ReasonError = "";
      },
    });
    this.confirmUAT = false;
  }
  PRODUCTIONOK()
  {
    this.confirmation.confirm({
      key: "confirm-production",
      message: "Are you sure this request is ok and can be closed?",
      accept: () => {
        this.confirmProduction = false;
        this.confirmClose = false;
        this.Action = "Production Ok";
        this.reasondisplay = true;
        this.popupreason = "Can Close";
        this.ReasonError = "";
      },
    });
    this.confirmProduction = false;
  }
  SendMail(ID)
  {
    this.showLoading = true;
    this._facadeService.SendMail(ID).subscribe(
      (data) => {
        if (data.returnCode == 0) {
          this.showLoading = false;
          this.getRequestDetailsByID();
        } else {
          this.showLoading = false;
          this.Error = data.returnMessage;
        }
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
  ReasonClear() {
    this.ReasonError = "";
    this.popupreason = "";
    this.Action = "";
  }

  AddInputs() {
    this.addInputs = true;
    this.InputError = "";
    this.popupdescription = "";
    if (this.fileInput != undefined) {
      if (this.fileInput.files.length > 0) {
        this.fileInput.clear();
      }
    }
    this.TempInputs = [];
  }
  AddInput()
  { 
    this.showLoading = true;
    this.InputError = "";
    let ok = true;
    this.description = this.description.replace("<p>", ""); 
    this.description = this.description.replace("</p>", ""); 

    if (this.popupdescription == undefined || this.popupdescription == null || this.popupdescription == "") {
      ok = false;
      this.InputError = "Please enter description";
    }
    if(ok)
    {
      if(this.fileInput != undefined && this.fileInput != null)
      {
        if(this.fileInput.files.length > 0)
        {
          this.fileInput.files.forEach((file) => {
            let fileSize = file.size
            if (fileSize <= 10 && ok) 
            {
              this.InputError = "File size should be greater than 1KB";
              ok = false;
            }
          });
        }
      }
    }
    if (ok) 
    {
      this.popupdescription = this.popupdescription.replace("<p>", ""); 
      this.popupdescription = this.popupdescription.replace("</p>", ""); 
      var data : TempInputs = {
        "seq" : this.Inputs.length + this.TempInputs.length + 1,
        "note": this.popupdescription,
        "documents" : [],
        "documents1" : []
      }
      this.fileInput.files.forEach((file) => {
        data.documents.push(file);
        data.documents1.push(file.name);
      });
      this.TempInputs.push(data);
      this.addInputs = true;
      this.InputError = "";
      this.popupdescription = "";
      if (this.fileInput != undefined) {
        if (this.fileInput.files.length > 0) {
          this.fileInput.clear();
        }
      }
      this.showLoading = false;
    }
    else
      this.showLoading = false;
  }
MainInput()
{
  this.showLoading = true;
  this.InputError = "";
  if(this.TempInputs != null && this.TempInputs != undefined && this.TempInputs.length > 0)
  {
    var nofile = this.TempInputs.filter(x=> x.documents == null || x.documents == undefined || x.documents.length == 0);
    var withfile = this.TempInputs.filter(x=> x.documents != null && x.documents != undefined && x.documents.length > 0);
    var onlynotes = (withfile != null && withfile != undefined && withfile.length > 0) ? false : true;
    var a = false;
    if(nofile != null && nofile != undefined)
    {
      if(nofile.length > 0)
      {
        var NoFileInput = [];
        nofile.forEach(element => {
          var Model = {
            note:  element.note,
            seq : element.seq
          };
          NoFileInput.push(Model);
        });
        var FinalModel = {
          requestID:this.requestID,
          addedBy: parseInt(this.userId),
          type: this.status,
          onlyNotes: onlynotes,
          notes: NoFileInput
        };
        this._facadeService.addInputNoFile(FinalModel)
        .subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) 
            { 
              if(onlynotes)
              {
                this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT" || x.type == "Prodcution");
                if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                {
                  this.Inputs.forEach(value => {              
                    value.addedDate = new Date(value.addedDate+'Z');              
                  });
                }
                this.addInputs = false;
                this.TempInputs = [];
                this.showLoading = false;
              }
              else
              {
                if(withfile != null && withfile != undefined)
                {
                withfile.forEach((element,key) => {
                  const formData = new FormData();
                  formData.append("requestID", this.requestID.toString());
                  formData.append("applicationID", this.applicationID);
                  formData.append("departmentID", this.UserDepartmentID);
                  formData.append("addedBy", this.userId);
                  formData.append("type", this.status);
                  formData.append("note", element.note);
                  formData.append("seq", element.seq.toString());
                    element.documents.forEach((file) => {
                      formData.append("files", file);
                    });
                  if(withfile.length == (key + 1))   formData.append("last", "Y");  else   formData.append("last", "N");
                  this._facadeService.addInput(formData)
                  .subscribe(
                    (data) => {
                      this.apiResponse = data;
                      if (this.apiResponse.returnCode == 0) 
                      { 
                        if(withfile.length == (key + 1))
                        {
                          this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT" || x.type == "Prodcution");
                          if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                          {
                            this.Inputs.forEach((value, key) => {              
                              value.addedDate = new Date(value.addedDate+'Z');              
                            });
                          }
                          this.addInputs = false;
                          this.TempInputs = [];
                          this.showLoading = false;
                        }
                      }
                      else
                      {
                        this.InputError = this.apiResponse.returnMessage;
                        this.showLoading = false;
                      }
                    },
                    (error) => {
                      this.showLoading = false;
                      console.log(error);
                    }
                  );
                });
                }
              }
            }
            else
            {
              this.InputError = this.apiResponse.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else a = true;
    }
    else a = true;
    if(a)
    {
      if(withfile != null && withfile != undefined)
      {
      withfile.forEach((element,key) => {
        const formData = new FormData();
        formData.append("requestID", this.requestID.toString());
        formData.append("applicationID", this.applicationID);
        formData.append("departmentID", this.UserDepartmentID);
        formData.append("addedBy", this.userId);
        formData.append("type", this.status);
        formData.append("note", element.note);
        formData.append("seq", element.seq.toString());
          element.documents.forEach((file) => {
            formData.append("files", file);
          });
        if(withfile.length == (key + 1))   formData.append("last", "Y");  else   formData.append("last", "N");
        this._facadeService.addInput(formData)
        .subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) 
            { 
              if(withfile.length == (key + 1))
              {
                this.Inputs = this.apiResponse.returnObject.filter(x=> x.type == "UAT" || x.type == "Prodcution");
                if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
                {
                  this.Inputs.forEach((value, key) => {              
                    value.addedDate = new Date(value.addedDate+'Z');              
                  });
                }
                this.addInputs = false;
                this.TempInputs = [];
                this.showLoading = false;
              }
            }
            else
            {
              this.InputError = this.apiResponse.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      });
      }
    }
  }
  else
  {
    this.InputError = "Please add atleast one input";
    this.showLoading = false;
  }
}
  onFileUpload(event) {
    this.InputError = "";
    let fileSize = event.files[0].size;
    if (fileSize <= 10) {
     this.InputError = "File size should be greater than 1KB";
    }
  }
  SendCancel() {
    this.confirmation.confirm({
      key: "confirm-cancel",
      message: "Are you sure you want to cancel this request?",
      accept: () => {
        this.confirmCancel = false;
        this.reasondisplay = true;
        this.Action="Cancel";
        this.popupreason = "Cancel";
        this.ReasonError = "";
      },
    });
    this.confirmCancel = false;
  }
  SendClose() {
    this.confirmation.confirm({
      key: "confirm-close",
      message: "Are you sure you want to close this request?",
      accept: () => {
        this.confirmClose = false;
        this.Action = "Close";
        this.reasondisplay = true;
        this.popupreason = "Close";
        this.ReasonError = "";
      },
    });
    this.confirmClose = false;
  }
  AddReason() {
    this.showLoading = true;
    this.ReasonError = "";
    if (this.popupreason == undefined || this.popupreason == "") {
      this.ReasonError = "Please enter Reason.";
      this.showLoading = false;
    }
    if (this.Action == undefined || this.Action == "") {
      this.ReasonError = "Please select action.";
      this.showLoading = false;
    }
    if(this.popupreason != undefined && this.popupreason != "" && this.Action != undefined && this.Action != "" && this.ReasonError == "") 
    {
      var Model = {
        requestID:this.requestID,
        userID: parseInt(this.userId),
        remarks: this.popupreason,
      };
      if(this.Action == "Cancel")
      {
        this._facadeService.SendCancel(Model).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.reasondisplay = false;
              this.Action="";
              this.popupreason = "";
              this.ReasonError = "";
              // this.getRequestDetailsByID();
              this.showLoading = false;
              this.Back();
            } else {
              this.showLoading = false;
              this.ReasonError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
      else if(this.Action == "Close")
      {
        this._facadeService.SendClose(Model).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.reasondisplay = false;
              this.Action="";
              this.popupreason = "";
              this.ReasonError = "";
              // this.getRequestDetailsByID();
              this.showLoading = false;
              this.Back();
            } else {
              this.showLoading = false;
              this.ReasonError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
      else if(this.Action == "Move to Production")
      {
        this._facadeService.SendUAT(Model).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.reasondisplay = false;
              this.Action="";
              this.popupreason = "";
              this.ReasonError = "";
              this.getRequestDetailsByID();
              this.showLoading = false;
            } else {
              this.showLoading = false;
              this.ReasonError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
      else if(this.Action == "Production Ok")
      {
        this._facadeService.SendProduction(Model).subscribe(
          (data) => {
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.reasondisplay = false;
              this.Action="";
              this.popupreason = "";
              this.ReasonError = "";
              this.getRequestDetailsByID();
              this.showLoading = false;
            } else {
              this.showLoading = false;
              this.ReasonError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            console.log(error);
            this.showLoading = false;
          }
        );
      }
    }
    else {
      this.ReasonError = "Error occured, please referesh the page.";
      this.showLoading = false;
    }
  }

  getRequestDetailsByID(){
    this.showLoading = true;
    this._facadeService.getRequestDetailsByGUID(this.requestID).subscribe(
      (data) => {
        this.requestDetails = data.returnObject;                
        if(this.requestDetails!=null){  
          this.isUAT = this.requestDetails.isUAT;
          this.isProduction = this.requestDetails.isProduction;
          this.code = this.requestDetails.code;
          this.requestedByName = this.requestDetails.requestedByName;
          this.selectedreqDate = new Date(this.requestDetails.requestDate+'Z');
          this.applicationName = this.requestDetails.applicationName;
          if(this.requestDetails.status != 'Hold' && this.requestDetails.status != 'Ready for Production' && this.requestDetails.status != 'Production' && this.requestDetails.status != 'Ready to Close' && this.requestDetails.status != 'Closed' && this.requestDetails.status != 'Cancelled')
          {
            if(this.requestDetails.requestedBy == parseInt(this.userId))
              this.cancancel = true;
          }
          this.type = this.requestDetails.type;
          this.priority = this.requestDetails.priority;
          this.title = this.requestDetails.title;
          this.progress = this.requestDetails.progressCompleted;
          this.description = this.requestDetails.description;
          this.status = this.requestDetails.status;
          this.selecteddueDate = this.requestDetails.actualEndDate != null && this.requestDetails.actualEndDate != undefined ? new Date(this.requestDetails.actualEndDate+'Z') : null; 
          this.confirmedbyName = this.requestDetails.confirmedbyName;
          this.confirmedremarks = this.requestDetails.confirmedRemarks;
          this.confirmedDate =this.requestDetails.confirmedDate != null && this.requestDetails.confirmedDate != undefined ? new Date(this.requestDetails.confirmedDate+'Z') : null; 

          this.cancelledByName = this.requestDetails.cancelledByName;
          this.cancelledRemarks = this.requestDetails.cancelledRemarks;
          this.cancelledDate =this.requestDetails.cancelledDate != null && this.requestDetails.cancelledDate != undefined ? new Date(this.requestDetails.cancelledDate+'Z') : null; 

          this.closedByName = this.requestDetails.closedByName;
          this.closedRemarks = this.requestDetails.closedRemarks;
          this.closedDate = this.requestDetails.closedDate != null && this.requestDetails.closedDate != undefined ? new Date(this.requestDetails.closedDate+'Z') : null; 
       
          this.uATAcknowledgeName = this.requestDetails.uatAcknowledgeName;
          this.uATAcknowledgeRemarks = this.requestDetails.uatAcknowledgeRemarks;
          this.uATAcknowledgeDate = this.requestDetails.uatAcknowledgeDate != null && this.requestDetails.uatAcknowledgeDate != undefined ? new Date(this.requestDetails.uatAcknowledgeDate+'Z') : null; 
          
          this.productionAcknowledgeName = this.requestDetails.productionAcknowledgeName;
          this.productionAcknowledgeRemarks = this.requestDetails.productionAcknowledgeRemarks;
          this.productionAcknowledgeDate = this.requestDetails.productionAcknowledgeDate != null && this.requestDetails.productionAcknowledgeDate != undefined ? new Date(this.requestDetails.productionAcknowledgeDate+'Z') : null; 

          this.startDate = this.requestDetails.currentEndDate != null && this.requestDetails.currentEndDate != undefined ? new Date(this.requestDetails.currentEndDate+'Z') : null; 
          this.notesList = this.requestDetails.notes;
          if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
          {
            this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList.forEach(value => {  
                value.ok = false;   
                if(value.createdBy == parseInt(this.userId))
                  value.ok = true;         
                if(value.createdDate.toString().indexOf('Z') > 0)   
                  value.createdDate = new Date(value.createdDate);   
                else
                  value.createdDate = new Date(value.createdDate+'Z');              
              });
            }
          }
          this.ViewAddedDocuments = this.requestDetails.documents;
          if(this.ViewAddedDocuments!=undefined && this.ViewAddedDocuments != null && this.ViewAddedDocuments.length > 0)
          {
            this.ViewAddedDocuments.forEach(value => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }
          else
            this.ViewAddedDocuments = [];

          this.Inputs = this.requestDetails.inputs;
          if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
          {
            this.Inputs = this.Inputs.filter(x=> x.type == "UAT" || x.type == "Prodcution");
            if(this.Inputs!=undefined && this.Inputs != null && this.Inputs.length > 0)
            {
              this.Inputs.forEach(value => {              
                value.addedDate = new Date(value.addedDate+'Z');              
              });
            }
          }
          else
            this.Inputs = [];

          this.canAddInputs = false;
          this.canClose = false;
          if(this.requestDetails.requestedBy == parseInt(this.userId) || this.requestDetails.createdBy == parseInt(this.userId))
          {  
            this.canClose = true; this.NoteAccess = true; this.canAddInputs = true;
          }

          if(this.requestDetails.confirmedBy != null && this.requestDetails.confirmedBy != undefined && !this.NoteAccess)
          {
            if(this.requestDetails.confirmedBy == parseInt(this.userId))
            {
              this.NoteAccess = true; this.canAddInputs = true; this.canClose = true; 
            }
          }
          if(this.requestDetails.accountContact != null && this.requestDetails.accountContact != undefined && !this.NoteAccess)
          {
            if(this.requestDetails.accountContact.length > 0)
            {
              this.requestDetails.accountContact.forEach(value => {              
                if(value == parseInt(this.userId) && !this.NoteAccess)
                  this.NoteAccess = true; this.canAddInputs = true; this.canClose = true;          
              });
            }
          }
          if(this.canAddInputs)
          {
            if(this.status != "UAT" && this.status != "Production")
              this.canAddInputs = false;
          }

          if(!this.NoteAccess)
          {
            if(this.userFeature)
              this.NoteAccess = true;
          }
          if(this.NoteAccess)
          {
            if(this.status == "Saved" || this.status == "Pending Confirmation" || this.status == "Seek Clarification")
              this.NoteAccess = false;
          }
          this.Clarification1 = []; this.SClarification = [];
          var mainclare = this.requestDetails.clarifications;
          if(mainclare!=undefined && mainclare != null)
          {
            if(mainclare.length > 0)
            {
              mainclare.forEach(value => {
                if ( value.askedDate !== null &&  value.askedDate !== undefined )
                value.askedDate = new Date(new Date(value.askedDate).toDateString() + " " + new Date(value.askedDate).toTimeString() + " UTC");
                if ( value.answerDate !== null &&  value.answerDate !== undefined )
                value.answerDate = new Date(new Date(value.answerDate).toDateString() + " " + new Date(value.answerDate).toTimeString() + " UTC");
                if(!this.NoteAccess)
                {
                  if(value.answerBy == parseInt(this.userId))
                    this.NoteAccess = true;
                }
              });
              this.Clarification1 = mainclare.filter(x=>x.type != "Support");
              this.SClarification = mainclare.filter(x=>x.type == "Support");
            } 
          }
        }
        this.index = 1;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  ResponseCon(ID, Q, A,type) {
    if(type == 'S')
    {
      this.addclarS = true;
      this.QuestionS = Q;
      this.askedByNameS = A;
      this.CIDS = ID;
      if(this.requestDetails.confirmedBy == undefined || this.requestDetails.confirmedBy == null)
        this.requestDetails.confirmedBy = 0;
      if (this.requestDetails.requestedBy == parseInt(this.userId) || this.requestDetails.confirmedBy == parseInt(this.userId))
        this.QButtonS = true;
      else
      {
        this.QButtonS = false;
        if(this.requestDetails.accountContact != null && this.requestDetails.accountContact != undefined)
        {
          if(this.requestDetails.accountContact.length > 0)
          {
            this.requestDetails.accountContact.forEach(value => {              
              if(value == parseInt(this.userId) && !this.QButtonS)
                this.NoteAccess = true;             
            });
          }
        }
      }
      this.QuestionErrorS = "";
      this.popupquestionS = "";
    }
    else
    {
      this.addclar1 = true;
      this.Question = Q;
      this.askedByName = A;
      this.CID = ID;
      if (this.requestDetails.requestedBy == parseInt(this.userId) && (this.status == "Pending Confirmation" || this.status == "Seek Clarification"))
        this.QButton = true;
      else
        this.QButton = false;
      this.QuestionError = "";
      this.popupquestion = "";
    }
  }
  AddAnswer(type) {
    this.showLoading = true;
    var ok = false;
    var ClarifyModel;
    if(type == 'S')
    {
      this.QuestionErrorS = "";
      if (this.popupquestionS == undefined || this.popupquestionS == "") {
        this.QuestionErrorS = "Please enter Answer.";
        this.showLoading = false;
      }
      if (this.CIDS == undefined || this.CIDS == 0) {
        this.QuestionErrorS = "Error occured, please referesh the page.";
        this.showLoading = false;
      }
      if(this.popupquestionS != undefined && this.popupquestionS != "" && this.CIDS != undefined && this.CIDS != 0 && this.QuestionErrorS == "")
      {
        ClarifyModel = {
          clarificationID: this.CIDS,
          answer: this.popupquestionS,
          answerBy: parseInt(this.userId)
        };
        ok = true;
      }
    }
    else
    {
      this.QuestionError = "";
      if (this.popupquestion == undefined || this.popupquestion == "") {
        this.QuestionError = "Please enter Answer.";
        this.showLoading = false;
      }
      if (this.CID == undefined || this.CID == 0) {
        this.QuestionError = "Error occured, please referesh the page.";
        this.showLoading = false;
      }
      if(this.popupquestion != undefined && this.popupquestion != "" && this.CID != undefined && this.CID != 0 && this.QuestionError == "")
      {
        ClarifyModel = {
          clarificationID: this.CID,
          answer: this.popupquestion,
          answerBy: parseInt(this.userId)
        };
        ok = true;
      }
    }
    if(ok)
    {
      this._facadeService.AddAnswer(ClarifyModel).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            location.reload();
          } else {
            this.showLoading = false;
            if(type == 'S')
              this.QuestionErrorS = this.apiResponse.returnMessage;
            else
              this.QuestionError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  QuestionClear(type) {
    if(type == 'S')
    {
      this.addclarS = false;
      this.QuestionS = "";
      this.QuestionErrorS = "";
      this.popupquestionS = "";
      this.CIDS = 0;
    }
    else
    {
      this.addclar1 = false;
      this.Question = "";
      this.QuestionError = "";
      this.popupquestion = "";
      this.CID = 0;
    }
  }

AddNote(){
  this.DocsError = "";
  if(this.issueNote != null && this.issueNote != undefined) 
  {
    if(this.issueNote!= '')
    { 
      this.issueNote = this.issueNote.replace("<p>", ""); 
      this.issueNote = this.issueNote.replace("</p>", "");
      this.showLoading = true;
      var model = {
        "requestID":this.requestID,
        "note":this.issueNote,
        "displayToCustomer" : true,
        "addedBy":parseInt(this.userId)
      };
      this._facadeService.AddNote(model).subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
              if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
              {
                this.notesList.forEach(value => {     
                  value.ok = false;   
                  if(value.createdBy == parseInt(this.userId))
                    value.ok = true;      
                  if(value.createdDate.toString().indexOf('Z') > 0)   
                    value.createdDate = new Date(value.createdDate);   
                  else
                    value.createdDate = new Date(value.createdDate+'Z');              
                });
              }
            }
            this.issueNote = '';
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.DocsError = "Please enter note";
  }
  else
    this.DocsError = "Please enter note";
}

onBasicUploadAuto(event){
  this.showLoading = true;
  this.DocsError = "";
   let fileSize = event.files[0].size;
  if (fileSize <= 10)
  {
    this.DocsError = "File size should be greater than 1KB";
    this.showLoading = false;
  }
  else
  {
    const formData = new FormData();
    formData.append("requestID", this.requestID.toString());
    formData.append("applicationID", this.applicationID);
    formData.append("departmentID", this.UserDepartmentID);
    formData.append("addedBy", this.userId);
    formData.append("displayToCustomer", "true");
    if (event != undefined) {
      if (event.files.length > 0) {
        event.files.forEach((file) => {
          formData.append("files", file);
        });
      } else formData.append("files", JSON.stringify([]));
    } else formData.append("files", JSON.stringify([]));
    console.log(formData);
    this._facadeService.addADocuments(formData)
      .subscribe(
        (data) => {
          console.log(data);
          this.apiResponse = data;
          if (this.apiResponse.returnCode == 0) {
            this.showLoading = false;
            if (this.fubauto != undefined) {
              if (this.fubauto.files.length > 0) {
                this.fubauto.clear();
              }
            }
            this.notesList =  this.apiResponse.returnObject;
            if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
            {
              this.notesList =  this.notesList.filter (x=>x.displayToCustomer);
              if(this.notesList!=undefined && this.notesList != null && this.notesList.length > 0)
              {
                this.notesList.forEach(value => {   
                  value.ok = false;   
                  if(value.createdBy == parseInt(this.userId))
                    value.ok = true;        
                  if(value.createdDate.toString().indexOf('Z') > 0)   
                    value.createdDate = new Date(value.createdDate);   
                  else
                    value.createdDate = new Date(value.createdDate+'Z');              
                });
              }
            }
            this.showLoading = false;
          } else {
            this.showLoading = false;
            this.DocsError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  }
  DMSToken(filename,userfilename){
    this.showLoading = true;
    var newname = userfilename.substr(0, userfilename.lastIndexOf('.'));
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    });
  }

  }

  