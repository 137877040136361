import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';
import { DashboardCount } from 'src/app/models/IssueDetails';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  AllProductsValue:boolean=false;
  showLoading:boolean;
  userId = sessionStorage.getItem('userId');
  clientId= sessionStorage.getItem('clientId');
  Counts: DashboardCount;
  isChartDataAvailable:boolean;
  statusChart:any;
  options: any;
  labels: number[] = [];
  priorityChartlabels: number[] = [];
  isPriorityChartDataAvailable:boolean;
  priorityChart:any;
  typeChartdata:any[]=[];

  typeChartlabels: number[] = [];
  istypeChartDataAvailable:boolean;
  typeChart:any;

  TimelineOptions: any;
  selectedTimelineOption: any;
  isTimelineDataAvailable:boolean;
  Timeline: any = [];
  requestData:any;
  DocumentsCount:any;
  data:any[]=[];
  priorityChartdata:any[]=[];

  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,private router: Router, private sanitizer: DomSanitizer,private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.TimelineOptions = [
      { name: 'Week',value: 7 },
      { name: '2 Weeks',value: 14 },
      { name: '3 Weeks',value: 21 },
      { name: 'Month',value: 30 },
    ];
    this.AllProductsValue = false;
    if(sessionStorage.allproducts != undefined && sessionStorage.allproducts != null)
        this.AllProductsValue = sessionStorage.allproducts == "true" ? true : false;
    this.getCounts();
    this.getChartData();
    this.getPriorityChartData();
    this.gettypeChartData();
    this.cols = [
      { field: 'code', header: 'Request ID', dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'requestDate', header: 'Requested Date', dynamicPlaceHolder: 'Search', width: '140px' },
      { field: 'title', header: 'Short Description', dynamicPlaceHolder: 'Search', width: '40%' },
      { field: 'type', header: 'Request Type', dynamicPlaceHolder: 'Search', width: '180px' },
      { field: 'requestedByName', header: 'Request By', dynamicPlaceHolder: 'Search', width: '150px' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search', width: '100px' },
      // { field: 'dueDate', header: 'Target Date', dynamicPlaceHolder: 'Search', width: '120px' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search', width: '180px' },
    ];
    sessionStorage.code = "";
    sessionStorage.requestDate = "";
    sessionStorage.title = "";
    sessionStorage.type = "";
    sessionStorage.requestedByName = "";
    sessionStorage.priority = "";
    sessionStorage.dueDate = "";
    sessionStorage.status = "";
    this.getDashboardTableData();
    this.getDashboardTimeline(7);
}

getCounts() {
  this.showLoading = true;
  this._facadeService.getDashboardCounts(this.userId).subscribe(
    (data) => {
      this.Counts = data.returnObject;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}

getChartData() {
  this.showLoading = true;
  this._facadeService.getChartData(this.userId).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.labels.push(res.returnObject[i].status);
      }
      this.data = [];
      for (let i = 0; i < res.returnObject.length; i++) {
        this.data.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.statusChart = {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              '#ff832b',
              '#f1c21b',
              '#0043ce',
              '#da1e28',
              '#6f6f6f',
              '#24a148',
              '#ba4e00',
              '#8a3ffc',
              '#8e6a00',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
gettypeChartData() {
  this.showLoading = true;
  this._facadeService.getTypeChartData(this.userId).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.typeChartlabels.push(res.returnObject[i].status);
      }
      for (let i = 0; i < res.returnObject.length; i++) {
        this.typeChartdata.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.istypeChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.typeChart = {
        labels: this.typeChartlabels,
        datasets: [
          {
            data: this.typeChartdata,
            backgroundColor: [
              '#FBC02D',
              '#2196F3',
              '#689F38',
              '#FF0000',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

getPriorityChartData() {
  this.showLoading = true;
  this._facadeService.getPriorityChartData(this.userId,false).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartlabels.push(res.returnObject[i].status);
      }
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartdata.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isPriorityChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.priorityChart = {
        labels: this.priorityChartlabels,
        datasets: [
          {
            data: this.priorityChartdata,
            backgroundColor: [
              '#990000',
              '#FF0000',
              '#ff9933',
              '#66cc33',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
getDashboardTimeline(type) {
  this.showLoading = true;
    this._facadeService.getDashboardTimeline(this.userId, type, parseInt(this.clientId)).subscribe(
    (data) => {
      this.showLoading = false;
      this.Timeline = data.returnObject;  
      if( this.Timeline.length>0) {  
        this.isTimelineDataAvailable = true;   
      var dict = []; // create an empty dictionary  
      for (let i = 0; i < this.Timeline.length; i++) { 
        let convertactiondate = new Date(this.Timeline[i].actionDate+'Z');  
        dict.push({
          requestID: this.Timeline[i].requestID,
          title: this.Timeline[i].title,
          icon: this.Timeline[i].icon,
          subTitleMod: this.sanitizer.bypassSecurityTrustHtml("The Request " + '<a style="cursor:pointer;">' + this.Timeline[i].requestname+ '</a>' +" "+this.Timeline[i].subTitle+ " by "+ this.Timeline[i].actionByUserName + " on " +convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString())  
      });        
      }        
      //console.log("Time Line subtitle modified data Data :: " + JSON.stringify(dict));
      this.Timeline = dict;  
    } 
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'Active Requests','trSearch',true);
}
exportExcel() {
  /* table id is passed over here */
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 80 },{ wpx : 90 },{ wpx : 400 },{ wpx : 135},{ wpx : 135},{ wpx : 60 },{ wpx : 135 }];
  // ,{ wpx : 90 }
  /* save to file */
  XLSX.writeFile(wb, "Active Requests.xlsx");
}
delete_row(ws, row_index) {
  let range = XLSX.utils.decode_range(ws["!ref"])
  for (var R = row_index; R < range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
      if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
        ws[this.ec(R + 1, C)].v = "";
    }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({ r: r, c: c })
}


getDashboardTableData() {
  this.requestData=[];
  this.showLoading = true;
  sessionStorage.allproducts = this.AllProductsValue;
  if(this.AllProductsValue)
  {
    var status = [];
    // status.push("Saved");
    status.push("Pending Confirmation");
    status.push("Seek Clarification");
    status.push("New");
    status.push("In-Progress");
    status.push("UAT");
    status.push("Ready for Production");
    status.push("Production");
    status.push("Ready to Close");

    var myRequestsData =
    {
      "type": [],
      "status": status,
      "application": [],
      "priority": [],
      "requestedBy": [],
      "assignTo": [],
      "internalStatus": [],
      "requestOwner": [],
      "userGUID": "",
      "includeInternal" : false
    }
    this._facadeService.MyRequests(myRequestsData).subscribe(
      (data) => {
        this.requestData = data.returnObject;
        if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
        {
          this.requestData.forEach((value, key) => {   
            if(value.requestDate != '' && value.requestDate != null)
            value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
            if(value.dueDate != '' && value.dueDate != null)
            value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");
          });
        }
        this.CountData();
        this.showLoading = false;
        },
        (error) => {  console.log(error); this.showLoading = false;  }
    );
  }
  else
  {
    this._facadeService.GetDashboardTableData(this.userId).subscribe(
      (data) => {
        this.requestData = data.returnObject;
        if(this.requestData!=undefined && this.requestData != null && this.requestData.length > 0)
        {
          this.requestData.forEach((value, key) => {   
            if(value.requestDate != '' && value.requestDate != null)
            value.requestDate = new Date(new Date(value.requestDate).toDateString() + " " + new Date(value.requestDate).toTimeString() + " UTC");
            if(value.dueDate != '' && value.dueDate != null)
            value.dueDate = new Date(new Date(value.dueDate).toDateString() + " " + new Date(value.dueDate).toTimeString() + " UTC");
          });
        }
        this.CountData();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
test(val, field, dt) {
  if (field == "code")
    sessionStorage.code = val;
  else if (field == "requestDate")
    sessionStorage.requestDate = val;
  else if (field == "title")
    sessionStorage.title = val;
  else if (field == "type")
    sessionStorage.type = val;
  else if (field == "requestedByName")
    sessionStorage.requestedByName = val;
  else if (field == "priority")
    sessionStorage.priority = val;
  else if (field == "status")
    sessionStorage.status = val;
  dt = dt.filter(val, field, 'contains');
  this.CountData();
}

CountData() {
  if (this.requestData != undefined && this.requestData != null) {
    var temp = this.requestData;
    if (this.requestData.length > 0) {
      if (sessionStorage.code != "")
        temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.requestDate != "")
        temp = temp.filter(x => x.requestDate.toLowerCase().includes(sessionStorage.requestDate.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.type != "")
        temp = temp.filter(x => x.type.toLowerCase().includes(sessionStorage.type.toLowerCase()));
      if (sessionStorage.priority != "")
        temp = temp.filter(x => x.priority.toLowerCase().includes(sessionStorage.priority.toLowerCase()));
      if (sessionStorage.requestedByName != "")
        temp = temp.filter(x => x.requestedByName.toLowerCase().includes(sessionStorage.requestedByName.toLowerCase()));
      if (sessionStorage.status != "")
        temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    }
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
ViewMyRequest(rowdata,status,createdBy) {
  sessionStorage.requestID = rowdata;
  sessionStorage.From = "ClientDashboard";
  if(status == "Saved" && createdBy == parseInt(this.userId))
    this.router.navigate(['/newrequest']);
  else
    this.router.navigate(['/requestDetails']);
}
// download() {
//   /* table id is passed over here */   
//   let element = document.getElementById('excel-table'); 
//   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//   /* generate workbook and add the worksheet */
//   const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   ws['!cols'] = [{ wpx : 250 },{ wpx : 300 },{ wpx : 500 },{ wpx : 150 },{ wpx : 150 },{ wpx : 150 },{ wpx : 80 },{ wpx : 80 }];
//   this.delete_row(wb.Sheets.Sheet1, 1);
//   /* save to file */
//   XLSX.writeFile(wb, "Active Products.xlsx");
// }


Request(all,status,val){
  if(!all)
  {
    if(status != "Confirmation")
    {
      sessionStorage.dashboardstatus = status;
      this.router.navigate(['/myRequests',{ index: val }]);    
    }
    else
      this.router.navigate(['/pendingConfirmationRequest',{ index: val }]);    
  }
  else{
    sessionStorage.dashboardstatus = status;
    sessionStorage.FromDash = 'true';
    this.router.navigate(['/reportByTime',{ index: val }]);    
  }
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'requestDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.requestDate = this.datePipe.transform( e.requestDate, 'MM/dd/yyyy');
  });
}

}
